//Candidate Chat Page
//Candiate chat and Contact form Details
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { SpeechRecognitionService } from "../CV_Service.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { HttpserviceService } from "../Services/httpservice.service";
// import { NgScrollbar } from 'ngx-scrollbar';
import { TranslateService } from "@ngx-translate/core";
import { EncryptionService } from "../Services/encryption.service";
import {
  ScrollToService,
  ScrollToConfigOptions,
} from "@nicky-lenaers/ngx-scroll-to";
import { DeviceDetectorService } from "ngx-device-detector";
import { map, startWith } from "rxjs/operators";
import { AlertComponent } from "../Common_Alert/Common_Alert.component";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatSnackBar,
} from "@angular/material";
import { urlEnv } from "../../environments/env";

interface IWindow extends Window {
  webkitSpeechRecognition: any;
  SpeechRecognition: any;
}
/**
 * Page for Candidate Chat Bot,User Can Chat give a info to us
 */

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: "app-my-account",
  templateUrl: "./Candidate_Chat.component.html",
  styleUrls: ["./Candidate_Chat.component.css"],
})
export class MyAccountComponent implements OnInit {
  /** variable desc
   * Color Code Varibale initialised
   */
  public logonUrl = urlEnv;
  languagedata: any;
  currentlanguage: any;
  windowScrolled: boolean;
  JobCategoryColor: any;
  compentenceColor: any;
  SkillsColor: any;
  EducationColor: any;
  CarrerColor: any;
  professionColor: any;

  getAdioData: any;
  getAccessTokenForChat: any;
  speechData: any;
  userMessageData: any;
  msgData = false;
  public obj: any;
  public obj2: any;
  public adminData: any;
  public adminDataWithId: any;
  public adminDataMsg: any;
  jobTitle: any;
  mikeIconGif = false;
  mikeIcon = true;
  adminDataId: any;
  AdminData: any;
  questionData = [];
  answerData = [];
  admintypingGIF = false;
  emptyMsg = false;
  adminMsg: any;
  // getTLIGETQUESTION_URLFlag = false;
  percentage = 0;
  loadingPercentage = 0;
  TLIQuestionChat = false;
  //   shrinkHeader = false;

  /**
   * Page Div Condition starts here
   */
  /**
   * @ignore
   */

  headerChatDiv = true;
  msgSendDiv = true;
  chatDiv = true;
  thankyouBtnDiv = false;
  ContactDiv = false;
  avatorIconDiv = false;
  //Page Div Condition Ends here
  speechRecognition: any;
  responseWait = false;

  TLIGETQUESTION_URL = false;
  simpleChat = true;
  EasyApply: boolean;
  directApply: boolean;

  ContactForm: FormGroup;
  EmptyErr: boolean;
  emailverify: boolean = true;
  ValidErr: boolean;
  profileReport: any;
  contentProfile: any;
  colorCode: any;
  accesstoken: any;
  compeYellow = false;
  compeRed = false;
  compeGreen = false;
  jobcateFlag = false;
  skillFlag = false;
  compentenceFlag = false;
  eduFlag = false;
  professionFlag = false;
  personalFlag = false;
  getEmailId: any;
  getMobileNumber = new FormControl("", [Validators.required]);
  getpostalcode = new FormControl("", [Validators.required]);
  setcvData: any;
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);

  colorCodearr: any;
  thankyouBtn1 = false;

  avatorSpeaking: boolean;
  feedBackFlag1: any;
  matchReportFlag1: any;
  companyData: any;
  companyDataLogo: any;
  Logo: object;
  companyName: any;
  welcomeText: any;
  openTermsConditionsCV: any;
  param1: any;
  param2: any;
  response: any;
  countryListValues: any;
  countriesValue: any;
  country: any;
  countryCtrl = new FormControl();

  /**
   * @ignore
   */

  constructor(
    public encrypt: EncryptionService,
    private activatedRoute: ActivatedRoute,
    private speechRecognitionService: SpeechRecognitionService,
    private toastr: ToastrService,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    public http: HttpserviceService,
    private router: Router,
    private _scrollToService: ScrollToService,
    private deviceService: DeviceDetectorService
  ) {
    this.speechData = "";
    var lang = localStorage.getItem("language");

    translate.addLangs(["en", "de"]);

    if (lang) {
      translate.setDefaultLang(lang);

      translate.use(lang);
      this.currentlanguage = lang;
    } else {
      translate.setDefaultLang("de");

      translate.use("de");
      this.currentlanguage = "de";
    }
    this.countryListValues = this.countryCtrl.valueChanges.pipe(
      startWith(""),
      map((country) =>
        country ? this._filterCountry(country) : this.countriesValue
      )
    );
  }

  @ViewChild("scroll", { read: ElementRef }) public scroll: ElementRef<any>;

  public scrollBottom() {
    // console.log(this.scroll.nativeElement.scrollTop)
    // console.log(this.scroll.nativeElement.scrollHeight)
    this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
  }
  scrHeight: any;
  scrWidth: any;
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  /**  function desc
   * Init Function Starts here
   */

  deviceInfo = null;
  JobTitleCandidate: any;
  blueCollar: any;
  ngOnInit() {
    this.getlanguagevalue();
    this.getScreenSize();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();

    console.debug("InitCandidateChat");
    var old = localStorage.getItem("OldUrl");
    if (old == "true") {
      console.debug("old");
      this.activatedRoute.queryParams.subscribe((params) => {
        this.param1 = params["dt"];
        this.param2 = params["type"];
        if (this.param1 == undefined || this.param2 == undefined) {
          this.router.navigate([""]);
        }
      });
    }

    this.simpleChat = true;
    this.TLIGETQUESTION_URL = false;
    this.avatorIconDiv = false;
    
    var lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "de";
    console.debug("TEST");
    this.get_countrycode();
    console.debug("TEST");

    let type = localStorage.getItem("applytype");

    if (type == "directapply") {
      this.directApply = true;
    } else {
      this.EasyApply = true;
    }
    this.blueCollar = sessionStorage.getItem("UrlBluecollar");
    console.log("BlueCollar?", this.blueCollar);
    this.companyDetails();
    this.feedBackFlag1 = sessionStorage.getItem("UrlDecryptedFeedBack");
    this.matchReportFlag1 = sessionStorage.getItem("UrlDecryptedFeedBack");
    this.accesstoken = JSON.parse(localStorage.getItem("token"));
    let adid = sessionStorage.getItem("UrlDecryptedAdId");
    let chat_caseid = sessionStorage.getItem("UrlChatCaseId");
    let local_chat = sessionStorage.getItem("LocalChat")

    this.obj = {};

    this.obj.custom_profile_id = "";
    this.obj.ad_id = adid;
    this.obj.id = "";
    this.obj.language = lang;
    this.obj.answer = "";
    this.obj.doc_type = "CV";
    this.obj.order_id = "";
    this.obj.secret = "";

    if (this.blueCollar == "true") {
      this.obj.chat_keywords = "1";
    } else {
      this.obj.chat_keywords = "0";
    }
    if (type == "directapply") {
      this.obj.chat_case = chat_caseid;
    } else {
      this.obj.chat_case = "20012";
    }

    if (local_chat && local_chat == "true")
    {
      this.obj.local_chat=true;
    }
    else
    {
      this.obj.local_chat=false;
    }


    this.speechRecognitionService.getSimpleChat(this.obj).subscribe(
      (data) => {
        if (data.status == 200) {
          this.adminData = data;
          this.adminDataId = data.res.id;
          this.adminMsg = data.res.question;

          var dataParams = {
            text: this.adminMsg,
          };
          var initialised = false;
          this.http
            .commonMethod(dataParams, this.logonUrl.text_to_voice)
            .subscribe((response) => {
              this.getAdioData = response;
              if (this.getAdioData.status == 200) {
                console.log("audioConvert", this.getAdioData);
                // let audio = new Audio();
                let audio = <HTMLVideoElement>(
                  document.getElementById("sampleAudio")
                );
                audio.src = `data:audio/mpeg;base64,` + this.getAdioData.res;
                audio.load();
                audio.play();
                document.getElementById("mouth").classList.add("speaking");
                audio.addEventListener("ended", function () {
                  audio.currentTime = 0;
                  // console.log("ended");
                  document.getElementById("mouth").classList.remove("speaking");
                });
                // audio.autoplay = true;

                /**
                 * @ignore
                 */
                var chatData = {
                  value: this.adminMsg,
                  restype: "qs",
                };
                this.questionData.push(chatData);
                this.percentage = data.res.progress;
                this.loadingPercentage = this.percentage;
                //Pushing the Chat Data Starts here
                console.log("Progress", this.percentage);
              }
            });
          /**
           * @ignore
           */
          sessionStorage.setItem("userId", this.adminDataId);
          sessionStorage.setItem("chatId", this.adminData.res.chatid);
          //   sessionStorage.setItem('profileId', this.adminData.res.custom_profile_id);
          //Store the Value in SS Ends Here
        }
      },
      (error) => console.log(error)
    );
    // End of initFunctionCall
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.ContactForm = this.formBuilder.group({
      getFirstName: [""],
      getLastName: [""],
      getpostalcode: ["", [Validators.required]],
      getMobileNumber: ["", [Validators.required]],
      emailFormControl: [
        "",
        [Validators.required, Validators.pattern(emailregex)],
      ],
    });

    /**
     * @ignore
     */
    var candidateAdid = sessionStorage.getItem("UrlDecryptedAdId");
    if (this.directApply) {
      var getJobTitle = {
        method: "AUTH",
        id_list: "",
        ad_id: candidateAdid,
        local_chat:sessionStorage.getItem("LocalChat")
      };
      console.log(getJobTitle);
      this.http
        .commonMethod(getJobTitle, this.logonUrl.get_ad_data)
        .subscribe((data) => {
          console.log("jobtitle", data);
          var response: any = data;

          if (response.status == 200) {
            this.jobTitle = response.res.message[0].title;
            this.JobTitleCandidate = this.jobTitle;
            sessionStorage.setItem("JobTitleCandidate", this.jobTitle);
          } else {
            console.log("Job title not Available");
          }
        }),
        (error) => {
          console.log(error);
        };
    }
  }
  private _filterCountry(value: string) {
    const filterValue = value.toLowerCase();
    // console.log(filterValue)
    return this.countriesValue.filter(
      (state) => state.country.toLowerCase().indexOf(filterValue) === 0
    );
  }
  /**
   * Getting Country Code Autopopulated Here
   */
  get_countrycode() {
    // var flagdata = {
    //   "flag": "GET_COUNTRY_CODE"
    // }#
    console.log("get_countryCode");
    this.http
      .commonMethodGet(this.logonUrl.getCountryCode)
      .subscribe((data) => {
    console.log("get_countryCode  response", data);

        this.response = data;
        if (this.response && this.response.status == 200) {
          this.countriesValue = this.response.country_detail;
          console.log("success", this.countriesValue);
        } else {
        }
      });
  }
  /**
   * Common Alert Message
   * @example msg,Errtype
   */
  alertmsg(msg, errtype) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: { data: msg, classname: errtype },
      duration: 4000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: "blue-snackbar",
    });
  }

  //toCheck
  getlanguagevalue() {
    var language = this.currentlanguage;
    this.http.getlangvalue(language).subscribe(
      (data) => {
        if (data != null) {
          var details: any = data;
          this.languagedata = details;
          // console.log(details, "details");
        } else {
        }
      },
      (error) => {}
    );
  }

  citiesList(data) {
    console.log(data);
    // this.countryName = data.name;
    // this.sortcountryName = data.sortname
    // this.cityId = id;
    this.country = data.international_dialing;
  }
  /**  company Desc
   * company Loago Detais Starts here By Balaji Sachin
   */

  companyDetails() {
    let urlAdid = sessionStorage.getItem("UrlDecryptedAdId");
    let UrlDecryptedRecruiterId = sessionStorage.getItem(
      "UrlDecryptedRecruiterId"
    );
    var companyData;
    if (this.directApply) {
      companyData = {
        // "flag": "GET_COMPANY_LOGO",
        ad_id: urlAdid,
        recruiter_id: UrlDecryptedRecruiterId,
      };
    } else {
      companyData = {
        // "flag": "GET_COMPANY_LOGO",
        ad_id: urlAdid,
        recruiter_id: UrlDecryptedRecruiterId,
      };
    }
    this.http
      .commonMethod(companyData, this.logonUrl.get_company_logo)
      .subscribe((data) => {
        this.companyData = data;

        console.log("Data", this.companyData);
        if (this.companyData.status == 200) {
          this.companyDataLogo = this.companyData;
          this.Logo = this.companyDataLogo.res.logo;
          this.companyName = this.companyDataLogo.res.company_name;
          this.openTermsConditionsCV = this.companyDataLogo.res.terms_conditions;
          this.welcomeText = this.companyDataLogo.res.welcome_text;
          // console.log(this.companyDataLogo.res.status[0].logo);
        }
      });
  }
  //End Here Logo

  // /**
  //  * Conflict Function Starts here
  //  */
  // getTLIGETQUESTION_URL() {
  //   this.getcurrentProfile();
  //   this.simpleChat = false;
  //   this.TLIGETQUESTION_URL = true;

  //   /**
  //    * @ignore
  //    */
  //   var getId = sessionStorage.getItem("userId");
  //   var chatid = sessionStorage.getItem("chatId");
  //   var cvId = sessionStorage.getItem("cvId");

  //   let adid = sessionStorage.getItem("UrlDecryptedAdId");

  //   this.obj2 = {};

  //   this.obj2.ad_id = adid;
  //   this.obj2.id = getId;
  //   this.obj2.cv_id = cvId;
  //   this.obj2.doc_type = "CV";
  //   this.obj2.mode = "conflict";
  //   this.obj2.answer = this.userMessageData;

  //   this.speechRecognitionService.getTLIGETQUESTION_URL(this.obj2).subscribe(
  //     (data) => {
  //       console.log("Progress for TLI chat", this.percentage);

  //       this.adminDataWithId = data;
  //       if (this.adminDataWithId.status == 200) {
  //         // Getting TLI Chat API Initially End
  //         if (this.adminDataWithId.res.finish != "1") {
  //           this.adminDataMsg = this.adminDataWithId.res.question;
  //           var dataParams = {
  //             text: this.adminDataMsg,
  //           };
  //           var initialised = false;
  //           this.http
  //             .commonMethod(dataParams, this.logonUrl.text_to_voice)
  //             .subscribe((response) => {
  //               this.getAdioData = response;

  //               // let audio = new Audio();
  //               let audio = <HTMLVideoElement>(
  //                 document.getElementById("sampleAudio")
  //               );
  //               audio.src = `data:audio/mpeg;base64,` + this.getAdioData.res;
  //               audio.load();
  //               audio.play();
  //               document.getElementById("mouth").classList.add("speaking");
  //               audio.addEventListener("ended", function () {
  //                 audio.currentTime = 0;
  //                 console.log("ended");
  //                 document.getElementById("mouth").classList.remove("speaking");
  //               });
  //               // audio.autoplay = true;
  //             });
  //           //Pushing the Chat Data Starts here
  //           var chatData = {
  //             value: this.adminDataMsg,
  //             restype: "qs",
  //           };
  //           this.questionData.push(chatData);

  //           this.admintypingGIF = false;
  //           //Pushing the Chat Data Ends here
  //           // Text to Speech Starts Ends Here
  //           //Make Gif and Loading percentage Start
  //           this.percentage = this.percentage;
  //           this.loadingPercentage = this.percentage;
  //           //Make Gif and Loading percentage End
  //         } else {
  //           this.adminDataMsg = data.res.question;
  //           var dataParams = {
  //             // "flag": "TEXT_TO_VOICE",
  //             // "clientid": "1",
  //             text: this.adminDataMsg,
  //             // "deviceid": "",
  //             // "osid": ""
  //           };
  //           var initialised = false;
  //           this.http
  //             .commonMethod(dataParams, this.logonUrl.text_to_voice)
  //             .subscribe((response) => {
  //               this.getAdioData = response;
  //               if (this.getAdioData.status == 200) {
  //                 let audio = <HTMLVideoElement>(
  //                   document.getElementById("sampleAudio")
  //                 );
  //                 audio.src = `data:audio/mpeg;base64,` + this.getAdioData.res;
  //                 audio.load();
  //                 audio.play();
  //                 document.getElementById("mouth").classList.add("speaking");
  //                 audio.addEventListener("ended", function () {
  //                   audio.currentTime = 0;
  //                   console.log("ended");
  //                   document
  //                     .getElementById("mouth")
  //                     .classList.remove("speaking");
  //                 });
  //               }
  //             });

  //           //Pushing the Chat Data Starts here
  //           var chatData = {
  //             value: data.res.question,
  //             restype: "qs",
  //           };
  //           this.questionData.push(chatData);
  //           this.admintypingGIF = false;
  //           this.thankyouBtnDiv = true;
  //           this.thankyouBtn1 = true;
  //           this.msgSendDiv = false;
  //           //Pushing the Chat Data Ends here

  //           //Make Gif and Loading percentage Start
  //           this.percentage = this.percentage;
  //           this.loadingPercentage = this.percentage;
  //           //Make Gif and Loading percentage End
  //         }
  //       }
  //     },
  //     (error) => console.log(error)
  //   );
  // }
  // //Conflict Function Ends here

  /**  function desc
   * Simpe Chat Function Starts here
   */
  getSimpleChatApi() {
    var lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "de";
    var getId = sessionStorage.getItem("userId");
    var getChatId = sessionStorage.getItem("chatId");
    let adid = sessionStorage.getItem("UrlDecryptedAdId");
    let chat_caseid = sessionStorage.getItem("UrlChatCaseId");
    let type = localStorage.getItem("applytype");
    this.blueCollar = sessionStorage.getItem("UrlBluecollar");
    let local_chat = sessionStorage.getItem("LocalChat")

    this.obj = {};

    this.obj.custom_profile_id = "";
    this.obj.ad_id = adid;
    this.obj.id = getId;
    this.obj.language = lang;
    this.obj.answer = this.userMessageData;
    this.obj.doc_type = "CV";
    this.obj.order_id = "";
    this.obj.secret = "";


    if (this.blueCollar == "true") {
      this.obj.chat_keywords = "1";
    } else {
      this.obj.chat_keywords = "0";
    }
    if (type == "directapply") {
      this.obj.chat_case = chat_caseid;
    } else {
      this.obj.chat_case = "20012";
    }

    if (local_chat && local_chat == "true")
    {
      this.obj.local_chat=true;
    }
    else
    {
      this.obj.local_chat=false;
    }

    // console.log("SimpleChat", this.obj);
    this.speechRecognitionService.getSimpleChat(this.obj).subscribe(
      (data) => {
        if (data.status == 200) {
          // console.log("simple Chat", data);

          /**
           * @ignore
           */
          if (data.res.question != "") {
            if (data.res.finish != "1") {
              this.adminData = data;
              this.adminDataId = data.res.id;
              this.adminMsg = data.res.question;
              var dataParams = {
                text: this.adminMsg,
              };
              var initialised = false;
              this.http
                .commonMethod(dataParams, this.logonUrl.text_to_voice)
                .subscribe((response) => {
                  this.getAdioData = response;
                  if (this.getAdioData.status == 200) {
                    try{
                    let audio = <HTMLVideoElement>(
                      document.getElementById("sampleAudio")
                    );
                    audio.src =
                      `data:audio/mpeg;base64,` + this.getAdioData.res;
                    audio.load();
                    audio.play();
                    document.getElementById("mouth").classList.add("speaking");
                    // audio.autoplay = true
                    audio.addEventListener("ended", function () {
                      audio.currentTime = 0;
                      console.log("ended");
                      document
                        .getElementById("mouth")
                        .classList.remove("speaking");
                    });
                  }
                  catch(e){console.warn("check audio",e)}
                  }
                });

              /**
               * Pushing CHat Data Starts here
               */
              /**
               * @ignore
               */

              var chatData = {
                value: this.adminMsg,
                restype: "qs",
              };
              this.questionData.push(chatData);
              this.admintypingGIF = false;
              //Ends here

              /**
               * @ignore
               */
              this.percentage = data.res.progress;
              this.loadingPercentage = this.percentage;
              console.log("Progress Simple Chat", this.percentage);
            } else {
              this.adminDataMsg = data.res.question;
              var dataParams = {
                text: this.adminDataMsg,
              };
              var initialised = false;
              this.http
                .commonMethod(dataParams, this.logonUrl.text_to_voice)
                .subscribe((response) => {
                  this.getAdioData = response;
                  if (this.getAdioData.status == 200) {
                   try {
                      let audio = <HTMLVideoElement>(document.getElementById("sampleAudio"));
                    audio.src =
                      `data:audio/mpeg;base64,` + this.getAdioData.res;
                    audio.load();
                    audio.play();
                    document.getElementById("mouth").classList.add("speaking");
                    audio.addEventListener("ended", function () {
                      audio.currentTime = 0;
                      console.log("ended");
                      document
                        .getElementById("mouth")
                        .classList.remove("speaking");
                    });
                  }
                  catch(e){console.warn("check audio",e)}
                  }
                });
              // Storing Session for CVID
              var chatData = {
                value: data.res.question,
                restype: "qs",
              };
              this.questionData.push(chatData);
              this.admintypingGIF = false;
              sessionStorage.setItem("cvId", data.res.cv_id);
              var blueCollar = sessionStorage.getItem("UrlBluecollar");
              //  Disabled Conflict Chat by Business Request
              // if (blueCollar == "true") {
                this.admintypingGIF = false;
                this.thankyouBtnDiv = true;
                this.thankyouBtn1 = true;
                this.msgSendDiv = false;
                //Pushing the Chat Data Ends herey

                //Make Gif and Loading percentage Start
                this.percentage = data.res.progress;
                this.loadingPercentage = this.percentage;
                console.log("Progress Simple Chat End", this.percentage);
              // } else {
              //   this.percentage = data.res.progress;
              //   this.getTLIGETQUESTION_URL();
              // }
            }
          } else {
            var blueCollar = sessionStorage.getItem("UrlBluecollar");
            // Disabled Conflict Chat by Business Request
            // if (blueCollar == 'true') {
            this.admintypingGIF = false;
            this.thankyouBtnDiv = true;
            this.thankyouBtn1 = true;
            this.msgSendDiv = false;
            //Pushing the Chat Data Ends here

            this.percentage = data.res.progress;
            this.loadingPercentage = this.percentage;
            // this.ContactDetailsBtn();
            // } else {
            //   this.getTLIGETQUESTION_URL();
            // }
          }
        }
      },
      (error) => console.log(error)
    );
  }

  ngAfterViewChecked() {
    if (!this.ContactDiv) {
      this.scrollBottom();
    }
  }
  //Simpe Chat Function Ends here

  /**
   * One to one mAtch Starts Here
   */
  getOnetoOneMatch() {
    var cvIdNmatch = sessionStorage.getItem("cvId");
    let adidNmatch = sessionStorage.getItem("UrlDecryptedAdId");

    this.obj = {};

    (this.obj.method = "1TO1"), (this.obj.adidNmatch = adidNmatch);
    this.obj.cvIdNmatch = cvIdNmatch;
    this.obj.matching_filter_list = [];
    this.obj.db_state = "1";
    this.obj.detail_info = "0";
    this.obj.local_chat=sessionStorage.getItem("LocalChat")

    this.speechRecognitionService.getOneToMatch(this.obj).subscribe(
      (data) => {
        console.log("One-to-One Match", data);
        this.response = data;
        if (this.response.status == 200) {
          this.setStateCandidate();
          // this.candidateConfirmation();
        }
      },
      (error) => console.log(error)
    );
  }
  //One to one mAtch Ends Here
  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: "destination",
    };

    this._scrollToService.scrollTo(config);
  }

  /**
   * Send BUtton Function Starts Here
   */
  sendMsgBtn() {
    let audio = <HTMLVideoElement>document.getElementById("sampleAudio");
    audio.pause();
    document.getElementById("mouth").classList.remove("speaking");

    if (this.questionData && this.questionData[0].value == "") {
      this.toastr.warning("Bitte warten");
    } else {
      if (this.admintypingGIF == true) {
        this.toastr.warning("Bitte warten");
      } else {
        if (this.speechData != "") {
          this.userMessageData = this.speechData;
          var chatData = {
            value: this.userMessageData,
            restype: "ans",
          };
          this.questionData.push(chatData);
          this.admintypingGIF = true;
          this.speechData = "";
          this.getSimpleChatApi();
        } else {
          this.toastr.error("Bitte eine Nachricht eingeben");
          this.admintypingGIF = false;
        }
      }
    }
  }
  //Send BUtton Function Ends Here
  getCvData: any;
  getZipCode: any;
  getEmail: any;
  getPhoneNumber: any;
  getvorname: any;
  getnachname: any;

  /**
   * Contact Form for Candidate Details to show By Balaji Sachin
   */

  ContactDetailsBtn() {
    // this.get_countrycode()
    this.ContactDiv = true;
    this.TLIGETQUESTION_URL = false;
    this.simpleChat = false;
    this.msgSendDiv = false;
    this.chatDiv = false;
    this.thankyouBtnDiv = false;
    this.thankyouBtn1 = false;
    this.avatorIconDiv = true;
    this.country = "+49";
    var cvId = sessionStorage.getItem("cvId");
    var getJobTitle = {
      method: "AUTH",
      id_list: "",
      cv_id: cvId,
      local_chat:sessionStorage.getItem("LocalChat")
    };
    // console.log(getJobTitle);
    this.http
      .commonMethod(getJobTitle, this.logonUrl.get_cv_data)
      .subscribe((data) => {
        this.getCvData = data;
        console.log("GetCVDATA", this.getCvData);
        if (this.getCvData.status == 200) {
          this.getZipCode = this.getCvData.res.message[0].zip;
          this.getEmail = this.getCvData.res.message[0].email;
          this.getPhoneNumber = this.getCvData.res.message[0].phone;
          if (
            this.getCvData.res.message[0].vorname &&
            this.getCvData.res.message[0].nachname
          ) {
            console.log("with First name & Last Name");
            this.getvorname = this.getCvData.res.message[0].vorname;
            this.getnachname = this.getCvData.res.message[0].nachname;
            this.ContactForm.patchValue({
              emailFormControl: this.getEmail,
              getpostalcode: this.getZipCode,
              getMobileNumber: this.getPhoneNumber,
              getFirstName: this.getvorname,
              getLastName: this.getnachname,
            });
          } else {
            console.log("without First name & Last Name");
            this.ContactForm.patchValue({
              emailFormControl: this.getEmail,
              getpostalcode: this.getZipCode,
              getMobileNumber: this.getPhoneNumber,
            });
          }
        }

        // this.jobTitle = data.res.message[0].title;
      }),
      (error) => {
        console.log(error);
      };

    this.headerChatDiv = false;
  }
  /**
   * @ignore
   */
  restartChat() {
    this.admintypingGIF = false;
    this.questionData = [];

    this.percentage = 0;
    this.loadingPercentage = 0;
    this.speechData = "";
    this.thankyouBtnDiv = false;
    this.thankyouBtn1 = false;
    this.msgData = true;
    this.msgSendDiv = true;
    this.ngOnInit();
  }
  /**
   * @ignore
   */
  saveChatHistory() {
    this.toastr.success("Die Chat Historie wurde gespeichert.");
    var userId = sessionStorage.getItem("userId");
    var chatId = sessionStorage.getItem("chatId");
    var profileId = sessionStorage.getItem("profileId");
    var cvId = sessionStorage.getItem("cvId");
    console.log(
      "userId:",
      userId + "chatId:",
      chatId + "profileId:",
      profileId + "cvId:",
      cvId
    );
  }
  /**
   * Application Confirmation for Candiate Email
   */
  /**
   * @ignore
   * toCheck
   */

  applicationConfirmation: any;
  candidateConfirmation() {
    console.log(
      "Phone NUmber",
      this.ContactForm.controls.getMobileNumber.value
    );
    var type = localStorage.getItem("applytype");
    var setCandidateInfo;
    var cvIdCandidate = sessionStorage.getItem("UrlDecryptedAdId");
    console.log("cvId", cvIdCandidate);
    var InternalRefrenceId = sessionStorage.getItem("UrlInternalRefrenceId");
    console.log("UrlInternalRefrenceId", InternalRefrenceId);
    var descryptEmail = sessionStorage.getItem("UrlDecryptEmail");

    let adid = sessionStorage.getItem("UrlDecryptedAdId");
    const cv_id = sessionStorage.getItem("cvId");
    if (type == "directapply") {
      console.log("chat");
      setCandidateInfo = {
        // "flag": "APPLICATION_CONFORMATION",
        // "profileid": "1",
        // "apply_type": "DIRECTAPPLY",
        internal_reference: InternalRefrenceId,
        email: descryptEmail,
        job_title: sessionStorage.getItem("JobTitleCandidate"),
        job_id: InternalRefrenceId,
        candidate_firstname: this.ContactForm.controls.getFirstName.value,
        candidate_lastname: this.ContactForm.controls.getLastName.value,
        candidate_email: this.ContactForm.controls.emailFormControl.value,
        candidate_phone: this.ContactForm.controls.getMobileNumber.value,
        ad_id: adid,
        cv_id: cv_id,
        directapply: true,
        // "deviceid": "",
        // "osid": ""
      };
    } else {
      console.log("easyApply");
      setCandidateInfo = {
        internal_reference: InternalRefrenceId,
        easyapply: true,
        email: descryptEmail,
        job_title: "UX Manager",
        job_id: cvIdCandidate,
        candidate_firstname: this.ContactForm.controls.getFirstName.value,
        candidate_lastname: this.ContactForm.controls.getLastName.value,
        candidate_email: this.ContactForm.controls.emailFormControl.value,
        candidate_phone: this.ContactForm.controls.getMobileNumber.value,
        ad_id: adid,
        cv_id: cv_id,
      };
    }

    let url_application_conformation = this.logonUrl.application_conformation
    if (sessionStorage.getItem("LocalChat") && sessionStorage.getItem("LocalChat")=="true" )
    {
      url_application_conformation = this.logonUrl.local_application_conformation
    }
    console.log("setCandidateInfo", setCandidateInfo);
    this.http
      .commonMethod(setCandidateInfo, url_application_conformation)
      .subscribe(
        (data) => {
          console.log("APPLICATION_CONFORMATION", data);
          this.applicationConfirmation = data;
          if (this.applicationConfirmation.status == 200) {
            // TODO
            // this.emailUpdate()
            console.log(
              this.applicationConfirmation,
              "Res Application Confirmation"
            );
          }
        },
        (error) => console.log(error)
      );
  }
  /**
   * Email Update
   */

  emailUpdate() {
    var UpdateEmail = {
      email: this.ContactForm.controls.emailFormControl.value,
      ad_id: sessionStorage.getItem("UrlDecryptedAdId"),
    };
    this.http
      .commonMethod(UpdateEmail, this.logonUrl.email_update_adid)
      .subscribe((data) => {
        console.log("EMail UPdate", data);
      });
  }
  //End
  // Call SetCVData for End the Page
  /**
   * White Color all fields
   */

  setCvData() {
    console.log("Direct Apply White and Easy Apply");
    if (
      this.ContactForm.controls.emailFormControl.status == "VALID" &&
      this.ContactForm.controls.getMobileNumber.value != "" &&
      this.ContactForm.controls.getpostalcode.value != ""
    ) {
      sessionStorage.setItem(
        "cv_email",
        this.ContactForm.controls.emailFormControl.value
      );
      const cv_id = sessionStorage.getItem("cvId");
      let adid = sessionStorage.getItem("UrlDecryptedAdId");

      var getId = sessionStorage.getItem("userId");
      var getcvId = sessionStorage.getItem("cvId");
      var getProfileId = sessionStorage.getItem("profileId");
      var getChatId = sessionStorage.getItem("chatId");
      this.obj = {};
      this.obj.method = "AUTH";
      this.obj.cvid = getcvId;
      this.obj.name = this.ContactForm.controls.getFirstName.value;
      this.obj.surname = this.ContactForm.controls.getLastName.value;
      this.obj.zipcode = this.ContactForm.controls.getpostalcode.value;
      this.obj.location = "";
      this.obj.custid = "";
      this.obj.permanentjob = "";
      this.obj.fulltimejob = "";
      this.obj.email = this.ContactForm.controls.emailFormControl.value.toLowerCase();
      this.obj.phone =
        this.country + this.ContactForm.controls.getMobileNumber.value;
      this.obj.countrycodes = "";

      this.speechRecognitionService.setCvData(this.obj).subscribe(
        (data) => {
          console.log(data, "setcvdataobj");
          if (data.status == 200) {
            this.setcvData = data;
            var blueCollar = sessionStorage.getItem("UrlBluecollar");
            if (blueCollar == "true") {
              this.getOnetoOneMatch();
              var dtUrl = sessionStorage.getItem("urlDt");
              var applyType = localStorage.getItem("applytype");
              var old = localStorage.getItem("OldUrl");
              if (old == "true") {
                this.router.navigate(["/success"], {
                  queryParams: {
                    type: applyType,
                    dt: dtUrl,
                  },
                });
              } else {
                this.router.navigate(["/success/" + dtUrl]);
              }
            }

            // this.setStateCandidate();
            else {
              var feedBackFlag = sessionStorage.getItem("UrlDecryptedFeedBack");
              var matchReportFlag = sessionStorage.getItem(
                "UrlDecryptedMatchReport"
              );
              var applyType = localStorage.getItem("applytype");
              if (applyType == "directapply") {
                // if (JSON.parse(feedBackFlag)) {
                //   var dtUrl = sessionStorage.getItem('urlDt');
                //   var applyType = localStorage.getItem('applytype');
                //   var old = localStorage.getItem("OldUrl")
                //   if (old == "true") {
                //     this.router.navigate(['/success']
                //       , {
                //         queryParams: {
                //           type: applyType,
                //           dt: dtUrl
                //         }
                //       }
                //     );
                //   } else {
                //     this.router.navigate(['/success/' + dtUrl]
                //     );
                //   }
                //   this.getOnetoOneMatch();
                //   //  this.setStateCandidate();

                // }
                // else if (JSON.parse(matchReportFlag)) {
                //   var dtUrl = sessionStorage.getItem('urlDt');
                //   var applyType = localStorage.getItem('applytype');
                //   var old = localStorage.getItem("OldUrl")
                //   if (old == "true") {
                //     this.router.navigate(['/success']
                //       , {
                //         queryParams: {
                //           type: applyType,
                //           dt: dtUrl
                //         }
                //       }
                //     );
                //   } else {
                //     this.router.navigate(['/success/' + dtUrl]
                //     );
                //   }
                //   this.getOnetoOneMatch();
                //   // this.setStateCandidate();

                // }
                // else {
                this.getOnetoOneMatch();
                var dtUrl = sessionStorage.getItem("urlDt");
                var applyType = localStorage.getItem("applytype");
                var old = localStorage.getItem("OldUrl");
                if (old == "true") {
                  this.router.navigate(["/success"], {
                    queryParams: {
                      type: applyType,
                      dt: dtUrl,
                    },
                  });
                } else {
                  this.router.navigate(["/success/" + dtUrl]);
                }

                // this.setStateCandidate();
                // }
              } else {
                console.log("Easy Apply Call");
                this.easyApplyToFeedBack();
              }
            }
          } else {
            console.log("set Cv Data Not Success");
          }
        },
        (error) => console.log(error)
      );
      // },
      //   error => console.log(error)
      // )
    } else {
      this.toastr.warning("Bitte alle Pflichtfelder ausfüllen.");
    }
  }
  /**
   * @ignore
   */

  StraightApplyChat() {
    var blueCollar = sessionStorage.getItem("UrlBluecollar");
    console.log("Blue Collar true");
    if (
      this.ContactForm.controls.getMobileNumber.value != "" &&
      this.ContactForm.controls.getpostalcode.value != ""
    ) {
      sessionStorage.setItem(
        "cv_email",
        this.ContactForm.controls.emailFormControl.value
      );
      const cv_id = sessionStorage.getItem("cvId");
      let adid = sessionStorage.getItem("UrlDecryptedAdId");

      sessionStorage.setItem(
        "getAccessTokenforcandidatechat",
        this.getAccessTokenForChat
      );
      /**
       * @ignore
       */
      var getId = sessionStorage.getItem("userId");
      var getcvId = sessionStorage.getItem("cvId");
      var getProfileId = sessionStorage.getItem("profileId");
      var getChatId = sessionStorage.getItem("chatId");
      this.obj = {};
      this.obj.method = "AUTH";
      this.obj.cvid = getcvId;
      this.obj.name = this.ContactForm.controls.getFirstName.value;
      this.obj.surname = this.ContactForm.controls.getLastName.value;
      this.obj.zipcode = this.ContactForm.controls.getpostalcode.value;
      this.obj.location = "";
      this.obj.custid = "";
      this.obj.permanentjob = "";
      this.obj.fulltimejob = "";
      this.obj.email = this.ContactForm.controls.emailFormControl.value.toLowerCase();
      this.obj.phone =
        this.country + this.ContactForm.controls.getMobileNumber.value;
      this.obj.countrycodes = "";
      this.obj.local_chat= sessionStorage.getItem("LocalChat")

      this.speechRecognitionService.setCvData(this.obj).subscribe(
        (data) => {
          console.log(data, "setcvdataobj");
          if (data.status == 200) {
            this.setcvData = data;
            var dtUrl = sessionStorage.getItem("urlDt");
            var applyType = localStorage.getItem("applytype");
            var old = localStorage.getItem("OldUrl");

            this.getOnetoOneMatch();
            if (old == "true") {
              this.router.navigate(["/success"], {
                queryParams: {
                  type: applyType,
                  dt: dtUrl,
                },
              });
            } else {
              this.router.navigate(["/success/" + dtUrl]);
            }
          } else {
            console.log("Set CV Data Not Success");
          }
          // this.setStateCandidate();
        },
        (error) => {console.log(error);}
      );
      // },
      //   error => console.log(error)
      // )
    } else {
      this.toastr.warning("Bitte alle Pflichtfelder ausfüllen.");
      // this.alertmsg(this.languagedata.ALERTS.MOBILE, 'error');
    }
  }
  // Straight Apply From Chat to success Ends Here
  SetStateData: any;
  setStateCandidate() {
    let cv_id1 = sessionStorage.getItem("cvId");
    let urlAdid1 = sessionStorage.getItem("UrlDecryptedAdId");
    // var applyType = localStorage.getItem('applytype');

    // if (applyType == 'directapply') {
    var setStateValues = {
      method: "AUTH",
      ad_id: urlAdid1,
      cv_id: cv_id1,
      state_list: [
        {
          ad_id: urlAdid1,
          cv_id: cv_id1,
          state: 70,
        },
      ],
    };

    let state_url= this.logonUrl.set_state
    if(sessionStorage.getItem("LocalChat") && sessionStorage.getItem("LocalChat") == "true")
      {
        state_url = this.logonUrl.local_set_state
      }

    this.http.commonMethod(setStateValues, state_url).subscribe(
      (data) => {
        console.log("SetStateCandidate", data);
        this.SetStateData = data;
        if (this.SetStateData.status == 200) {
          let cv_id = sessionStorage.getItem("cvId");
          let urlAdid = sessionStorage.getItem("UrlDecryptedAdId");
          let UrlDecryptedRecruiterId = sessionStorage.getItem(
            "UrlDecryptedRecruiterId"
          );

          this.obj = {};

          this.obj.method = "AUTH";
          this.obj.cv_id = cv_id;
          this.obj.ad_id = urlAdid;
          this.obj.recruiter_id = UrlDecryptedRecruiterId;
          this.obj.organisation_id = "111";
          this.obj.local_chat=sessionStorage.getItem("LocalChat")

          this.speechRecognitionService.setProtectCandidate(this.obj).subscribe(
            (data) => {
              console.log("SetProtectCandidate", data);
              if (data.status == 200) {
                this.candidateConfirmation();
              }
            },
            (error) => console.log(error)
          );
        }
      },
      (error) => console.log(error)
    );
  }
  /**
   * @ignore
   */
  easyApplyToFeedBack() {
    var feedBackFlag = sessionStorage.getItem("UrlDecryptedFeedBack");
    if (feedBackFlag == "true") {
      var applyType = localStorage.getItem("applytype");

      this.getOnetoOneMatch();

      var dtUrl = sessionStorage.getItem("urlDt");
      var applyType = localStorage.getItem("applytype");
      var old = localStorage.getItem("OldUrl");
      if (old == "true") {
        this.router.navigate(["/success"], {
          queryParams: {
            type: applyType,
            dt: dtUrl,
          },
        });
      } else {
        this.router.navigate(["/success/" + dtUrl]);
      }
    } else {
      var applyType = localStorage.getItem("applytype");
      if (applyType == "directapply") {
        console.log("Calling One-to-One Match");
        this.getOnetoOneMatch();
      } else {
        console.log("Calling One-to-One Match Easy Apply");
        this.getOnetoOneMatch();
      }
      var dtUrl = sessionStorage.getItem("urlDt");
      var applyType = localStorage.getItem("applytype");
      var old = localStorage.getItem("OldUrl");
      if (old == "true") {
        this.router.navigate(["/success"], {
          queryParams: {
            type: applyType,
            dt: dtUrl,
          },
        });
      } else {
        this.router.navigate(["/success/" + dtUrl]);
      }
    }
  }
  /**
   * @ignore
   */
  getErrorEmailMessage() {
    var blueCollar = sessionStorage.getItem("UrlBluecollar");
    if (blueCollar != "true") {
      return this.emailFormControl.hasError("required")
        ? "Email Adresse ist ein Pflichtfeld"
        : this.emailFormControl.hasError("email")
        ? "Das ist keine Email Adresse"
        : "";
    }
  }
  /**
   * @ignore
   */
  getErrorMessageMobileNo() {
    return this.getMobileNumber.hasError("required")
      ? "Telefonnummer eingeben"
      : "";
  }
  /**
   * @ignore
   */
  getErrorpostcode() {
    return this.getpostalcode.hasError("required")
      ? "Postleitzahl eingeben"
      : "";
  }
  /**
   * @ignore
   */
  getcurrentProfile() {
    var cvid = sessionStorage.getItem("cvId");
    const currentProfileFlags = {
      id: cvid,
      doc_type: "CV",
    };
    this.http
      .commonMethod(currentProfileFlags, this.logonUrl.get_current_profile)
      .subscribe((data) => {
        console.log(data);
        this.profileReport = data;

        this.contentProfile = this.profileReport.res;
        this.colorCode = this.contentProfile.color_info_list;

        this.JobCategoryColor = this.contentProfile.main_skill.main_sk_unique;
        this.compentenceColor = this.contentProfile.ad_text_entries_bu_27;
        this.SkillsColor = this.contentProfile.ad_text_entries_ab_61;
        this.EducationColor = this.contentProfile.ad_profile_info.krit_8_max;
        this.CarrerColor = this.contentProfile.ad_profile_info.krit_10_min;
        this.professionColor = this.contentProfile.ad_general_info.zipcode;
        //Color Code Var initilize Ends Here
        let arr = [];
        arr.push(this.colorCode);
        this.colorCodearr = arr;
        console.log("ColorCode for TLI Chat", this.colorCodearr);
      });
  }
}
