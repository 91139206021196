import{environment} from '../environments/environment'


export const urlEnv = {
    verifyEmail: environment.server_url + "/v2/verifyEmail",
    resendOTP: environment.server_url + "/v2/resendOTP",
    verifyOTP: environment.server_url + "/v2/verifyOTP",
    register: environment.server_url + "/v2/register",
    login: environment.server_url + "/v2/login",
    logout: environment.server_url + "/v2/logout",
    forgotPassword: environment.server_url + "/v2/forgotPassword",
    resetPassword: environment.server_url + "/v2/resetPassword",
    changePassword: environment.server_url + "/v2/changePassword",
    getCountries: environment.server_url + "/v2/getCountries",
    getCities: environment.server_url + "/v2/getCities",
    getZipcodes: environment.server_url + "/v2/getZipcodes",
    getZipCity: environment.server_url + "/v2/getZipCity",
    getIndustry: environment.server_url + "/v2/ getIndustry",
    session_check: environment.server_url + "/v2/session_check",
    kill_session: environment.server_url + "/v2/kill_session ",


    verfication_token: environment.server_url + "/logon/v2/verfication_token ",
    get_report: environment.server_url + "/logon/v2/get_report",
    get_report_active_sourcing: environment.server_url + "/logon/v2/get_report_active_sourcing",
    get_profile: environment.server_url + "/logon/v2/get_profile",
    get_match_report: environment.server_url + "/logon/v2/get_match_report",
    get_match_report_active_sourcing: environment.server_url + "/logon/v2/get_match_report_active_sourcing",
    set_report: environment.server_url + "/logon/v2/set_report",
    set_cv_data: environment.server_url + "/logon/v2/set_cv_data",
    local_set_cv_data: environment.server_url + "/logon/v2/local_set_cv_data",
    get_cv_data: environment.server_url + "/logon/v2/get_cv_data",
    local_get_cv_data: environment.server_url + "/logon/v2/local_get_cv_data",
    set_ad_data: environment.server_url + "/logon/v2/set_ad_data",
    local_set_ad_data: environment.server_url + "/logon/v2/local_set_ad_data",
    get_ad_data: environment.server_url + "/logon/v2/get_ad_data",
    local_get_ad_data: environment.server_url + "/logon/v2/local_get_ad_data",
    n_to_m_match: environment.server_url + "/logon/v2/n_to_m_match",
    local_n_to_m_match: environment.server_url + "/logon/v2/local_n_to_m_match",
    get_list_by_state: environment.server_url + "/logon/v2/get_list_by_state",
    get_list_by_state_filtered: environment.server_url + "/logon/v2/get_list_by_state_filtered",
    get_list_by_state_active_sourcing: environment.server_url + "/logon/v2/get_list_by_state_active_sourcing",
    local_get_list_by_state: environment.server_url + "/logon/v2/local_get_list_by_state",
    get_active_sourcing: environment.server_url + "/logon/v2/get_active_sourcing",
    get_heatmap: environment.server_url + "/logon/v2/get_heatmap",
    set_favorite: environment.server_url + "/logon/v2/set_favorite",
    local_set_favorite: environment.server_url + "/logon/v2/local_set_favorite",
    get_favorite: environment.server_url + "/logon/v2/get_favorite",
    local_get_favorite: environment.server_url + "/logon/v2/local_get_favorite",
    set_state: environment.server_url + "/logon/v2/set_state",
    local_set_state: environment.server_url + "/logon/v2/local_set_state",
    delete_values: environment.server_url + "/logon/v2/delete_values",
    get_current_profile: environment.server_url + "/logon/v2/get_current_profile",
    get_profile_for_chatcase: environment.server_url + "/logon/v2/get_profile_for_chatcase",
    get_skillset_id_list: environment.server_url + "/logon/v2/get_skillset_id_list",
    get_dumy_ad: environment.server_url + "/logon/v2/create_dummy_ad",
    get_talent_csv: environment.server_url + "/logon/v2/get_talent_csv",
    get_document: environment.server_url + "/logon/v2/get_document",
    local_get_document: environment.server_url + "/logon/v2/local_get_document",
    text_to_voice: environment.server_url + "/logon/v2/text_to_voice",
    getCountryCode: environment.server_url + "/v2/getCountryCode",
    contacts_informations: environment.server_url + "/v2/contacts_informations",
    email_update_adid: environment.server_url + "/v2/email_update_adid",
    ad_status_checking: environment.server_url + "/v2/ad_status_checking",
    chat_status_checking: environment.server_url + "/v2/chat_status_checking",
    e_recruiter_ids: environment.server_url + "/v2/e_recruiter_ids",
    cvid_based_adid: environment.server_url + "/v2/cvid_based_adid",
    link_expired_status: environment.server_url + "/v2/link_expired_status",
    simple_chat: environment.server_url + "/logon/v2/simple_chat",
    tli_get_question: environment.server_url + "/logon/v2/tli_get_question",
    insert_payment_detail: environment.server_url + "/v2/insert_payment_detail",
    get_payment_detail: environment.server_url + "/v2/get_payment_detail",
    e_recruiter_timestamp: environment.server_url + "/v2/e_recruiter_timestamp",
    get_cvid_list: environment.server_url + "/v2/get_cvid_list",
    company_logo: environment.server_url + "/v2/company_logo",
    get_company_logo: environment.server_url + "/v2/get_company_logo",
    job_like_dislike: environment.server_url + "/v2/job_like_dislike",
    get_id_details: environment.server_url + "/v2/get_id_details",
    get_ad_url: environment.server_url + "/v2/get_ad_url",
    get_cv_url: environment.server_url + "/v2/get_cv_url",
    easy_apply_links: environment.server_url + "/v2/easy_apply_links",
    linkbased_adid_cvid: environment.server_url + "/v2/linkbased_adid_cvid",
    link_checking: environment.server_url + "/v2/link_checking",
    get_my_data_counters :environment.server_url + "/logon/v2/get_my_data_counters",
    local_get_my_data_counters :environment.server_url + "/logon/v2/local_get_my_data_counters",
    get_my_ids :environment.server_url + "/logon/v2/get_my_ids",
    set_protect_candidate :environment.server_url + "/logon/v2/set_protect_candidate",
    local_set_protect_candidate :environment.server_url + "/logon/v2/local_set_protect_candidate",
    application_conformation :environment.server_url + "/v2/application_conformation",
    local_application_conformation: environment.server_url + "/v2/local_application_conformation",
    combined_delete_values: environment.server_url + "/logon/v2/combined_delete_values",
}