import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from "lodash";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import {urlEnv} from '../environments/env';

interface IWindow extends Window {
  webkitSpeechRecognition: any;
  SpeechRecognition: any;
}

@Injectable({
  providedIn: 'root'
})
export class SpeechRecognitionService {
  constructor(private zone: NgZone, private http: HttpClient) {
  }
  
 
  
  public param: any;
//New
public logonUrl = urlEnv;
//Old
  private isLogged = localStorage.getItem('isLogged');
  private token = localStorage.getItem('token');
  private userEmail = localStorage.getItem('userEmail');
  private sessionid = localStorage.getItem('sessionid');
  // betterHeadsApi = environment.server_url + "logon";
  private head = new HttpHeaders({
    'Content-Type': 'application/json',
    'sessionid': this.sessionid,
    // 'email': this.userEmail
  });
  getlocalvalues() {
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
  }  
  setProtectCandidate(datas): Observable<any> {
    var head = new HttpHeaders().set('content-type', 'application/json');
    if(this.sessionid)
    {
      head = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('sessionid', this.sessionid);
    }   
      this.param = {
        "method":datas.method,"cv_id": datas.cv_id, "ad_id": datas.ad_id, "recruiter_id": datas.recruiter_id, "organisation_id": datas.organisation_id
      }
    
      let url_set_protect_candidate =this.logonUrl.set_protect_candidate
     
      if(sessionStorage.getItem("LocalChat") && sessionStorage.getItem("LocalChat") == "true")
      {
        url_set_protect_candidate = this.logonUrl.local_set_protect_candidate
      }


    return this.http.post(url_set_protect_candidate, this.param,{headers:head}).pipe(
      tap(data => {
        //  alert(JSON.stringify(data));
      }),
      // catchError(this.getError)
    );
  }

  getSimpleChat(datas): Observable<any> {
    this.getlocalvalues();
    const head = new HttpHeaders()
      .set('content-type', 'application/json')
      // .set('sessionid', this.sessionid)
      // console.log("SESSIONID",this.sessionid);
      // console.log("URL", this.logonUrl.simple_chat)
   return this.http.post(this.logonUrl.simple_chat, datas, { headers: head }).pipe(
      tap(data => {
        //  alert(JSON.stringify(data));
      }),
      // catchError(this.getError)
    );


  }
  
  getOneToMatch(datas): Observable<any> {
    this.getlocalvalues();
    var head = new HttpHeaders().set('content-type', 'application/json');
    if(this.sessionid)
    {
      head = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('sessionid', this.sessionid);
    }
      this.param = {
        "method": datas.method,"ad_id": datas.adidNmatch, "cv_id": datas.cvIdNmatch, "matching_filter_list": datas.matching_filter_list, "db_state": datas.db_state, "detail_info": datas.detail_info
      }
      let url_n_to_m_match =this.logonUrl.n_to_m_match
      if(sessionStorage.getItem("LocalChat") && sessionStorage.getItem("LocalChat") == "true")
      {
        url_n_to_m_match = this.logonUrl.local_n_to_m_match
      }
    return this.http.post(url_n_to_m_match, this.param, { headers: head }).pipe(
      tap(data => {
      }),
    );
  }
  
  getTLIGETQUESTION_URL(datas): Observable<any> {
    var head = new HttpHeaders().set('content-type', 'application/json');
    if(this.sessionid)
    {
      head = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('sessionid', this.sessionid);
    }
   //   console.log(datas);
    return this.http.post(this.logonUrl.tli_get_question, datas,{headers:head}).pipe(
      tap(data => {
        //  alert(JSON.stringify(data));
      }),
      // catchError(this.getError)
    );


  }
  getTLIGETQUESTION_URL_ADMIN(datas): Observable<any> {
    var head = new HttpHeaders().set('content-type', 'application/json');
    if(this.sessionid)
    {
      head = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('sessionid', this.sessionid);
    }
     
        this.param = {
          "ad_id": datas.adid, "id": datas.id, "cv_id": datas.cvid, "doc_type": datas.doctype, "mode": datas.chatmode, "answer": datas.answer
        
      
      }
    
    //   console.log(this.param);
    return this.http.post(this.logonUrl.tli_get_question, this.param,{headers:head}).pipe(
      tap(data => {
        //  alert(JSON.stringify(data));
      }),
      // catchError(this.getError)
    );


  }
  
  getJobTitle(datas): Observable<any> {
    var head = new HttpHeaders().set('content-type', 'application/json');
    if(this.sessionid)
    {
      head = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('sessionid', this.sessionid);
    }
      this.param = {
        "method":datas.method, "id_list": datas.id_list, "ad_id": datas.ad_id
      }
    return this.http.post(this.logonUrl.get_ad_data, this.param, { headers: this.head }).pipe(
      tap(data => {
        //  alert(JSON.stringify(data));
      }),
      // catchError(this.getError)
    );


  }
  getCvData(datas): Observable<any> {
    const head = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('sessionid', this.sessionid)
    return this.http.post(this.logonUrl.get_cv_data, datas,{headers:head}).pipe(
      tap(data => {
        //  alert(JSON.stringify(data));
      }),
      // catchError(this.getError)
    );


  }
  setCvData(datas): Observable<any> {
    var head = new HttpHeaders().set('content-type', 'application/json');
    if(this.sessionid)
    {
      head = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('sessionid', this.sessionid);
    }
      this.param = {
        "method": datas.method,
         "cv_id": datas.cvid,
          "name": datas.name,
           "surname": datas.surname, 
           "zipcode": datas.zipcode,
        "location": datas.location, 
        "cust_id": datas.custid,
         "permanent": datas.permanentjob, 
         "fulltime": datas.fulltimejob, 
         "email": datas.email, 
         "phone": datas.phone,
          "country_code": datas.countrycodes,
          "local_chat":datas.local_chat
          // "keyword_string": "Developer"
      }
    return this.http.post(this.logonUrl.set_cv_data, this.param,{headers:head}).pipe(
      tap(data => {
        //  alert(JSON.stringify(data));
      }),
      // catchError(this.getError)
    );


  }
  DestroySpeechObject() {
    // this.speechRecognition.stop();
  }
}
