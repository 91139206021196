// Dialog Component for all popup login,Register.change password,File
// upload,Payment,Confirm Payment,terms and Condition etc....
import {Component, Inject, Input, ViewChild, ElementRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar} from '@angular/material';
import {NgNoValidate} from '@angular/forms/src/directives/ng_no_validate_directive';
import {HttpserviceService} from '../Services/httpservice.service';
import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from "@angular/router";
import {AlertComponent} from '../Common_Alert/Common_Alert.component';
import {map, startWith} from 'rxjs/operators';
import {urlEnv} from '../../environments/env';
// import { Observable, of, throwError } from 'rxjs'; for Chat
import {SpeechRecognitionService} from '../CV_Service.service';
import {ToastrService} from 'ngx-toastr';
//End
import {IfStmt} from '@angular/compiler';
declare let L;
/**
 * Page for Common Dialog for all the pages,
 * login,Register.change password,File upload,Payment,Confirm Payment,terms and Condition etc....
 */
/**
 * @title Dialog with header, scrollable content and actions
 */
@Injectable({providedIn: 'root'})
@Component({selector: 'app-dialog', templateUrl: './Dialog.component.html', styleUrls: ['./Dialog.component.css']})
export class DialogComponent {
    //Common Ajax Varibale
    public logonUrl = urlEnv
    // for Chat by Balaji Starts here
    countryCtrl = new FormControl();
    speechData : any;
    userMessageData : any;
    msgData = false;
    Kundenreferenz1 : any

    public obj : any;
    public obj2 : any;
    public adminData : any;
    public adminDataWithId : any;
    public adminDataMsg : any;
    mikeIconGif = false;
    mikeIcon = true;
    adminDataId : any;
    AdminData : any;
    questionData = [];
    answerData = [];
    admintypingGIF = false;
    emptyMsg = false;
    adminMsg : any
    getTLIGETQUESTION_URLFlag = false;
    percentage = 0;
    loadingPercentage = 0;
    test : any;
    TLIQuestionChat = false;

    // Page Div Condition starts here
    simpleChat = true;
    msgSendDiv = true;
    thankyouBtnDiv = false;
    // Page Div Condition Ends here
    speechRecognition : any;
    responseWait = false;

    TLIGETQUESTION_URL = false;
    // End here

    emailFlag = false;
    commonloader : boolean = false;
    commonloaderAS : boolean = false;
    termsdiv = true;
    sessionid : string = "";
    goSuccessFlag = false;
    paymentdetailsform : FormGroup;
    candidatelogoform : FormGroup;
    directApplyform : FormGroup;
    pricingform : FormGroup;
    erecruiterform : FormGroup;
    hintptfirstname : boolean = false;
    hintptcompanyname : boolean = false;
    hintptlastname : boolean = false;
    hintptcity : boolean = false;
    hintptstreetname : boolean = false;
    hintptzip : boolean = false;
    hintptcountryname : boolean = false;
    hintpttelephoneno : boolean = false;
    hintvatnumber : boolean = false;
    hintptemail : boolean = false;
    hintptvalidemail : boolean = false;
    hintcontact_person : boolean = false;
    hintcompanies : boolean = false;

    Facillity_Mgnt_room_cleaning : boolean = false;
    Food_retail : boolean = false;
    General_retail : boolean = false;
    Hospitality_kitchen : boolean = false;
    Hospitality_service : boolean = false;
    Industriehelfer : boolean = false;
    Logistics_driver : boolean = false;
    logistics_warehouse : boolean = false;
    Nursing_general_nursing : boolean = false;
    Nursing_geriatric_nursing : boolean = false;
    Tourism_housekeeping : boolean = false;
    // hintptvalidemail: var heat; boolean = false; direct apply checkbox

    one : boolean = false;
    fifty : boolean = false;
    twofifty : boolean = false;
    thousand : boolean = false;
    fivethousand : boolean = false;
    tenthousand : boolean = false;
    twentythousand : boolean = false;

    fifty1 : boolean = false;
    two1 : boolean = false;
    three1 : boolean = false;
    four1 : boolean = false;
    five1 : boolean = false;
    six1 : boolean = false;

    uploadfile : boolean;
    Noownjobad : boolean;
    payment_confirmaccept : boolean = false;
    hint_payment_confirmaccept : boolean = false;
    togglecv : boolean = false

    /**
   * @ignore
   */
    @ViewChild('otp1')private elementRef1 : ElementRef;
    /**
 * @ignore
 */
    @ViewChild('otp2')private elementRef2 : ElementRef;
    /**
 * @ignore
 */
    @ViewChild('otp3')private elementRef3 : ElementRef;
    /**
 * @ignore
 */
    @ViewChild('otp4')private elementRef4 : ElementRef;
    /**
 * @ignore
 */
    @ViewChild('otp5')private elementRef5 : ElementRef;
    /**
 * @ignore
 */
    @ViewChild('otp6')private elementRef6 : ElementRef;
    /**
 * @ignore
 */
    @ViewChild('demo11')private demo11 : ElementRef;
    /**
 * @ignore
 */
    @ViewChild('Content')contentArea : ElementRef;
    /**
 * @ignore
 */
    @ViewChild('panel', {read: ElementRef})public panel : ElementRef < any >;
    /**
   * @ignore
   */
    @ViewChild('fileInput')fileInput;
    /**
 * @ignore
 */
    @ViewChild('fileInput1')fileInput1;
    /**
 * @ignore
 */
    @ViewChild('fileInput2')fileInput2;
    /**
 * @ignore
 */
    @ViewChild('fileInput4')fileInput4;

    /**
   * @ignore
   */
    @ViewChild('map1')map1;

    public dialogname : any;
    public getlogindetails : any = {};
    loginform : FormGroup;
    hintemailId : boolean = false;
    hintvaildemailId : boolean = false;

    fcnemail : string = "";
    loginform1 : FormGroup;
    changepwdform : FormGroup;
    hintpassword : boolean = false;

    hintoldpwd : boolean = false;
    hintnewpwd : boolean = false;
    hintconfirmpwd : boolean = false;
    cpwdvaliderror : boolean = false;
    logincommonerror : boolean = false;
    logincommonerrortext : string = "";
    cpwdcommonerror : boolean = false;
    cpwdcommonerrortext : string = "";
    EEEE : boolean;
    sss : boolean;
    public getrecruiterreport : any = {};
    RegisterEmailForm : FormGroup;
    RegisterOTPForm : FormGroup;
    fp_RegisterOTPForm : FormGroup;
    RegisterForm : FormGroup;
    fb_updateForm : FormGroup;
    public otp : string = "";
    EmptyErr : boolean;
    emailverify : boolean = true;
    ValidErr : boolean;
    MailMessage : string = "";
    resetForm : FormGroup;
    passowrdError : boolean;

    hintErrpassword : boolean = false;
    hintconfirmpassword : boolean = false;
    Errvalidpassword : boolean = false;
    Errmatchpassword : boolean = false;

    resendOTPdiv : boolean = false;
    resendOTPforgetdiv : boolean = false;
    OTPErr : boolean = false;
    OTPdiv : boolean = true;
    ForgotOTPErr : boolean = false;
    ForgotOTPdiv : boolean = true;
    response : any;
    isLogged : string;
    userEmail : string;
    accesstoken : any;
    data1 : any;
    menuFlag1 : boolean;
    counter : number = 0;
    recruiter_id : string;
    loginuser : string;
    contentHeight : any;
    hintcompany_name : boolean;
    hintterms_conditions : boolean;
    hintwelcome_text : boolean;
    hintcompany_logo : boolean;
    Imagename : any;
    Imagetype : any;
    imagePreview : string | ArrayBuffer;
    welcomeTextCV : any;
    selectedVal1 : string;
    selectionlink : boolean;
    linktype : boolean = false;
    reLogin : boolean;
    userEmail1 : string;
    Imagename2 : any;
    Imagetype2 : string;
    imagePreview2 : string | ArrayBuffer;
    imagePreview4 : string | ArrayBuffer;
    fileuploadFlag : boolean = false;
    recruiterid : string;
    localvalue : {
        access_token: any;
        ad_berufsgruppe: any;
        ad_id: any;
        clientadid: any;
    };
    Imagename1 : any;
    Imagetype1 : any;
    imagePreview1 : string | ArrayBuffer;
    fileuploadFlag1 : boolean;
    Ownjob_ad : any;
    Noownjob_ad : boolean;
    Kundenreferenz : boolean;
    plandetails : any;
    one1 : boolean;
    internal_reference : any;
    bluecollar : boolean;
    paymentdetails : any;
    hintforjobtitle : boolean;
    termslink : boolean;
    chat_history : any;
    clientad_id : any;
    currentlanguage : any;
    languagedata : any;
    country_detail : any;
    countrycd : any;
    countryListValues : any;
    countriesValue : any;
    country : any;
    CV_GetHeatMap : any;
    AD_GetHeatMap : any;
    getData1 : any;
    secondArry : any[][];
    firstArry : any[][];
    map : any;
    map2 : any;
    mapcount : number = 0;
    response1 : any;
    m1 : any;
    first : any;
    second : any;
    timestampdata : any;
    ok_button:boolean = true;
    /**
 * @ignore
 */
    constructor(private dialog : MatDialog, private formBuilder : FormBuilder, public translate : TranslateService, public http : HttpserviceService, private spinner : NgxSpinnerService, private router : Router, public snackBar : MatSnackBar, public HTTPServiceService : HttpserviceService, private speechRecognitionService : SpeechRecognitionService, private toastr : ToastrService, public dialogRef : MatDialogRef < DialogComponent >, @Inject(MAT_DIALOG_DATA)public data) {
        this.speechData = "";

        var lang = localStorage.getItem('language')
        translate.addLangs(['en', 'de']);
        if (lang) {
            translate.setDefaultLang(lang);
            translate.use(lang);
            this.currentlanguage = lang
        } else {
            translate.setDefaultLang('de');
            translate.use('de');
            this.currentlanguage = 'de'
        }

        this.countryListValues = this
            .countryCtrl
            .valueChanges
            .pipe(startWith(''), map(country => country
                ? this._filterCountry(country)
                : this.countriesValue));
    }
    directApply : boolean;
    easyApply : boolean;
    getadId : any;
    getAccessToken : any;
    recruiter_id1 : any;
    chat : boolean = true;
    feedback : boolean = false
    matchreport : boolean = false
    default_welcometext : boolean = false
    default_termsconditions : boolean = false
    optionlink : boolean = false
    getCVType : any;
    getCVDT : any;
    avatorSpeaking : boolean;
    UrlChat : any;
    isReadOnly : boolean = false;
    isReadOnlyTC : boolean = false;

    // var heat;

    hidelandingfilepopupload : boolean = false;

    @ViewChild('scroll', {read: ElementRef})public scroll : ElementRef < any >;
    /**
   * @ignore
   */
    public scrollBottom() {
        if (this.scroll) {
            this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
        }
    }
    /**
 * @ignore
 */
    openPdfDoc1() {
        window.open('/assets/Allgemeine.pdf', '_blank');
    }
    /**
 * @ignore
 */
    openPdfDoc2() {

        window.open('/assets/Datenschutz_BHeads.pdf', '_blank');
    }
    termsAndConditonData : any;
    enhancedLinktrue = false
    /**
 *@ignore
 */
    enhancedLink(value) {
        console.log(value.checked)
        if (value.checked == true) {
            this.enhancedLinktrue = true
        } else {
            this.enhancedLinktrue = false
        }
    }
    /**
 *@implements Ng init Function starts Here
 */
    ngOnInit() {
        console.log(this.data.dialogname);
        this.checkboxfix1('Noownjob_ad');

        if (this.data.dialogname == 'CompanyTermsAndCondions') {
            this.welcomeTextCV = this.data.openTermsConditionsForCV;
            var test = JSON.parse(this.welcomeTextCV);
            // console.log(test);
            this.termsAndConditonData = test;
        }
        if (this.data.dialogname == 'customizecandidatepopup') {
            if (this.linktype) {
                // alert()
                this.RefrenceLabel = true
            }
        }
        this.UrlChat = this.data.chatUrl;

        this.selectionlink = false;
        this.selectedVal1 = 'option1';
        this.getCVType = sessionStorage.getItem("type");
        this.getCVDT = sessionStorage.getItem("UrladId");
        // if ( this.data.forEasyApplyJD || this.data.forEasyApplyHitMap ||
        // this.data.forMatchReportPage || this.data.forSuccessPage) {
        if (this.data.dialogname == 'goSuccessFlag' || this.data.dialogname == 'candidateClose' || this.data.dialogname == 'emailFlag') {

            this.getEmailIdCandidate();

        }
        // } this.thankyouBtnDiv = true;
        this.getadId = sessionStorage.getItem('adId');
        if (JSON.parse(localStorage.getItem('token')) != "") {
            this.accesstoken = JSON.parse(localStorage.getItem('token'));
        }
        this.userEmail = localStorage.getItem('userEmail');
        if (this.userEmail) {
            this.loginuser = this
                .userEmail
                .split('@')[0];
        }
        this.recruiter_id1 = localStorage.getItem("recruiter_id");
        this.recruiterid = localStorage.getItem('recruiter_id');
        let type = localStorage.getItem("applytype");
        this.linktype = JSON.parse(localStorage.getItem("linktype"));
        console.log(this.linktype, "linktype ")

        if (type == 'directapply ') {
            this.directApply = true;
        } else {
            this.easyApply = true;
        }
        // console.log(this.data);
        if (this.data.dialogname == 'linkpopup') {
            this.getTLIGETQUESTION_URL();
        }

        this.loginforms();
        this.fb_updateForms();
        this.changepasswordform();
        let emailregex : RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.RegisterEmailForm = this
            .formBuilder
            .group({
                RegisterEmail: [
                    '',
                    [
                        Validators.required, Validators.pattern(emailregex)
                    ]
                ]
            })
        this.RegisterFormgroup();
        this.RegisterOTPFormgroup();
        this.fp_RegisterOTPFormgroup();
        this.paymentdetailsforms();
        this.candidatelogoforms();
        this.directApplyforms();
        this.pricingforms();
        this.sss = true;
        this.EEEE = false;
        if (this.data.dialogname === 'Logindetailspopup') {
            //this.login();
        }

        if (this.data.dialogname === 'Paymentdetailspopup') {
            this.get_countrycode()
            this.get_paymentdetail();
        }

        if (this.data.dialogname == 'chatHistortPopup') {
            this.get_chathistory()
        }

        this.getlanguagevalue()

        if (this.data.dialogname == "GetHeatMap") {
            if(this.data.OkButton){
                if (this.data.OkButton == "false"){
                    this.ok_button = false;
                }
            }
            this.heatMapDialog = true
            this.getdataheatmap(true)

            this.map = L
                .map('map', {
                minZoom: 5,
                maxZoom: 10
            })
                .setView([
                    51.1657, 10.4515
                ], 5);

            var tiles = L
                .tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'})
                .addTo(this.map);
        }
    }
    analyzing : boolean = false
    collecting : boolean = false
    finalizing : boolean = false
    oops : boolean = false
    /**
 * Head Map Start Here
 */
    getdataheatmap(i) {
        this.commonloader = true

        var localvalue = JSON.parse(localStorage.getItem('token'))
        var flagdata = {

            "method": "AUTH",
            "ad_id": localvalue.ad_id,
            "cv_id": "",
            "document_type": "CV"
        }
        this.analyzing = true
        var firstArry = []
        var secondArry = []
        // var heat;
        setTimeout(() => {
            this.analyzing = false;
            this.collecting = true
            this.finalizing = false
        }, 5000);
        setTimeout(() => {
            this.analyzing = false;
            this.collecting = false
            this.finalizing = true
        }, 7000);
        setTimeout(() => {
            this.analyzing = false;
            this.collecting = false
            this.finalizing = false
            this.oops = true
        }, 12000);

        this
            .HTTPServiceService
            .commonMethod(flagdata, this.logonUrl.get_heatmap)
            .subscribe(data => {
                this.response = data
                if (this.response && this.response.status == 200) {

                    this.CV_GetHeatMap = data;
                    this.getData = this.CV_GetHeatMap.res.message.HeatmapData
                    for (var i = 0; i < this.getData.length; i++) {
                        var lat = this.getData[i].match_infos_cv_latitude;
                        var long = this.getData[i].match_infos_cv_longitude;
                        if (lat !== null && long !== null && lat !== 0 && long !== 0) {
                            firstArry.push([lat, long]);
                        }
                    }
                    this.firstArry = []
                    console.log(firstArry.length, "length")
                    this.firstArry = firstArry.map(function (p) {
                        return [p[0], p[1]];
                    });
                    // this.firstArry = JSON.parse(localStorage.getItem('one')) if (firstArry.length
                    // != 0) {
                    this.first = L
                        .heatLayer(this.firstArry)
                        .addTo(this.map);
                    this.analyzing = false;
                    this.collecting = false
                    this.finalizing = false
                    this.oops = false
                    this.commonloader = false
                    // } localStorage.setItem('one',JSON.stringify(this.firstArry))
                    flagdata.document_type = "AD";
                    this
                        .HTTPServiceService
                        .commonMethod(flagdata, this.logonUrl.get_heatmap)
                        .subscribe(data1 => {
                            this.response1 = data1
                            if (this.response1 && this.response1.status == 200) {
                                this.AD_GetHeatMap = this.response1;
                                this.getData1 = this.AD_GetHeatMap.res.message.HeatmapData
                                for (var i = 0; i < this.getData1.length; i++) {
                                    var lat = this.getData1[i].match_infos_ad_latitude;
                                    var long = this.getData1[i].match_infos_ad_longitude;
                                    if (lat !== null && long !== null && lat !== 0 && long !== 0) {
                                        secondArry.push([lat, long]);
                                    }
                                }
                                this.secondArry = []
                                console.log(secondArry.length, "length")
                                this.secondArry = secondArry.map(function (p) {
                                    return [p[0], p[1]];
                                });

                                // console.log(this.firstArry, "first") console.log(this.secondArry, "second")

                            }
                        })
                }

            })
    }
    /**
   * Head Functionlity Start here
   */
    toggleadcv() {

        this
            .map
            .remove();
        this.map = L
            .map('map', {
            minZoom: 5,
            maxZoom: 10
        })
            .setView([
                51.1657, 10.4515
            ], 5);
        var tiles = L
            .tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'})
            .addTo(this.map);
        if (this.togglecv) {
            if (this.firstArry && this.firstArry.length > 0) {
                this.first = L
                    .heatLayer(this.firstArry)
                    .addTo(this.map);
            }

            this.togglecv = false
        } else {

            this.togglecv = true
            if (this.secondArry && this.secondArry.length > 0) {
                this.second = L
                    .heatLayer(this.secondArry)
                    .addTo(this.map);
            }

        }
    }
    heatMapDialog = false
    getData : any
    /**
 * @ignore
 */
    private _filterCountry(value : string) {
        const filterValue = value.toLowerCase();
        console.log(this.countriesValue)
        return this
            .countriesValue
            .filter(state => state.country.toLowerCase().indexOf(filterValue) === 0);
    }

    /**
   * @ignore
   */
    ngAfterViewChecked() {
        this.scrollBottom()
    }
    /**
 * @ignore
 */
    goToChat() {
        var old = localStorage.getItem("OldUrl")
        var dtUrl = sessionStorage.getItem('urlDt');
        var applyType = localStorage.getItem('applytype');
        if (old == "true") {
            this
                .router
                .navigate(['/chat'], {
                    queryParams: {
                        type: applyType,
                        dt: dtUrl
                    }
                });
        } else {
            this
                .router
                .navigate(['/chat/' + dtUrl]);
        }

    }
    /**
 * @ignore
 */
    goToMatchReport() {
        var old = localStorage.getItem("OldUrl")
        var dtUrl = sessionStorage.getItem('urlDt');
        var applyType = localStorage.getItem('applytype');
        if (old == "true") {
            this
                .router
                .navigate(['/match-report'], {
                    queryParams: {
                        type: applyType,
                        dt: dtUrl
                    }
                });
        } else {
            this
                .router
                .navigate(['/match-report/' + dtUrl]);
        }
    }
    /**
 * @ignore
 */
    goToSuccess() {
        var old = localStorage.getItem("OldUrl")
        var dtUrl = sessionStorage.getItem('urlDt');
        var applyType = localStorage.getItem('applytype');
        if (old == "true") {
            this
                .router
                .navigate(['/success'], {
                    queryParams: {
                        type: applyType,
                        dt: dtUrl
                    }
                });
        } else {
            this
                .router
                .navigate(['/success/' + dtUrl]);
        }

    }
    EasyApplyPayment = false
    /**
 * @ignore
 */
    goConfirmationPopup() {
        this.EasyApplyPayment = true
        sessionStorage.setItem('EasyApplyAdidPayment', "true");
        console.log("EasyApply", this.EasyApplyPayment)
        // this.getMyIdsEasyApply()
        this
            .dialogRef
            .close("beforeApplyPaymentOk");

    }
    /**
   * @ignore
   */
    citiesList(data) {
        this.country = data.international_dialing
        this
            .paymentdetailsform
            .patchValue({countrycode: data.international_dialing});

    }
    /**
   * @ignore
   */
    docheckall(a, b) {
        var value = 0
        if (this.data.dialogname == 'directapply_prizing') {
            value = 349
        } else if (this.data.dialogname == 'easyapply_prizing') {
            value = 29
        }
        this.plandetails = b
        this.finalValue = (a * b) + value;
        console.log(this.finalValue);
        var test = a - 3;
        var test1 = test * b;
        var test3 = test1 - (test1 * 50) / 100;
        this.finalValue = test3;
        // alert(test3);

    }
    /**
 * @ignore
 */
    checkLoginBeforePricing(value) {
        if (value == "eassy") {
            if (this.one1 || this.fifty1 || this.two1 || this.three1 || this.four1 || this.five1 || this.six1) {
                var data = {
                    value: this.finalValue,
                    goto: 'beforePricingPage',
                    plandetails: this.plandetails
                }
                this
                    .dialogRef
                    .close(data);
            } else {
                const dialogRef = this
                    .dialog
                    .open(DialogComponent, {
                        disableClose: false,
                        data: {
                            dialogname: 'checkboxpopup'
                        }
                    });
                dialogRef
                    .afterClosed()
                    .subscribe((result : any) => {});
            }

        } else if (value == "direct") {
            if (this.one || this.fifty || this.twofifty || this.thousand || this.fivethousand || this.tenthousand || this.twentythousand) {
                var data = {
                    value: this.finalValue,
                    goto: 'beforePricingPage',
                    plandetails: this.plandetails
                }
                this
                    .dialogRef
                    .close(data);
            } else {
                const dialogRef = this
                    .dialog
                    .open(DialogComponent, {
                        disableClose: false,
                        data: {
                            dialogname: 'checkboxpopup'
                        }
                    });
                dialogRef
                    .afterClosed()
                    .subscribe((result : any) => {});
            }
        }

    }

    //for Chat by balaji
    getAdioData : any;
    /**
 * Comflict Chats Start here
 */
    getTLIGETQUESTION_URL() {
        this.accesstoken = JSON.parse(localStorage.getItem('token'));
        var getAccessToken = localStorage.getItem('getAccessToken');

        this.obj2 = {};

        this.obj2.adid = this.accesstoken.ad_id;
        this.obj2.id = this.accesstoken.ad_id;
        this.obj2.cvid = "";
        this.obj2.doctype = "AD";
        this.obj2.chatmode = "conflict";
        this.obj2.answer = this.userMessageData;

        this
            .speechRecognitionService
            .getTLIGETQUESTION_URL_ADMIN(this.obj2)
            .subscribe(data => {
                // Getting Simple Chat API Initially Starts
                this.adminDataWithId = data;
                // Getting Simple Chat API Initially End
                if (this.adminDataWithId.res.finish != '1') {
                    this.adminDataMsg = this.adminDataWithId.res.question;
                    //Pushing the Chat Data Starts here
                    var chatData = {
                        value: this.adminDataMsg,
                        restype: "qs"
                    }
                    this
                        .questionData
                        .push(chatData);
                    this.admintypingGIF = false;
                    //Pushing the Chat Data Ends here

                    this.percentage = this.percentage + 12;
                    this.loadingPercentage = this.percentage;
                    //Make Gif and Loading percentage End
                } else {
                    //Pushing the Chat Data Starts here
                    var chatData = {
                        value: data.res.question,
                        restype: "qs"
                    }
                    this
                        .questionData
                        .push(chatData);
                    console.log(this.adminDataWithId, 'access token');
                    this.admintypingGIF = false;
                    this.thankyouBtnDiv = true;
                    this.msgSendDiv = false;
                    //Pushing the Chat Data Ends here Store the Value in SS starts Here
                    sessionStorage.setItem('chatId', this.adminDataWithId.res.chatid);
                    //Store the Value in SS Ends Here Make Gif and Loading percentage Start
                    this.percentage = 100;
                    this.loadingPercentage = this.percentage;
                    //Make Gif and Loading percentage End
                }
            }, error => console.log(error))
    }
    /**
   * @ignore
   */
    sendMsgBtn(value) {
        // this.avatorSpeaking = false;

        document
            .getElementById('mouth')
            .classList
            .remove('speaking');
        if (this.questionData && this.questionData[0].value == '') {
            this
                .toastr
                .warning("Bitte warten");
        } else {

            if (this.admintypingGIF == true) {
                this
                    .toastr
                    .warning("Bitte warten");

            } else {

                // document.getElementById('mouth').classList.remove('speaking');
                this.admintypingGIF = true;

                if (this.speechData != '') {

                    this.userMessageData = this.speechData;
                    var chatData = {
                        value: this.userMessageData,
                        restype: "ans"
                    }
                    this
                        .questionData
                        .push(chatData);
                    this.speechData = '';
                    this.getTLIGETQUESTION_URL();

                } else {

                    this
                        .toastr
                        .error("Bitte eine Nachricht eingeben");
                    this.admintypingGIF = false;

                }
            }
        }

    }

    /**
   * @ignore
   */
    //Restart the Chat
    restartChat() {

        this.admintypingGIF = false;
        this.questionData = [];
        this.speechData = '';
        this.percentage = 0;
        this.loadingPercentage = 0;
        this.ngOnInit();
    }

    get_countrycode() {

        this
            .http
            .commonMethodGet(this.logonUrl.getCountryCode)
            .subscribe(data => {

                this.response = data

                this.countriesValue = this.response.country_detail
                console.log("success", this.countriesValue)

            });

    }
    /**
   * Common Alert
   */
    alertmsg(msg, errtype) {
        this
            .snackBar
            .openFromComponent(AlertComponent, {
                data: {
                    data: msg,
                    classname: errtype
                },
                duration: 4000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: 'blue-snackbar'
            });
    }
    /**
 * @ignore
 */
    focus(input, ev, id) {

        if (ev.keyCode == 46) {
            let previousElement = document.getElementById(id)as HTMLInputElement;
            previousElement.value = "";

        } else if (ev.keyCode == 8) {
            if (input == 2) {
                if (this.counter == 0) {
                    this.counter++;
                } else {
                    this.counter = 0;
                    this
                        .elementRef1
                        .nativeElement
                        .focus();
                }
            } else if (input == 3) {
                if (this.counter == 0) {
                    this.counter++;
                } else {
                    this.counter = 0;
                    this
                        .elementRef2
                        .nativeElement
                        .focus();
                }
            } else if (input == 4) {
                if (this.counter == 0) {
                    this.counter++;
                } else {
                    this.counter = 0;
                    this
                        .elementRef3
                        .nativeElement
                        .focus();
                }
            } else if (input == 5) {
                if (this.counter == 0) {
                    this.counter++;
                } else {
                    this.counter = 0;
                    this
                        .elementRef4
                        .nativeElement
                        .focus();
                }
            } else if (input == 6) {
                if (this.counter == 0) {
                    this.counter++;
                } else {
                    this.counter = 0;
                    this
                        .elementRef5
                        .nativeElement
                        .focus();
                }
            }
        } else if (ev.keyCode >= 48 && ev.keyCode <= 57 || ev.keyCode >= 97 && ev.keyCode <= 105 || ev.keyCode == 13) {
            if (input == 1) {
                this
                    .elementRef2
                    .nativeElement
                    .focus();
            } else if (input == 2) {
                this
                    .elementRef3
                    .nativeElement
                    .focus();
            } else if (input == 3) {
                this
                    .elementRef4
                    .nativeElement
                    .focus();
            } else if (input == 4) {
                this
                    .elementRef5
                    .nativeElement
                    .focus();
            } else if (input == 5) {
                this
                    .elementRef6
                    .nativeElement
                    .focus();
            }
        } else {
            ev.preventDefault();
        }
        this.OTPErr = false;
        this.OTPdiv = true;
        this.ForgotOTPErr = false;
        this.ForgotOTPdiv = true;

    }
    /**
 * @ignore
 */
    changepasswords() {

        const dialogRef = this
            .dialog
            .open(DialogComponent, {
                disableClose: true,
                data: {
                    dialogname: 'changepasswordpopup'
                }
            });
        dialogRef
            .afterClosed()
            .subscribe((result : any) => {});
    }
    /**
 * @ignore
 */
    ClearpwdErr() {
        this.hintoldpwd = false;
        this.hintnewpwd = false;
        this.hintconfirmpwd = false;
        this.cpwdcommonerror = false;
        this.cpwdvaliderror = false;
    }
    /**
 * @ignore
 */
    changepasswordsubmit() {
        if (this.changepwdform.value.oldpassword == "") {
            this.hintoldpwd = true;
            this.hintnewpwd = false;
            this.hintconfirmpwd = false;
        } else if (this.changepwdform.value.newpassword == "") {
            this.hintoldpwd = false;
            this.hintnewpwd = true;
            this.hintconfirmpwd = false;
            this.cpwdvaliderror = false;
            this.cpwdcommonerror = false;
        } else if (this.changepwdform.value.confirmpassword == "") {
            this.hintoldpwd = false;
            this.hintnewpwd = false;
            this.hintconfirmpwd = true;
            this.cpwdvaliderror = false;
            this.cpwdcommonerror = false;
        } else if (this.changepwdform.controls.confirmpassword.errors != null || this.changepwdform.controls.newpassword.errors != null) {
            this.hintoldpwd = false;
            this.hintnewpwd = false;
            this.hintconfirmpwd = false;
            this.cpwdvaliderror = true;
            this.cpwdcommonerror = false;
        } else if (this.changepwdform.value.confirmpassword != this.changepwdform.value.newpassword) {
            this.hintoldpwd = false;
            this.hintnewpwd = false;
            this.hintconfirmpwd = false;
            this.cpwdvaliderror = false;
            this.cpwdcommonerror = true;
            return false;
        } else {
            this.hintoldpwd = false;
            this.hintnewpwd = false;
            this.hintconfirmpwd = false;
            this.cpwdcommonerror = false;
            this.getlogindetails = {};
            // this.getlogindetails.flag = "CHANGE_PASSWORD"; this.getlogindetails.profileid
            // = "1";
            this.getlogindetails.email = this.userEmail;
            this.getlogindetails.oldpassword = this.changepwdform.value.oldpassword;
            this.getlogindetails.password = this.changepwdform.value.confirmpassword;
            // this.spinner.show();
            this.commonloader = true;
            this
                .HTTPServiceService
                .commonMethod(this.getlogindetails, this.logonUrl.changePassword)
                .subscribe(data => {
                    // this.spinner.hide();
                    this.commonloader = false;
                    if (data != null) {
                        var details : any = data;
                        if (details.status == 200) {
                            this.alertmsg(details.message, 'success')
                            this
                                .dialogRef
                                .close();
                        } else {

                            this.alertmsg(details.message, 'error')
                        }
                    } else {}
                }, error => {});
        }

    }
    /**
 * @ignore
 */
    forgetpasswordsubmit() {
        if (this.fb_updateForm.controls.newpassword.value == "") {
            this.hintErrpassword = true;
            this.hintconfirmpassword = false;
            this.Errvalidpassword = false;
            this.Errmatchpassword = false;
            return false;
        } else if (this.fb_updateForm.controls.confirmpassword.value == "") {
            this.hintErrpassword = false;
            this.hintconfirmpassword = true;
            this.Errvalidpassword = false;
            this.Errmatchpassword = false;
            return false;
        } else if (this.fb_updateForm.controls.newpassword.errors != null || this.fb_updateForm.controls.confirmpassword.errors != null) {
            this.hintErrpassword = false;
            this.hintconfirmpassword = false;
            this.Errvalidpassword = true;
            this.Errmatchpassword = false;
            return false;
        } else if (this.fb_updateForm.value.newpassword != this.fb_updateForm.value.confirmpassword) {
            this.hintErrpassword = false;
            this.hintconfirmpassword = false;
            this.Errvalidpassword = false;
            this.Errmatchpassword = true;
            return false;
        } else {
            this.getlogindetails = {};
            this.getlogindetails.email = this
                .loginform1
                .value
                .emailId
                .toLowerCase();
            this.getlogindetails.password = this.fb_updateForm.value.confirmpassword;
            // this.spinner.show();
            this.commonloader = true;
            this
                .HTTPServiceService
                .withoutSessionMethod(this.getlogindetails, this.logonUrl.resetPassword)
                .subscribe(data => {
                    // this.spinner.hide();
                    this.commonloader = false;
                    if (data != null) {
                        var details : any = data;
                        if (details.status == 200) {
                            this.data.dialogname = "Logindetailspopup";
                            this.alertmsg(details.message, 'success');
                        } else {
                            this.alertmsg(details.message, 'error');

                        }
                    } else {}
                }, error => {});
        }

    }
    finalValue : any;
    /**
   * @ignore
   */
    openTermsPayment() {
        window.open('/wp-content/uploads/2020/09/AGB-und-Auftragsdatenverarbeitung.pdf', '_blank');
    }
    chatData : any;
    /**
 * Showing Chat History Popup
 */
    get_chathistory() {
        console.log("Dialog ChatHistory Id", this.data);
        var daflag = {

            "ad_id": this.data.ad_id

        }

        this
            .http
            .commonMethod(daflag, this.logonUrl.get_id_details)
            .subscribe(data => {
                console.log("chat History", data);
                this.response = data
                if (this.response.status == 200) {

                    console.log("Get ID Deatails", this.response);
                    console.log(this.response.res.blue_collor);
                    console.log(this.response.res.white_collor);

                    var flagdata1 = {
                        // "flag": "GET_DOCUMENT",
                        "method": "AUTH",
                        // "clientid": "1",
                        "cv_id": this.data.cv_id,
                        "document_type": "CV",
                        "blue_collor": false,
                        "white_collor": false,
                        "easyapply": false
                    }
                    if (this.response.res.blue_collor) {
                        flagdata1.blue_collor = true
                        flagdata1.white_collor = false
                        flagdata1.easyapply = false
                    } else if (this.response.res.white_collor) {
                        flagdata1.blue_collor = false
                        flagdata1.white_collor = true
                        flagdata1.easyapply = false
                    } else {
                        flagdata1.blue_collor = false
                        flagdata1.white_collor = false
                        flagdata1.easyapply = true
                    }

                    // console.log(flagdata1);

                    let url_get_document =this.logonUrl.get_document;

                    if (this.data.local_chat && this.data.local_chat == true)
                        {
                            url_get_document =this.logonUrl.local_get_document
                        }
                    this
                        .http
                        .commonMethod(flagdata1, url_get_document)
                        .subscribe(data => {
                            this.chatData = data
                            if (this.response.status == 200) {
                                console.log("fullChat", this.chatData);
                                var data1 = this.chatData.res.simple_chat
                                this.chat_history = this.chatData.res.simple_chat
                                var test = this.chatData.res.conflict_chat
                                console.log('SimpleChat', data1);
                                if (test != '' && test != undefined) {
                                    this.chat_history = this
                                        .chat_history
                                        .concat(test);
                                    console.log('Conflict Chat', test)
                                }
                                console.log("both", this.chat_history)
                            }
                        })
                }
            });
    }

    /**
   *GET_HEATMAP_DATA
   */
    getheatmapdata() {
        var flagdata = {
            "method": "AUTH", // "flag": "GET_HEATMAP_DATA",
            "ad_id": "16cef93fba77441d96f9fc1cdc280074",
            "cv_id": "4e1bc2f957f4d2981697a8185f4e64a5",
            "document_type": "CV"
        }
        this
            .HTTPServiceService
            .commonMethod(flagdata, this.logonUrl.get_heatmap)
            .subscribe(data => {
                // if (this.response.status == 200) {
                console.log("test", data)
                this.CV_GetHeatMap = data
                // }
            })

    }

    /**
   *Main Login Start Here
   */
    GetHeatMap : any

    login(goto) {
        if (this.loginform1.value.emailId != "" && this.loginform1.value.fcnpassword != "" && this.hintvaildemailId == false) {
            this.getlogindetails = {};

            this.getlogindetails.email = this
                .loginform1
                .value
                .emailId
                .toLowerCase();
            this.getlogindetails.password = this.loginform1.value.fcnpassword;
            this.reLogin = false;
            // this.spinner.show();
            this.commonloader = true;

            this
                .HTTPServiceService
                .withoutSessionMethod(this.getlogindetails, this.logonUrl.login)
                .subscribe(data => {
                    // console.log("checking",data.status);
                    this.commonloader = false;
                    if (data != null) {
                        var details : any = data;
                        if (details.status == 200) {
                            this.hintemailId = false;
                            this.hintvaildemailId = false;
                            this.hintpassword = false;
                            if (details.recruiter_id) {
                                localStorage.setItem("recruiter_id", details.recruiter_id)
                                this.recruiter_id = localStorage.getItem('recruiter_id');
                            }
                            localStorage.setItem("sessionid", details.sessionid)
                            localStorage.setItem("isLogged", "true")
                            localStorage.setItem("userEmail", this.loginform1.controls.emailId.value)
                            this.isLogged = localStorage.getItem('isLogged');
                            this.sessionid = localStorage.getItem('sessionid');
                            this.userEmail = localStorage.getItem('userEmail');
                            if (this.isLogged == "true") {
                                this.menuFlag1 = false
                            } else {
                                this.menuFlag1 = true
                            }
                            if (this.userEmail) {
                                this.loginuser = this
                                    .userEmail
                                    .split('@')[0];
                            }
                            this.commonloader = false;

                            let getArray = [];
                            getArray.push({email: this.loginform1.controls.emailId.value, session: details.sessionid})
                            // this.router.navigate(['/recruiterfeedback'])
                            if (goto == "payment") {
                                this
                                    .dialogRef
                                    .close(goto);
                            } else if (goto == "reg") {
                                this
                                    .dialogRef
                                    .close(goto);
                            } else if (goto == "onlypayment") {
                                this
                                    .dialogRef
                                    .close(goto);
                            } else if (goto == "bluecollor") {

                                this.data.goto = "bluecollor";
                                this.data.goto1 = "bluecollor";
                                this
                                    .dialogRef
                                    .close(goto);
                                // this.get_paymentdetail();
                                this.getlink_bluecollor()
                            } else {
                                this
                                    .dialogRef
                                    .close(getArray);

                            }
                            // this.sessionid=details.sessionid;
                            if (details.message == true) {
                                this.alertmsg(details.message, 'success');
                            } else {
                                this.alertmsg("Erfolgreich eingeloggt", 'success');
                            }

                        } else {
                            this.logincommonerror = true;
                            this.logincommonerrortext = details.message;
                            this.hintemailId = false;
                            this.hintvaildemailId = false;
                            this.hintpassword = false;
                            if (details.message == "Sie sind bereits auf einem anderem Gerät eingeloggt.") {
                                this.reLogin = true;
                            }
                            // this.getlogindetails.userEmail
                            this.alertmsg(details.message, 'error');
                        }
                        // this.spinner.hide();

                    } else {}
                }, error => {});
        } else {
            if (this.loginform1.value.emailId == "") {
                this.hintemailId = true;
                // this.hintemailIdtext = "Please enter the emailid";
            } else if (this.hintvaildemailId != false) {
                this.hintvaildemailId = true;
                //this.hintemailIdtext = "Please enter the valid emailid";
            } else if (this.loginform1.value.fcnpassword == "") {
                this.hintpassword = true;
                // this.hintpasswordtext = "Please enter the password";
            }
        }
    }
    /**
   * @ignore
   */
    relogin(goto) {

        if (this.loginform1) {
            this.userEmail1 = this.loginform1.value.emailId
        } else {
            this.userEmail1 = this.loginform1.value.emailId
        }
        var data = {
            "email": this.userEmail1,
            "password": this.loginform1.value.fcnpassword
        }

        console.log(data)
        this
            .HTTPServiceService
            .withoutSessionMethod(data, this.logonUrl.kill_session)
            .subscribe(data => {
                // this.spinner.hide();
                console.log("relogin")
                if (data != null) {
                    var details : any = data;
                    if (details.status == 200) {
                        this.hintemailId = false;
                        this.hintvaildemailId = false;
                        this.hintpassword = false;
                        if (details.recruiter_id) {
                            localStorage.setItem("recruiter_id", details.recruiter_id)
                            this.recruiter_id = localStorage.getItem('recruiter_id');
                        }
                        localStorage.setItem("sessionid", details.sessionid)
                        localStorage.setItem("isLogged", "true")
                        localStorage.setItem("userEmail", this.loginform1.controls.emailId.value)
                        this.isLogged = localStorage.getItem('isLogged');
                        this.sessionid = localStorage.getItem('sessionid');
                        this.userEmail = localStorage.getItem('userEmail');
                        if (this.isLogged == "true") {
                            this.menuFlag1 = false
                        } else {
                            this.menuFlag1 = true
                        }
                        if (this.userEmail) {
                            this.loginuser = this
                                .userEmail
                                .split('@')[0];
                        }
                        this.commonloader = false;

                        let getArray = [];
                        getArray.push({email: this.loginform1.controls.emailId.value, session: details.sessionid})
                        // this.router.navigate(['/recruiterfeedback'])
                        if (goto == "payment") {
                            this
                                .dialogRef
                                .close(goto);
                        } else if (goto == "reg") {

                            this
                                .dialogRef
                                .close(goto);
                        } else {
                            this
                                .dialogRef
                                .close(getArray);

                        }
                        if (details.message == true) {
                            this.alertmsg(details.message, 'success');
                        } else {
                            this.alertmsg("Erfolgreich eingeloggt", 'success');
                        }
                    } else {}
                }
            }, error => {});

    }
    /**
   * @ignore
   */
    loginforms() {
        let emailregex : RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.loginform1 = this
            .formBuilder
            .group({

                emailId: [
                    '',
                    [
                        Validators.required, Validators.pattern(emailregex)
                    ]
                ],
                fcnpassword: [
                    '',
                    [Validators.required]
                ]
            });
    }
    /**
   * @ignore
   */
    changepasswordform() {
        let strongPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,12})');
        this.changepwdform = this
            .formBuilder
            .group({
                oldpassword: [
                    '',
                    [
                        Validators.required, Validators.pattern(strongPassword)
                    ]
                ],
                newpassword: [
                    '',
                    [
                        Validators.required, Validators.pattern(strongPassword)
                    ]
                ],
                confirmpassword: [
                    '',
                    [
                        Validators.required, Validators.pattern(strongPassword)
                    ]
                ]
            })
    }
    /**
   * @ignore
   */
    get emailId() {
        return this
            .loginform1
            .get('emailId');
    }
    /**
 * @ignore
 */
    emailvalidation(event) {
        this.logincommonerror = false;
        this.logincommonerrortext = "";
        let email = this.loginform1.value.emailId;
        var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(email)) {
            this.hintvaildemailId = true;
            this.hintemailId = false;
            //this.hintemailIdtext = "Please enter the valid emailid";

        } else {
            this.hintvaildemailId = false;
            this.hintemailId = false;
            //this.hintemailIdtext = "";

        }
    }

    /**
   * @ignore
   */
    secondcheck(value, chatstart) {
        if (value == "continue") {
            var name = "chat"
            this
                .dialogRef
                .close(name);
        } else {
            var name = "second"
            this
                .dialogRef
                .close(name);
        }
        // this.dialogRef.close();
    }
    /**
   * @ignore
   */
    clearError() {
        this.emailverify = true;
        this.EmptyErr = false;
        this.ValidErr = false;
    }
    /**
 * @ignore
 */
    ClearErr() {
        this.hintemailId = false;
        this.hintvaildemailId = false;
        this.hintpassword = false;
    }
    /**
 * @ignore
 */
    ClearPassword() {
        this.Errmatchpassword = false;
        this.Errvalidpassword = false;
        this.hintErrpassword = false;
        this.hintconfirmpassword = false;
    }

    /**
   * @ignore
   */
    onclickUrl() {
        //this.routerLink[(this.data.url)];
        window.open(this.data.url, "_blank");

    }
    /**
 * @ignore
 */
    onclickUrl1() {
        //this.routerLink[(this.data.url)];
        window.open(this.data.Enhanced_URL, "_blank");

    }
    /**
   * @ignore
   */
    EMAIL_verify(goto1) {

        this.getlogindetails = {};
        this.getlogindetails.email = this
            .RegisterEmailForm
            .value
            .RegisterEmail
            .toLowerCase();
        // var convertlowercaseEmail = this.getlogindetails.email.toLowerCase();
        console.log(this.getlogindetails);
        // this.spinner.show();
        this.commonloader = true;
        this
            .HTTPServiceService
            .withoutSessionMethod(this.getlogindetails, this.logonUrl.verifyEmail)
            .subscribe(data => {
                // this.spinner.hide();
                this.commonloader = false;
                if (data != null) {
                    var details : any = data;
                    if (details.status == 200) {
                        this.alertmsg(details.message, 'success')
                        if (goto1 = "reg") {
                            this.data.dialogname = "OTPdetailspopup";
                            this.data.reglog = "reg";
                        } else if (goto1 = "bluecollor") {
                            this.data.dialogname = "OTPdetailspopup";
                            this.data.reglog = "bluecollor";
                        } else {
                            this.data.dialogname = "OTPdetailspopup";

                        }
                        setTimeout(() => {
                            this.resendOTPdiv = true;
                        }, 1000);
                    } else {
                        //this.MailMessage=details.message;
                        this.alertmsg(details.message, 'error')
                    }
                } else {}
            }, error => {});
    }
    /**
 * @param EmailId
 */
    OTP_verify(formname, emailid, goto1) {
        this.getlogindetails = {};
        this.getlogindetails.email = emailid; //this.RegisterEmailForm.value.RegisterEmail,
        this.getlogindetails.otp = this.otp;
        // this.spinner.show();
        this.commonloader = true;
        this
            .HTTPServiceService
            .withoutSessionMethod(this.getlogindetails, this.logonUrl.verifyOTP)
            .subscribe(data => {
                // this.spinner.hide();
                this.commonloader = false;
                if (data != null) {
                    var details : any = data;
                    if (details.status == 200) {
                        if (formname == "forgotpassword") {
                            this.data.dialogname = "fp_passworddetailspopup";
                        } else {
                            if (goto1 == "reg") {
                                this.data.dialogname = "passworddetailspopup";
                                this.data.reglog = "reg";
                            } else if (goto1 == "bluecollor") {
                                this.data.dialogname = "passworddetailspopup";
                                this.data.reglog = "bluecollor";
                            } else {
                                this.data.dialogname = "passworddetailspopup";
                            }
                        }

                    } else {
                        this.alertmsg(details.message, 'error')
                    }
                } else {}
            }, error => {});
    }
    /**
 *@param goto1
 @example  Html it comes from
 */
    Register(goto1) {
        this.MailMessage = "";
        if (this.RegisterForm.controls.newpassword.value == "") {
            this.hintErrpassword = true;
            this.hintconfirmpassword = false;
            this.Errvalidpassword = false;
            this.Errmatchpassword = false;
        } else if (this.RegisterForm.controls.confirmpassword.value == "") {
            this.hintErrpassword = false;
            this.hintconfirmpassword = true;
            this.Errvalidpassword = false;
            this.Errmatchpassword = false;
        } else if (this.RegisterForm.controls.newpassword.errors != null || this.RegisterForm.controls.confirmpassword.errors != null) {
            this.hintErrpassword = false;
            this.hintconfirmpassword = false;
            this.Errvalidpassword = true;
            this.Errmatchpassword = false;
        } else if (this.RegisterForm.value.newpassword != this.RegisterForm.value.confirmpassword) {
            this.hintErrpassword = false;
            this.hintconfirmpassword = false;
            this.Errvalidpassword = false;
            this.Errmatchpassword = true;
        } else {
            this.MailMessage = "";
            this.getlogindetails = {};
            this.getlogindetails.email = this
                .RegisterEmailForm
                .value
                .RegisterEmail
                .toLowerCase();
            this.getlogindetails.password = this.RegisterForm.value.confirmpassword;
            // this.spinner.show();
            this.commonloader = true;
            this
                .HTTPServiceService
                .withoutSessionMethod(this.getlogindetails, this.logonUrl.register)
                .subscribe(data => {
                    // this.spinner.hide();
                    this.commonloader = false;
                    if (data != null) {
                        var details : any = data;
                        if (details.status == 200) {
                            if (goto1 == "reg") {
                                this.data.dialogname = "successregisterpopup";
                                this.data.reglog = "reg";
                            } else if (goto1 == "bluecollor") {
                                this.data.dialogname = "successregisterpopup";
                                this.data.reglog = "bluecollor";
                            } else {
                                this.data.dialogname = "successregisterpopup";
                            }
                        } else {
                            //this.MailMessage = details.message;
                            this.alertmsg(details.message, 'error')
                        }
                    } else {}
                }, error => {});
        }
    }
    /**
 * @ignore
 */
    RegisterOTPFormgroup() {
        this.RegisterOTPForm = this
            .formBuilder
            .group({
                RegisterOTPnum1: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum2: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum3: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum4: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum5: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum6: [
                    '',
                    [Validators.required]
                ]
            })
    }
    /**
 * @ignore
 */
    fp_RegisterOTPFormgroup() {
        this.fp_RegisterOTPForm = this
            .formBuilder
            .group({
                RegisterOTPnum1: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum2: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum3: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum4: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum5: [
                    '',
                    [Validators.required]
                ],
                RegisterOTPnum6: [
                    '',
                    [Validators.required]
                ]
            })
    }
    /**
 * @ignore
 */
    paymentdetailsforms() {
        this.paymentdetailsform = this
            .formBuilder
            .group({
                ptfirstname: [
                    '',
                    [Validators.required]
                ],
                ptcompanyname: [
                    '',
                    [Validators.required]
                ],
                ptlastname: [
                    '',
                    [Validators.required]
                ],
                ptcity: [
                    '',
                    [Validators.required]
                ],
                ptstreetname: [
                    '',
                    [Validators.required]
                ],
                ptzip: [
                    '',
                    [Validators.required]
                ],
                ptcountryname: [
                    '',
                    [Validators.required]
                ],
                pttelephoneno: [''],
                vatnumber: [''],
                countrycode: [''],
                ptemail: [
                    '',
                    [Validators.required]
                ],

                paytype: this
                    .formBuilder
                    .array([])
            })
    }
    /**
 * @ignore
 */
    candidatelogoforms() {
        this.candidatelogoform = this
            .formBuilder
            .group({
                company_name: [
                    '',
                    [Validators.required]
                ],
                company_logo: [
                    '',
                    [Validators.required]
                ],
                welcome_text: [
                    '',
                    [Validators.required]
                ],
                // welcome_text: ['', [Validators.required]],
                terms_conditions: [
                    '',
                    [Validators.required]
                ],
                contact_person: [
                    '',
                    [Validators.required]
                ],
                companies: [
                    '',
                    [Validators.required]
                ],
                refrence_id: [
                    '',
                    [Validators.required]
                ],
                Aufgaben: [
                    '',
                    [Validators.required]
                ],
                Anforderungen: [
                    '',
                    [Validators.required]
                ],
                Firmenhintergrund: [
                    '',
                    [Validators.required]
                ],
                Sonstiges: [
                    '',
                    [Validators.required]
                ]
            })
    }
    /**
 * @ignore
 */
    directApplyforms() {
        this.directApplyform = this
            .formBuilder
            .group({jobtitle: [''], Postal_code: [''], Internal_Reference: ['']})
    }
    /**
 * @ignore
 */
    pricingforms() {
        this.erecruiterform = this
            .formBuilder
            .group({Kundenreferenz: [''], Postal_code: [''], welcome_text: ['']})
    }
    /**
 * @ignore
 */
    erecruiterforms() {
        this.pricingform = this
            .formBuilder
            .group({job_title: [''], Postal_code: [''], email: ['']})
    }
    /**
 * @ignore
 */
    ptemailvalidation() {
        let email = this.paymentdetailsform.value.ptemail;
        if (email != "") {
            var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!pattern.test(email)) {
                this.hintptvalidemail = true;
                this.hintptemail = false;

            } else {
                this.hintptvalidemail = false;
                this.hintptemail = false;
            }
        } else {
            this.hintptemail = true;
            this.hintptvalidemail = false;

        }
    }
    /**
 * @ignore
 */
    ptfieldvalidation() {
        if (this.paymentdetailsform.value.ptfirstname == "") {
            this.hintptfirstname = true;
            return false;
        } else {
            this.hintptfirstname = false;
        }
        if (this.paymentdetailsform.value.ptcompanyname == "") {
            this.hintptcompanyname = true;
            return false;
        } else {
            this.hintptcompanyname = false;
        }

        if (this.paymentdetailsform.value.ptstreetname == "") {
            this.hintptstreetname = true;
            return false;
        } else {
            this.hintptstreetname = false;

        }
        if (this.paymentdetailsform.value.ptzip == "") {
            this.hintptzip = true;
            return false;
        } else {
            this.hintptzip = false;

        }
        if (this.paymentdetailsform.value.ptemail == "") {
            this.hintptemail = true;
            return false;
        } else if (this.hintptvalidemail == true) {
            this.hintptemail = false;
            this.hintptvalidemail = true;
            return false;
        } else {
            this.hintptemail = false;
            this.hintptvalidemail = false;

        }
        if (this.paymentdetailsform.value.ptlastname == "") {
            this.hintptlastname = true;
            return false;
        } else {
            this.hintptlastname = false;
        }

        if (this.paymentdetailsform.value.ptcity == "") {
            this.hintptcity = true;
            return false;
        } else {
            this.hintptcity = false;

        }
        if (this.paymentdetailsform.value.ptcountryname == "") {
            this.hintptcountryname = true;
            return false;
        } else {
            this.hintptcountryname = false;
        }

        if (this.paymentdetailsform.value.pttelephoneno == "") {
            this.hintpttelephoneno = true;
            return false;
        } else {
            this.hintpttelephoneno = false;

        }
        if (this.paymentdetailsform.value.vatnumber == "") {
            this.hintvatnumber = true;
            return false;
        } else {
            this.hintvatnumber = false;

        }
        this.data.dialogname = "confirmpopup";

    }
    /**
   * Forgot Password Start here
   */
    forgotpasword() {
        if (this.loginform1.value.emailId != "" && this.hintvaildemailId == false) {
            this.getlogindetails = {};
            this.getlogindetails.email = this
                .loginform1
                .value
                .emailId
                .toLowerCase();
            // this.spinner.show();
            this.commonloader = true;
            this
                .HTTPServiceService
                .withoutSessionMethod(this.getlogindetails, this.logonUrl.forgotPassword)
                .subscribe(data => {
                    // this.spinner.hide();
                    this.commonloader = false;
                    if (data != null) {
                        var details : any = data;
                        if (details.status == 200) {

                            this.hintemailId = false;
                            this.hintvaildemailId = false;
                            this.alertmsg(details.message, 'success');
                            this.data.dialogname = 'fp_OTPdetailspopup';
                            setTimeout(() => {
                                this.resendOTPforgetdiv = true;
                            }, 1000);
                            // this.data.dialogname = 'resetpassword';
                        } else {
                            this.alertmsg(details.message, 'error');
                            this.hintemailId = false;
                            this.hintvaildemailId = false;
                        }
                    } else {}
                }, error => {});
        } else {
            if (this.loginform1.value.emailId == "") {
                this.hintemailId = true;
                this.hintvaildemailId = false;
            } else if (this.hintvaildemailId != false) {
                this.hintvaildemailId = true;
                this.hintemailId = false;
            }
        }

    }
    /**
   * @ignore
   */
    RegisterFormgroup() {
        let strongPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,12})');
        this.RegisterForm = this
            .formBuilder
            .group({
                newpassword: [
                    '',
                    [
                        Validators.required, Validators.pattern(strongPassword)
                    ]
                ],
                confirmpassword: [
                    '',
                    [
                        Validators.required, Validators.pattern(strongPassword)
                    ]
                ]
            })
    }
    /**
 * @ignore
 */
    fb_updateForms() {
        let strongPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,12})');
        this.fb_updateForm = this
            .formBuilder
            .group({
                newpassword: [
                    '',
                    [
                        Validators.required, Validators.pattern(strongPassword)
                    ]
                ],
                confirmpassword: [
                    '',
                    [
                        Validators.required, Validators.pattern(strongPassword)
                    ]
                ]
            })
    }
    /**
 * @ignore
 */
    resetPasswordForm() {

        this.resetForm = this
            .formBuilder
            .group({
                passowrd: [
                    '',
                    [Validators.required]
                ]
            })
    }
    /**
   * @ignore
   */
    openpopupReset() {
        var data = this.resetForm.value.passowrd
        const strongPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,12})');
        if (data) {
            if (!strongPassword.test(data)) {
                this.passowrdError = true;
                data.focus;
            } else {
                this.passowrdError = false;
            }
        }
    }

    /**
   * @ignore
   */
    setname(event, value2) {
        // console.log("df")
        if (value2 == 'terms') {
            const image = event.target.files[0];
            this.Imagename2 = image.name;
            this.Imagetype2 = image
                .name
                .split(".")[1];
            if (image.size > 3001546) {

                this.Imagename2 = '';
                this.Imagetype2 = '';
                alert("Datei sollte kleiner als 3MB sein");
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                var imageFormate = event
                    .target
                    .files[0]
                    .name
                    .split(".")[1];
                console.log("image data**************************", imageFormate)
                reader.onload = (e) => {
                    this.imagePreview4 = reader.result;
                    console.log(this.imagePreview4)
                    this.imagePreview4 = this.imagePreview4 as string;
                    var imagePreview = this
                        .imagePreview4
                        .split("data:image/")[1];
                    // this.uploadImage(imageFormate, imagePreview);
                    if (this.imagePreview4) {
                        this.fileuploadFlag1 = true
                        this.candidatelogoform.value.terms_conditions = this.imagePreview4
                    }

                }
                this.hintterms_conditions = false;
            }
        } else {
            const image = event.target.files[0];
            this.Imagename = image.name;
            this.Imagetype = image
                .name
                .split(".")[1];
            if (image.size > 3001546) {

                this.Imagename = '';
                this.Imagetype = '';
                alert("Datei sollte kleiner als 3 MB sein");
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                var imageFormate = event
                    .target
                    .files[0]
                    .name
                    .split(".")[1];
                // console.log("image data**************************", imageFormate)
                reader.onload = (e) => {
                    this.imagePreview = reader.result;
                    // console.log(this.imagePreview)
                    this.imagePreview = this.imagePreview as string;
                    var imagePreview = this
                        .imagePreview
                        .split("data:image/")[1];
                    // this.uploadImage(imageFormate, imagePreview);
                    if (this.imagePreview) {
                        this.candidatelogoform.value.company_logo = this.imagePreview
                    }

                }
                this.hintcompany_logo = false;
            }

        }

    }

    /**
   * @ignore
   */
    setname1(event) {
        const image = event.target.files[0];
        this.Imagename1 = image.name;
        this.Imagetype1 = image
            .name
            .split(".")[1];
        if (image.size > 10000000) {
            this.Imagename1 = '';
            this.Imagetype1 = '';
            alert("Datei zu Groß");
            return;
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            var imageFormate = event
                .target
                .files[0]
                .name
                .split(".")[1];
            sessionStorage.setItem("imagesFormat", imageFormate)
            // console.log("image data**************************", imageFormate)
            reader.onload = (e) => {
                this.imagePreview1 = reader.result;
                // console.log(this.imagePreview2)
                this.imagePreview1 = this.imagePreview1 as string;
                var imagePreview1 = this
                    .imagePreview1
                    .split("data:image/")[1];
                var test = this.imagePreview1
                var test2 = test
                    .toString()
                    .replace(/\s/g, "");
                test2 = test
                    .toString()
                    .replace(/[+]/g, "-");
                if (test2) {
                    test2 = test2.split("base64,")[1];
                }
                // console.log(test2);
                sessionStorage.setItem("Base64File", test2)
                // this.uploadImage(imageFormate, imagePreview);
                if (this.imagePreview1) {
                    this.fileuploadFlag1 = true
                    // this.candidatelogoform.value.company_logo = this.imagePreview1
                }
            }
        }
    }
    /**
 * After File Upload Call Set Ad and Some Flag
 */
    startupload() {
        console.debug("START UPLOAD", this);
        if ((this.Facillity_Mgnt_room_cleaning || this.Food_retail || this.General_retail || this.Hospitality_kitchen || this.Hospitality_service || this.Industriehelfer || this.Logistics_driver || this.logistics_warehouse || this.Nursing_general_nursing || this.Nursing_geriatric_nursing || this.Tourism_housekeeping || (this.Kundenreferenz && this.Kundenreferenz1 != "")) && (this.Ownjob_ad || this.Noownjob_ad)) {
            var chat_id;
            if (this.Facillity_Mgnt_room_cleaning) {
                chat_id = 10099
                // chat_id = 20012
            } else if (this.Food_retail) {
                chat_id = 10199
            } else if (this.General_retail) {
                chat_id = 10299
            } else if (this.Hospitality_kitchen) {
                chat_id = 10399
            } else if (this.Hospitality_service) {
                chat_id = 10499
            } else if (this.Industriehelfer) {
                chat_id = 10599
            } else if (this.Logistics_driver) {
                chat_id = 10699
            } else if (this.logistics_warehouse) {
                chat_id = 10799
            } else if (this.Nursing_general_nursing) {
                chat_id = 10899
            } else if (this.Nursing_geriatric_nursing) {
                chat_id = 10999
            } else if (this.Tourism_housekeeping) {
                chat_id = 11099
            } else if (this.Kundenreferenz) {
                chat_id = this.Kundenreferenz1
            }

            this.commonloader = true;
            this.bluecollar = true
            // localStorage.removeItem('linktype');
            localStorage.setItem('bluecollar', JSON.stringify(this.bluecollar))
            if (this.Ownjob_ad) {
                sessionStorage.setItem('NoOwnAd', 'false');
                // console.log(this.imagePreview2, "image base 64")
                if (this.imagePreview1) {
                    var test = this.imagePreview1
                    // console.log(typeof this.imagePreview2)
                    if (test) {
                        var test2 = test
                            .toString()
                            .replace(/\s/g, "");
                        test2 = test
                            .toString()
                            .replace(/[+]/g, "-");
                        if (test2) {
                            test2 = test2.split("base64,")[1];
                        }
                        const obj = {

                            "method": "AUTH",
                            "base64": test2,
                            "document_type": "AD",
                            "extension": this.Imagetype1,
                            "link": "",
                            "output": "JSON",
                            "engine": "RAW",
                            "read_only": "0",
                            "read_nlp": "0",
                            "info_only": "0",
                            "blue_collor": true,
                            "email": this.userEmail,
                            "chat_caseId": chat_id
                        }
                        this.commonloader = true;
                        this
                            .HTTPServiceService
                            .commonMethod(obj, this.logonUrl.get_profile)
                            .subscribe(res => {
                                console.log(res, 'res ts');
                                this.response = res
                                if (this.response.status == 200) {
                                    console.log("success")
                                    this.localvalue = {
                                        access_token: this.response.res.message.access_token,
                                        ad_berufsgruppe: this.response.res.message.ad_berufsgruppe,
                                        ad_id: this.response.res.message.ad_id,
                                        clientadid: this.response.res.message.clientadid
                                    };
                                    localStorage.setItem("token", JSON.stringify(this.localvalue))
                                    localStorage.setItem("identifier", 'true')
                                    if (this.isLogged) {
                                        var setAdValueList = {
                                            "method": "AUTH",
                                            "ad_id": this.response.res.message.ad_id,
                                            "permanent": "",
                                            "fulltime": "",
                                            "email": this.userEmail,
                                            "city": "",
                                            "free_text": "",
                                            "title": "",
                                            "location": "",
                                            "date": "",
                                            "company": "",
                                            "url": "",
                                            "as_company": "",
                                            "organisation_id": "111",
                                            "recruiter_id": this.recruiterid,
                                            "country_code": "",
                                            "reference_id": "",
                                            "local_chat": false

                                        }
                                        this
                                            .HTTPServiceService
                                            .commonMethod(setAdValueList, this.logonUrl.set_ad_data)
                                            .subscribe(data => {
                                                this.commonloader = false;
                                                this
                                                    .dialogRef
                                                    .close();
                                                this
                                                    .router
                                                    .navigate(['/recruiterfeedback'])
                                            })
                                    } else {
                                        this.commonloader = false;
                                        this
                                            .dialogRef
                                            .close();
                                        this
                                            .router
                                            .navigate(['/recruiterfeedback'])
                                    }

                                } else {
                                    // alert("Somthing went wrong please try after some time");
                                    const dialogRef = this
                                        .dialog
                                        .open(DialogComponent, {
                                            disableClose: false,
                                            data: {
                                                dialogname: 'commonerrorpopup'
                                            }
                                        });
                                    dialogRef
                                        .afterClosed()
                                        .subscribe((result : any) => {});
                                    this.commonloader = false;
                                }
                            })
                    } else {}
                } else {
                    const dialogRef = this
                        .dialog
                        .open(DialogComponent, {
                            disableClose: false,
                            data: {
                                dialogname: 'fileuploadpopup'
                            }
                        });
                    dialogRef
                        .afterClosed()
                        .subscribe((result : any) => {
                            this.commonloader = false;
                        });
                    this.commonloader = false;
                }

            } else if (this.Noownjob_ad) {
                // this.internal_reference = 32432
                // sessionStorage.setItem('NoOwnAd', 'false');
                sessionStorage.setItem('NoOwnAd', 'true');
                if (this.clientad_id) {
                    var data122 = {
                        client_ad_id: this.internal_reference,
                        title: this.directApplyform.value.jobtitle,
                        Postal_code: this.directApplyform.value.Postal_code
                    }
                    localStorage.setItem('jobdetails', JSON.stringify(data122));
                    this.getlink_bluecollor()
                } else {
                    if (this.directApplyform.value.jobtitle != "" && this.directApplyform.value.Postal_code != "" && this.directApplyform.value.Internal_Reference != "") {
                        var data122 = {
                            client_ad_id: this.internal_reference,
                            title: this.directApplyform.value.jobtitle,
                            Postal_code: this.directApplyform.value.Postal_code
                        }
                        localStorage.setItem('jobdetails', JSON.stringify(data122));
                        console.log("Blue collor")
                        var flag = {
                            "method": "AUTH",
                            "chat_caseId": chat_id,
                            "document_type": "AD",
                            "blue_collor": true,
                            "internal_reference": this.directApplyform.value.Internal_Reference,
                            "local_chat": sessionStorage.getItem("NoOwnAd")
                        }
                        this
                            .HTTPServiceService
                            .commonMethod(flag, this.logonUrl.get_profile_for_chatcase)
                            .subscribe(data => {
                                this.commonloader = false;
                                this.response = data;
                                console.log("chatCase", this.response)
                                if (this.response.res.message) {

                                    this.clientad_id = this.response.res.client_ad_id
                                    var localvalue = {
                                        access_token: "",
                                        ad_id: "",
                                        clientadid: "",
                                        internal_reference: ""
                                    }
                                    // localvalue = JSON.parse(localStorage.getItem('token'))
                                    localvalue.ad_id = this.response.res.message.ad_id
                                    localvalue.clientadid = this.response.res.client_ad_id
                                    localvalue.internal_reference = this.directApplyform.value.Internal_Reference
                                    localStorage.setItem("token", JSON.stringify(localvalue))
                                    // this.dialogRef.close();
                                    this.startupload();
                                }
                            })
                    } else {
                        // alert("please fill the title and postcode")
                        this.commonloader = false;
                        this.hintforjobtitle = true;

                    }
                }
            }
        } else {
            const dialogRef = this
                .dialog
                .open(DialogComponent, {
                    disableClose: false,
                    data: {
                        dialogname: 'directapplycheckbox'
                    }
                });
            dialogRef
                .afterClosed()
                .subscribe((result : any) => {});
        }
    }
    /**
 * @ignore
 */
    testcheck() {
        console.log("call")
        this.hintforjobtitle = false
    }
    /**
 * @ignore
 */
    getlink_bluecollor() {
        this.isLogged = localStorage.getItem('isLogged')
        var localvalue = JSON.parse(localStorage.getItem('token'))
        this.userEmail = localStorage.getItem('userEmail')
        this.recruiterid = localStorage.getItem('recruiter_id')
        if (this.isLogged == "true") {
            var flag = {
                "email": this.userEmail,
                "ad_id": localvalue.ad_id
            }
            this
                .HTTPServiceService
                .commonMethod(flag, this.logonUrl.email_update_adid)
                .subscribe(data => {
                    this.commonloader = false;
                    this.response = data
                    if (this.response.status == 200) {
                        console.log(this.directApplyform.value.jobtitle, this.directApplyform.value.Postal_code, "f")
                        this.response = data
                        // if (this.response.status.errorcode == "111") {
                        var demovalue1 = JSON.parse(localStorage.getItem('jobdetails'));
                        var setAdValueList = {

                            "method": "AUTH",
                            "ad_id": localvalue.ad_id,
                            "permanent": "",
                            "fulltime": "",
                            "email": this.userEmail,
                            "city": "",
                            "free_text": "",
                            "title": this.directApplyform.value.jobtitle,
                            "location": this.directApplyform.value.Postal_code,
                            "date": "",
                            "company": "",
                            "url": "",
                            "as_company": "",
                            "organisation_id": "111",
                            "recruiter_id": this.recruiterid,
                            "country_code": "",
                            "reference_id": "",
                            "local_chat": sessionStorage.getItem("NoOwnAd")
                        }
                        if (this.directApplyform.value.jobtitle == "") {
                            setAdValueList.title = demovalue1.title
                        }
                        if (this.directApplyform.value.Postal_code == "") {
                            setAdValueList.location = demovalue1.Postal_code
                        }
                        this
                            .HTTPServiceService
                            .commonMethod(setAdValueList, this.logonUrl.set_ad_data)
                            .subscribe(data => {
                                this.commonloader = false;
                                this.response = data
                                if (this.response.status == 200) {
                                    this.hidelandingfilepopupload = true
                                    this.dialogRef.close();
                                    const dialogRef = this
                                        .dialog
                                        .open(DialogComponent, {
                                            disableClose: true,
                                            data: {
                                                dialogname: 'confirmpaymentpopup',
                                                email: this.userEmail,
                                                goto1: "bluecollor"
                                            }
                                        });
                                    dialogRef
                                        .afterClosed()
                                        .subscribe((result : any) => {
                                            console.log("success")
                                            this
                                                .dialogRef
                                                .close();
                                        });
                                }
                            })
                        // } })
                    } else {}
                })
        } else {
            // this.getLinkdialog();
            this.commonloader = false;
            this.hidelandingfilepopupload = true
            const dialogRef = this
                .dialog
                .open(DialogComponent, {
                    disableClose: true,
                    data: {
                        dialogname: 'loginpopup',
                        goto: 'bluecollor',
                        goto1: "bluecollor"
                    }
                });
            dialogRef
                .afterClosed()
                .subscribe((result : any) => {
                    console.log("back")
                    this
                        .dialogRef
                        .close();

                });
        }
    }

    /**
   *Main File Upload
   */
    setname2(event) {
        const image = event.target.files[0];
        this.Imagename2 = image.name;
        this.Imagetype2 = image
            .name
            .split(".")[1];
        if (image.size > 10000000) {
            this.Imagename2 = '';
            this.Imagetype2 = '';
            alert("File size should be less than 50KB");
            return;
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            var imageFormate = event
                .target
                .files[0]
                .name
                .split(".")[1];
            sessionStorage.setItem("imagesFormat", imageFormate)
            reader.onload = (e) => {
                this.imagePreview2 = reader.result;
                // console.log("befor",this.imagePreview2)
                this.imagePreview2 = this.imagePreview2 as string;
                var imagePreview2 = this
                    .imagePreview2
                    .split("data:image/")[1];
                // console.log("after as string", this.imagePreview2);
                var test = this.imagePreview2
                var test2 = test
                    .toString()
                    .replace(/\s/g, "");
                test2 = test
                    .toString()
                    .replace(/[+]/g, "-");
                if (test2) {
                    test2 = test2.split("base64,")[1];
                }
                sessionStorage.setItem("Base64File", test2)
                if (this.imagePreview2) {
                    this.fileuploadFlag = true
                    this.candidatelogoform.value.company_logo = this.imagePreview2
                }
            }
        }
    }
    /**
 * @ignore
 */
    erecuiterFileUpload(event) {
        const image = event.target.files[0];
        this.Imagename2 = image.name;
        this.Imagetype2 = image
            .name
            .split(".")[1];
        if (image.size > 10000000) {
            this.Imagename2 = '';
            this.Imagetype2 = '';
            alert("Datei zu groß");
            return;
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            var imageFormate = event
                .target
                .files[0]
                .name
                .split(".")[1];
            sessionStorage.setItem("imagesFormat", imageFormate)
            reader.onload = (e) => {
                this.imagePreview = reader.result;
                this.imagePreview = this.imagePreview as string;
                // var imagePreview = this.imagePreview.split("data:image/")[1];
                var test = this.imagePreview
                var test2 = test
                    .toString()
                    .replace(/\s/g, "");
                test2 = test
                    .toString()
                    .replace(/[+]/g, "-");
                if (test2) {
                    test2 = test2.split("base64,")[1];
                }
                // console.log(test2);
                sessionStorage.setItem("Base64File", test2)
                if (this.imagePreview) {
                    this.fileuploadFlag = true
                }
            }
        }
    }
    /**
 * E-Recruiter File Upload
 */
    eassyrecuriterfileupload() {
        var fileformatlist = [];
        fileformatlist.push("docx", "doc", "pdf");
        let format = fileformatlist.includes(sessionStorage.getItem("imagesFormat"));

        if (format == false) {
            alert("Dateityp nicht unterstützt")
            // return false;
        } else {
            this.commonloader = true;

            localStorage.removeItem("identifier")
            var base64 = sessionStorage.getItem("Base64File")
            if (this.imagePreview) {
                var test = this.imagePreview
                var test2 = test
                    .toString()
                    .replace(/\s/g, "");
                test2 = test
                    .toString()
                    .replace(/[+]/g, "-");
                if (test2) {
                    test2 = test2.split("base64,")[1];
                }

                const obj = {
                    "method": "AUTH",
                    "base64": test2,
                    "document_type": "AD",
                    "extension": sessionStorage.getItem("imagesFormat"),
                    "link": "",
                    "output": "JSON",
                    "engine": "RAW",
                    "read_only": "0",
                    "read_nlp": "0",
                    "info_only": "0",
                    "email": "",
                    "e_recruiter": true
                }

                this
                    .HTTPServiceService
                    .commonMethod(obj, this.logonUrl.get_profile)
                    .subscribe(res => {
                        this.response = res
                        if (this.response.status == 200) {
                            this.localvalue = {
                                access_token: this.response.res.message.access_token,
                                ad_berufsgruppe: this.response.res.message.ad_berufsgruppe,
                                ad_id: this.response.res.message.ad_id,
                                clientadid: this.response.res.message.clientadid
                            };
                            localStorage.setItem("token", JSON.stringify(this.localvalue))

                            var setAdValueList = {

                                "method": "AUTH",
                                "ad_id": this.response.res.message.ad_id,
                                "permanent": "",
                                "fulltime": "",
                                "email": this.userEmail,
                                "city": "",
                                "free_text": "",
                                "title": "",
                                "location": "",
                                "date": "",
                                "company": "",
                                "url": "",
                                "as_company": "",
                                "organisation_id": "111",
                                "recruiter_id": this.recruiterid,
                                "country_code": "",
                                "reference_id": "",
                                "local_chat": false
                            }
                            this
                                .HTTPServiceService
                                .commonMethod(setAdValueList, this.logonUrl.set_ad_data)
                                .subscribe(data => {
                                    console.log("SET_AD_DATA", data);
                                    this.commonloader = false;
                                    this
                                        .dialogRef
                                        .close();
                                    this
                                        .router
                                        .navigate(['/easyRecruiterFeedback'])
                                })

                        } else {
                            // alert("Somthing went wrong please try after some time");
                            const dialogRef = this
                                .dialog
                                .open(DialogComponent, {
                                    disableClose: false,
                                    data: {
                                        dialogname: 'commonerrorpopup'
                                    }
                                });
                            dialogRef
                                .afterClosed()
                                .subscribe((result : any) => {});
                            this.commonloader = false;
                        }
                    })
            } else {
                const dialogRef = this
                    .dialog
                    .open(DialogComponent, {
                        disableClose: false,
                        data: {
                            dialogname: 'fileuploadpopup'
                        }
                    });
                dialogRef
                    .afterClosed()
                    .subscribe((result : any) => {
                        this.commonloader = false;
                    });
            }
        }

    }
    /**
 * Direct Apply File Upload
 */
    directfileupload() {
        var fileformatlist = [];
        fileformatlist.push("docx", "doc", "pdf");
        let format = fileformatlist.includes(this.Imagetype2);

        if (format == false) {
            alert("Invalid file format")

        } else {
            // console.log(this.imagePreview2, "image base 64")
            localStorage.removeItem("identifier")
            if (this.imagePreview2) {
                var test = this.imagePreview2
                var test2 = test
                    .toString()
                    .replace(/\s/g, "");
                test2 = test
                    .toString()
                    .replace(/[+]/g, "-");
                if (test2) {
                    test2 = test2.split("base64,")[1];
                }

                const obj = {
                    "method": "AUTH",
                    "base64": test2,
                    "document_type": "AD",
                    "extension": this.Imagetype2,
                    "link": "",
                    "output": "JSON",
                    "engine": "RAW",
                    "read_only": "0",
                    "read_nlp": "0",
                    "info_only": "0",
                    "white_collor": true,
                    "chat_caseId": 1,
                    "email": ""

                }
                this.commonloader = true;
                this
                    .HTTPServiceService
                    .commonMethod(obj, this.logonUrl.get_profile)
                    .subscribe(res => {
                        console.log(res, 'res ts');
                        this.response = res
                        if (this.response.status == 200) {
                            this.localvalue = {
                                access_token: this.response.res.message.access_token,
                                ad_berufsgruppe: this.response.res.message.ad_berufsgruppe,
                                ad_id: this.response.res.message.ad_id,
                                clientadid: this.response.res.message.clientadid
                            };
                            localStorage.setItem("token", JSON.stringify(this.localvalue))
                            if (this.isLogged) {
                                var setAdValueList = {

                                    "method": "AUTH",
                                    "ad_id": this.response.res.message.ad_id,
                                    "permanent": "",
                                    "fulltime": "",
                                    "email": this.userEmail,
                                    "city": "",
                                    "free_text": "",
                                    "title": "",
                                    "location": "",
                                    "date": "",
                                    "company": "",
                                    "url": "",
                                    "as_company": "",
                                    "organisation_id": "111",
                                    "recruiter_id": this.recruiterid,
                                    "country_code": "",
                                    "reference_id": "",
                                    "local_chat": false
                                }
                                this
                                    .HTTPServiceService
                                    .commonMethod(setAdValueList, this.logonUrl.set_ad_data)
                                    .subscribe(data => {
                                        this.commonloader = false;
                                        this
                                            .dialogRef
                                            .close();
                                        this
                                            .router
                                            .navigate(['/recruiterfeedback'])
                                    })
                            } else {
                                this.commonloader = false;
                                this
                                    .dialogRef
                                    .close();
                                this
                                    .router
                                    .navigate(['/recruiterfeedback'])
                            }

                        } else {
                            // alert("Somthing went wrong please try after some time");
                            const dialogRef = this
                                .dialog
                                .open(DialogComponent, {
                                    disableClose: false,
                                    data: {
                                        dialogname: 'commonerrorpopup'
                                    }
                                });
                            dialogRef
                                .afterClosed()
                                .subscribe((result : any) => {});
                            this.commonloader = false;
                        }
                    })
            } else {
                const dialogRef = this
                    .dialog
                    .open(DialogComponent, {
                        disableClose: false,
                        data: {
                            dialogname: 'fileuploadpopup'
                        }
                    });
                dialogRef
                    .afterClosed()
                    .subscribe((result : any) => {});
            }

        }
    }
    /**
 * E_recruiter File Upload
 */
    easyRecruiterFileUpload() {

        localStorage.removeItem("identifier")
        var base64 = sessionStorage.getItem("Base64File")
        if (base64) {
            const obj = {
                "method": "AUTH",
                "base64": base64,
                "document_type": "AD",
                "extension": sessionStorage.getItem("imagesFormat"),
                "link": "",
                "output": "JSON",
                "engine": "RAW",
                "read_only": "0",
                "read_nlp": "0",
                "info_only": "0",
                "e_recruiter": true
            }
            this.commonloader = true;
            this
                .HTTPServiceService
                .commonMethod(obj, this.logonUrl.get_profile)
                .subscribe(res => {
                    console.log(res, 'res ts');
                    this.response = res
                    if (this.response.status == 200) {
                        console.log("success")
                        this.localvalue = {
                            access_token: this.response.res.message.access_token,
                            ad_berufsgruppe: this.response.res.message.ad_berufsgruppe,
                            ad_id: this.response.res.message.ad_id,
                            clientadid: this.response.res.message.clientadid
                        };
                        localStorage.setItem("token", JSON.stringify(this.localvalue))

                        var setAdValueList = {

                            "method": "AUTH",
                            "ad_id": this.response.res.message.ad_id,
                            "permanent": "",
                            "fulltime": "",
                            "email": this.userEmail,
                            "city": "",
                            "free_text": "",
                            "title": "",
                            "location": "",
                            "date": "",
                            "company": "",
                            "url": "",
                            "as_company": "",
                            "organisation_id": "111",
                            "recruiter_id": this.recruiterid,
                            "country_code": "",
                            "reference_id": "",
                            "local_chat": false
                        }
                        this
                            .HTTPServiceService
                            .commonMethod(setAdValueList, this.logonUrl.set_report)
                            .subscribe(data => {
                                console.log("SET_AD_DATA", data);
                                this.commonloader = false;
                                this
                                    .dialogRef
                                    .close();
                                this
                                    .router
                                    .navigate(['/easyRecruiterFeedback'])
                            })

                    } else {
                        // alert("Somthing went wrong please try after some time");
                        const dialogRef = this
                            .dialog
                            .open(DialogComponent, {
                                disableClose: false,
                                data: {
                                    dialogname: 'commonerrorpopup'
                                }
                            });
                        dialogRef
                            .afterClosed()
                            .subscribe((result : any) => {});
                        this.commonloader = false;
                    }
                })
        } else {
            const dialogRef = this
                .dialog
                .open(DialogComponent, {
                    disableClose: false,
                    data: {
                        dialogname: 'fileuploadpopup'
                    }
                });
            dialogRef
                .afterClosed()
                .subscribe((result : any) => {});
        }

    }
    /**
   * @ignore
   */
    // E-Recruiter Feedback Call Active Source Starts Here by Balaji
    getActiveSourceData : any
    getActiveSourceAdid : any
    getAD_data(value) {
        // this.commonloader = true;
        console.log(value, "getaddata")
        var flagdata = {
            "method": "AUTH",
            "id_list": value,
            "ad_id": ""
        }
        this
            .http
            .commonMethod(flagdata, this.logonUrl.get_ad_data)
            .subscribe(res => {
                console.log(res, 'GEt AD data');
                this.commonloader = false;
                const dialogRef = this
                    .dialog
                    .open(DialogComponent, {
                        disableClose: false,
                        data: {
                            dialogname: 'activesourcingmessage'
                        }
                    });
                dialogRef
                    .afterClosed()
                    .subscribe((result : any) => {
                        console.log("su")
                        this
                            .dialogRef
                            .close();
                    });

                this.response = res
                if (this.response.status == 200) {
                    console.log(this.response, "response")

                } else {}
            })
    }
    /**
   * @ignore
   */
    closeheatmap() {
        this
            .dialogRef
            .close();
        this
            .router
            .navigate(['/cockpit']);
    }
    /**
 * @ignore
 */
    getlocalvalues() {
        this.isLogged = localStorage.getItem('isLogged');
        this.sessionid = localStorage.getItem('sessionid');
        this.userEmail = localStorage.getItem('userEmail');
        if (this.isLogged == "true") {
            this.menuFlag1 = true;
        } else {
            this.menuFlag1 = false;
        }
        if (this.userEmail) {
            this.loginuser = this
                .userEmail
                .split('@')[0];
        }
    }
    /**
 * @ignore
 */
    gotopayment(gotovalue) {
        if (gotovalue == "payment") {

            this.GetActiveSouce()
        } else if (gotovalue == "reg") {
            this.GetActiveSouce()
        }
        // this.feedbackReportData();

    }
    sessionvar : any

    /**
   * Check Login common Function for All Pages
   */
    checkLogin() {
        // To close the heatmap dialog in ER Joblist
        if(!this.ok_button){
            this.dialogRef.close();
            return
        }
        this.commonloaderAS = true
        this.analyzing = false;
        this.collecting = false
        this.finalizing = false
        this.oops = false
        this.isLogged = localStorage.getItem('isLogged');
        if (this.isLogged != "true") {
            const dialogRef = this
                .dialog
                .open(DialogComponent, {
                    disableClose: true,
                    data: {
                        dialogname: 'loginpopup',
                        goto: 'payment',
                        goto1: "reg"
                    }
                });
            dialogRef
                .afterClosed()
                .subscribe((result : any) => {
                    this.getlocalvalues();
                    if (result == "payment") {
                        this.gotopayment(result);
                    } else if (result == "reg") {
                        this.gotopayment(result);
                    }
                    this.commonloaderAS = false
                });
        } else {
            this.isLogged = localStorage.getItem('isLogged');
            var sessionid = localStorage.getItem('sessionid');
            var data = {

                "email": this.userEmail,
                "sessionid": sessionid
            }
            this
                .http
                .commonMethod(data, this.logonUrl.session_check)
                .subscribe(res => {
                    if (res) {
                        this.sessionvar = res;
                        if (this.sessionvar.status == 200) {
                            console.log("Login Success for Eye Check");
                            if (!this.userEmail) {
                                this.userEmail = localStorage.getItem('userEmail');
                            }
                            this.GetActiveSouce()
                        } else {
                            const dialogRef = this
                                .dialog
                                .open(DialogComponent, {
                                    disableClose: true,
                                    data: {
                                        dialogname: 'loginpopup',
                                        goto: 'payment',
                                        goto1: "reg"
                                    }
                                });
                            dialogRef
                                .afterClosed()
                                .subscribe((result : any) => {
                                    this.getlocalvalues();
                                    if (result == "payment") {
                                        this.gotopayment(result);
                                    } else if (result == "reg") {
                                        this.gotopayment(result);
                                    }
                                    this.commonloaderAS = false
                                });
                        }
                    }

                })
        }
    }
    /**
   * @ignore
   */
    demo() {
        setTimeout(() => {
            alert()
            return 'testClass'
        }, 500);
    }
    /**
 * @function Get Active Source State Start here
 */
    GetActiveSouce() {
        var advalue = JSON.parse(localStorage.getItem('eraddetails'))

        var getActiveSource = {
            // "flag": "GET_ACTIVE_SOURCING",
            "method": "AUTH",
            "title": localStorage.getItem("JobTitleRecruiter"),
            "base64": "",
            "extension": "",
            "link": "",
            "ad_id": this.accesstoken.ad_id,
            "email": this.userEmail,
            "zip_code": advalue.zipcode,
            "country_code": advalue.country_code,
            "search_distance":"100",
            "semantic_search":[]
        }
        this
            .http
            .commonMethod(getActiveSource, this.logonUrl.get_active_sourcing)
            .subscribe(data => {

                this.getActiveSourceData = data;
                if (this.getActiveSourceData.status == 200) {
                    console.log("ActiveSourceData", this.getActiveSourceData);
                    this.getActiveSourceAdid = this.getActiveSourceData.res.header.parameter.ad_id;
                    console.log(this.getActiveSourceAdid);
                    this.userEmail = localStorage.getItem('userEmail')
                    var flag = {

                        "email": this.userEmail,
                        "ad_id": this.getActiveSourceData.res.header.parameter.ad_id

                    }
                    this
                        .http
                        .commonMethod(flag, this.logonUrl.email_update_adid)
                        .subscribe(data => {
                            this.response = data
                            if (this.response.status == 200) {
                                this.getAD_data(this.getActiveSourceAdid)
                                sessionStorage.setItem("ActiveSurceAdid", this.getActiveSourceAdid);
                                localStorage.removeItem("cockpit")
                                localStorage.setItem("CockpitAdmin", 'recruiterFeedback')
                            }

                        })

                } else {}

            });
    }
    // E-Recruiter Feedback Call Active Source Ends Here by Balaji

    /**
   * @ignore
   */
    setdefault() {
        this.default_welcometext = true
        if (this.default_welcometext) {
            this.candidatelogoform.value.welcome_text = "Deine Stimme zählt! Wenn Du Dich für die ausgewählte Stelle bewerben möchtest, e" +
                    "rzähle mir doch einfach von Deinen Erfahrungen";
            this.isReadOnly = true;
        } else {
            this.candidatelogoform.value.welcome_text = ""
            this.isReadOnly = false;
        }
    }
    /**
 * @ignore
 */
    setdefault1() {
        console.log("dfsdfsd")
        if (this.default_termsconditions) {
            this.candidatelogoform.value.terms_conditions = "Bitte nenne/bestätige die vom Gesetz vorgeschriebene Kontaktperson für Datenschu" +
                    "tz in Deinem Unternehmen"
            this.isReadOnlyTC = true;
        } else {
            this.candidatelogoform.value.terms_conditions = ""
            this.isReadOnlyTC = false;
        }
    }
    /**
 * @ignore
 */
    check() {}
    /**
 * @ignore
 */
    clear() {
        console.log("logo")
        this.candidatelogoform.value.company_logo = "dgs"
    }
    /**
   * @ignore
   */
    seterecruiterdata() {
        var flagdata = {
            file: this.data.fileinput,
            job_titile: "",
            postcode: "",
            email: ""
        }
        this
            .http
            .commonMethod(flagdata, this.logonUrl.set_report)
            .subscribe(res => {
                // this.spinner.hide();
                this.response = res
                if (this.response.status == 200) {} else {}
            })

    }

    navigatetoLandingPage(name) {
        this
            .router
            .navigate(['/']);
        this
            .dialogRef
            .close(name);

    }
    /**
   * @ignore
   */
    setoptions(value) {
        this.linktype = JSON.parse(localStorage.getItem('linktype'));
        if (this.linktype) {
            this.data.chat = true
            this.data.feedback = true

            this.openpopup('linkpopupregister', '')
            localStorage.removeItem('linktype');
        } else {
            if (this.bluecollar) {
                this.data.chat = true
                this.data.feedback = false
                this.data.matchreport = false
            } else {
                this.data.chat = true
                this.data.feedback = true
                this.data.matchreport = true
            }

            this.openpopup('linkpopupregister', '')
        }
    }
    /**
   * Open Popup for All Dialog popup
   * @example based on the Html Name and Value it Will Show
   */
    openpopup(name, goto1) {
        this.otp = this.RegisterOTPForm.value.RegisterOTPnum1 + this.RegisterOTPForm.value.RegisterOTPnum2 + this.RegisterOTPForm.value.RegisterOTPnum3 + this.RegisterOTPForm.value.RegisterOTPnum4 + this.RegisterOTPForm.value.RegisterOTPnum5 + this.RegisterOTPForm.value.RegisterOTPnum6
        if (name == 'OTPdetailspopup') {
            if (this.RegisterEmailForm.value.RegisterEmail == "") {
                this.emailverify = false;
                this.EmptyErr = true;
                this.ValidErr = false;
            } else {
                var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!pattern.test(this.RegisterEmailForm.value.RegisterEmail)) {
                    this.emailverify = false;
                    this.EmptyErr = false;
                    this.ValidErr = true;
                } else {
                    this.emailverify = false;
                    this.EmptyErr = false;
                    this.ValidErr = false;
                    this.EMAIL_verify(goto1);
                }
            }
            // this.data.dialogname = name;
        } else if (name == 'passworddetailspopup') {
            if (this.RegisterOTPForm.value.RegisterOTPnum1 == "" || this.RegisterOTPForm.value.RegisterOTPnum2 == "" || this.RegisterOTPForm.value.RegisterOTPnum2 == "" || this.RegisterOTPForm.value.RegisterOTPnum3 == "" || this.RegisterOTPForm.value.RegisterOTPnum4 == "" || this.RegisterOTPForm.value.RegisterOTPnum5 == "" || this.RegisterOTPForm.value.RegisterOTPnum6 == "" || this.otp == "") {
                this.OTPErr = true;
                this.OTPdiv = false;
            } else {
                this.OTP_verify("", this.RegisterEmailForm.value.RegisterEmail.toLowerCase(), goto1);
            }
            // this.data.dialogname = name;
        } else if (name == 'fp_passworddetailspopup') {
            this.otp = "";
            this.otp = this.fp_RegisterOTPForm.value.RegisterOTPnum1 + this.fp_RegisterOTPForm.value.RegisterOTPnum2 + this.fp_RegisterOTPForm.value.RegisterOTPnum3 + this.fp_RegisterOTPForm.value.RegisterOTPnum4 + this.fp_RegisterOTPForm.value.RegisterOTPnum5 + this.fp_RegisterOTPForm.value.RegisterOTPnum6

            if (this.fp_RegisterOTPForm.value.RegisterOTPnum1 == "" || this.fp_RegisterOTPForm.value.RegisterOTPnum2 == "" || this.fp_RegisterOTPForm.value.RegisterOTPnum2 == "" || this.fp_RegisterOTPForm.value.RegisterOTPnum3 == "" || this.fp_RegisterOTPForm.value.RegisterOTPnum4 == "" || this.fp_RegisterOTPForm.value.RegisterOTPnum5 == "" || this.fp_RegisterOTPForm.value.RegisterOTPnum6 == "" || this.otp == "") {
                this.ForgotOTPErr = true;
                this.ForgotOTPdiv = false;
            } else {
                this.OTP_verify('forgotpassword', this.loginform1.value.emailId.toLowerCase(), "");
            }

        } else if (name == 'successregisterpopup') {
            // else if (name == 'successregisterpopup') {
            this.Register(goto1);

            // this.data.dialogname = name;
        } else if (name == 'Paymentdetailspopup') {
            if (this.payment_confirmaccept) {
                this.get_countrycode()
                this.get_paymentdetail();
                this.data.goto = this.data.goto;
                this.data.candidatedata = this.data.candidatedata;
                this.data.dialogname = name;
            } else {
                this.hint_payment_confirmaccept = true;
            }

            // this.data.dialogname = name;
        } else if (name == "Registerdetailspopup") {
            if (goto1 == "reg") {
                this.data.dialogname = name;
                this.data.reglog = "reg"
            } else if (goto1 == "bluecollor") {
                this.data.dialogname = name;
                this.data.goto = "bluecollor";
                this.data.reglog = "bluecollor";
            } else {
                this.data.dialogname = name;
            }
        } else if (name == "Logindetailspopup") {
            if (goto1 == "reg") {
                this.data.dialogname = name;
                this.data.goto = "reg";
            } else if (goto1 == "bluecollor") {
                this.data.dialogname = name;
                this.data.goto = "bluecollor";
            } else {
                this.data.dialogname = name;
                this.data.goto = goto1;

            }

        } else if (name == "resetpassword") {
            this.forgotpasword();
            // this.data.dialogname = name;
        } else if (name == "Paymentdetailspopup") {
            this.get_countrycode()
            this.get_paymentdetail();
            this.data.dialogname = name;
        } else if (name == "confirmpopup") {
            this.ptfieldvalidation();
            // this.data.dialogname = name;
        } else if (name == "candidatelogopopup") {
            this.data.dialogname = name;
        } else if (name == "onlypayment") {
            // this.dialogRef.close(name);
            this.data.dialogname = "Paymentdetailspopup";
        } else if (name == 'customizecandidatepopup') {
            if (this.payment_confirmaccept) {
                this.data.dialogname = 'customizecandidatepopup'
            } else {
                this.hint_payment_confirmaccept = true;
            }
        } else if (name == "linkpopupregister") {
            // this.data.dialogname = name;
            var flagdata
            var demovalue = JSON.parse(localStorage.getItem('jobdetails'));
            this.accesstoken = JSON.parse(localStorage.getItem('token'));
            var refrence_id = sessionStorage.getItem("refrenceEasyApply")
            if (this.linktype) {

                flagdata = {

                    "job_title": "UX Manager",
                    "job_id": refrence_id,
                    "email": this.userEmail,
                    "ad_id": sessionStorage.getItem('easyApplyDummyAdid'),
                    "payment_email": this.data.paymen1,
                    "reference": refrence_id,
                    "company_name": this.data.company_name
                }

            } else {

                flagdata = {

                    "ad_id": this.accesstoken.ad_id,
                    "email": this.userEmail,
                    "company_name": this.data.company_name,
                    "job_title": demovalue.title,
                    "job_id": this.accesstoken.internal_reference,
                    "payment_email": this.data.paymen1,
                    "enhanced": false,
                    "aufgaben": "",
                    "anforderungen": "",
                    "firmenhintergrund": "",
                    "sonstiges": "",
                    "local_chat": false

                }
                var noOwnAd=sessionStorage.getItem("NoOwnAd")
                console.log("OWN AD:", noOwnAd)
                if (noOwnAd)
                {
                    flagdata.local_chat=noOwnAd;
                }
                var enhanced = sessionStorage.getItem("enhanced")
                if (enhanced) {
                    flagdata.enhanced = true
                    flagdata.aufgaben = sessionStorage.getItem("Aufgaben")
                    flagdata.anforderungen = sessionStorage.getItem("Anforderungen")
                    flagdata.firmenhintergrund = sessionStorage.getItem("Firmenhintergrund")
                    flagdata.sonstiges = sessionStorage.getItem("Sonstiges")
                }

            }
            console.log("Obj", flagdata)
            this.commonloader = true;
            var url;
            // let type = localStorage.getItem("applytype");
            if (!this.linktype) {
                url = this.logonUrl.get_ad_url
            } else {
                url = this.logonUrl.get_cv_url
            }
            console.log(url, "Url")
            this
                .http
                .commonMethod(flagdata, url)
                .subscribe(res => {
                    // this.spinner.hide();
                    this.response = res;
                    console.log("GetAdUrl", this.response)
                    if (this.response.status == 200) {
                        sessionStorage.removeItem("enhanced")
                        if (this.linktype) {
                            this.data.url = this.response.res.URL;
                        }
                        if (this.data.url) {
                            this.data.originalurl = this
                                .data
                                .url
                                .split("/")[3];
                        }
                        this.data.url = this.response.res.URL;
                        this.data.Enhanced_URL = this.response.res.Enhanced_URL
                        if (this.data.url) {
                            this.commonloader = false;
                            this.data.dialogname = name;
                        } else {
                            // this.data.dialogname = name;
                        }
                    } else {}
                })
        } else if (name == "jobdescriptionpopup") {
            this.data.dialogname = name;
        }
    }
    /**
 * @ignore
 */
    get_paymentdetail() {
        console.log("paymentdetails");
        var flag = {

            "email": localStorage.getItem('userEmail')
        }

        this
            .http
            .commonMethod(flag, this.logonUrl.get_payment_detail)
            .subscribe(res => {
                this.response = res
                if (this.response.status == 200) {
                    // this.get_countrycode()
                    console.log(this.response)
                    this.paymentdetails = this.response
                    if (this.response.payment_informations) {
                        this
                            .paymentdetailsform
                            .patchValue({
                                ptfirstname: this.response.payment_informations.firstname,
                                ptlastname: this.response.payment_informations.lastname,
                                ptcompanyname: this.response.payment_informations.company_name,
                                ptcity: this.response.payment_informations.city,
                                ptstreetname: this.response.payment_informations.street,
                                ptcountryname: this.response.payment_informations.country,
                                ptzip: this.response.payment_informations.zip,
                                ptemail: this.response.payment_informations.payment_email,
                                pttelephoneno: this.response.payment_informations.telephone,
                                vatnumber: this.response.payment_informations.vat_no,
                                countrycode: this.response.payment_informations.country_code
                            });
                        this.countrycd = this.response.payment_informations.country_code
                        this.country = "+49"

                    }

                } else {}
            })

    }
    // Method for Getting Easy Apply Adid
    /**
 * @ignore
 */
    getEasyApplyAdid : any
    GetAccesTokenSetAddata : any
    getMyIdsEasyApply() {
        var flag = {
            "method": "AUTH"
        }
        this
            .http
            .commonMethod(flag, this.logonUrl.get_my_ids)
            .subscribe(res => {
                this.getEasyApplyAdid = res;
                if (this.getEasyApplyAdid.status == 200) {

                    sessionStorage.setItem('EasyApplyAdid', this.getEasyApplyAdid.res.message.ad_id);
                    console.log("GetMyI", this.getEasyApplyAdid)
                    var setAdValueList = {
                        "method": "AUTH",
                        "ad_id": this.getEasyApplyAdid.res.message.ad_id,
                        "permanent": "",
                        "fulltime": "",
                        "email": this.userEmail,
                        "city": "",
                        "free_text": "",
                        "title": "",
                        "location": "",
                        "date": "",
                        "company": "",
                        "url": "",
                        "as_company": "",
                        "organisation_id": "111",
                        "recruiter_id": this.recruiterid,
                        "country_code": "",
                        "reference_id": "",
                        "local_chat": false
                    }
                    this
                        .HTTPServiceService
                        .commonMethod(setAdValueList, this.logonUrl.set_ad_data)
                        .subscribe(data => {
                            var setAdData = data;
                            console.log("Set Ad Data Response", setAdData)
                        })
                }
            })
    }
    /**
 * Main Payment Start Here
 */
    Paymentdetails(goto) {

        // var EasyApplyAdidPayment = sessionStorage.getItem("EasyApplyAdidPayment")
        this.recruiterid = localStorage.getItem('recruiter_id');

        if (this.paymentdetailsform.value.hintptvalidemail == "") {
            this.hintptvalidemail = true;
        }

        if (this.paymentdetailsform.value.ptfirstname == "") {
            this.hintptfirstname = true;
        } else if (this.paymentdetailsform.value.ptlastname == "") {
            this.hintptlastname = true;
        } else if (this.paymentdetailsform.value.ptcompanyname == "") {
            this.hintptcompanyname = true;
        } else if (this.paymentdetailsform.value.ptcity == "") {
            this.hintptcity = true;
        } else if (this.paymentdetailsform.value.ptstreetname == "") {
            this.hintptstreetname = true;
        } else if (this.paymentdetailsform.value.ptcountryname == "") {
            this.hintptcountryname = true;
        } else if (this.paymentdetailsform.value.ptzip == "") {
            this.hintptzip = true// else if (this.paymentdetailsform.value.pttelephoneno == "") {
            // this.hintpttelephoneno = true; };
        } else if (this.paymentdetailsform.value.ptemail == "") {
            this.hintptemail = true// else if (this.paymentdetailsform.value.vatnumber == "") {
            // this.hintvatnumber = true; };
        } else {
            let email = this.paymentdetailsform.value.ptemail;

            this.data.paymen1 = this.paymentdetailsform.value.ptemail
            if (email != "") {
                var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!pattern.test(email)) {
                    this.hintptvalidemail = true;
                    this.hintptemail = false;
                    return false;
                }
                this.hintptvalidemail = false;
                this.hintptemail = false;
                this.hintptvalidemail = false;
                this.hintptfirstname = false;
                this.hintptlastname = false;
                this.hintptcompanyname = false;
                this.hintptcity = false;
                this.hintptstreetname = false;
                this.hintptcountryname = false;
                this.hintptzip = false;
                this.hintpttelephoneno = false;
                this.hintvatnumber = false;
                this.hintptemail = false;
                this.getlogindetails = {};

                this.getlogindetails.email = this.userEmail;
                this.getlogindetails.firstname = this.paymentdetailsform.value.ptfirstname;
                this.getlogindetails.lastname = this.paymentdetailsform.value.ptlastname;
                this.getlogindetails.company = this.paymentdetailsform.value.ptcompanyname;
                this.getlogindetails.city = this.paymentdetailsform.value.ptcity;
                this.getlogindetails.street = this.paymentdetailsform.value.ptstreetname;
                this.getlogindetails.country = this.paymentdetailsform.value.ptcountryname;
                this.getlogindetails.zipcode = this.paymentdetailsform.value.ptzip;
                this.getlogindetails.telephone = this.paymentdetailsform.value.pttelephoneno;
                this.getlogindetails.vat_no = this.paymentdetailsform.value.vatnumber;
                this.getlogindetails.paymentemail = this
                    .paymentdetailsform
                    .value
                    .ptemail
                    .toLowerCase();
                this.getlogindetails.countrycode = this.country;

                if (this.linktype) {
                    this.getlogindetails.payment_mode = "CV";
                } else {
                    this.getlogindetails.payment_mode = "AD";
                }

                if (this.data.goto == "closeRecruiter") {
                    this.getlogindetails.job_title = this.data.candidatedata.title;
                    this.getlogindetails.created_date = this.data.candidatedata.date;
                    this.getlogindetails.payment_id = "";
                    this.getlogindetails.ad_id = this.data.candidatedata.profile_ad_id;
                    this.getlogindetails.cv_id = this.data.candidatedata.profile_cv_id;
                    this.getlogindetails.recruiter_id = this.recruiterid;
                    this.getlogindetails.timestamp = this.data.candidatedata.timestamp;
                    this.getlogindetails.expired_timestamp = "";
                    this.getlogindetails.amount = "";
                    this.getlogindetails.payment_type = "";
                    this.getlogindetails.organisation_id = "111";
                    this.getlogindetails.payment_mode = "";
                    this.getlogindetails.e_recruiter = true;

                }

                this.commonloader = true;
                this
                    .HTTPServiceService
                    .commonMethod(this.getlogindetails, this.logonUrl.insert_payment_detail)
                    .subscribe(data => {
                        // this.spinner.hide();

                        this.commonloader = false;
                        if (data != null) {
                            var details : any = data;
                            // console.log(details.headers);
                            if (details.status == 200) {
                                this.data.paymentid = details.status.paymentid;
                                console.log(this.data.goto)
                                if (goto == "confirmpayment") {
                                    // this.data.dialogname = "confirmpaymentpopup";
                                    this.data.dialogname = "customizecandidatepopup";

                                } else if (this.data.goto == "onlypayment") {
                                    this
                                        .dialogRef
                                        .close(name);

                                } else if (this.data.goto == "closeRecruiter") {
                                    this
                                        .dialogRef
                                        .close("success");

                                } else if (goto == "bluecollor") {
                                    this.data.dialogname = "customizecandidatepopup";
                                    this.data.goto = "bluecollor"
                                } else {
                                    this.data.dialogname = "customizecandidatepopup";
                                    // this.data.dialogname = "customizecandidatepopup";
                                    this.data.customcvid = details.status.cvid;
                                    // sessionStorage.setItem('eassyapplycvid', details.status.cvid)
                                }
                                setTimeout(() => {
                                    var name12 = this.paymentdetailsform.value.ptcompanyname
                                    this
                                        .candidatelogoform
                                        .patchValue({company_name: name12});
                                }, 500);
                                sessionStorage.removeItem("EasyApplyAdidPayment")
                                sessionStorage.removeItem("EasyApplyAdid")

                            } else {
                                this.alertmsg(details.message, 'error')
                            }
                        } else {}
                    }, error => {});
            }
        }
    }
    company_namev : any

    // this.test1();
    /**
 * @ignore
 */
    test1() {
        this.userEmail = localStorage.getItem('userEmail');
        if (this.userEmail) {
            this.loginuser = this
                .userEmail
                .split('@')[0];
        }
    }
    /**
 * @ignore
 */
    deleteimage() {
        this.imagePreview = ""
        this
            .candidatelogoform
            .patchValue({company_logo: ""});
    }
    /**
 * @ignore
 */
    namechange() {
        // this.candidatelogoform.patchValue({   companies:
        // this.candidatelogoform.value.company_name, });
    }
    /**
   * @ignore
   */
    clearcandidate() {
        this.hintcompanies = false
        this.hintcontact_person = false
    }

    /**
   * @ignore
   * toCheck
   */
    getlanguagevalue() {
        var language = this.currentlanguage
        this
            .HTTPServiceService
            .getlangvalue(language)
            .subscribe(data => {
                if (data != null) {
                    var details : any = data;
                    this.languagedata = details
                    console.log(details, "details");
                } else {}
            }, error => {});
    }
    /**
 * Candidate Logo Uplaod and Link Creation
 */
    // Company Logo
    GetDummyAdid : any
    getRefrenceName : boolean = false
    RefrenceLabel = false
    EnhancedField = false
    setcandidatedetails(goto) {
        console.log(this.candidatelogoform.value.company_logo)

        console.log("Easy Apply", this.linktype)
        // console.log(this.candidatelogoform.value.company_logo)
        var value;
        sessionStorage.setItem("refrenceEasyApply", this.candidatelogoform.value.refrence_id)
        let email = this.candidatelogoform.value.companies;
        if (email != "") {
            var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!pattern.test(email)) {
                this.hintcompanies = true;
                return false;
            }
        }
        this.test1();
        if (!this.linktype && this.enhancedLinktrue == true) {
            sessionStorage.setItem("enhanced", "true")
            if (this.candidatelogoform.value.Aufgaben || this.candidatelogoform.value.Anforderungen || this.candidatelogoform.value.Firmenhintergrund == "") {
                this.EnhancedField = true
            }
        }

        if (this.imagePreview) {
            this.candidatelogoform.value.company_logo = this.imagePreview
        }
        if (this.candidatelogoform.value.company_logo) {
            let fileformatlistLogo = [];
            fileformatlistLogo.push("png", "jpg", "jpeg");
            let formatLogo = fileformatlistLogo.includes(this.Imagetype);
            if (this.Imagetype === undefined) 
                formatLogo = true;
            console.log("Logo Format", formatLogo)

            if (formatLogo == false) {
                console.log(this.Imagetype);
                alert("Invalid Logo format")
                return false;
            }
        }
        if (this.imagePreview4) {
            this.candidatelogoform.value.terms_conditions = this.imagePreview4
        }
        this.default_welcometext = true
        if (this.default_welcometext) {
            this.candidatelogoform.value.welcome_text = this.languagedata.DIALOG.CONTENT67 + this.languagedata.DIALOG.CONTENT68
        }

        var termsandcondition = {
            title: "Datenschutzhinweise ",
            des: "Diese Informationen geben Ihnen einen Überblick über die Verarbeitung Ihrer pers" +
                    "onenbezogenen Daten, wenn Sie sich bei uns per Online-Chat („Easy Apply“) bewerb" +
                    "en. Wir informieren Sie darüber, welche Daten wir von Ihnen erheben und wie wir " +
                    "sie verwenden.",
            "Q_one": "Wer ist für die Datenverarbeitung verantwortlich?  ",
            "Q_oneans": "Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten bei der Bewerb" +
                    "ung ist die",
            "Q_oneans1": "- Unternehmen, Kontaktperson (ideally address of the company) ",
            "Q_oneans2": "E-Mail-Adresse ",
            "Q_two": "An wen können Sie sich bei Fragen zur Datenverarbeitung wenden?  ",
            "Q_twoans": "Bei Fragen zur Verarbeitung Ihrer Daten können Sie sich jederzeit an den Datensc" +
                    "hutzbeauftragten wenden. Die Kontaktdaten des Ansprechpartners für Datenschutzfr" +
                    "agen lautet: ",
            "Q_twoans1": "- E-Mail Adresse  ",
            "Q_three": "Welche Daten verarbeiten wir von Ihnen?",
            "Q_threeans": "Wir verarbeiten die personenbezogenen Daten, die Sie uns beim Online-Chat nennen" +
                    ": - Berufserfahrung (aktuelle und vorherige Position, aktuelle und vorherige Arb" +
                    "eitgeber, Dauer der jeweiligen Beschäftigung, Perioden ohne Beschäftigung) - Fäh" +
                    "igkeiten - Weiterbildungen - Sprachen - Name - Email-Adresse - Telefonnummer ",
            "Q_four": "Für welche Zwecke verarbeiten wir Ihre Daten und auf welcher Rechtsgrundlage erf" +
                    "olgt dies?",
            "Q_fourans": "Wenn Sie sich per Online-Chat bei uns bewerben, werden Sprachaufnahmen von Ihnen" +
                    " angefertigt und gespeichert. Es findet keine Analyse oder Bewertung der Spracha" +
                    "ufnahme statt. Die Sprachaufnahmen werden per google speech to text API in .txt " +
                    "Dateien umgewandelt und auf den LogOn Server core.erecruiting.io übermittelt. Di" +
                    "e Übermittlung erfolgt als base64. Der Base64 string wird in einer SQL Datenbank" +
                    " auf dem LogOn Core Server zur weiteren Verarbeitung gespeichert. Die Textprotok" +
                    "olle werden dann, ggf. mit den von Ihnen hochgeladenen Dokumenten, durch ausgewä" +
                    "hlte Mitarbeiter des Personalbereiches zum Zwecke der Bearbeitung Ihrer Bewerbun" +
                    "g und zur Bewerberauswahl weiterverarbeitet. ",
            "Q_fourans1": "Die Verarbeitung Ihrer Daten im Rahmen der Bewerbung erfolgt auf der Rechtsgrund" +
                    "lage Art. 6 Abs.1 Buchstabe b Datenschutz-Grundverordnung (zum Zweck der Bewerbe" +
                    "rauswahl und Kontaktaufnahme, vorvertragliche Maßnahmen zum Zweck der Bewerberau" +
                    "swahl) und § 26 Abs. 1 Satz 1 BDSG. ",
            "Q_five": "Wer hat Zugang zu Ihren Daten und aus welchem Grund? ",
            "Q_fiveans": "Ihre personenbezogenen Daten werden ausschließlich für Zwecke der Bearbeitung Ih" +
                    "rer Bewerbung verarbeitet. Die Daten sind nur ausgewählten Mitarbeiterinnen und " +
                    "Mitarbeitern der Personalabteilung und den für die Auswahl verantwortlichen Pers" +
                    "onen im Unternehmen zugänglich. Zudem können von uns eingesetzte Dienstleister, " +
                    "die uns bei der Erfüllung der Aufgaben unterstützen, Zugriff auf die Daten erhal" +
                    "ten. Eine Datenübermittlung in Länder außerhalb der EU/ des EWR findet nicht sta" +
                    "tt. ",
            "Q_six": "Automatisierte Entscheidungsfindung; Profiling",
            "Q_sixans": "Ihre personenbezogenen Daten werden weder für automatisierte Entscheidungsfindun" +
                    "g noch für Profiling verwendet.",
            "Q_seven": "Wie lange werden die Daten gespeichert?",
            "Q_sevenans": "Wir speichern Ihre Daten, solange wie dies für die Entscheidung über Ihre Bewerb" +
                    "ung erforderlich ist. Soweit ein Beschäftigungsverhältnis nicht zustande kommt, " +
                    "können wir Ihre Daten darüber hinaus noch weiter speichern, soweit dies zur Vert" +
                    "eidigung gegen mögliche Rechtsansprüche erforderlich ist. In der Regel werden di" +
                    "e Bewerbungsunterlagen incl. der Textprotokolle nach sechs Monaten gelöscht. Die" +
                    " Sprachaufnahmen werden 3 Monate nach erfolgter Aufnahme gelöscht. ",
            "Q_eight": "Welche Rechte haben Sie? ",
            "Q_eightans": "Sie können Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten verl" +
                    "angen. Sollten Ihre Angaben nicht (mehr) zutreffend sein, können Sie die Bericht" +
                    "igung Ihrer Daten verlangen. Sollten Ihre Daten unvollständig sein, können Sie e" +
                    "ine Vervollständigung verlangen. Sie haben das Recht, die Löschung Ihrer Daten z" +
                    "u verlangen. Bitte beachten Sie, dass ein Anspruch auf Löschung von dem Vorliege" +
                    "n eines legitimen Grundes abhängt. Zudem dürfen keine Vorschriften bestehen, die" +
                    " uns zur Aufbewahrung Ihrer Daten verpflichten. Sie haben das Recht, die Einschr" +
                    "änkung der Verarbeitung Ihrer Daten zu verlangen. Bitte beachten Sie, dass ein A" +
                    "nspruch auf Einschränkung der Verarbeitung von dem Vorliegen eines legitimen Gru" +
                    "ndes abhängt. ",
            "Q_eightans1": "Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergebe" +
                    "n, gegen die Verarbeitung Ihrer Daten Widerspruch einzulegen. Im Falle eines ber" +
                    "echtigten Widerspruchswerden wir Ihre Daten nicht mehr verarbeiten. Sie sind ber" +
                    "echtigt, bei einer Datenschutzaufsichtsbehörde Beschwerde einzureichen, wenn Sie" +
                    " mit der Verarbeitung Ihrer Daten nicht einverstanden sind. Sie haben das Recht," +
                    " personenbezogene Daten, die Sie uns mitgeteilt haben, in einem elektronischen F" +
                    "ormat zu erhalten. "
        }

        if (!this.default_termsconditions) {
            this.candidatelogoform.value.terms_conditions = termsandcondition
        }

        if (this.candidatelogoform.value.company_name == "") {
            this.hintcompany_name = true;
        }

        if (this.linktype && this.candidatelogoform.value.refrence_id == "") {
            this.getRefrenceName = true
        } else if (!this.default_termsconditions && this.candidatelogoform.value.contact_person == "") {
            this.hintcontact_person = true;
        } else if (!this.default_termsconditions && this.candidatelogoform.value.companies == "") {
            this.hintcompanies = true;
        } else if (this.default_termsconditions && this.candidatelogoform.value.terms_conditions == "") {
            this.hintterms_conditions = true;
            // else if (!this.default_termsconditions &&
            // this.candidatelogoform.value.company_logo == "") {  this.hintcompany_logo =
            // true; }
        } else {
            this.data.company_name = this.candidatelogoform.value.company_name
            if (this.linktype) {
                console.log("easy Apply")
                var createDummyAdid = {

                    "method": "AUTH",
                    "country_code": "de",
                    "organisation_id": "111",
                    "recruiter_id": this.recruiter_id1,
                    "reference": this.candidatelogoform.value.refrence_id
                }
                this.commonloader = true;
                console.log(createDummyAdid)
                this
                    .http
                    .commonMethod(createDummyAdid, this.logonUrl.get_dumy_ad)
                    .subscribe(data => {
                        console.log(data)
                        this.GetDummyAdid = data
                        if (this.GetDummyAdid.status == 200) {
                            sessionStorage.setItem("easyApplyDummyAdid", this.GetDummyAdid.res.message.ad_id)
                            value = {

                                "company_name": this.candidatelogoform.value.company_name,
                                "company_logo": this.candidatelogoform.value.company_logo,
                                "company_logo_url": "",
                                "welcome_text": this.candidatelogoform.value.welcome_text,
                                "terms_conditions": this.candidatelogoform.value.terms_conditions,
                                "terms_and_condition_file": "",
                                "terms_and_condition_link": "",
                                "ad_id": this.GetDummyAdid.res.message.ad_id,
                                "recruiter_id": this.recruiter_id1,
                                "contact_person": this.candidatelogoform.value.contact_person,
                                "companies": this.candidatelogoform.value.companies,
                                "reference": this.candidatelogoform.value.refrence_id
                            }
                            if (this.optionlink) {
                                value.company_logo = this.candidatelogoform.value.company_logo
                                value.company_logo_url = ""
                            } else {
                                value.company_logo_url = this.candidatelogoform.value.company_logo
                                value.company_logo = ""
                            }

                            if (this.termslink) {
                                value.terms_and_condition_link = this.candidatelogoform.value.terms_conditions;
                                value.terms_and_condition_file = ""
                                value.terms_conditions = ""
                            } else if (!this.default_termsconditions) {
                                value.terms_and_condition_link = ""
                                value.terms_and_condition_file = ""
                            } else {
                                value.terms_and_condition_link = ""
                                value.terms_conditions = ""
                                value.terms_and_condition_file = this.candidatelogoform.value.terms_conditions
                            }

                            console.log("easyApply", value);

                            this
                                .HTTPServiceService
                                .commonMethod(value, this.logonUrl.company_logo)
                                .subscribe(data => {
                                    console.log(data);
                                    // this.spinner.hide();
                                    this.commonloader = false;
                                    if (data != null) {
                                        var details : any = data;
                                        if (details.status == 200) {
                                            console.log("Goto", goto)
                                            if (goto == "bluecollor") {
                                                this.data.goto = 'bluecollor'
                                                this.data.got1 = 'bluecollor'
                                                this.openpopup('linkpopupregister', '')
                                                // this.data.dialogname = "linkpopupregister"
                                            } else {
                                                this.bluecollar = JSON.parse(localStorage.getItem('bluecollar'))
                                                if (this.bluecollar) {

                                                    this.setoptions("")
                                                } else {
                                                    this.setoptions("")

                                                }
                                                var UpdateEmail = {
                                                    "email": localStorage.getItem('userEmail'),
                                                    "ad_id": sessionStorage.getItem("easyApplyDummyAdid")
                                                }
                                                this
                                                    .http
                                                    .commonMethod(UpdateEmail, this.logonUrl.email_update_adid)
                                                    .subscribe(data => {
                                                        console.log("EMail UPdate", data);
                                                    })

                                            }
                                            this.data.company_name = this.candidatelogoform.value.company_name

                                        } else {
                                            this.alertmsg(details.message, 'error')
                                        }
                                    } else {}
                                }, error => {});
                        }
                    })
            } else {

                console.log("direct Apply")
                if (this.enhancedLinktrue) {
                    sessionStorage.setItem("Aufgaben", this.candidatelogoform.value.Aufgaben)
                    sessionStorage.setItem("Anforderungen", this.candidatelogoform.value.Anforderungen)
                    sessionStorage.setItem("Firmenhintergrund", this.candidatelogoform.value.Firmenhintergrund)
                    sessionStorage.setItem("Sonstiges", this.candidatelogoform.value.Sonstiges)
                }
                value = {

                    "company_name": this.candidatelogoform.value.company_name,
                    "company_logo": this.candidatelogoform.value.company_logo,
                    "company_logo_url": "",
                    "welcome_text": this.candidatelogoform.value.welcome_text,
                    "terms_conditions": this.candidatelogoform.value.terms_conditions,
                    "terms_and_condition_file": "",
                    "terms_and_condition_link": "",
                    "ad_id": this.accesstoken.ad_id,
                    "recruiter_id": this.recruiter_id1,
                    "contact_person": this.candidatelogoform.value.contact_person,
                    "companies": this.candidatelogoform.value.companies,
                    "reference": ""
                }
                if (this.termslink) {
                    value.terms_and_condition_link = this.candidatelogoform.value.terms_conditions;
                    value.terms_and_condition_file = ""
                    value.terms_conditions = ""
                } else if (!this.default_termsconditions) {
                    value.terms_and_condition_link = ""
                    value.terms_and_condition_file = ""
                } else {
                    value.terms_and_condition_link = ""
                    value.terms_conditions = ""
                    value.terms_and_condition_file = this.candidatelogoform.value.terms_conditions
                }
                value.email = localStorage.getItem('userEmail');
                this.commonloader = true;
                console.log("Direct Apply Value", value);
                this
                    .HTTPServiceService
                    .commonMethod(value, this.logonUrl.company_logo)
                    .subscribe(data => {
                        console.log(data);
                        // this.spinner.hide();
                        this.commonloader = false;
                        if (data != null) {
                            var details : any = data;
                            if (details.status == 200) {
                                if (goto == "bluecollor") {
                                    this.data.goto = 'bluecollor'
                                    this.data.got1 = 'bluecollor'
                                    this.openpopup('linkpopupregister', '')
                                    // this.data.dialogname = "linkpopupregister"
                                } else {
                                    this.bluecollar = JSON.parse(localStorage.getItem('bluecollar'))
                                    if (this.bluecollar) {
                                        //  this.openpopup('linkpopupregister', '')
                                        this.setoptions("")
                                    } else {
                                        this.setoptions("")
                                        // this.data.dialogname = "CVoptions"
                                    }
                                }
                                this.data.company_name = this.candidatelogoform.value.company_name
                                // this.data.dialogname = "confirmpaymentpopup"; this.data.dialogname =
                                // "confirmpaymentpopup";
                            } else {
                                this.alertmsg(details.message, 'error')
                            }
                        }

                    }, error => {});
            }
        }

    }
    /**
   * @ignore
   */
    directcheckbocfix(value, data) {
        if (value == "one") {
            if (this.one == true) {
                this.one = false
            } else {
                this.clearcheckboxdirect()
                this.one = true
            }
        } else if (value == "fifty") {
            if (this.fifty == true) {
                this.fifty = false
            } else {
                this.clearcheckboxdirect()
                this.fifty = true
            }
        } else if (value == "twofifty") {
            if (this.twofifty == true) {
                this.twofifty = false
            } else {
                this.clearcheckboxdirect()
                this.twofifty = true

            }
        } else if (value == "thousand") {
            if (this.thousand == true) {
                this.thousand = false
            } else {
                this.clearcheckboxdirect()
                this.thousand = true

            }
        } else if (value == "fivethousand") {
            if (this.fivethousand == true) {
                this.fivethousand = false
            } else {
                this.clearcheckboxdirect()
                this.fivethousand = true

            }
        } else if (value == "tenthousand") {
            if (this.tenthousand == true) {
                this.tenthousand = false
            } else {
                this.clearcheckboxdirect()
                this.tenthousand = true

            }
        } else if (value == "twentythousand") {
            if (this.twentythousand == true) {
                this.twentythousand = false
            } else {
                this.clearcheckboxdirect()
                this.twentythousand = true// eassy

            }
        } else if (value == "one1") {
            if (this.one1 == true) {
                this.one1 = false
            } else {
                this.clearcheckboxeassy()
                this.one1 = true

            }
        } else if (value == "fifty1") {
            if (this.fifty1 == true) {
                this.fifty1 = false
            } else {
                this.clearcheckboxeassy()
                this.fifty1 = true

            }
        } else if (value == "two1") {
            if (this.two1 == true) {
                this.two1 = false
            } else {
                this.clearcheckboxeassy()
                this.two1 = true

            }
        } else if (value == "three1") {
            if (this.three1 == true) {
                this.three1 = false
            } else {
                this.clearcheckboxeassy()
                this.three1 = true

            }
        } else if (value == "four1") {
            if (this.four1 == true) {
                this.four1 = false
            } else {
                this.clearcheckboxeassy()
                this.four1 = true

            }
        } else if (value == "five1") {
            if (this.five1 == true) {
                this.five1 = false
            } else {
                this.clearcheckboxeassy()
                this.five1 = true

            }
        } else if (value == "six1") {
            if (this.six1 == true) {
                this.six1 = false
            } else {
                this.clearcheckboxeassy()
                this.six1 = true

            }
        }
    }
    /**
   * Blue Collar
   * @example Based on checkbox value user can Select
   */
    checkboxfix(value, data) {
        if (value == "chat") {
            if (this.chat == true) {
                this.chat = false
            } else {
                this.chat = true
            }
        } else if (value == "feedback") {
            if (this.feedback == true) {
                this.feedback = false
            } else {
                this.feedback = true
            }
        } else if (value == "matchreport") {
            if (this.matchreport == true) {
                this.matchreport = false
            } else {
                this.matchreport = true
            }
        } else if (value == "default_welcometext") {
            if (this.default_welcometext == true) {
                this.default_welcometext = false;

            } else {
                this.default_welcometext = true;

            }
        } else if (value == "default_termsconditions") {
            if (this.default_termsconditions == true) {
                this.default_termsconditions = false
            } else {
                this.default_termsconditions = true
            }
        } else if (value == "payment_confirmaccept") {
            if (this.payment_confirmaccept == true) {
                this.payment_confirmaccept = false
            } else {
                this.payment_confirmaccept = true// pricing options
            }
        } else if (value == 'Facillity_Mgnt_room_cleaning') {
            if (this.Facillity_Mgnt_room_cleaning == true) {
                this.Facillity_Mgnt_room_cleaning = false
            } else {
                this.clearcheckbox()
                this.Facillity_Mgnt_room_cleaning = true
            }
        } else if (value == 'Food_retail') {
            if (this.Food_retail == true) {
                this.Food_retail = false
            } else {
                this.clearcheckbox()
                this.Food_retail = true
            }
        } else if (value == 'General_retail') {
            if (this.General_retail == true) {
                this.General_retail = false
            } else {
                this.clearcheckbox()
                this.General_retail = true
            }
        } else if (value == 'Hospitality_kitchen') {
            if (this.Hospitality_kitchen == true) {
                this.Hospitality_kitchen = false
            } else {
                this.clearcheckbox()
                this.Hospitality_kitchen = true
            }
        } else if (value == 'Hospitality_service') {
            if (this.Hospitality_service == true) {
                this.Hospitality_service = false
            } else {
                this.clearcheckbox()
                this.Hospitality_service = true
            }
        } else if (value == 'Industriehelfer') {
            if (this.Industriehelfer == true) {
                this.Industriehelfer = false
            } else {
                this.clearcheckbox()
                this.Industriehelfer = true
            }
        } else if (value == 'Logistics_driver') {
            if (this.Logistics_driver == true) {
                this.Logistics_driver = false
            } else {
                this.clearcheckbox()
                this.Logistics_driver = true
            }
        } else if (value == 'logistics_warehouse') {
            if (this.logistics_warehouse == true) {
                this.logistics_warehouse = false
            } else {
                this.clearcheckbox()
                this.logistics_warehouse = true
            }
        } else if (value == 'Nursing_general_nursing') {
            if (this.Nursing_general_nursing == true) {
                this.Nursing_general_nursing = false
            } else {
                this.clearcheckbox()
                this.Nursing_general_nursing = true
            }
        } else if (value == 'Nursing_geriatric_nursing') {
            if (this.Nursing_geriatric_nursing == true) {
                this.Nursing_geriatric_nursing = false
            } else {
                this.clearcheckbox()
                this.Nursing_geriatric_nursing = true
            }
        } else if (value == 'Tourism_housekeeping') {
            if (this.Tourism_housekeeping == true) {
                this.Tourism_housekeeping = false
            } else {
                this.clearcheckbox()
                this.Tourism_housekeeping = true
            }
        } else if (value == 'Kundenreferenz') {
            if (this.Kundenreferenz == true) {
                this.Kundenreferenz = false
            } else {
                this.clearcheckbox()
                this.Kundenreferenz = true
            }
        }

    }
    /**
 * @ignore
 */
    checkboxfix1(value) {
        if (value == 'Ownjob_ad') {
            if (this.Ownjob_ad == true) {
                this.uploadfile = false;
                this.Ownjob_ad = false
                this.Noownjobad = false
            } else {
                this.Ownjob_ad = true
                this.Noownjob_ad = false
                this.uploadfile = true;
                this.Noownjobad = false;
            }
        } else if (value == 'Noownjob_ad') {
            if (this.Noownjob_ad == true) {
                this.Noownjobad = false;
                this.Noownjob_ad = false
            } else {
                this.Noownjob_ad = true
                this.Noownjobad = true;
                this.Ownjob_ad = false;
                this.uploadfile = false;
            }
        }
    }
    /**
   * @ignore
   */
    clearcheckbox() {
        this.Facillity_Mgnt_room_cleaning = false
        this.Food_retail = false
        this.General_retail = false
        this.Hospitality_kitchen = false
        this.Hospitality_service = false
        this.Industriehelfer = false
        this.Logistics_driver = false
        this.logistics_warehouse = false
        this.Nursing_general_nursing = false
        this.Nursing_geriatric_nursing = false
        this.Tourism_housekeeping = false
        this.Kundenreferenz = false
        this.Kundenreferenz1 = ""
    }
    /**
 * @ignore
 */
    clearcheckboxdirect() {
        this.one = false;
        this.fifty = false;
        this.twofifty = false;
        this.thousand = false;
        this.fivethousand = false;
        this.tenthousand = false;
        this.twentythousand = false;
    }
    /**
 * @ignore
 */
    clearcheckboxeassy() {
        this.one1 = false;
        this.fifty1 = false;
        this.two1 = false;
        this.three1 = false;
        this.four1 = false;
        this.five1 = false;
        this.six1 = false;
    }
    /**
   * @ignore
   */
    clearPaymentErr() {
        this.hintptvalidemail = false;
        this.hintptfirstname = false;
        this.hintptlastname = false;
        this.hintptcompanyname = false;
        this.hintptcity = false;
        this.hintptstreetname = false;
        this.hintptcountryname = false;
        this.hintptzip = false;
        this.hintpttelephoneno = false;
        this.hintvatnumber = false;
        this.hintptemail = false;
        this.hintterms_conditions = false;
    }
    /**
 * Resend Otp
 */
    ResendOTP() {
        this.getlogindetails = {};
        this.getlogindetails.email = this
            .RegisterEmailForm
            .controls
            .RegisterEmail
            .value
            .toLowerCase(); // "sangeethat@sterlingsoftware.co.in",

        // this.spinner.show();
        this.commonloader = true;
        this
            .HTTPServiceService
            .withoutSessionMethod(this.getlogindetails, this.logonUrl.resendOTP)
            .subscribe(data => {
                // this.spinner.hide();
                this.commonloader = false;
                if (data != null) {
                    var details : any = data;
                    if (details.status == 200) {
                        this.alertmsg(details.message, 'success')
                        setTimeout(() => {
                            this.resendOTPdiv = false;
                        }, 10000);
                        setTimeout(() => {
                            this.resendOTPdiv = true;
                        }, 10000);
                    } else {
                        this.alertmsg(details.message, 'error')
                    }
                } else {}
            }, error => {});
    }
    /**
 * Resend forgot Otp Function Start here
 */
    ResendForgetOTP() {
        this.getlogindetails = {};

        this.getlogindetails.email = this
            .loginform1
            .controls
            .emailId
            .value
            .toLowerCase(); // "sangeethat@sterlingsoftware.co.in",
        // this.spinner.show();
        this.commonloader = true;
        this
            .HTTPServiceService
            .withoutSessionMethod(this.getlogindetails, this.logonUrl.resendOTP)
            .subscribe(data => {
                // this.spinner.hide();
                this.commonloader = false;
                if (data != null) {
                    var details : any = data;
                    if (details.status == 200) {
                        this.alertmsg(details.message, 'success')
                        setTimeout(() => {
                            this.resendOTPforgetdiv = false;
                        }, 1000);
                        setTimeout(() => {
                            this.resendOTPforgetdiv = true;
                        }, 1000);
                    } else {
                        this.alertmsg(details.message, 'error')
                    }
                } else {}
            }, error => {});
    }
    /**
   * @ignore
   */
    setstatus() {
        var data = {
            "ad_id": this.accesstoken.ad_id,
            "chat_status": true
        }
        this
            .http
            .commonMethod(data, this.logonUrl.chat_status_checking)
            .subscribe(res => {
                this.response = res
                if (this.response.status == 200) {
                    var close = true
                    this
                        .dialogRef
                        .close(close);
                } else {}
            })
    }

    /**
 * @ignore
 */
    // Balaji for GetEmail in dialog
    getEmailId : any;
    getEmailIdCandidate() {
        let cvId = sessionStorage.getItem('cvId');
        let getJobTitle = {
                method: "AUTH",
                id_list: "",
                cv_id: cvId
            };
            // console.log(getJobTitle);
        this
                .speechRecognitionService
                .getCvData(getJobTitle)
                .subscribe(data => {
                    console.log('jobtitle', data);
                    this.getEmailId = data.res.message
                        ? data.res.message[0].email
                        : sessionStorage.getItem('cv_email');
                }),
            error => {
                console.log(error);
            };

            //  this.headerChatDiv = false;
        }
    }
