import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
/**
 * Page for Candidate Success , this is Last Page for candidate
 */
@Component({
  selector: 'app-success',
  templateUrl: './Candidate_Success.component.html',
  styleUrls: ['./Candidate_Success.component.css']
})
export class SuccessComponent implements OnInit {
  /**
   * @ignore
   */
  constructor(public translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute) {
    var lang = localStorage.getItem('language')

    translate.addLangs(['en', 'de']);

    if (lang) {

      translate.setDefaultLang(lang);

      translate.use(lang);

    } else {

      translate.setDefaultLang('de');

      translate.use('de');

    }
  }
  /**
* @ignore
*/
  openTermsConditions: any
  /**
 * @ignore
 */
  param1: any;
  /**
 * @ignore
 */
  param2: any;
  /**
 * @ignore
 */
  ngOnInit() {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   console.log(params);
    //   this.param1 = params['dt'];
    //   this.param2 = params['type'];
    //   console.log(this.param1);
    //   console.log(this.param2);
    //   if(this.param1 == undefined || this.param2 == undefined){
    //     this.router.navigate(['']);
    //   }
    // });
  }

}
