import { Component, OnInit } from '@angular/core';
import { MatDialog, } from '@angular/material';
import { DialogComponent } from '../Dialog/Dialog.component';

/**
 * Page for Candidate Heat Map
 */
@Component({
  selector: 'app-hitmap',
  templateUrl: './Candidate_Heatmap.component.html',
  styleUrls: ['./Candidate_Heatmap.component.css']
})
export class HitmapComponent implements OnInit {

  // Timer 
  timercountdown = '';
  timerflag = null;
  loadingTimer = 0;

  constructor(private dialog: MatDialog,
    // @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.countDown();
  }

  countDown() {
    let intervalId = 0;
    let seconds = 59;
    let minutes = 0;

    intervalId = window.setInterval(() => {
      seconds -= 1;
      this.loadingTimer = 100;
      if (seconds === 0) {
        this.timercountdown = "";
      } else {
        if (seconds < 0) {
          seconds = 0;
        }
        this.timercountdown = `${minutes} : ${seconds} `;
        this.loadingTimer = seconds - 1;
        if (this.loadingTimer == 30) {
          let type = localStorage.getItem("applytype");
          if (type != 'directapply') {
            const dialogRef = this.dialog.open(DialogComponent, {
              // width: '300px',
              // height: '250px',
              data: { dialogname: 'EasyApplyemailjobmatch', forEasyApplyJD: "yes" },
              
            });
            dialogRef.afterClosed().subscribe(() => {
            });
          }
        }
      }
      if (minutes < 0) {
        this.timercountdown = "";
      }
    }, 1000);
  }
  
}
