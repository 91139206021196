import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormControl, FormsModule, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpserviceService } from '../Services/httpservice.service';
import { ToastrService } from 'ngx-toastr';
import { AlertComponent } from '../Common_Alert/Common_Alert.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

/**
 * New Design Landing Page 
 */
@Component({
  selector: 'app-new-lp',
  templateUrl: './New_Landing.component.html',
  styleUrls: ['./New_Landing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NewLPComponent implements OnInit {
  hintcompanies: boolean = false;
  hintmail: boolean = false;
  logincommonerror: boolean = false;
  logincommonerrortext: string = "";
  hintvaildemailId: boolean = false;
  hintemailId: boolean = false;
  hintpttelephoneno: boolean = false;
/**
 * @ignore
 */
  constructor(
    public router: Router,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    public http: HttpserviceService,
    private toastr: ToastrService,
    public snackBar: MatSnackBar,

  ) {
    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
    }

  }
 /**
 * @ignore
 */
  ngOnInit() {
   
  }

  
/**
 * Common Alert Message for All Pages
 * @param Message and Errtype
 * @example session Invalid, Success or Failure
 */
  alertmsg(msg, errtype) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: { data: msg, classname: errtype },
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'blue-snackbar'
    });
  }
  
 
/**
 * @ignore
 */
  ContactFormDetails1: any

}
