import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Common_Header/Common_Header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './Candidate_Home/Candidate_Home.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatFormFieldModule } from '@angular/material';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MyAccountComponent } from './Candidate_Chat/Candidate_Chat.component';
import { DialogComponent } from './Dialog/Dialog.component';
import { FeedBackScreenComponent } from './Candidate_FeedBack/Candidate_FeedBack.component';
import { MatchReportComponent } from './Candidate_Chart-Report/Candidate_Chart-Report.component';
import { ChartsModule } from 'ng2-charts';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { TooltipModule } from 'ng2-tooltip-directive';
import { CVAdminComponent } from './Candidate_Admin/Candidate_Admin.component';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { Ng5SliderModule } from 'ng5-slider';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTreeModule,
  MatTooltipModule,
} from '@angular/material';
import { SuccessComponent } from './Candidate_Success/Candidate_Success.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FeedbackComponent } from './DirectApply_Feedback/DirectApply_Feedback.component';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { DaAdminComponent } from './DirectApply_Admin/DirectApply_Admin.component';
import { EasyApplyComponent } from './Candidate_Easy-Apply_JD/Candidate_Easy-Apply_JD.component';
import { HitmapComponent } from './Candidate_HeatMap/Candidate_Heatmap.component';
import { NvD3Module } from 'ng2-nvd3';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertComponent } from './Common_Alert/Common_Alert.component';
import { Spyscroll } from './spyscroll';
// import { MyAccountRecruiterComponent } from './my-account-recruiter/my-account-recruiter.component';
import { PricingMVPComponent } from './Pricing-mvp/Pricing-mvp.component';
import { LandingPageComponent } from './Old_Landing_Page/Old_Landing_Page.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CockpitComponent } from './Cockpit/Cockpit.component';
import { ErecruiterLandingComponent } from './E_Recruiter-landing/E_Recruiter-landing.component';
import { EASYRECRUITERFEEDBACKComponent } from './ER_Feedback/ER_Feedback.component';
import { EasyRecruiterAdminComponent } from './ER_Admin/ER_Admin.component';
import { NewLPComponent } from './New_Landing/New_Landing.component';
// import { CVLandingComponent } from './cv-landing/cv-landing.component';
// import { NewHeaderComponent } from './new-header/new-header.component';
import { ADLandingComponent } from './DirectApply_Landing/DirectApply_Landing.component';
import { FooterComponent } from './Common_Footer/Common_Footer.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent, Spyscroll, HomeComponent, MyAccountComponent, DialogComponent,
    MatchReportComponent, SuccessComponent, FeedBackScreenComponent, HitmapComponent,
    FeedbackComponent, EASYRECRUITERFEEDBACKComponent, DaAdminComponent, CVAdminComponent, EasyApplyComponent, AlertComponent, LandingPageComponent, PricingMVPComponent, CockpitComponent, ErecruiterLandingComponent, EasyRecruiterAdminComponent, NewLPComponent, ADLandingComponent, FooterComponent
  ],
  imports: [
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    }),
    DeviceDetectorModule.forRoot(),
    NvD3Module,
    TooltipModule,
    MatTooltipModule,
    NgxSpinnerModule,
    BrowserModule,
    Ng5SliderModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule, BrowserAnimationsModule, PerfectScrollbarModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule, MatSelectModule, ScrollingModule, MatToolbarModule, FormsModule, ReactiveFormsModule, MatDialogModule,
    MatButtonModule, MatInputModule, MatIconModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ScrollingModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatRippleModule,
    FlexLayoutModule, MatMenuModule, ChartsModule,
    HttpClientModule,
    ScrollToModule.forRoot(),
    AngularFullpageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgCircleProgressModule.forRoot({
      outerStrokeColor: '#01b79e',
      innerStrokeColor: '#ebf2f8',
      outerStrokeWidth: 5,
      innerStrokeWidth: 100,
      animationDuration: 300,
      space: 0,
      responsive: true,
      imageHeight: 90,
      imageWidth: 60,
      showImage: true
    }),
    AlifeFileToBase64Module,
  ],
  providers: [],
  entryComponents: [DialogComponent, AlertComponent],
  bootstrap: [AppComponent]
})

export class AppModule { }


