// Home Component Page for Candidate First which sets all Data in localStorage
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { DialogComponent } from "../Dialog/Dialog.component";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SpeechRecognitionService } from "../CV_Service.service";
import { EncryptionService } from "../Services/encryption.service";
import { HttpserviceService } from "../Services/httpservice.service";
import { urlEnv } from "../../environments/env";

/**
 * Home Page for Candidate  , User can View Company Details as well as T & C also
 */
@Component({
  selector: "app-home",
  templateUrl: "./Candidate_Home.component.html",
  styleUrls: ["./Candidate_Home.component.css"],
})
export class HomeComponent implements OnInit {
  /**  variable desc
   * variable Declaration Starts Here
   */
  public logonUrl = urlEnv;
  param1: any;
  param2: any;
  companyData: any;
  companyDataLogo: any;
  Logo: object;
  companyName: any;
  welcomeText: any;
  openTermsConditionsCV: any;
  response: any;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public http: HttpserviceService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private speechRecognitionService: SpeechRecognitionService,
    public encrypt: EncryptionService
  ) {
    var lang = localStorage.getItem("language");

    translate.addLangs(["en", "de"]);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    } else {
      translate.setDefaultLang("de");
      translate.use("de");
    }
  }
  directApply: boolean;
  accesstoken: any;
  jobTitle: any;
  getUrl: any;
  getCandidateUrlDetails: any;
  BoldWelcomeText1: any;
  linkExpireData: any;
  linkNotExpired: boolean;
  getUrlResponse: any;
  easyApply: boolean;
  openTermsConditionsText: any;
  openTermsConditionsLink: any;
  companies: any;
  contactperson: any;
  email: any;
  // variable Declaration Ends Here
  /**
   * Terms and Condition Popup Start Here
   */
  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: "termsandconditionspopup", chatUrl: this.getUrl },
    });
    dialogRef.afterClosed().subscribe((result: any) => {});
  }
  //Terms and Condition Popup End Here


  /**  function Desc
   * Init Function Start Here
   */
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.param1 = params["dt"];
      this.param2 = params["type"];

      if (this.param1 && this.param2) {
        this.OldUrlCall();
      } else {
        this.newUrlCall();
      }
      this.companyDetails();
    });
  }

  //Init Function Ends Here

  /**
   * Calling Old Url Function Starts
   */

  OldUrlCall() {
    localStorage.setItem("OldUrl", "true");
    localStorage.removeItem("NewUrl");
    this.getUrl = window.location.href;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.param1 = params["dt"];
      this.param2 = params["type"];
      var checkExpireId = this.encrypt.encryption("Decrypt", this.param1);
      var checkParse = JSON.parse(checkExpireId);
      console.log("Id :", checkParse.dt);
      var checkUrl = {
        ad_id: checkParse.dt,
      };
      this.http
        .commonMethod(checkUrl, this.logonUrl.link_expired_status)
        .subscribe((data) => {
          this.linkExpireData = data;
          console.log("LinkSourceData", this.linkExpireData);
          if (this.linkExpireData.status == 200) {
            var getUrlData = {
              link: this.getUrl,
              // "link":"https://qat-sterlingbetterheads.tk/home/qweqwe/12321/SAP_HANA__w_m_"
            };
            this.http
              .commonMethod(getUrlData, this.logonUrl.linkbased_adid_cvid)
              .subscribe((data) => {
                console.log("Get Adid and CVid flag Data:", data);
              });
            this.linkNotExpired = true;

            console.log("Url Params :", this.param1, this.param2);

            if (
              this.param1 == undefined ||
              this.param2 == undefined ||
              this.param1 == null
            ) {
              this.router.navigate([""]);
            }
            sessionStorage.setItem("urlDt", this.param1);
            if (this.param2) {
              localStorage.setItem("applytype", this.param2);
            }
            this.getUrl = window.location.href;
            let type = localStorage.getItem("applytype");
            if (type == "directapply") {
              this.directApply = true;
            } else {
              this.easyApply = true;
            }

            //GetJob Title
            localStorage.setItem("type", this.param2);
            sessionStorage.setItem("type", this.param2);
            // let adid = localStorage.getItem("UrladId");
            var EncryptedAdid = this.encrypt.encryption("Decrypt", this.param1);
            console.log("URLParasId", EncryptedAdid);
            var ParseUrl = JSON.parse(EncryptedAdid);
            console.log(ParseUrl.dt);
            sessionStorage.setItem("UrlDecryptedAdId", ParseUrl.dt);
            var getUrlDetails;

            getUrlDetails = {
              ad_id: ParseUrl.dt,
            };

            this.http
              .commonMethod(getUrlDetails, this.logonUrl.get_id_details)
              .subscribe((data) => {
                this.getCandidateUrlDetails = data;
                console.log(
                  "GET ID Details data:",
                  this.getCandidateUrlDetails
                );
                if (this.getCandidateUrlDetails.status == 200) {
                  console.log(this.getCandidateUrlDetails.res.feedback);
                  console.log(this.getCandidateUrlDetails.res.match_report);
                  console.log(this.getCandidateUrlDetails.res.recruiter_id);
                  console.log(this.getCandidateUrlDetails.res.client_ad_id);
                  console.log(this.getCandidateUrlDetails.res.email);
                  console.log(this.getCandidateUrlDetails.res.blue_collor);
                  sessionStorage.setItem(
                    "UrlDecryptedFeedBack",
                    this.getCandidateUrlDetails.res.feedback
                  );
                  sessionStorage.setItem(
                    "UrlDecryptedMatchReport",
                    this.getCandidateUrlDetails.res.match_report
                  );
                  sessionStorage.setItem(
                    "UrlDecryptedRecruiterId",
                    this.getCandidateUrlDetails.res.recruiter_id
                  );
                  sessionStorage.setItem(
                    "UrlDecryptClientAdId",
                    this.getCandidateUrlDetails.res.client_ad_id
                  );
                  sessionStorage.setItem(
                    "UrlDecryptEmail",
                    this.getCandidateUrlDetails.res.email
                  );
                  sessionStorage.setItem(
                    "UrlBluecollar",
                    this.getCandidateUrlDetails.res.blue_collor
                  );
                  sessionStorage.setItem(
                    "UrlChatCaseId",
                    this.getCandidateUrlDetails.res.chat_case
                  );
                  sessionStorage.setItem(
                    "UrlInternalRefrenceId",
                    this.getCandidateUrlDetails.res.internal_reference
                  );
                  this.companyDetails();
                }
              });

            this.accesstoken = JSON.parse(localStorage.getItem("token"));
            // var Encrypted = this.encrypt.encryption("Decrypt", this.param1);
            if (this.directApply) {
              var getJobTitle = {
                method: "AUTH",
                id_list: "",
                ad_id: ParseUrl.dt,
              };
              console.log(getJobTitle);
              this.http
                .commonMethod(getJobTitle, this.logonUrl.get_ad_data)
                .subscribe((data) => {
                  // console.log("jobtitle", data);
                  // if(data.status == 200) {
                  //   this.jobTitle = data.res ? data.res.message[0].title : '';
                  // }
                }),
                (error) => {
                  console.log(error);
                };
            }
          } else {
            const dialogRef = this.dialog.open(DialogComponent, {
              disableClose: true,
              data: { dialogname: "linkExpiredPopup" },
            });
            dialogRef.afterClosed().subscribe((result: any) => {});
          }
        });
    });
  }
  //Calling Old Url Function Ends Here

  data: any;

  socialMediaUrl = false;
  /**
   * Calling New Url Function Starts
   */
  newUrlCall() {
    localStorage.setItem("NewUrl", "true");
    localStorage.removeItem("OldUrl");

    this.getUrl = window.location.href;
    var getUrl = this.getUrl.split("?")[1];

    if (getUrl != null && getUrl != "") {
      var urlforAll = this.getUrl.split("?")[0];
      var actualUrl;
      if (urlforAll.includes("home/")) actualUrl = urlforAll.split("home/")[1];
      else actualUrl = urlforAll.split("auto/")[1];

      sessionStorage.setItem("urlDt", actualUrl);
      console.log("Coming From Social Media Url", actualUrl);
      this.socialMediaUrl = true;
    } else {
      var urlforAll1;
      if (this.getUrl.includes("home/")) urlforAll1 = this.getUrl.split("home/")[1];
      else urlforAll1 = this.getUrl.split("auto/")[1];
      sessionStorage.setItem("urlDt", urlforAll1);
      console.log("Coming From Normal URl", urlforAll1);
    }

    var getUrlData = {
      link: this.getUrl,
    };
    if (this.socialMediaUrl) {
      getUrlData.link = urlforAll;
    } else {
      getUrlData.link = this.getUrl;
    }

    this.http
      .commonMethod(getUrlData, this.logonUrl.linkbased_adid_cvid)
      .subscribe(data => {
        this.getUrlResponse = data;
        if (this.getUrlResponse.status == 200) {
          var getUrlAdid = this.getUrlResponse.res.ad_id;
          var getUrlCvid = this.getUrlResponse.res.cv_id;
          var getUrlType = this.getUrlResponse.res.document_type;

          this.Aufgaben = this.getUrlResponse.res.aufgaben;
          this.Anforderungen = this.getUrlResponse.res.anforderungen;
          this.Firmenhintergrund = this.getUrlResponse.res.firmenhintergrund;
          this.Sonstiges = this.getUrlResponse.res.sonstiges;

          sessionStorage.setItem("LocalChat",this.getUrlResponse.res.local_chat);

          if (getUrlCvid == null || getUrlCvid == "") {
            this.param1 = getUrlAdid;
          } else {
            this.param1 = getUrlCvid;
          }
          this.param2 = getUrlType;
          var checkUrl = {
            ad_id: this.param1,
          };
          this.http
            .commonMethod(checkUrl, this.logonUrl.link_expired_status)
            .subscribe(data => {
              this.linkExpireData = data;
              // console.log("LinkSourceData",this.linkExpireData);
              if (this.linkExpireData.status == 200) {
                this.linkNotExpired = true;
                if (
                  this.param1 == undefined ||
                  this.param2 == undefined ||
                  this.param1 == null
                ) {
                  this.router.navigate([""]);
                }

                if (this.param2) {
                  localStorage.setItem("applytype", this.param2);
                }
                this.getUrl = window.location.href;
                let type = localStorage.getItem("applytype");
                if (type == "directapply") {
                  this.directApply = true;
                } else {
                  this.easyApply = true;
                }

                //GetJob Title
                localStorage.setItem("type", this.param2);
                sessionStorage.setItem("type", this.param2);
                sessionStorage.setItem("UrlDecryptedAdId", this.param1);

                var getUrlDetails;
                getUrlDetails = {
                  ad_id: this.param1,
                };

                console.log(" *** getIDDetailsRQ: ",getUrlDetails)
                this.http
                  .commonMethod(getUrlDetails, this.logonUrl.get_id_details)
                  .subscribe(data => {

                    console.log("GET URL DETAILS RESPONSE", data)

                    this.getCandidateUrlDetails = data;
                    if (this.getCandidateUrlDetails.status == 200) {
                      sessionStorage.setItem(
                        "UrlDecryptedFeedBack",
                        this.getCandidateUrlDetails.res.feedback
                      );
                      sessionStorage.setItem(
                        "UrlDecryptedMatchReport",
                        this.getCandidateUrlDetails.res.match_report
                      );
                      sessionStorage.setItem(
                        "UrlDecryptedRecruiterId",
                        this.getCandidateUrlDetails.res.recruiter_id
                      );
                      sessionStorage.setItem(
                        "UrlDecryptClientAdId",
                        this.getCandidateUrlDetails.res.client_ad_id
                      );
                      sessionStorage.setItem(
                        "UrlDecryptEmail",
                        this.getCandidateUrlDetails.res.email
                      );
                      sessionStorage.setItem(
                        "UrlBluecollar",
                        this.getCandidateUrlDetails.res.blue_collor
                      );
                      sessionStorage.setItem(
                        "UrlChatCaseId",
                        this.getCandidateUrlDetails.res.chat_case
                      );
                      sessionStorage.setItem(
                        "UrlInternalRefrenceId",
                        this.getCandidateUrlDetails.res.internal_reference
                      );

                      this.companyDetails();
                    }
                  });

                this.accesstoken = JSON.parse(localStorage.getItem("token"));
                // var Encrypted = this.encrypt.encryption("Decrypt", this.param1);
                if (this.directApply) {
                  var getJobTitle = {
                    method: "AUTH",
                    idlist: "",
                    ad_id:  sessionStorage.getItem("UrlDecryptedAdId"),
                    local_chat:sessionStorage.getItem("LocalChat")
                  };
                    

                  console.log("*** JobTitlePayload:", getJobTitle);
                  this.http
                    .commonMethod(getJobTitle, this.logonUrl.get_ad_data)
                    .subscribe((data) => {
                      console.log("*** JobTitleResponse:", data);
                      var response: any = data;
                      if (response.status == 200) {
                        this.jobTitle = response.res
                          ? response.res.message[0].title
                          : "";
                      }
                    }),
                    (error) => {
                      console.log(error);
                    };
                }
              } else {
                const dialogRef = this.dialog.open(DialogComponent, {
                  disableClose: true,
                  data: { dialogname: "linkExpiredPopup" },
                });
                dialogRef.afterClosed().subscribe((result: any) => {});
              }
            });
        } else {
          console.log("Url Link Error");
        }
      });
  }
  //Calling New Url Function Ends Here

  /**
   * Click Operation
   */

  openTermsConditions() {
    this.openDialog();
  }

  Aufgaben: any;
  Anforderungen: any;
  Firmenhintergrund: any;
  Sonstiges: any;
  /**  function desc
   * Company Logo Function Starts
   */
  companyDetails() {
    let urlAdid = sessionStorage.getItem("UrlDecryptedAdId");
    let UrlDecryptedRecruiterId = sessionStorage.getItem(
      "UrlDecryptedRecruiterId"
    );
    var companyData;
    if (this.directApply) {
      companyData = {
        ad_id: urlAdid,
        recruiter_id: UrlDecryptedRecruiterId,
      };
    } else {
      companyData = {
        ad_id: urlAdid,
        recruiter_id: UrlDecryptedRecruiterId,
      };
    }
    this.http
      .commonMethod(companyData, this.logonUrl.get_company_logo)
      .subscribe((data) => {
        this.companyData = data;
        if (this.companyData.status == 200) {
          this.companyDataLogo = this.companyData;
          this.Logo = this.companyDataLogo.res.logo;
          this.companyName = this.companyDataLogo.res.company_name;
          this.openTermsConditionsText = this.companyDataLogo.res.terms_conditions;
          this.openTermsConditionsCV = this.companyDataLogo.res.terms_and_condition_file;
          this.openTermsConditionsLink = this.companyDataLogo.res.terms_and_condition_link;
          this.welcomeText = this.companyDataLogo.res.welcome_text;
          this.companies = this.companyDataLogo.res.companies;
          this.contactperson = this.companyDataLogo.res.contact_person;
          this.email = sessionStorage.getItem("UrlDecryptEmail");
          var welcomeText = this.welcomeText.split("!")[1];
          var BoldWelcomeText = this.welcomeText.split("!")[0];
          this.welcomeText = welcomeText;
          this.BoldWelcomeText1 = BoldWelcomeText;
          // console.log(this.welcomeText);
          // console.log(this.BoldWelcomeText1);
        } else {
          console.log("AD Id Not Exists");
        }
      });
  }
  //Company Logo Function Ends Here

  /**
   *  Terms and Condition Function Starts
   */

  TermsConditions() {
    if (
      this.openTermsConditionsCV != null &&
      this.openTermsConditionsCV != ""
    ) {
      const linkSource = this.openTermsConditionsCV;
      const downloadLink = document.createElement("a");
      const fileName = "TermsandCondition.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      // downloadLink.setAttribute("href", window.URL.createObjectURL(blob));

      var objAgent = navigator.userAgent;
      var objbrowserName = navigator.appName;
      // var objfullVersion = ''+parseFloat(navigator.appVersion);
      //  var objBrMajorVersion = parseInt(navigator.appVersion,10);
      var objOffsetVersion;
      if ((objOffsetVersion = objAgent.indexOf("Firefox")) != -1) {
        objbrowserName = "Firefox";
        // alert(objbrowserName);
        downloadLink.setAttribute("download", fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }

      //         document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    } else {
      if (
        this.openTermsConditionsLink != null &&
        this.openTermsConditionsLink != ""
      ) {
        window.open(this.openTermsConditionsLink, "_blank");
      } else {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: true,
          data: {
            dialogname: "CompanyTermsAndCondions",
            openTermsConditionsForCV: this.openTermsConditionsText,
            emailAd: this.companies,
            contactPerson: this.contactperson,
            companyName: this.companyName,
          },
        });
        dialogRef.afterClosed().subscribe((result: any) => {});
      }
    }
  }
  //Terms and Condition  Function Starts
}

export class DialogPagesComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: HomeComponent) {}
}
