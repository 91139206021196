// Princing Component for Calculation
import {
  Component, ViewChild, OnInit, ChangeDetectorRef, Output, EventEmitter,
  HostListener, Renderer2, ElementRef
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../Dialog/Dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { HttpserviceService } from '../Services/httpservice.service';
import { urlEnv } from '../../environments/env';
/**
 * Page for Pricing Candidate 
 */
@Component({
  selector: 'app-pricing-mvp',
  templateUrl: './Pricing-mvp.component.html',
  styleUrls: ['./Pricing-mvp.component.css']
})
export class PricingMVPComponent implements OnInit {
  //Ajax varibale
  public logonUrl = urlEnv;
  show: boolean = true;
  process_optimization: boolean = false;
  personalization_chat: boolean = false;
  directapply_prizing: boolean = false;
  eassyapply_prizing: boolean = false;
  eassyrecruiter_prizing: boolean = false;
  menuFlag: any
  close1: any
  commonloader: any
  langen: any
  showDetails = true;
  hideDetails = false;
  betterheadswhite = true
  label = "srini";
  sessionvar: any;
  directapply_plan: any = 39;
  eassyRecruiter_plan: any = 99;
  menuPosition: number;
  sticky: boolean;
  // process_optimization:boolean=false;
  /**
   * @ignore
   */
  constructor(
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    public service: HttpserviceService,
    // private eventEmitterService: EventEmitterService  
  ) { }
  @HostListener('window:scroll', ['$event'])
  /**
 * Based on the Window Size it May vary by Sachin Balaji
 */
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.menuPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }
  // @ViewChild('sidenav') sidenav: MatSidenav;
  sessionid: any;
  recruiterid: any;
  isLogged: any;
  userEmail: any;
  loginuser: any;
  getLinkbtn: boolean;
  TLI_chat: boolean;

  directApply: any;
  eassyApply: any;
  eassyRecruiter: any;

  // public onToggleSidenav = () => {this.sidenavToggle.emit();} 
  @Output() public sidenavToggle = new EventEmitter();
  /**
   * Init Function Starts here By Sachin Balaji
   */
  ngOnInit() {
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    this.recruiterid = localStorage.getItem('recruiter_id');
    this.betterheadswhite = true
    // this.firstComponentFunction();
    this.submit1()



  }

  @Output() onAdd = new EventEmitter();
  /**
 * @ignore
 */
  submit1() {
    if (!this.label) return;
    this.onAdd.emit(this.label)
    this.label = ''
  }
  /**
 * @ignore
 */
  showdetails() {
    this.hideDetails = true;
    this.showDetails = false;
    this.process_optimization = true;
    this.personalization_chat = true;
  }
  /**
 * @ignore
 */
  hidedetails() {
    this.showDetails = true;
    this.hideDetails = false;

    this.process_optimization = false;
    this.personalization_chat = false;
  }
  recruiterPrizing: any;
  /**
 * @ignore
 */
  checkboxfix(value, event) {
    console.log(value);
    if (value == "directapply_prizing") {
      if (this.directapply_prizing) {
        this.directapply_prizing = false
        this.directApply = false;
      } else {
        this.directapply_prizing = true
      }
    } else if (value == "eassyrecruiter_prizing") {
      if (this.eassyrecruiter_prizing) {
        this.eassyrecruiter_prizing = false
        this.recruiterPrizing = 0;
        this.eassyRecruiter = false;
      } else {
        this.eassyrecruiter_prizing = true
        this.recruiterPrizing = 29 - (29 * 10) / 100;
        console.log(this.recruiterPrizing);
      }
    }
    else if (value == "eassyapply_prizing") {
      if (this.eassyapply_prizing) {
        this.eassyapply_prizing = false
        this.eassyApply = 0
        this.eassyApply = false;
      } else {
        this.eassyapply_prizing = true
        this.eassyApply = 349 - (349 * 10) / 100;
        console.log(this.eassyApply);
      }
    }
  }
  eassyRecruiterFinalValue: any;
  /**
 * @ignore
 */
  clickplans(value) {
    if (value == 'easyrecruiter') {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'easyapply_prizing' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(result);
        if (result) {
          console.log(this.eassyRecruiter, "value")
          if (this.eassyrecruiter_prizing) {
            this.eassyRecruiter = result.value + this.recruiterPrizing;
            this.eassyRecruiter_plan = result.plandetails;
          } else {
            this.eassyRecruiter = false
          }

          // this.eassyRecruiterFinalValue = this.eassyRecruiter + this.recruiterPrizing;
        }
        if (result.goto == 'beforePricingPage') {
          // this.getLinkdialog();
        }
      });

    } else if (value == 'directapply') {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'directapply_prizing' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          console.log(this.directApply, 'value');
          if (this.directapply_prizing) {
            this.directApply = result.value
            this.directapply_plan = result.plandetails
          } else {
            this.directApply = false;
          }
        }
        if (result.goto == 'beforePricingPage') {
          // this.getLinkdialog();
        }
      });
    }
  }
  /**
   * Final Submit after Checked all Checkboxes in pricing Page
   * @example it will Calculate All Price and Show to he User
   */
  submit() {
    console.log('')
    if (this.directapply_prizing || this.eassyrecruiter_prizing || this.eassyapply_prizing) {
      if (this.directapply_prizing) {
       
        this.getLinkdialog();
      } else if (this.eassyrecruiter_prizing) {
        
        this.getLinkdialog();
      } else
        if (this.eassyapply_prizing) {
          this.getLinkdialog();
        }
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'checkboxpopup' },
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }
  }
  /**
   * @ignore
   */
  getlocalvalues() {
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    if (this.isLogged == "true") {
      this.menuFlag = false;
    } else {
      this.menuFlag = true;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail.split('@')[0];
    }
  }
  /**
 * Common Payment for Pricing
 */
  gotopayment(gotovalue) {
    this.userEmail = localStorage.getItem('userEmail');
    if (gotovalue == "onlypayment") {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'Paymentdetailspopup', email: this.userEmail, goto1: "onlypayment" }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    } else if (gotovalue == "reg") {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'Paymentdetailspopup', email: this.userEmail, goto1: "onlypayment" }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }
    // this.feedbackReportData();

  }
  
  /**
 * @ignore
 */
  getLinkdialog() {
    this.isLogged = localStorage.getItem('isLogged');
    if (this.isLogged != null && this.isLogged != undefined) {
      if (this.isLogged != "true") {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: true,
          data: { dialogname: 'loginpopup', goto: 'onlypayment', goto1: "reg" }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          // this.getlocalvalues();
          if (result == "payment") {
            this.gotopayment(result);
          } else if (result == "reg") {
            this.gotopayment(result);
          } else if (result == "onlypayment") {
            this.gotopayment(result);
          }
        });
      } else {
        if (!this.userEmail) {
          this.userEmail = localStorage.getItem('userEmail');
        }
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: true,
          data: { dialogname: 'confirmpaymentpopup', email: this.userEmail, goto1: "onlypayment" }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          this.getlocalvalues();
          this.getLinkbtn = true;
          this.TLI_chat = true;
          console.log(this.getLinkbtn, "1")

        });
      }
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'loginpopup' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.getlocalvalues();
      });
    }
  }
}
