//Candiate Feedback Page 
// Mapping Skill for the Canidate
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, NgForm, Validators, FormGroup } from '@angular/forms';
import { ErrorStateMatcher, } from '@angular/material/core';
import { DialogComponent } from '../Dialog/Dialog.component';
import { MatDialog } from '@angular/material';
import { SpeechRecognitionService } from '../CV_Service.service';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute, Params } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { HttpserviceService } from '../Services/httpservice.service';
import { EncryptionService } from '../Services/encryption.service';
import { map, startWith } from 'rxjs/operators';

/**
 * Page for Candidate Feedback , User can View the Feedback of his/her Profile Details in Feedback
 */

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-feed-back-screen',
    templateUrl: './Candidate_FeedBack.component.html',
    styleUrls: ['./Candidate_FeedBack.component.css']
})
export class FeedBackScreenComponent implements OnInit {
  /** variable desc
   * Varibale Declaration for Full Page
   */
   
    myModel: boolean;
    myModel1: boolean;
    currentSection = 'section1';
   /**
    * @ignore
    */
    public compentensesData: any;
    industryData: any;
    jobTitle: any;
    location: any;
    obj: any;
    profession: any;
    softSkills: any[];
    educationLevel: any;
    degree: any;
    content: any;
    EasyApply :boolean;
    directApply : boolean;
    slicedHardSkill: any;
    slicedSoftSkill: any;
    content_cv: any;
    HardskillLength: boolean;
    hardSkillLengthZero: boolean;
    softskillLength: boolean;
    softSkillLengthZero: boolean;
    finalHardSkils: any;
    finalSoftSkils: any;
    dataTest: any[];
    compentenceData: any[];
    hardSkils = [];
    softSkils = [];
    competencyLengthZero: boolean;
    competencyLength: boolean;
    industryLengthZero: boolean;
    industryLength: boolean;
    finalBullet1: any;
    slicedcompetency: any[];
    slicedindustry: any;
    HideExpandCompatency: boolean = true;
    HideHalfCompatency: boolean = false;
    HideHalfIndustry: boolean = false;
    HideExpandIndustry: boolean = true;
    HideIndusrtyTips: boolean;
    fulltime = null;
    city = '';
    countrycode = '';
    permanent = '';
    profile_name: any;
    profile_ad_values: any = [{ id: 1, value: 'Schulbildung' }, { id: 2, value: 'Lehrberuf' }, { id: 3, value: 'Studium' }, { id: 4, value: 'weiterführendes Studium' }];
    profile_ad: any;
    profile_ad_degree: any;
    profile_ad_training: any;
    professionLengthZero: boolean;
    professionLength: boolean;
    slicedskillLIst: any;
    removeHardskils1 = [];
    removeHardskillFlag = false;
    hideAccordionBtn: boolean = true;
    hideAccordionHideBtn: boolean;
    slicedIndustry: any;
    hideAccordionBtnindustry: boolean;
    hideAccordionHideBtnindustry: boolean;
    slicedHardSkil: any[];
    slicedSoftSkil: any[];
    HideHalfHardSkill: boolean = false;
     HideExpandHardSkill: boolean = true;
     HideHalfSoftSkills: boolean = false;
    fullcomp: boolean = false;
    fullhard: boolean = false;
    fullsoft: boolean = false;
    fullindustries: boolean = false;
    
  
  HideExpandSoftSkills: boolean = true;
    experienceFlag = false;
    countryFlagvalues: { "flag": string, "deviceid": "", "osid": "" };
    cityFlagvalues: { "countryid": "" }
    zipFlagvalues: {"city": any };
    profilename12: boolean = false;
    countryListValues: any;
    countriesValue: any;
    cityvalue: any;
    zipvalue: any;
    cityListValue: any;
    countryName: any;
    sortcountryName: any;
    cityName: any;
    zipListValue: any;
    countryCtrl = new FormControl();
    stateCtrl = new FormControl();
    zipCtrl = new FormControl();
    country: any;
    selectedZipValue: any;
    zipCodebasedCountry: any;
    cityId: any;
    zipcodedata1: any;
    ContactForm: FormGroup;
    accesstoken: any;
    profileReport: any;
    skillid: any;
    contentProfile: any;
    skillset: any;
    docx_document_unique :any;
    industriesData: any;
    slicedSoftSkills: any;
    finalIndustries: any;
    indutries: any;
    industrySeparatedValue: any;
    slicedIndustries: any;
    request_updatefeedback: any;
    cvidforSetreport: any;
    cv_idValue: any;
    accessTokenforSetreport: any;
    originalcompetency: any;
    originalHardSkils: any;
    originalSoftSkils: any;
    originalIndustries: any;
    response: any;
    originalIndustryList: any;
    contactFlag: boolean;
  country1: any;
  zipcode: any;
  zipcodedata: any;
  matcher = new MyErrorStateMatcher();
  getAccessToken: any;
  buuletData :any;
  dataTest_indus :any;
    softSkills_indus :any;
    getMobileNumber = new FormControl('',[Validators.required,Validators.minLength(10)]);
emailFormControl = new FormControl('', [
Validators.required,
Validators.email,
]); 
   /**
    * @ignore
    */
  private _filterCountry(value: string) {
    const filterValue = value.toLowerCase();
    return this.countriesValue.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
  }
   /**
    * @ignore
    */
  private _filterStates(value: string) {
    const filterValue = value.toLowerCase();
    return this.cityvalue.filter(state => state.cityname.toLowerCase().indexOf(filterValue) === 0);
  }
  /**
    * @ignore
    */
  private _filterZip(value: string) {
    return this.zipvalue;
  }

  //Decalration End Here

    constructor(private speechRecognitionService: SpeechRecognitionService,
      private activatedRoute: ActivatedRoute,
        public dialog: MatDialog, private toastr: ToastrService,
        public http: HttpserviceService,
        private formBuilder: FormBuilder,
        public translate: TranslateService,
        public encrypt: EncryptionService,
        private router: Router) {
          var lang = localStorage.getItem('language')
          translate.addLangs(['en', 'de']);
          if (lang) {
            translate.setDefaultLang(lang);
            translate.use(lang);
          } else {
            translate.setDefaultLang('de');
            translate.use('de');
          }
      
      
          this.countryListValues = this.countryCtrl.valueChanges
            .pipe(
              startWith(''),
              map(country => country ? this._filterCountry(country) : this.countriesValue)
            );
      
      
          this.cityListValue = this.stateCtrl.valueChanges
            .pipe(
              startWith(''),
              map(state => state ? this._filterStates(state) : this.cityvalue)
            );
      
          this.zipListValue = this.zipCtrl.valueChanges
            .pipe(
              startWith(''),
              map(zip => zip ? this._filterZip(zip) : this.zipvalue)
            );

          //   this.ContactForm = this.formBuilder.group({
          //     getFirstName: [''],
          //     getLastName: [''],
          //     getMobileNumber: ['',[Validators.required,Validators.minLength(10)]],
          //     emailFormControl: ['', [Validators.required]],
            
          // })
          let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.ContactForm = this.formBuilder.group({
        getFirstName: [''],
        getLastName: [''],
        getMobileNumber: ['',[Validators.required,Validators.minLength(10)]],
        emailFormControl: ['', [Validators.required, Validators.pattern(emailregex)]],
      })
           
    }

   /**
    * @ignore
    */
  param1: any;
 param2: any;
 param3 :any;
 redirectUrl :boolean= false;
 ParseUrl:any;
 getCandidateUrlDetails:any;
 getCandidateUrlDetailsRecruiterId:any;

 /** function Desc
  * Init Function starts Here By Balaji Sachin
  */
  
   ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      this.param1 = params['dt'];
      this.param2 = params['type'];
      this.param3 = params['mailurl'];
      var EncryptedUrlId = this.encrypt.encryption("Decrypt", this.param1);
      this.ParseUrl = JSON.parse(EncryptedUrlId);
      console.log(this.ParseUrl);
      sessionStorage.setItem('urlType', this.param2);
     if(this.param3 && this.ParseUrl){
       var getUrlDetails;
      this.redirectUrl = true;
      getUrlDetails =  {  
        "flag":"GET_ID_DETAILS",
        "profileid":"1",
        "adid":this.ParseUrl.ad_id,
        "doctype":"AD",
        "deviceid":"",
        "osid":""
         }
     
     console.log(getUrlDetails);
     this.http.getlangvalue(getUrlDetails)
      .subscribe(data => {
        console.log("GetIdDetails",data);
        this.getCandidateUrlDetails = data;
        this.getCandidateUrlDetailsRecruiterId = this.getCandidateUrlDetails.res.recruiter_id;
        localStorage.setItem('urlRecruiterId', this.getCandidateUrlDetailsRecruiterId);
        console.log("data",this.getCandidateUrlDetails);
        this.feedbackReportData();
        this.currentProfile();
        this.getCvData();
        this.getAdDataforTitle();
        this.countriesList();
        this.companyDetails();
        this.currentSection = 'section1';
        this.myModel = true;
        this.myModel1 = true;
        let type = sessionStorage.getItem("urlType");
          if (type == 'directapply') {
              this.directApply = true;
          }
          else {
              this.EasyApply = true;
          }
      });
     
    }
    });
    if(!this.redirectUrl){
      this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      this.param1 = params['dt'];
      this.param2 = params['type'];
      console.log(this.param1);
      console.log(this.param2);
      if(this.param1 == undefined || this.param2 == undefined){
        this.router.navigate(['']);
      }
    });
      let type = localStorage.getItem("applytype");
        if (type == 'directapply') {
            this.directApply = true;
        }
        else {
            this.EasyApply = true;
        }
        //Calling Function Start here
        this.feedbackReportData();
        this.currentProfile();
        this.getCvData();
        this.getAdDataforTitle();
        this.countriesList();
        this.companyDetails();
        //Calling Function Ends here
        this.currentSection = 'section1';
        this.myModel = true;
        this.myModel1 = true;
       
        let urlAdid = sessionStorage.getItem("UrlDecryptedAdId");
        const cv_id = sessionStorage.getItem('cvId');
        this.obj = {};
        this.obj.flag = "AUTH";
        this.obj.cv_id = cv_id;
        this.obj.ad_id = urlAdid;
        this.speechRecognitionService.getJobTitle(this.obj).subscribe(data => {
            console.log("tokeData",data);
            if (data.res) {
                this.getAccessToken = data.res.access_token;
                localStorage.setItem('getAccessTokenforcandidate', this.getAccessToken);
              
              }
           
        },
            error => console.log(error)
        )
    }
        
        
    }
     /**  
      * company Loago Detais Starts here By Balaji Sachin
      */

  companyData: object;
  companyDataLogo: any;
  Logo: object;
  companyName: any;
  welcomeText: any;
  openTermsConditionsCV: any;
  companyDetails(){
    if (this.redirectUrl) {
      console.log("redirectUrl")
      var rId = localStorage.getItem("urlRecruiterId")
      var companyData;
      var type = sessionStorage.getItem("urlType")
      if (type == 'directapply') {
        // alert(sessionStorage.getItem("urlRecruiterId"));
        companyData = {
          "flag": "GET_COMPANY_LOGO",
          "adid": this.ParseUrl.ad_id,
          "recruiter_id": localStorage.getItem("urlRecruiterId")
        }
      } else {
        companyData = {
          "flag": "GET_COMPANY_LOGO",
          "cvid": this.ParseUrl.ad_id,
          "recruiter_id": localStorage.getItem("urlRecruiterId")
        }
      }
    }
    else {
      let urlAdid = sessionStorage.getItem("UrlDecryptedAdId");
      let UrlDecryptedRecruiterId = sessionStorage.getItem("UrlDecryptedRecruiterId");
      if (this.directApply) {
        console.log("NormalUrl")
        companyData = {
          "flag": "GET_COMPANY_LOGO",
          "adid": urlAdid,
          "recruiter_id": UrlDecryptedRecruiterId
        }
      } else {
        companyData = {
          "flag": "GET_COMPANY_LOGO",
          "cvid": urlAdid,
          "recruiter_id": UrlDecryptedRecruiterId
        }
      }
    }
    console.log(companyData);
  
    this.http.getlangvalue(companyData)
      .subscribe(data => {
        
        this.companyData = data;
        
        console.log("CompanyLogoDetails",this.companyData);
        
        this.companyDataLogo = this.companyData;
        this.Logo = this.companyDataLogo.res.status[0].logo;
        this.companyName = this.companyDataLogo.res.status[0].company_name;
        this.openTermsConditionsCV = this.companyDataLogo.res.status[0].terms_conditions;
        this.welcomeText = this.companyDataLogo.res.status[0].welcome_text;
        console.log(this.companyDataLogo.res.status[0].logo);
       
      });
  }
  //End Here Logo
  report:any
  reportMainBody:any
  HideProfileName: boolean;
  HideProfileNameZero: boolean;
  HideDegree: boolean;
  HideDegreeZero: boolean;
  HideTraining: boolean;
  HideTrainingZero: boolean;
  /** 
   *  Calling  Get Feedback UFnctionlaity Starts here By Balaji Sachin 
   */ 
  /**
   * @ignore
   */
    feedbackReportData(){

        const getAccessTokenforcandidate1 = localStorage.getItem('getAccessTokenforcandidate');
        var cvId = sessionStorage.getItem('cvId');
        let adid = sessionStorage.getItem("UrlDecryptedAdId");
       var feedback;
       if(this.redirectUrl){
        feedback = {
            "method": "AUTH",
            "ad_id": this.ParseUrl.ad_id,
            "document_type": "AD",
            "report":"customer",
            "output":"data"

         
          }
       }
       else{
        feedback = {
            "method": "AUTH",
            "ad_id": adid,
            "document_type": "AD",
            "report":"customer",
            "output":"data"
       
        }
  }
  // console.log(feedback);
    this.speechRecognitionService.getOneToMatch(feedback).subscribe(data => {
        console.log("getReportCV", data);
        this.profession = data.res.read_quality ? data.res.read_quality.quality_list.document_berufsgruppe_unique : '';
        console.log(this.profession);
        this.cv_idValue = data.res.cv_profile_values.cv_id;
        this.accessTokenforSetreport = data.res.cv_profile_values.access_token;
      
        this.content_cv = data.res.content_cv;
        if(this.content_cv.length != 0){
        var keys = Object.keys(this.content_cv);
        var len = keys;

        var bullet1;
        var bullet2;
        var bullet3;
        var bullet4;
        this.compentenceData = [];
        this.dataTest = [];
        this.softSkills = [];
        
        var bullet1_indus;
        var bullet2_indus;
        var bullet3_indus;
        this.industryData = [];
        this.buuletData = [];
        this.dataTest_indus = [];
        this.softSkills_indus = [];
        this.indutries = [];
        this.hardSkils = [];
        this.report = data;
        this.reportMainBody = this.report.res
        this.profile_ad = this.reportMainBody.profile_cv.niveau_ausbildung
        console.log(this.profile_ad)
        // if()
        this.profile_ad_degree = this.reportMainBody.profile_cv.abschluesse
        // console.log(this.profile_ad_degree, " this.profile_ad_degree")
        this.profile_ad_training = this.reportMainBody.profile_cv.ausbildung
        if (this.profile_ad == "") {
          this.HideProfileName = true;
          this.HideProfileNameZero = true;
        } else if (this.profile_ad != "") {
          this.HideProfileName = true;
          this.HideProfileNameZero = false;
          this.profile_name = this.profile_ad_values[this.profile_ad - 1].value
          console.log(this.profile_name);
        }
        if (this.profile_ad_degree.length == 0) {
          this.HideDegree = false;
          this.HideDegreeZero = true;
        } else if (this.profile_ad_degree.length != 0) {
          this.HideDegree = true;
          this.HideDegreeZero = false;
        }

        if (this.profile_ad_training.length == 0) {
          this.HideTraining = false;
          this.HideTrainingZero = true;
        } else if (this.profile_ad_training.length != 0) {
          this.HideTraining = true;
          this.HideTrainingZero = false;
        }
        for (var i = 0; i < len.length; i++) {

            bullet1 = this.content_cv[len[i]].filter(item => item.bullet_id == "27");

            if (bullet1.length > 0) {
                this.competencyLengthZero = false;
                this.competencyLength = true;
                this.buuletData.push(bullet1);
                
                for (var j = 0; j < this.buuletData.length; j++) {
                  this.finalBullet1 = this.buuletData[j];
                  this.originalcompetency = this.finalBullet1;
                  console.log("comp",this.originalcompetency);
                 
                  let removeDuplication = this.finalBullet1.filter((v, i) => this.finalBullet1.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                  this.finalBullet1 = removeDuplication;
                 
                  if (this.fullcomp) {
                    this.slicedcompetency = this.finalBullet1;
                  } else {
                    if (this.finalBullet1.length > 3) {
                      this.slicedcompetency = this.finalBullet1.slice(1, 4);
                    }
                    else if (this.finalBullet1.length <= 3) {
                      this.slicedcompetency = this.finalBullet1;
                      this.HideExpandCompatency = false;
                      this.HideHalfCompatency = false;
                    }
                  }
                }
              } else if (this.buuletData.length < 1) {
                this.competencyLengthZero = true;
                this.competencyLength = false;
                
  
              }
            
              bullet2 = this.content_cv[len[i]].filter(item => item.abschnitt_id == "61" || item.abschnitt_id == "6");

              if (bullet2.length > 0) {
                this.HardskillLength = true;
                this.hardSkillLengthZero = false;
                this.hardSkils.push(bullet2);
                for (var j = 0; j < this.hardSkils.length; j++) {
                  this.finalHardSkils = this.hardSkils[j];
                  this.originalHardSkils = this.finalHardSkils
                  let removeDuplication = this.finalHardSkils.filter((v, i) => this.finalHardSkils.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                  this.finalHardSkils = removeDuplication;
                 
                  console.log("harskills",this.originalHardSkils);
                  if (this.fullhard) {
                    this.slicedHardSkill = this.finalHardSkils;
                  } else {
                    if (this.finalHardSkils.length > 3) {
                      this.slicedHardSkill = this.finalHardSkils.slice(1, 4);
                      this.HideExpandHardSkill = true;
                      this.HideHalfHardSkill = false;
                    }
                    else if (this.finalHardSkils.length <= 3) {
                      this.slicedHardSkill = this.finalHardSkils;
                      this.HideExpandHardSkill = false;
                      this.HideHalfHardSkill = false;
                    }
                  }
                }
              } else if (this.hardSkils.length == 0) {
                this.HardskillLength = false;
                this.hardSkillLengthZero = true;
              }

           
            

              bullet3 = this.content_cv[len[i]].filter(item => item.abschnitt_id == "62");
              if (bullet3.length > 0) {
                this.softskillLength = true;
                this.softSkillLengthZero = false;
                this.softSkils.push(bullet3);
                for (var j = 0; j < this.softSkils.length; j++) {
                  this.finalSoftSkils = this.softSkils[j];
                  this.originalSoftSkils = this.finalSoftSkils
                  let removeDuplication = this.finalSoftSkils.filter((v, i) => this.finalSoftSkils.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                  this.finalSoftSkils = removeDuplication;
                  if (this.fullsoft) {
                    this.slicedSoftSkills = this.finalSoftSkils;
                  } else {
                    if (this.finalSoftSkils.length > 3) {
                      this.slicedSoftSkills = this.finalSoftSkils.slice(1, 4);
                      this.HideHalfSoftSkills = false;
                      this.HideExpandSoftSkills = true;
                    }
                    else if (this.finalSoftSkils.length <= 3) {
                      this.slicedSoftSkills = this.finalSoftSkils
                      this.HideHalfSoftSkills = false;
                      this.HideExpandSoftSkills = false;
                    }
                  }
                }
              }
              else if (this.softSkils.length == 0) {
                this.softskillLength = false;
                this.softSkillLengthZero = true;
              }

              bullet4 = this.content_cv[len[i]].filter(item => item.bullet_id == "28");
              if (bullet4.length > 0) {
                this.industryLengthZero = false;
                this.industryLength = true;
                this.HideIndusrtyTips = true;
                this.indutries.push(bullet4);
                for (var j = 0; j < this.indutries.length; j++) {
                  this.finalIndustries = this.indutries[j];
                  this.originalIndustries = this.finalIndustries;
                  
                  let removeDuplication = this.finalIndustries.filter((v, i) => this.finalIndustries.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                  this.finalIndustries = removeDuplication;
                  
                  this.industrySeparatedValue = Array.prototype.map.call(this.finalIndustries, s => s.expression).toString();
                  this.industrySeparatedValue = this.industrySeparatedValue.replace(/,/g,", ");
                  if (this.fullindustries) {
                    this.slicedIndustries = this.finalIndustries;
                  } else {
                    if (this.finalIndustries.length > 3) {
                      this.slicedIndustries = this.finalIndustries.slice(1, 4);
                    }
                    else if (this.finalIndustries && this.finalIndustries.length <= 3) {
                      this.slicedIndustries = this.finalIndustries;
                      this.HideExpandIndustry = false;
                      this.HideHalfIndustry = false;
                    }
                  }
                }
              } else if (this.indutries && this.indutries.length == 0) {
                this.industryLengthZero = true;
                this.industryLength = false;
              }
        }
        if (this.slicedHardSkill && this.slicedHardSkill.length < 3) {
            this.HideExpandHardSkill = false;
            this.HideHalfHardSkill = false;
          }

          if (this.finalIndustries && this.finalIndustries.length == 1) {
            this.HideIndusrtyTips = false;
          }
        }
        else{
          this.competencyLengthZero = true;
          this.industryLengthZero = true;
          this.hardSkillLengthZero = true;
          this.softSkillLengthZero = true;

        this.docx_document_unique = data.res.document_berufsgruppe_unique;

        this.educationLevel = data.res.profile_cv ? data.res.profile_cv.niveau_ausbildung : '';
        this.degree = data.res.profile_cv ? data.res.profile_cv.abschluesse : '';
        this.content = data.res.profile_cv ? data.res.profile_cv.ausbildung : '';
        }
      
    }
    ),
        error => {
            console.log(error)
        }
    }
 /**
  * @ignore
  */ 
  getOnetoOneMatch(){
    var cvIdNmatch = sessionStorage.getItem('cvId');
    let adidNmatch = sessionStorage.getItem("UrlDecryptedAdId");
    this.obj = {};
    this.obj.flag = "1_TO_1_MATCH";
    this.obj.clientid = "1";
    this.obj.adidNmatch = adidNmatch;
    this.obj.cvIdNmatch = cvIdNmatch;
    this.obj.matching_filter_list =[];
    this.obj.db_state = "1";
    this.obj.detail_info ="0";
    this.obj.deviceid = "";
    this.obj.osid = "";
    this.speechRecognitionService.getOneToMatch(this.obj).subscribe(data => {
      console.log("1-to-end Match",data)
    },
    error => console.log(error)
  )
  }
//One to one mAtch Ends Here

    dialogForEmail() {
      // this.ContactForm = this.formBuilder.group({
       
      //   emailFormControl: [this.getCvEmail],
      //   getMobileNumber : [this.getCvMobile]
       
      // })
      // console.log(this.getCvEmail);
      // console.log(this.getCvMobile);
      console.log(this.ContactForm.controls.emailFormControl.value);
      console.log(this.ContactForm.controls.getMobileNumber.value);
      //  this.ContactForm.controls.getMobileNumber  = this.getCvMobile ;
        // if(this.type1 && this.type2){
          // if(this.ContactForm.controls.emailFormControl.status !="INVALID"
          // && this.ContactForm.controls.getMobileNumber.value != '') {
            var getAccessToken = sessionStorage.getItem('getAccessTokenforcandidatechat');
            console.log(getAccessToken);
            
            var getcvId = sessionStorage.getItem('cvId');
         
            this.obj = {};
            this.obj.flag = "SET_CV_DATA";
            this.obj.clientid = "1";
            this.obj.userid = "";
            this.obj.accesstoken = getAccessToken;
            this.obj.cvid = getcvId;
            this.obj.name = this.ContactForm.controls.getFirstName.value;
            this.obj.surname = this.ContactForm.controls.getLastName.value;
            this.obj.zipcode = this.selectedZipValue;
            this.obj.location = this.location;
            this.obj.custid = "";
            this.obj.permanentjob = this.type1;
            this.obj.fulltimejob = this.type2;
            this.obj.email = this.ContactForm.controls.emailFormControl.value;
            this.obj.phone = this.ContactForm.controls.getMobileNumber.value;
            this.obj.countrycodes = this.country,
            this.obj.deviceid = "";
            this.obj.osid = "";
            this.obj.sessionid = "";
            if(this.obj.countrycodes ="") this.obj.countrycodes="de";
      console.log(this.obj);
            this.speechRecognitionService.setCvData(this.obj).subscribe(data => {
              console.log("SetCV",data);
              
                this.setcvData = data;
                
                let type = localStorage.getItem("applytype");
                if (type != 'directapply') {
                  var dtUrl = sessionStorage.getItem('urlDt');
                  var applyType = localStorage.getItem('applytype');
                  this.router.navigate(['/success'], {
                    queryParams: {
                      type: applyType,
                      dt: dtUrl
                    }
                  });
                  // this.router.navigate(['/success']);
                  
                  this.getOnetoOneMatch();
                  this.setStateCandidate();
                } 
                else {
                  var matchReportFlag = sessionStorage.getItem('UrlDecryptedMatchReport');
                  if (matchReportFlag == 'true') {
                    var dtUrl = sessionStorage.getItem('urlDt');
                    var applyType = localStorage.getItem('applytype');
                    this.router.navigate(['/match-report'], {
                      queryParams: {
                        type: applyType,
                        dt: dtUrl
                      }
                    });
                  
                  this.getOnetoOneMatch();
                  }
                  else {
                    const dialogRef = this.dialog.open(DialogComponent, {

                      data: { dialogname: 'goSuccessFlag', forSuccessPage: 'yes' },
                  });
                  dialogRef.afterClosed().subscribe(() => {
                  });
                    
                    this.getOnetoOneMatch();
                    this.setStateCandidate();
                  }
               }
              
             
            },
              error => console.log(error)
            )
          // }else{
          //   this.toastr.warning("Please enter the  mandatory Fileds")
          // }
            
        // }
        // else{
        //     
        // }
        
    }
    /**
     * @ignore
     */
    setStateCandidate() {
     let cv_id = sessionStorage.getItem("cvId");
      this.accesstoken = JSON.parse(localStorage.getItem('token'));
      let urlAdid = sessionStorage.getItem("UrlDecryptedAdId");
      let UrlDecryptedRecruiterId = sessionStorage.getItem("UrlDecryptedRecruiterId");
      this.obj = {};
     
      this.obj.method ="AUTH";
      this.obj.cv_id = cv_id;
      this.obj.ad_id = urlAdid;
      this.obj.recruiter_id = UrlDecryptedRecruiterId;
      this.obj.organisation_id = "111";
     
      this.speechRecognitionService.setProtectCandidate(this.obj).subscribe(data => {
          console.log("SetProtectCandidate",data);
          let cv_id1 = sessionStorage.getItem("cvId");
      let urlAdid1 = sessionStorage.getItem("UrlDecryptedAdId");
      var applyType = localStorage.getItem('applytype');
    if (applyType == 'directapply') {
      var setStateValues =
      { 
   
      "method": "AUTH",
      "ad_id":urlAdid1,
      "cv_id":cv_id1,
      "state_list":[{
          "ad_id":urlAdid1,
          "cv_id":cv_id1,
          "state":70
      }]
      
     }
     this.http.getlangvalue(setStateValues).subscribe(data => {
         console.log("SetStateCandidate",data);
         this.getOnetoOneMatch();
     },
         error => console.log("SetStateError",error)
     )
    }
     
      },
          error => console.log("SetCandidateError",error)
      )
  }

    device: number = 1;
    dynamicData = false;

        /**
         * @ignore
         */
        showFullCompetency() {
            this.slicedcompetency = this.finalBullet1;
            this.HideHalfCompatency = true;
            this.HideExpandCompatency = false;
            this.fullcomp = true
          }
           /**
            * @ignore
            */
          showHalfCompetency() {
            this.slicedcompetency = this.finalBullet1.slice(1, 4);
            this.HideHalfCompatency = false;
            this.HideExpandCompatency = true;
            this.fullcomp = false
          }
        // Compentency Ends here

        // Profession starts
         /**
          * @ignore
          */
        showExpandSkill() {
            if (this.slicedskillLIst == 3) {
              this.slicedskillLIst = this.skillLIst;
              this.HideExpandSkill = false;
              this.HideHalfSkill = false;
            }
            this.slicedskillLIst = this.skillLIst;
            this.HideExpandSkill = false;
            this.HideHalfSkill = true;
          } 
           /**
            * @ignore
            */
          showHalfSkill() {
            this.slicedskillLIst = this.skillLIst.slice(1, 4);
            this.HideExpandSkill = true;
            this.HideHalfSkill = false;
          }
        // Profession Ends

        // Industry starts
         /**
          * @ignore
          */
        showFullIndustry() {
            if (this.slicedIndustries == 3) {
              this.slicedIndustries = this.finalIndustries;
              this.HideHalfIndustry = false;
              this.HideExpandIndustry = false;
            }
            this.slicedIndustries = this.finalIndustries;
            this.HideHalfIndustry = true;
            this.HideExpandIndustry = false;
            this.fullindustries = true
          }
        /**
         * @ignore
         */
          showHalfIndustry() {
            this.slicedIndustries = this.finalIndustries.slice(1, 4);
            this.HideHalfIndustry = false;
            this.HideExpandIndustry = true;
            this.fullsoft = false
          }
       //Industry End here 

        //    HardSkills Starts Here
         /**
          * @ignore
          */
        showFullHardSkill() {
            if (this.slicedHardSkill && this.slicedHardSkill.length == 3) {
              this.slicedHardSkill = this.finalHardSkils;
              this.HideHalfHardSkill = false;
              this.HideExpandHardSkill = false;
            }
            this.slicedHardSkill = this.finalHardSkils;
            this.HideHalfHardSkill = true;
            this.HideExpandHardSkill = false;
            this.fullcomp = true
          }
          /**
           * @ignore
           */
          showHalfHardSkill() {
            this.slicedHardSkill = this.finalHardSkils.slice(1, 4);
            this.HideHalfHardSkill = false;
            this.HideExpandHardSkill = true;
            this.fullcomp = false
          }
       //    HardSkills Ends Here

       //    SoftSkills Starts Here
        /**
         * @ignore
         */
       showFullSoftSkills() {
        if (this.slicedSoftSkills && this.slicedSoftSkills.length == 3) {
          this.slicedSoftSkills = this.finalSoftSkils;
          this.HideHalfSoftSkills = true;
          this.HideExpandSoftSkills = false;
        } else {
          this.slicedSoftSkills = this.finalSoftSkils;
          this.HideHalfSoftSkills = true;
          this.HideExpandSoftSkills = false;
        }
        this.fullsoft = true
      }
        /**
         * @ignore
         */
      showHalfSoftSkills() {
        this.slicedSoftSkills = this.finalSoftSkils.slice(1, 4);
        this.HideHalfSoftSkills = false;
        this.HideExpandSoftSkills = true;
        this.fullsoft = false
      }
         //    SoftSkills Ends Here
     
        /**
         *  country, city and zipcode methods
         */
        
    countriesList() {
     
      this.http.getlangvalue(this.countryFlagvalues)
        .subscribe(data => {
          this.countriesValue = data;
          console.log("sad", this.countriesValue);
          this.countriesValue = this.countriesValue.countries;
        });
    }
    
    citiesList(data) {
      // alert(data);
      console.log(data, "cities")
      var id = data.id;
      this.countryName = data.name;
      this.sortcountryName = data.sortname
      this.cityId = id;
  
      console.log("sad", this.countryCtrl);
      this.cityFlagvalues = {
       
        "countryid": id,
        
      }
      this.http.getlangvalue(this.cityFlagvalues)
        .subscribe(data => {
          this.cityvalue = data;
          this.cityvalue = this.cityvalue.cities;
        });
    }
   
    zipcodeData(data) {
      this.cityName = data
      this.zipFlagvalues = {
        
        "city": this.cityName
        
      }
      this.http.getlangvalue(this.zipFlagvalues)
        .subscribe(data => {
          this.zipvalue = data;
          this.zipvalue = this.zipvalue.zipcodes;
        })
    }


    getFirstName: any;
    getLastName: any;
    getEmailId: any;
    // getMobileNumber: any;
    setcvData: any;
    type1 :any;
    type2 :any;
    hintptemail: boolean = false;
    hintptvalidemail: boolean = false;

    
      zipCodeCountry(zip) {
        this.zipCodebasedCountry = {
       
          "zipcode": zip,
          "name": "Germany"
        }
        this.zipcode = zip
        this.http.getlangvalue(this.zipCodebasedCountry)
          .subscribe(data => {
            console.log(data, "data");
            this.zipcodedata = data
            // console.log(this.zipcodedata.city[0].cityname)
            if (this.zipcodedata) {
              if (this.zipcodedata.city) {
                this.location = this.zipcodedata.city[0].cityname
                this.country = this.zipcodedata.city[0].countyname
                this.zipcode = zip
              }
            }
    
          })
      }
      getCV :any;
      getCvFirstName :any;
      getCvLastName :any;
      getCvEmail :any;
      getCvMobile :any;
     
    getCvData() {
        var getAccessToken = localStorage.getItem('getAccessToken');
         /**
         * @ignore
         */
        var getId = sessionStorage.getItem('userId');
        var getcvId = sessionStorage.getItem('cvId');
        var getProfileId = sessionStorage.getItem('profileId');
        var getChatId = sessionStorage.getItem('chatId');
        if(this.redirectUrl){
          this.obj = {};
          this.obj.flag = "GET_CV_DATA";
          this.obj.clientid = "1";
          this.obj.doctype ="cv";
          this.obj.idlist = "";
          this.obj.id = this.ParseUrl.cv_id;
          this.obj.deviceid = "";
          this.obj.osid = "";
          this.obj.sessionid = "";
        }else{

       
        this.obj = {};
        this.obj.flag = "GET_CV_DATA";
        this.obj.clientid = "1";
        this.obj.doctype ="cv";
        this.obj.idlist = "";
        this.obj.id = getcvId;
        this.obj.deviceid = "";
        this.obj.osid = "";
        this.obj.sessionid = "";
        }
        this.speechRecognitionService.getCvData(this.obj).subscribe(data => {
          console.log("Form :",data);
            if (data.res.header.state.success == 1) {
              if(data.res.message == null){
                console.log("Message Not Available");
              }
              else{
                this.getCV = data;
                this.getCvFirstName =data.res.message[0].name;
                this.getCvLastName = data.res.message[0].surname;
                this.getCvEmail =data.res.message[0].email;
                this.getCvMobile = data.res.message[0].phone;
              }
            }
            else{
              console.log("Form Data Not Available");
            }
            
           
        },
            error => console.log(error)
        )
    }
     /**
      * @ignore
      */
    getAdDataforTitle(){
     
        let adid = sessionStorage.getItem("UrlDecryptedAdId");
        var getJobTitle;
   if(this.redirectUrl){
     console.log("redirectUrl");
    getJobTitle = {
      "flag":"GET_AD_DATA",
      "clientid":"1",
      "doctype":"ad",
      "idlist":"",
      "id":this.ParseUrl.ad_id,
      "deviceid":"",
      "osid":"",
      "sessionid":""
   }
   }else{
    if(this.directApply){
      console.log("NormalUrl");
       getJobTitle = {
          "flag":"GET_AD_DATA",
          "clientid":"1",
          "doctype":"ad",
          "idlist":"",
          "id":adid,
          "deviceid":"",
          "osid":"",
          "sessionid":""
       } 
   }
    
        console.log("Job",getJobTitle);
        this.speechRecognitionService.getJobTitle(getJobTitle).subscribe(data => {
            console.log("jobtitle", data);
          
                if(data.status== '422'){
                    console.log("error to get Job Title");
                }
                else{
                this.jobTitle = data.res.message[0].title;
                this.country1 = data.res.message[0].country;
                this.location = data.res.message[0].location;
                this.countrycode = data.res.message[0].country_code;
                }
        }
        ),
        error => {
            console.log(error)
        }
    }
       
    }

     /**
      * @ignore
      */
    onSectionChange(sectionId: string) {
        this.currentSection = sectionId;
    }
      /**
       * @ignore
       */
    scrollTo(section) {

        document.querySelector('#' + section)
            .scrollIntoView();
    }

   currentProfileFlags :any;
    carrerLevelProgressValue: number;
    skillLIst: any;
    SkillSeparatedValue: any;
    colorCode: any;
    HideExpandSkill: boolean;
    HideHalfSkill :boolean;
    HideSkillTips :boolean;
    currentProfile() {
      if(this.redirectUrl){
        this.currentProfileFlags = {
          "flag": "GET_CURRENT_PROFILE",
          "profileid": "1",
          "userid": "",
          "id": this.ParseUrl.cv_id,
          "doctype": "CV",
          "deviceid": "",
          "osid": "",
          "sessionid": ""
        }
      }else{
        this.accesstoken = JSON.parse(localStorage.getItem('token'));
        var cvid = sessionStorage.getItem('cvId');
        this.currentProfileFlags = {
          "flag": "GET_CURRENT_PROFILE",
          "profileid": "1",
          "userid": "",
          "id": cvid,
          "doctype": "CV",
          "deviceid": "",
          "osid": "",
          "sessionid": ""
        }
      }
        
        this.http.getlangvalue(this.currentProfileFlags)
          .subscribe(data => {
            this.profileReport = data;
            console.log("currentProfile",this.profileReport)
            this.contentProfile = this.profileReport.res;
           
            this.skillLIst = this.contentProfile.skill_id_list;
            this.SkillSeparatedValue = Array.prototype.map.call(this.skillLIst, s => s.sk_id_description).toString();
            // console.log(this.SkillSeparatedValue, " this.SkillSeparatedValue")
            this.colorCode = this.contentProfile.color_info_list;
            let removeDuplication = this.skillLIst.filter((v, i) => this.skillLIst.findIndex(item => item.sk_id_description == v.sk_id_description) === i);
            this.skillLIst = removeDuplication;
            if (this.skillLIst.length > 3) {
              this.slicedskillLIst = this.skillLIst.slice(1, 4);
              this.HideExpandSkill = true;
              this.HideHalfSkill = false;
            } else if (this.skillLIst.length <= 3) {
              this.slicedskillLIst = this.skillLIst;
              this.HideExpandSkill = false;
              this.HideHalfSkill = false;
            }
            if (this.skillLIst.length == 0) {
              this.professionLengthZero = true;
              this.professionLength = false;
              this.HideSkillTips = false;
            }
            else if (this.skillLIst.length != 0) {
              this.professionLengthZero = false;
              this.professionLength = true;
              this.HideSkillTips = true;
            }
    
           
    
    
          })
        var defalutvalue = {
          id: 82,
          name: "Germany",
          sortname: "DE"
        }
        if (!this.country || this.country == "") {
          this.country = "Germany"
          defalutvalue.name = this.country
        }
        this.citiesList(defalutvalue);
    
      }
      
      /**
       * @ignore
      */
  
     set_report(value, index, bullet_id, data, type) {
        // console.log("Set_report", value, index, bullet_id, data, type);
        var req = {};
        var cv_data_list = {};
        var content_cv = {};
        // var cv_data_list = {};
        // console.log("setReportAccesstoken",this.accessTokenforSetreport);
        // console.log("cv_idvalleu",this.cv_idValue);
        this.request_updatefeedback = {
          "flag": "UPDATE_FEEDBACK",
          "clientid": "1",
          "email": "",
          "accesstoken": this.accessTokenforSetreport,
          "doctype": "CV",
          "cvid": this.cv_idValue,
          "segmentlist": {
          }
        }
        if (value == 'cv_profile_values') {
          cv_data_list = {
            "title": this.jobTitle,
            "zip": this.selectedZipValue,
            "location": this.location,
            "country": this.country,
            "country_code": this.sortcountryName
          }
          this.request_updatefeedback.segmentlist.cv_data_list = cv_data_list;
    
        } else if (value == 'cv_data_list') {
    
        } else if (value == 'content_cv') {
          if (data) {

            var comp;
            // console.log("type",type);
            if (type == "competency") {
              // console.log(data.expression.toLowerCase(), "expression")
              comp = this.originalcompetency.filter(item => item.expression.toLowerCase() == data.expression.toLowerCase());
            } else if (type == "hardskill") {
              comp = this.originalHardSkils.filter(item => item.expression.toLowerCase() == data.expression.toLowerCase());
            } else if (type == "softskill") {
              comp = this.originalSoftSkils.filter(item => item.expression.toLowerCase() == data.expression.toLowerCase());
            } else if (type == "industries") {
              comp = this.originalIndustries.filter(item => item.expression.toLowerCase() == data.expression.toLowerCase());
            }
            // console.log("comp",comp)
            content_cv[data.bullet_id] = comp;
            console.log("content_cv",content_cv);
            // console.log("industy",this.originalIndustries);
          }
          this.request_updatefeedback.segmentlist.content_cv = content_cv;
        }
        // console.log(this.request_updatefeedback, "final request")
        this.http.getlangvalue(this.request_updatefeedback)
          .subscribe(res => {
            this.response = res;
            console.log("Removed Report",this.response);
            if (this.response.status == 200) {
                // alert("call");
              this.feedbackReportData();
              this.getOnetoOneMatch();
            } else {
    
            }
          })
      }
       /**
       * @ignore
       */
      removeSkill(i) {
        if (this.skillLIst.length < 5) {
          this.HideExpandSkill = false;
          this.HideHalfSkill = false;
        }
        if (this.skillLIst.length == 1) {
          this.professionLengthZero = true;
          this.professionLength = false;
        } else if (this.skillLIst.length > 1) {
          this.professionLengthZero = false;
          this.professionLength = true;
          var skill = this.skillLIst.splice(i, 1);
          var sk_id = skill[0].sk_id;
          var request_updatefeedback1 = {
            "flag": "UPDATE_FEEDBACK",
            "clientid": "1",
            "email": "",
            "accesstoken": this.accessTokenforSetreport,
            "doctype": "CV",
            "cvid": this.cv_idValue,
            "segmentlist": {
            }
          }
          this.http.getlangvalue(request_updatefeedback1)
            .subscribe(data => {
            })
        }
      }
       /** 
        *  Validation for Mobile and Email By Balaji Sachin
        */ 
        /**
         * @ignore
         */
      getErrorEmailMessage() {
        return this.emailFormControl.hasError('required') ? 'Enter the Email' :
        this.emailFormControl.hasError('email') ? 'Not a valid email' :
        '';
        }
        getErrorMessageMobileNo() {
        return this.getMobileNumber.hasError('required') ? 'Enter the Mobile number' : 
        '';
        } 

}




