//Navigation Page for Direct Apply
import { Component, OnInit, Injectable, ViewEncapsulation, ViewChild, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, FormGroupDirective, FormBuilder, NgForm, Validators } from '@angular/forms';
import { DialogComponent } from '../Dialog/Dialog.component';
import { HeaderComponent } from '../Common_Header/Common_Header.component';
import { HttpserviceService } from '../Services/httpservice.service';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ScrollToAnimationEasing, ScrollToService, ScrollToConfigOptions, ScrollToOffsetMap } from '@nicky-lenaers/ngx-scroll-to';
import { Router } from "@angular/router";
import {urlEnv} from '../../environments/env';
declare let L;
/**
 * Page for Direct Apply Admin
 */
@Component({
  selector: 'app-ad-landing',
  templateUrl: './DirectApply_Landing.component.html',
  styleUrls: ['./DirectApply_Landing.component.css'],
  providers: [HeaderComponent]
})
/**
 * @ignore
 */
@Injectable({
  providedIn: 'root'
})
export class ADLandingComponent implements OnInit {
/**
 * @ignore
 */
public logonUrl = urlEnv;
  fileipload: FormGroup;
  eassyapply: FormGroup;

  commonloader: boolean = false;
  menuFlag = false;
  reason = '';
  fileuploadFlag = false;
  filename = "";
  uploadfile = [];

  fileType: any;
  public baseURL: any;
  langen: boolean = true;
  response: any;
  sdsa: object;
  checked = true;
  withdoc = false;
  localvalue: any;
  getlogindetails: any;
  sessionid: string;
  isLogged: any;
  userEmail: any;
  withoutdoc: boolean = true;
  accesstoken: any;
  url: any;
  profilepush: any;
  loginuser: any;
  recruiterid: string;
  sessionvar: any;
  menusessionvar: any;
  public ngxScrollToDestination: string;
  // public ngxScrollToEvent: ScrollToEvent;
  public ngxScrollToDuration: number;
  public ngxScrollToEasing: ScrollToAnimationEasing;
  public ngxScrollToOffset: number;
  getLinkbtn: boolean;
  TLI_chat: boolean;
  badProfile: any;
  okProfile: any;

  setAdValueList: any;
  access_token_getFeedback: any;
  ad_data_list: any;
  location: any;
  title: any;
  date: any;
  country: any;
  menuFlag1: boolean;
  scrHeight: any;
  scrWidth: any;

  withouteassy: boolean = true;
  withdoceassy: boolean = false;
  profilepusheassy: any;
  id: any;
/**
 * @ignore
 */
  constructor(
    private _scrollToService: ScrollToService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public service: HttpserviceService,
    public header: HeaderComponent,
    private router: Router,
    private formBuilder: FormBuilder,
    // private spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
  ) {
    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
    }
  }

  // queue: Observable<FileQueueObject[]>;



/**
 *Init Function Starts here By Balaji
 */
  ngOnInit() {

    this.fileipload = this.formBuilder.group({
      withoutdoc: ['', [Validators.required]],
      withdoc: ['', [Validators.required]],
      profilepush: ['',],
      withdoceassy: ['', [Validators.required]],
      withouteassy: ['', [Validators.required]],
      profilepusheassy: ['', [Validators.required]]
    });
    this.fileipload.value.withoutdoc = true;
    if (localStorage.getItem('token') != "")
      this.accesstoken = JSON.parse(localStorage.getItem('token'));
    this.getScreenSize();
    if (location.hash == "#bluecollar") { this.opennewpopup(); }
    // this.header.demo();
  }

  /**
 * @example Getting the Window based on Screen Varies
 */
  @HostListener('window:resize', ['$event'])
  /**
 * @ignore
 */
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth,"dedede");

  }
  
/**
 *Open Dialog Popup for If Blue or White Collar
 */
  
  openuploadedialog() {
    console.log("open")
    // this.header.demo()
    localStorage.setItem('linktype', 'false');
    if (this.scrWidth >= 600) {
      localStorage.removeItem('bluecollar')
      if (this.fileipload.value.withoutdoc || this.fileipload.value.withdoc || this.fileipload.value.profilepush) {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: false,
          data: { dialogname: 'fileuploaddirectapplypopup' },
        });
        dialogRef.afterClosed().subscribe((result: any) => {

        });
      } else {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: false,
          data: { dialogname: 'checkboxpopup' },
        });
        dialogRef.afterClosed().subscribe((result: any) => {

        });
      }
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'mobileview' },
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }

  }

  /**
 *Open Dialog Popup for landingfileloadpopup
 */
  opennewpopup() {
    localStorage.setItem('linktype', 'false');
    if (this.scrWidth >= 600) {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'landingfileloadpopup' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'mobileview' },
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }
  }


}
