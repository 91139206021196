//Common Footer for All Page
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertComponent } from '../Common_Alert/Common_Alert.component';
import { FormControl, FormsModule, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { HttpserviceService } from '../Services/httpservice.service';
import {urlEnv} from '../../environments/env';
/**
 * Page for Common Footer for all the pages
 */
@Component({
  selector: 'app-footer',
  templateUrl: './Common_Footer.component.html',
  styleUrls: ['./Common_Footer.component.css']
})
export class FooterComponent implements OnInit {
  /**
   * Ajax Varibale
   */
  public logonUrl = urlEnv;
  hintmail: boolean;
  languagedata: any;
  currentlanguage: any;
/**
 * @ignore
 */
  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public http: HttpserviceService,
  ) {
    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
      this.currentlanguage = lang
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
      this.currentlanguage = 'de'
    }
  }
  ContactForm: FormGroup;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  getMobileNumber = new FormControl('', [Validators.required, Validators.minLength(10)]);
  ContactFormDetails1: any;
  hintcompanies: boolean = false;
  ContactFormDetails: any;
  checkedTick = false;
  hintpttelephoneno: boolean = false;

  /**
 * ngOnInit Function Start Here
 * @implements GetLang
 */
  ngOnInit() {
    this.getlanguagevalue()
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.ContactForm = this.formBuilder.group({
      getFirstName: [''],
      getLastName: [''],
      getMobileNumber: ['', [Validators.required, Validators.minLength(10)]],
      emailFormControl: ['', [Validators.required, Validators.pattern(emailregex)]],
      desc: [''],
      gettcheckbox: false,
    })
  }
  /**
 * Common alertmsg Function Start Here
 * @param 
 * msg
 * errtype
 */
  alertmsg(msg, errtype) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: { data: msg, classname: errtype },
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'blue-snackbar'
    });
  }
  /**
 * @ignore
 * toCheck
 */
  getlanguagevalue() {
    var language = this.currentlanguage
    this.http.getlangvalue(language)
      .subscribe(data => {
        if (data != null) {
          var details: any = data;
          this.languagedata = details
          console.log(details, "details");
        }
        else {

        }
      }, error => {

      });
  }
  commonloader: boolean = false;
  /**
 * @ignore
 */
  contactInfo() {

    let email = this.ContactForm.value.emailFormControl;
    let phone = this.ContactForm.value.getMobileNumber;
    let firstname = this.ContactForm.value.getFirstName;
    let lastname = this.ContactForm.value.getLastName;

    if (email != "" && phone != "" && firstname != "" && lastname != "" && email != null && phone != null && firstname != null && lastname != null) {
      var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!pattern.test(email)) {
        // this.hintcompanies = true;
        // this.alertmsg("please Enter Valid Email", 'error');
        this.alertmsg(this.languagedata.ALERTS.ENTEREMAIL, 'error');
        return false;
      }
      if (this.checkedTick) {
        var ContactInfo = {

         
          "firstname": this.ContactForm.controls.getFirstName.value,
          "lastname": this.ContactForm.controls.getLastName.value,
          "email": this.ContactForm.controls.emailFormControl.value,
          "phone": this.ContactForm.controls.getMobileNumber.value,
          "description": this.ContactForm.controls.desc.value,
          "terms_conditions": true

          
        }

        console.log("Contact",ContactInfo)
        this.commonloader = true
        this.http.commonMethod(ContactInfo,this.logonUrl.contacts_informations).subscribe(data => {
          console.log("EMail UPdate", data);
          this.ContactFormDetails = data
          if (this.ContactFormDetails.status == 200) {
            // this.toastr.success("Your Conatct Information Has been Saved")
            // this.alertmsg("Your Conatct Information Has been Saved", 'success');
            this.alertmsg(this.languagedata.ALERTS.SAVED, 'success');
            
            this.ContactForm.reset();
            this.commonloader = false
            this.checkedTick = false;
            // this.gettcheckbox
          }
        })
      } else {
        // this.alertmsg("please tick the Check Box", 'error');
        this.alertmsg(this.languagedata.ALERTS.CHECKBOX_ALERT, 'error');
      }
    } else {
      // this.alertmsg("please Enter All Mandatory Fileds", 'error');
      this.alertmsg(this.languagedata.ALERTS.FILL, 'error');
    }
  }

   /**
 * @ignore
 */
  checked(value) {
    console.log(value.checked)
    if (value.checked == true) {
      this.checkedTick = true
      // this.contactInfo()
    } else {

      this.checkedTick = false
    }
  }

   /**
 * @ignore
 */
  clearcandidate() {
    this.hintcompanies = false;
    this.hintmail = false;
    // this.hintpttelephoneno = false;
  }

  
  //Email Details Validations
  /**
 * @method newLetterSub
 */
  emailSub1: any
  newLetterSub() {
    // console.log(this.ref.nativeElement.value)
    // let this.emailSub1 = <HTMLElement>document.getElementById('test');
    // let emailSub1;
    console.log("Email Value", this.emailSub1)

    if (this.emailSub1 != "" && this.emailSub1 != null && this.emailSub1 != undefined) {
      var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!pattern.test(this.emailSub1)) {
        // this.hintmail = true;
        // this.alertmsg("Please Enter Valid Email", 'error');
        this.alertmsg(this.languagedata.ALERTS.ENTEREMAIL, 'error');
        return false;
      }
      var ContactInfo = {
       "subscribe_email": this.emailSub1
      }

      console.log(ContactInfo)
      this.http.commonMethod(ContactInfo,this.logonUrl.contacts_informations).subscribe(data => {
        this.ContactFormDetails1 = data
        if (this.ContactFormDetails1.status == 200) {
          // this.alertmsg("Your Email Has been Saved", 'success');
          this.alertmsg(this.languagedata.ALERTS.EMAIL, 'success');
          this.emailSub1 = '';
        }
      })
    }
    else {
      // this.alertmsg("Enter email", 'error');
      this.alertmsg(this.languagedata.ALERTS.ENTER_EMAIL, 'error');
    }

  }
  /**
 * @ignore
 */
  openPdfDoc2() {
    window.open('https://www.talk-n-job.de/wp-content/uploads/2020/09/DS-015-00_Datenschutz_ApplyZ.pdf', '_blank');
  }
  /**
 * @ignore
 */
  openPdfDoc1() {
    window.open('https://www.talk-n-job.de/wp-content/uploads/2020/09/AGB-und-Auftragsdatenverarbeitung.pdf', '_blank');
  }


}
