//E-Recruiter Landing for File Upload
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../Dialog/Dialog.component';
import { HttpserviceService } from '../Services/httpservice.service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
/**
 * Page for E-Recruiter Landing ,File Upload for E-Recruiter
 */
declare let L;
@Component({
  selector: 'app-erecruiter-landing',
  templateUrl: './E_Recruiter-landing.component.html',
  styleUrls: ['./E_Recruiter-landing.component.css']
})
export class ErecruiterLandingComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public HTTPServiceService: HttpserviceService, private router: Router,
    public dialog: MatDialog
  ) {
    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
    }
  }

  ngOnInit() {
    
  }

  openFileUpload() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: false,
      data: { dialogname: 'fileuploadErecruiter' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {

      //  this.democlick()
    });
  }
}
