
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MatSidenav } from '@angular/material/sidenav';
import { DialogComponent } from '../Dialog/Dialog.component';
import { HttpserviceService } from '../Services/httpservice.service';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, debounceTime } from 'rxjs/operators';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Alert } from 'selenium-webdriver';
import { empty } from 'rxjs';
import { AlertComponent } from '../Common_Alert/Common_Alert.component';
import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common'
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import * as html2canvas from 'html2canvas';
import { Options, LabelType } from 'ng5-slider';
import {urlEnv} from '../../environments/env';
declare var jsPDF: any;
/**
 * Page for Recruiter Feedback ,Recriuter Create a Link for Candidate
 */
@Component({
  selector: 'app-feedback',
  templateUrl: './DirectApply_Feedback.component.html',
  styleUrls: ['./DirectApply_Feedback.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class FeedbackComponent implements OnInit {
  /** variable Desc
   *  Variable Declaration Start here for FeedBack page By balaji
   */
  public logonUrl = urlEnv
  countryCtrl = new FormControl();
  stateCtrl = new FormControl();
  zipCtrl = new FormControl();
  countryListValues: any;
  cityListValue: any;
  commonloader: boolean = false;
  backgrounddiv_enable: boolean = false;

  myModel: boolean;
  myModel1: boolean;
  countryFlagvalues: { "flag": string, "deviceid": "", "osid": "" };
  countriesValue: any;
  countryList: object;
  cityFlagvalues: { "countryid": "",}
  cityvalue: any;
  cityList: any;
  @ViewChild('sidenav') sidenav: MatSidenav;
  report: any;
  reportBody: any;
  docx_index: any;
  docx_document_unique: any;
  dataList: any;
  ad_data_list: any;
  date: any;
  title: any;
  ad_data_country: any;
  location: any;
  country: any;
  content_ad: any;
  reportMainBody: any;
  profile_ad: any;
  profile_ad_values: any = [{ id: 1, value: 'Schulbildung' }, { id: 2, value: 'Lehrberuf' }, { id: 3, value: 'Studium' }, { id: 4, value: 'weiterführendes Studium' }];
  remote_value: any = [{ id: 1, value: 'Ja', checked: false }, { id: 2, value: 'Nein', checked: false }]

  profile_name: any;
  profile_ad_degree: any;
  profile_ad_training: any;
  cityId: any;
  zip: any;
  accesstoken: any;
  zipFlagvalues: {"city": any };
  zipvalue: any;
  zipListValue: any;
  profilesscratch: any
  noValueprofileAd: boolean;
  ValueprofileAdL: boolean;
  notUXmanager: any;
  UXmanager: boolean;
  locationShow: boolean;
  locationhide: boolean;
  showEducation: any;
  HideEducation: boolean;
  checked = true;
  carrerLevelProgressValue: number;
  remoteHide: boolean = true;
  remoteShow: boolean = false;
  permanentdata: any;
  fulltimedata: any;
  badProfile: boolean;
  gooProfile: boolean;
  avagProfile: boolean;
  content_ad_values: any;
  content_ad_bullet27: any;
  content_ad_bullet3: any;
  buuletData: any[];
  finalBullet1: any;
  hardSkill: any;
  hardSkils: any[];
  finalHardSkils: any;
  softSkils: any;
  finalSoftSkils: any;
  indutries: any[];
  finalIndustries: any;
  slicesValueCompetency: any;
  slicedcompetency: any[];
  notAvail: any;
  yes: boolean;
  no: boolean;

  HideExpandCompatency: boolean = true
  HideHalfCompatency: boolean = false
  slicedHardSkill: any;
  HideHalfHardSkill: boolean = false;
  HideExpandHardSkill: boolean = true;
  slicedSoftSkills: any;
  HideHalfSoftSkillsl: boolean = false;
  HideExpandSoftSkills: boolean = true;
  slicedIndustries: any;
  HideHalfIndustry: boolean = false;
  HideExpandIndustry: boolean = true;
  locationLengthCheck: boolean;
  countryLengthCheck: boolean;
  locationTitleCheck: boolean;
  setAdValueList: any;
  zipCodebasedCountry: any;
  currentProfileFlags: any;
  profileReport: any;
  ProfileContent: any;
  contentProfile: any;
  skillLIst: any;
  colorCode: any;
  competencyLengthZero: boolean;
  competencyLength: boolean;
  HardskillLength: boolean;
  hardSkillLengthZero: boolean;
  skillZero: boolean;
  softskillLength: boolean;
  softSkillLengthZero: boolean;
  industryLengthZero: boolean;
  industryLength: boolean;
  professionLengthZero: boolean;
  professionLength: boolean;
  compeGreen: any;
  compeYellow: any;
  compeRed: any;
  skillYellow: boolean;
  skillGreen: boolean;
  skillRed: boolean;
  proGreen: boolean;
  proYellow: boolean;
  proRed: boolean;
  careerGreen: boolean;
  careerYellow: boolean;
  careerRed: boolean;
  uid: any;
  HideExpandSkill: boolean;
  HideHalfSkill: boolean;
  HideProfileName: boolean;
  HideProfileNameZero: boolean;
  HideDegree: boolean;
  HideDegreeZero: boolean;
  HideTraining: boolean;
  HideTrainingZero: boolean;
  eduGreen: boolean;
  eduYellow: boolean;
  eduRed: boolean;
  okProfile: boolean;
  goodProfile: boolean;
  ad_id: boolean = false;
  titlecheck: boolean = false;
  citycon: boolean = false;
  profiletype: any;
  isLogged: any;
  sessionid: any;
  userEmail: any;
  menuFlag: any;
  rule: boolean;
  rule1: boolean;
  ad_idValue: any;
  access_token_getFeedback: any;
  SkillSeparatedValue: any;
  HideSkillTips: boolean;
  industrySeparatedValue: any;
  HideIndusrtyTips: boolean;
  selectedZipValue: any;
  cityName: any;
  countryName: any;
  sortcountryName: any;
  internal_reference: any;
  internal_reference1: boolean;
  readonly_feedback:boolean = false;

  newEdu:any;
  response: any;
  url: any;
  finalload: boolean = false;
  reason: string;
  HideHalfSoftSkills: boolean;
  slicedskillLIst: any;
  loginuser: any;
  langen: boolean = true;
  zipcodedata: any;
  zipcode: any;
  location1: any;
  country1: any;
  request_updatefeedback: any;
  sessionvar: any;
  val: boolean;
  checksession1: boolean
  remotevalue: any;
  getLinkbtn: boolean = false;
  TLI_chat: boolean = false
  originalcompetency: any;
  originalHardSkils: any;
  originalSoftSkils: any;
  originalIndustries: any;
  fullcomp: boolean = false;
  fullhard: boolean = false;
  fullsoft: boolean = false;
  fullindustries: boolean = false;

  profilename12: boolean = false;
  zipcode1: any;
  client_ad_id: any;
  recruiter_id: string;
  deletecount: any = 0;
  date1: any;
  bluecollar: any;
  data122: { client_ad_id: any; title: any; };
  internal_Reference: any;
  careerlevelvalue: string;
  careerlevelvalue_f: number = 1;
  careerlevel: Options = {};
  languagedata: any;
  currentlanguage: any;
  skillset_id: any = [];
  // deletecount:any=0;
 /**
 * @ignore
 */
  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    public http: HttpserviceService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private router: Router,
    private urlpath: PlatformLocation,
  ) {
    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
      this.currentlanguage = lang
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
      this.currentlanguage = 'de'
    }

    // this.router.events.subscribe(val => {

    // });
    this.urlpath.onPopState(() => {
      // const dialogRef = this.dialog.open(DialogComponent, {
      //   disableClose: true,
      //   data: { dialogname: 'datalose', url: this.url }
      // });
      // dialogRef.afterClosed().subscribe((result: any) => {
      //   if (result == "proceed") {
      //     this.deletejob();
      //   } else if (result == "cancel") {
      //     event.preventDefault();
      //   }
      // });
      // this.deletejob();
    });
    // this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event =>  this.getData()); 

    this.countryListValues = this.countryCtrl.valueChanges
      .pipe(
        startWith(''),
        map(country => country ? this._filterCountry(country) : this.countriesValue)
      );


    this.cityListValue = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this._filterStates(state) : this.cityvalue)
      );

    this.zipListValue = this.zipCtrl.valueChanges
      .pipe(
        startWith(''),
        map(zip => zip ? this._filterZip(zip) : this.zipvalue)
      );
  }
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  feedbackPopup = null;
  // matcher = new MyErrorStateMatcher();
  menuFlag1: boolean;

    /** function Desc
     *  Init Function Start here by Balaji Sachin
     */
  ngOnInit() {
    this.getlanguagevalue();
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    this.recruiter_id = localStorage.getItem('recruiter_id');
    this.bluecollar = JSON.parse(localStorage.getItem('bluecollar'));
    if (this.isLogged == "true") {
      this.menuFlag1 = true;
    } else {
      this.menuFlag1 = false;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail.split('@')[0];
    }
    // this.country="Germany"
    this.myModel = true;
    this.myModel1 = true;
    this.accesstoken = JSON.parse(localStorage.getItem('token'));
    if(this.accesstoken.readonly){
      this.readonly_feedback = this.accesstoken.readonly
    }
    console.log(this.badProfile)
    this.countriesList();
    this.commonloader = true;
    this.backgrounddiv_enable = false;
    this.feedbackReportData();
    // this.checksession()

  }
  ngOnDestroy() {

    this.deletejob();

  }

  //Get language 
/**
 * @ignore
 * toCheck
 */
  getlanguagevalue() {
    var language = this.currentlanguage
    this.http.getlangvalue(language)
      .subscribe(data => {
        if (data != null) {
          var details: any = data;
          this.languagedata = details
          this.careerlevel = {
            floor: 1,
            ceil: 4,
            translate: (value: number, label: LabelType): string => {
              switch (value) {
                case 1:
                  return this.languagedata.CAREER_LEVEL.ENTRY_LEVEL;
                case 2:
                  return this.languagedata.CAREER_LEVEL.ERSTEERFAHRUNGEN;
                case 3:
                  return this.languagedata.CAREER_LEVEL.SPEZIALIST;
                case 4:
                  return this.languagedata.CAREER_LEVEL.SENIOR;
                default:
                  return this.languagedata.CAREER_LEVEL.ENTRY_LEVEL;
              }
            }
          }
        }
        else {

        }
      }, error => {

      });
  }
  aus(aus: any, arg1: string) {
    throw new Error("Method not implemented.");
  }

  //Delete Link Checking 
/**
 * @ignore
 */
  deletejob() {
    var data = {
     
      "method": "AUTH",
      "email": this.userEmail,
      "ad_id": this.accesstoken.ad_id,
      "document_type": "AD",
      "delete_list": [
        "READ",
        "PROFILE",
        "MATCH",
        "FILES"
      ]
     
    }
    this.http.commonMethod(data,this.logonUrl.link_checking)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {
          // console.log(this.response, 'setstate res');

        } else {

        }
      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });
  }
 /** 
 * Method Desc
 * Common Session Check in All Page 
 */
  checksession() {
    var test = this.isLogged
    var data = {
    
        "email": this.userEmail,
        "sessionid": this.sessionid
       
    }
    this.http.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          this.sessionvar = res
          if (this.sessionvar.status == '200') {

            console.log("session check success")
            this.checksession1 = true
          } else if (this.sessionvar.status == '422') {
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.isLogged = localStorage.getItem('isLogged');
            this.sessionid = localStorage.getItem('sessionid');
            this.userEmail = localStorage.getItem('userEmail');
            this.loginuser = ""
            this.menuFlag1 = false;
            if (test == "true") {
              this.alertmsg(this.sessionvar.message, 'error');
            }
            this.checksession1 = false
            this.router.navigate(['/'])
            return false
          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });
    this.deletecount = 0
  }

  menuclicksessioncheck(value) {
    var data = {
     
        "email": this.userEmail,
        "sessionid": this.sessionid
       
    }
    this.http.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          this.sessionvar = res
          if (this.sessionvar.status == '200') {
            console.log("session check success")
            if (value == "changepassword") {
              this.changepasswords()
            } else if (value == "easyapply") {
              // this.geteasyapplylink()
              this.router.navigate(['/cvadmin'])
            } else if (value == "admin") {
              this.router.navigate(['/daadmin'])
            } else if (value == "directapply") {
              this.openDialog3()
            }
          } else if (this.sessionvar.status == '422') {
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.isLogged = localStorage.getItem('isLogged');
            this.sessionid = localStorage.getItem('sessionid');
            this.userEmail = localStorage.getItem('userEmail');
            this.loginuser = ""
            this.alertmsg(this.sessionvar.message, 'error');
            this.menuFlag1 = false;
            this.menuFlag = false;
            this.router.navigate(['/'])
          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });

  }
  /** 
   *  Get CV URl
   */
  geteasyapplylink() {
    var flagdata = {
      "flag": "GET_CV_URL",
      "profileid": "1",
      "email": this.userEmail,
      "clientadid": "",
      "addesc": "android jd",
      "deviceid": "",
      "osid": "",
      "sessionid": this.sessionid
    }
    this.http.commonMethod(flagdata,this.logonUrl.get_cv_url)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200) {
          this.url = this.response.res.URL;
          this.sidenav.close();

          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            data: { dialogname: 'linkpopupregister', url: this.url }
          });
          dialogRef.afterClosed().subscribe((result: any) => {

          });
        } else {

        }
      })
  }

  /** 
   * Getting Local Url by Balji Sachin
   */
  getlocalvalues() {
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    if (this.isLogged == "true") {
      this.menuFlag1 = true;
    } else {
      this.menuFlag1 = false;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail.split('@')[0];
    }
  }
   /** 
    * @ignore 
    */
   
  languageSelector(val) {
    localStorage.setItem('language', val);
    if (val == "de") {
      this.langen = true;
    } else {
      this.langen = false;
    }
  }

  //Country Code Start Here
   /** 
    * @ignore 
    */
  
  private _filterCountry(value: string) {
    const filterValue = value.toLowerCase();
    if (this.countriesValue.length>0)
    {
      return this.countriesValue.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else
    {
      return this.countriesValue;
    }
   
  }
   /** 
    * @ignore 
    */
  
  private _filterStates(value: string) {
    const filterValue = value.toLowerCase();
    if (this.cityvalue) {
      return this.cityvalue.filter(state => state.cityname.toLowerCase().indexOf(filterValue) === 0);
    }
  }
   /** 
    * @ignore 
    */
  
  private _filterZip(value: string) {
    return this.zipvalue;
  }
   /** 
    * Feedback Data Starts here
    */
  feedbackReportData() {
    var req = {
      
      "method": "AUTH",
      "ad_id": this.accesstoken.ad_id,
      "document_type": "AD",
      "report":"customer",
      "output":"data"

    }
    this.http.commonMethod(req,this.logonUrl.get_report)
      .subscribe(data => {
        this.report = data;
        
        this.reportMainBody = this.report.res
        console.log("reportMainBody", this.reportMainBody)
        this.skillset_id =[]
        for (var i = 0; i < this.reportMainBody.ad_profile_values.profil_info.length; i++) {
            this.skillset_id.push(this.reportMainBody.ad_profile_values.profil_info[i].skillset_id)
        }
        console.log("SKILLSET_ID", this.skillset_id)
        if (this.reportMainBody) {
          if (this.reportMainBody.read_quality_ad) {
            this.reportBody = this.reportMainBody.read_quality_ad.quality_list;
          }
          if (this.reportBody) {
            this.docx_index = this.reportBody.document_index;
          }
          if (this.reportBody) {
            this.docx_document_unique = this.reportBody.document_berufsgruppe_unique;
          }
          this.ad_data_list = this.reportMainBody.ad_data_list[0]
          this.date1 = this.ad_data_list.date
          if (this.date1) {
            var dd = this.date1.split('-')[2];
            var mm = this.date1.split('-')[1];
            var yy = this.date1.split('-')[0];
            yy = yy.substr(-2, 2)
            dd = dd.concat(".");
            var finaldate = dd.concat(mm);
            finaldate = finaldate.concat('.');
            finaldate = finaldate.concat(yy)
            this.date = finaldate
          }
          
          if (this.reportMainBody.feedback_status) {
            this.getLinkbtn = this.reportMainBody.feedback_status.link_status
            this.TLI_chat = this.reportMainBody.feedback_status.chat_status
            this.client_ad_id = this.reportMainBody.feedback_status.client_ad_id
            // if (this.reportMainBody.feedback_status.internal_reference) {
              // this.internal_Reference = this.reportMainBody.feedback_status.internal_reference
              // this.accesstoken = JSON.parse(localStorage.getItem('token'));
              // this.accesstoken.internal_reference = this.internal_Reference
              // localStorage.setItem("token", this.accesstoken);
            // } 
            if (this.reportMainBody.profile_ad_job_titles.job_id) {
                this.internal_Reference = this.reportMainBody.profile_ad_job_titles.job_id
            }
            else {
              this.internal_Reference = ""
            }

          }


          this.location = this.ad_data_list.location
          this.country = this.ad_data_list.country
          this.zip = this.ad_data_list.zip
          this.permanentdata = this.ad_data_list.permanent
          this.fulltimedata = this.ad_data_list.fulltime
          this.content_ad = this.reportMainBody.content_ad;
          this.profile_ad = this.reportMainBody.profile_ad.niveau_ausbildung
          // if()
          this.profile_ad_degree = this.reportMainBody.profile_ad.abschluesse
          this.profile_ad_training = this.reportMainBody.profile_ad.ausbildung
          this.ad_idValue = this.reportMainBody.ad_profile_values.ad_id;
          sessionStorage.setItem('adId', this.ad_idValue);
          this.access_token_getFeedback = this.reportMainBody.ad_profile_values.access_token;

          // for payment
          /** 
           * @ignore 
           */
          this.data122 = {
            client_ad_id: this.client_ad_id,
            title: this.title
          }
          localStorage.setItem('jobdetails', JSON.stringify(this.data122));


          if (this.reportMainBody.profile_ad_job_titles) {
            if (this.reportMainBody.profile_ad_job_titles.job_title) {
              this.title = this.reportMainBody.profile_ad_job_titles.job_title
            } else if (this.reportMainBody.profile_ad_job_titles.profile_job_title) {
              this.title = this.reportMainBody.profile_ad_job_titles.profile_job_title
            }
          }


          if (this.docx_index <= 0.2) {
            this.badProfile = true;
            this.okProfile = false
            this.goodProfile = false;
            this.profiletype = "Bad Profile"
            this.getLinkbtn = false

          } else if (this.docx_index > 0.21 && this.docx_index <= 0.6) {
            this.badProfile = false;
            this.okProfile = true;
            this.goodProfile = false;
            this.profiletype = "Ok Profile"
          } else if (this.docx_index > 0.61) {
            this.badProfile = false;
            this.okProfile = false;
            this.goodProfile = true;
            this.profiletype = "Very Good Profile"
          }

          if (this.location && this.location.length >= 5) {
            this.locationLengthCheck = false;
          }
          // if (this.country.length >= 8) {
          //   this.countryLengthCheck = false;
          // }
          if (this.title && this.title.length >= 10) {
            this.locationTitleCheck = false;
          }
          var keys = Object.keys(this.content_ad);
          var len = keys;
          var bullet1;
          var bullet2;
          var bullet3;
          var bullet4;
          this.buuletData = [];
          this.hardSkils = [];
          this.softSkils = [];
          this.indutries = [];

          for (var i = 0; i < len.length; i++) {
            bullet1 = this.content_ad[len[i]].filter(item => item.bullet_id == "27");
            if (bullet1.length > 0) {
              this.competencyLengthZero = false;
              this.competencyLength = true;
              this.buuletData.push(bullet1);
              for (var j = 0; j < this.buuletData.length; j++) {
                this.finalBullet1 = this.buuletData[j];
                this.originalcompetency = this.finalBullet1
                let removeDuplication = this.finalBullet1.filter((v, i) => this.finalBullet1.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalBullet1 = removeDuplication;
                if (this.fullcomp) {
                  this.slicedcompetency = this.finalBullet1;
                } else {
                  if (this.finalBullet1.length > 7) {
                    this.slicedcompetency = this.finalBullet1.slice(1, 8);
                  }
                  else if (this.finalBullet1.length <= 7) {
                    this.slicedcompetency = this.finalBullet1;
                    this.HideExpandCompatency = false;
                    this.HideHalfCompatency = false;
                  }
                }
              }
            } else if (this.buuletData.length < 1) {
              this.competencyLengthZero = true;
              this.competencyLength = false;


            }
            bullet2 = this.content_ad[len[i]].filter(item => item.abschnitt_id == "61" || item.abschnitt_id == "6");

            if (bullet2.length > 0) {
              this.HardskillLength = true;
              this.hardSkillLengthZero = false;
              this.hardSkils.push(bullet2);
              for (var j = 0; j < this.hardSkils.length; j++) {
                this.finalHardSkils = this.hardSkils[j];
                this.originalHardSkils = this.finalHardSkils
                let removeDuplication = this.finalHardSkils.filter((v, i) => this.finalHardSkils.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalHardSkils = removeDuplication;
                if (this.fullhard) {
                  this.slicedHardSkill = this.finalHardSkils;
                } else {
                  if (this.finalHardSkils.length > 7) {
                    this.slicedHardSkill = this.finalHardSkils.slice(1, 8);
                    this.HideExpandHardSkill = true;
                    this.HideHalfHardSkill = false;
                  }
                  else if (this.finalHardSkils.length <= 7) {
                    this.slicedHardSkill = this.finalHardSkils;
                    this.HideExpandHardSkill = false;
                    this.HideHalfHardSkill = false;
                  }
                }
              }
            } else if (this.hardSkils.length == 0) {
              this.HardskillLength = false;
              this.hardSkillLengthZero = true;
            }


            bullet3 = this.content_ad[len[i]].filter(item => item.abschnitt_id == "62");
            if (bullet3.length > 0) {
              this.softskillLength = true;
              this.softSkillLengthZero = false;
              this.softSkils.push(bullet3);
              for (var j = 0; j < this.softSkils.length; j++) {
                this.finalSoftSkils = this.softSkils[j];
                this.originalSoftSkils = this.finalSoftSkils
                let removeDuplication = this.finalSoftSkils.filter((v, i) => this.finalSoftSkils.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalSoftSkils = removeDuplication;
                if (this.fullsoft) {
                  this.slicedSoftSkills = this.finalSoftSkils;
                } else {
                  if (this.finalSoftSkils.length > 7) {
                    this.slicedSoftSkills = this.finalSoftSkils.slice(1, 8);
                    this.HideHalfSoftSkills = false;
                    this.HideExpandSoftSkills = true;
                  }
                  else if (this.finalSoftSkils.length <= 7) {
                    this.slicedSoftSkills = this.finalSoftSkils
                    this.HideHalfSoftSkills = false;
                    this.HideExpandSoftSkills = false;
                  }
                }
              }
            }
            else if (this.softSkils.length == 0) {
              this.softskillLength = false;
              this.softSkillLengthZero = true;
            }

            bullet4 = this.content_ad[len[i]].filter(item => item.bullet_id == "28");
            if (bullet4.length > 0) {
              this.industryLengthZero = false;
              this.industryLength = true;
              this.HideIndusrtyTips = true;
              this.indutries.push(bullet4);
              for (var j = 0; j < this.indutries.length; j++) {
                this.finalIndustries = this.indutries[j];
                this.originalIndustries = this.finalIndustries
                let removeDuplication = this.finalIndustries.filter((v, i) => this.finalIndustries.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalIndustries = removeDuplication;
                this.industrySeparatedValue = Array.prototype.map.call(this.finalIndustries, s => s.expression).toString();
                this.industrySeparatedValue = this.industrySeparatedValue.replace(/,/g, ", ");
                if (this.fullindustries) {
                  this.slicedIndustries = this.finalIndustries;
                } else {
                  if (this.finalIndustries.length > 7) {
                    this.slicedIndustries = this.finalIndustries.slice(1, 8);
                  }
                  else if (this.finalIndustries && this.finalIndustries.length <= 7) {
                    this.slicedIndustries = this.finalIndustries;
                    this.HideExpandIndustry = false;
                    this.HideHalfIndustry = false;
                  }
                }
              }
            } else if (this.indutries && this.indutries.length == 0) {
              this.industryLengthZero = true;
              this.industryLength = false;
            }
          }
          if (this.slicedHardSkill && this.slicedHardSkill.length < 7) {
            this.HideExpandHardSkill = false;
            this.HideHalfHardSkill = false;
          }

          if (this.finalIndustries && this.finalIndustries.length == 1) {
            this.HideIndusrtyTips = false;
          }

          this.currentProfile();
          if (this.title == "" || this.location == "undefined") {
            this.title = ""
          }

          if (this.location == "" || this.location == "undefined") {
            this.location = ""
          }
          if (this.country == "" || this.location == "undefined") {
            this.country = ""
          }

          if (this.reportMainBody.profile_ad == "") {
            this.noValueprofileAd = true;
            this.ValueprofileAdL = false;
          } else if (this.reportMainBody.profile_ad != "") {
            this.noValueprofileAd = false;
            this.ValueprofileAdL = true;
          }

          if (this.title == "") {
            this.notUXmanager = true;
            this.UXmanager = false;
          }
          else if (this.title != "") {
            this.notUXmanager = false;
            this.UXmanager = true;
          }

          if (this.profile_ad == "") {
            this.HideProfileName = true;
            this.HideProfileNameZero = true;
          } else if (this.profile_ad != "") {
            this.HideProfileName = true;
            this.HideProfileNameZero = false;
            this.profile_name = this.profile_ad_values[this.profile_ad - 1].value
          }

          if (this.profile_ad_degree.length == 0) {
            this.HideDegree = false;
            this.HideDegreeZero = true;
          } else if (this.profile_ad_degree.length != 0) {
            this.HideDegree = true;
            this.HideDegreeZero = false;
          }

          if (this.profile_ad_training.length == 0) {
            this.HideTraining = false;
            this.HideTrainingZero = true;
          } else if (this.profile_ad_training.length != 0) {
            this.HideTraining = true;
            this.HideTrainingZero = false;
          }



        }
      });
  }
   /** 
    * @ignore 
    */

  onSliderChange(event) {
    console.log(event)
    if (event.value == 0) {
      this.carrerLevelProgressValue = event.value;
      this.careerlevelvalue = "Berufseinsteiger"
    } else if (event.value == 1) {
      this.carrerLevelProgressValue = event.value;
      this.careerlevelvalue = "Berufseinsteiger"
    }
    else if (event.value == 2) {
      this.carrerLevelProgressValue = event.value;
      this.careerlevelvalue = "erste Erfahrungen"
    }
    else if (event.value == 3) {
      this.carrerLevelProgressValue = event.value;
      this.careerlevelvalue = "Spezialist"

    } else if (event.value == 4) {
      this.carrerLevelProgressValue = event.value;
      this.careerlevelvalue = "Senior"
    } else {
      this.carrerLevelProgressValue = 1;
    }


  }
   /** 
    * @ignore 
    */
  careerlevelset() {
    console.log(this.careerlevelvalue_f, "careerlevelvalue_f")
    this.set_report("ad_profile_values", "", "", "", "", "") 
  }
 
 /** 
  *  Get Current Profile by Balaji Sachin
  */
  currentProfile() {
    this.currentProfileFlags = {
      "id":  this.accesstoken.ad_id,
      "doc_type": "AD"
    }
    this.http.commonMethod(this.currentProfileFlags,this.logonUrl.get_current_profile)
      .subscribe(data => {
        this.profileReport = data;
        this.contentProfile = this.profileReport.res;
        // console.log(this.contentProfile);
        if (this.contentProfile.ad_profile_info.krit_8 == 1) {
          this.carrerLevelProgressValue = 1;
          this.careerlevelvalue = "Berufseinsteiger"
          this.careerlevelvalue_f = 1
        }
        else if (this.contentProfile.ad_profile_info.krit_8 == 2) {
          this.carrerLevelProgressValue = 2;
          this.careerlevelvalue = "erste Erfahrungen"
          this.careerlevelvalue_f = 2
        }
        else if (this.contentProfile.ad_profile_info.krit_8 == 3) {
          this.carrerLevelProgressValue = 3;
          this.careerlevelvalue = "Spezialist"
          this.careerlevelvalue_f = 3

        } else if (this.contentProfile.ad_profile_info.krit_8 == 4) {
          this.carrerLevelProgressValue = 4;
          this.careerlevelvalue = "Senior"
          this.careerlevelvalue_f = 4
        } else {
          this.carrerLevelProgressValue = 1;
          this.careerlevelvalue = "Berufseinsteiger"
          this.careerlevelvalue_f = 1
        }


        // skill_id_list
       /** 
        * @ignore 
        */
        this.title = this.contentProfile.ad_general_info.title
        this.skillLIst = this.contentProfile.skill_id_list;
        this.SkillSeparatedValue = Array.prototype.map.call(this.skillLIst, s => s.sk_id_description).toString();
        this.SkillSeparatedValue = this.SkillSeparatedValue.replace(/,/g, ", ");
        this.colorCode = this.contentProfile.color_info_list;
        let removeDuplication = this.skillLIst.filter((v, i) => this.skillLIst.findIndex(item => item.sk_id_description == v.sk_id_description) === i);
        this.skillLIst = removeDuplication;

        //Sort "Berufsgruppe" so that the main professtion is on index 0 in the list
        let main_skill_id = this.contentProfile.main_skill.main_sk_id
        let index_of_mainskill = this.skillLIst.findIndex((element) => element.sk_id == main_skill_id)
        if (index_of_mainskill != 0){
          let index_for_exchange = 0

          var b = this.skillLIst[index_for_exchange];
          this.skillLIst[index_for_exchange] = this.skillLIst[index_of_mainskill];
          this.skillLIst[index_of_mainskill] = b;
        }
        console.log(this.skillLIst, "SKILLLISTAFTER")
        if (this.skillLIst.length > 7) {
          this.slicedskillLIst = this.skillLIst.slice(1, 8);
          this.HideExpandSkill = true;
          this.HideHalfSkill = false;
        } else if (this.skillLIst.length <= 7) {
          this.slicedskillLIst = this.skillLIst;
          this.HideExpandSkill = false;
          this.HideHalfSkill = false;
        }
        if (this.skillLIst.length == 0) {
          this.professionLengthZero = true;
          this.professionLength = false;
          this.HideSkillTips = false;
        }
        else if (this.skillLIst.length != 0) {
          this.professionLengthZero = false;
          this.professionLength = true;
          this.HideSkillTips = true;
        }

        if (this.slicedcompetency && this.slicedcompetency.length != 0 && this.colorCode.occupation_yellow_flag == 1) {
          this.compeGreen = false;
          this.compeYellow = true;
          this.compeRed = false;
        } else if (this.slicedcompetency && this.slicedcompetency.length != 0 && this.colorCode.occupation_yellow_flag == 0) {
          this.compeGreen = true;
          this.compeYellow = false;
          this.compeRed = false;
        } else if (this.slicedcompetency && this.slicedcompetency.length == 0 && this.colorCode.occupation_yellow_flag == 0) {
          this.compeGreen = false;
          this.compeYellow = false;
          this.compeRed = true;
        }

        if (this.slicedHardSkill && (this.slicedHardSkill.length != 0 || this.slicedSoftSkills.length != 0) && this.colorCode.skills_yellow_flag == 1) {
          this.skillGreen = false;
          this.skillYellow = true;
          this.skillRed = false;
        } else if (this.slicedHardSkill && (this.slicedHardSkill.length != 0 || this.slicedSoftSkills.length != 0) && this.colorCode.skills_yellow_flag == 0) {
          this.skillGreen = true;
          this.skillYellow = false;
          this.skillRed = false;
        } else if (this.slicedHardSkill && (this.slicedHardSkill.length == 0 || this.slicedSoftSkills.length != 0) && this.colorCode.skills_yellow_flag == 0) {
          this.skillGreen = false;
          this.skillYellow = false;
          this.skillRed = true;
        }

        if (this.skillLIst && this.skillLIst.length != 0 && this.colorCode.main_skill_unique_yellow_flag == 1) {
          this.proGreen = false;
          this.proYellow = true;
          this.proRed = false;
        } else if (this.skillLIst && this.skillLIst.length != 0 && this.colorCode.main_skill_unique_yellow_flag == 0) {
          this.proGreen = true;
          this.proYellow = false;
          this.proRed = false;
        } else if (this.skillLIst && this.skillLIst.length == 0 && this.colorCode.main_skill_unique_yellow_flag == 0) {
          this.proGreen = false;
          this.proYellow = false;
          this.proRed = true;
        }

        if (this.contentProfile.career_lvl_description == empty && this.colorCode.career_lvl_yellow_flag == 0) {
          // alert(1)
          this.careerGreen = true;
          this.careerYellow = false;
          this.careerRed = false;
        } else if (this.contentProfile.career_lvl_description == empty && this.colorCode.career_lvl_yellow_flag == 1) {
          // alert(2)
          this.careerGreen = false;
          this.careerYellow = true;
          this.careerRed = false;
        } else if (this.contentProfile.career_lvl_description != empty && this.colorCode.career_lvl_yellow_flag == 0) {
          // alert(3)
          this.careerGreen = false;
          this.careerYellow = false;
          this.careerRed = true;
        }


        if (this.profile_ad == empty && this.colorCode.education_yellow_flag == 0) {
          this.eduGreen = true;
          this.eduYellow = false;
          this.eduRed = false;
        } else if (this.profile_ad == empty && this.colorCode.education_yellow_flag == 1) {
          this.eduGreen = false;
          this.eduYellow = true;
          this.eduRed = false;
        } else if (this.profile_ad != empty && this.colorCode.education_yellow_flag == 0) {
          this.eduGreen = false;
          this.eduYellow = false;
          this.eduRed = true;
        }

        this.backgrounddiv_enable = true;
        this.commonloader = false;


        this.finalload = true

        var identifier = JSON.parse(localStorage.getItem("identifier"))
        var chat_id;
        if (!identifier) {
          if (this.contentProfile.main_skill.main_sk_id) {
            chat_id = this.contentProfile.main_skill.main_sk_id;
            // chat_id = 20012;
          } else {
            chat_id = 12;
            // chat_id = 20012;
          }

          var localvalue = JSON.parse(localStorage.getItem('token'))
          var flag = {
            
            "email": this.userEmail,
            "ad_id": localvalue.ad_id
           
          }
          this.http.commonMethod(flag,this.logonUrl.email_update_adid)
            .subscribe(data => {
              this.commonloader = false;
              this.response = data
              if (this.response.status == 200) {

              } else {

              }
            })
        }

      })

    var defalutvalue = {
      id: 82,
      name: "Germany",
      sortname: "DE"
    }
    if (!this.country || this.country == "") {
      this.country = "Germany"
      defalutvalue.name = this.country
    }
    this.citiesList(defalutvalue);

  }

 

  // button hide and show functions
   /** 
    * @ignore 
    */
  showFullCompetency() {
    this.slicedcompetency = this.finalBullet1;
    this.HideHalfCompatency = true;
    this.HideExpandCompatency = false;
    this.fullcomp = true
  }
   /** 
    * @ignore 
    */
  showHalfCompetency() {
    this.slicedcompetency = this.finalBullet1.slice(1, 8);
    this.HideHalfCompatency = false;
    this.HideExpandCompatency = true;
    this.fullcomp = false
  }
    /** 
     * @ignore 
     */
  showFullHardSkill() {
    if (this.slicedHardSkill && this.slicedHardSkill.length == 7) {
      this.slicedHardSkill = this.finalHardSkils;
      this.HideHalfHardSkill = false;
      this.HideExpandHardSkill = false;
    }
    this.slicedHardSkill = this.finalHardSkils;
    this.HideHalfHardSkill = true;
    this.HideExpandHardSkill = false;
    this.fullcomp = true
  }
   /** 
    * @ignore 
    */
  showHalfHardSkill() {
    this.slicedHardSkill = this.finalHardSkils.slice(1, 8);
    this.HideHalfHardSkill = false;
    this.HideExpandHardSkill = true;
    this.fullcomp = false
  }
    /** 
     * @ignore 
     */
  showFullSoftSkills() {
    if (this.slicedSoftSkills && this.slicedSoftSkills.length == 7) {
      this.slicedSoftSkills = this.finalSoftSkils;
      this.HideHalfSoftSkills = true;
      this.HideExpandSoftSkills = false;
    } else {
      this.slicedSoftSkills = this.finalSoftSkils;
      this.HideHalfSoftSkills = true;
      this.HideExpandSoftSkills = false;
    }
    this.fullsoft = true
  }
   /** 
    * @ignore 
    */
  showHalfSoftSkills() {
    this.slicedSoftSkills = this.finalSoftSkils.slice(1, 8);
    this.HideHalfSoftSkills = false;
    this.HideExpandSoftSkills = true;
    this.fullsoft = false
  }
   /** 
    * @ignore 
    */
  showFullIndustry() {
    if (this.slicedIndustries == 7) {
      this.slicedIndustries = this.finalIndustries;
      this.HideHalfIndustry = false;
      this.HideExpandIndustry = false;
    }
    this.slicedIndustries = this.finalIndustries;
    this.HideHalfIndustry = true;
    this.HideExpandIndustry = false;
    this.fullindustries = true
  }
   /** 
    * @ignore 
    */
  showHalfIndustry() {
    this.slicedIndustries = this.finalIndustries.slice(1, 8);
    this.HideHalfIndustry = false;
    this.HideExpandIndustry = true;
    this.fullsoft = false
  }
     /** 
      * @ignore 
      */
  showExpandSkill() {
    if (this.slicedskillLIst == 7) {
      this.slicedskillLIst = this.skillLIst;
      this.HideExpandSkill = false;
      this.HideHalfSkill = false;
    }
    this.slicedskillLIst = this.skillLIst;
    this.HideExpandSkill = false;
    this.HideHalfSkill = true;
  }
   /** 
    * @ignore 
    */
  showHalfSkill() {
    this.slicedskillLIst = this.skillLIst.slice(1, 8);
    this.HideExpandSkill = true;
    this.HideHalfSkill = false;
  }


   /** 
    * @ignore 
    */
  PrintFunction() {
    this.showFullCompetency();
    this.showFullHardSkill();
    this.showFullSoftSkills();
    this.showFullIndustry();
    this.showExpandSkill();
    this.createFile();
    // this.slicedcompetency = this.finalBullet1;
    // this.slicedSoftSkills = this.finalSoftSkils;
    // this.slicedHardSkill = this.finalHardSkils;
    // this.slicedIndustries = this.finalIndustries;
    // this.slicedskillLIst = this.skillLIst;
  }
  // dailog boxes


   /** 
    * @ignore 
    */
  setvalue(data, i) {

    if (this.remote_value[i].checked == true) {
      this.remote_value[i].checked = false
      this.remotevalue = ""
    } else {
      this.remote_value[i].checked = true
      this.remotevalue = data.value
    }

  }

   /** 
    * @ignore 
    */
  openDialog1() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: false,
      data: { dialogname: 'Registerdetailspopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length != 0) {
        localStorage.setItem("userEmail", result[0].email);
        localStorage.setItem('sessionid', result[0].session);
        this.sessionid = localStorage.getItem('sessionid');
        this.userEmail = localStorage.getItem("userEmail");
        this.isLogged = localStorage.getItem("isLogged");
        this.menuFlag = false;
      }
      else {
        this.menuFlag = true;
      }
    });
  }
   /** 
    * @ignore 
    */
  startTLIchat() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'mat-dialog-container1',
      data: { dialogname: 'linkpopup', forTLIchat: "yes" }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result)
      if (result) {
        var data = {
          
          "ad_id": this.reportMainBody.feedback_status.ad_id,
          "chat_status": true,
          
        }
        this.http.commonMethod(data,this.logonUrl.chat_status_checking)
          .subscribe(res => {
            this.response = res
            if (this.response.status == 200) {
              this.setAdvalues();
              this.feedbackReportData();
              this.currentProfile();
              this.TLI_chat = true;
              //  this.getLinkbtn = true;
              

            } else {

            }
          })
      } else {

      }
    });
  }
   /** 
    * @ignore 
    */
  checkboxfix(value, filed) {
    if (value == "adid") {
      if (this.ad_id == true) {
        this.ad_id = false
      } else {
        this.ad_id = true
      }
    } else if (value == "titlecheck") {
      if (this.titlecheck == true) {
        this.titlecheck = false
      } else {
        this.titlecheck = true
      }
    } else if (value == "citycon") {
      if (this.citycon == true) {
        this.citycon = false
      } else {
        this.citycon = true
      }
    }
  }
   /** 
    * @ignore 
    */
  loginclick() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'Logindetailspopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length != 0) {
        localStorage.setItem("userEmail", result[0].email);
        localStorage.setItem('sessionid', result[0].session);
        this.sessionid = localStorage.getItem('sessionid');
        this.userEmail = localStorage.getItem("userEmail");
        this.isLogged = localStorage.getItem("isLogged");
        this.menuFlag = false;
      } else {
        // this.menuFlag = true;
      }
      this.sessionid = localStorage.getItem('sessionid');
      this.userEmail = localStorage.getItem("userEmail");
      this.isLogged = localStorage.getItem("isLogged");

      if (this.userEmail) {
        this.loginuser = this.userEmail.split('@')[0];
      }
      if (this.isLogged == "true") {
        this.menuFlag1 = true;
      } else {
        this.menuFlag1 = false;
      }

    });
  }
  close(reason: string) {
    this.reason = reason;
    // this.sidenav.close();
  }
    /** 
     * @ignore 
     */
  getLinkdialog() {
    // this.checksession();
    // localStorage.removeItem('linktype');
    this.getlocalvalues();
    this.setAdvalues()
    this.isLogged = localStorage.getItem('isLogged');

    // if (this.ad_data_list.permanent && this.ad_data_list.fulltime && this.remotevalue != "" && this.remotevalue != undefined) {
    if (this.goodProfile) {
      // if (!this.ad_id && !this.titlecheck && !this.citycon) {
      if (this.ad_idValue == "" || this.internal_Reference == "" || this.title == "" || this.location == "" || this.country == "" ||
        this.ad_id == false || this.titlecheck == false || this.citycon == false || this.zip == "") {
        this.secondcheck1();
      } else {
        if (this.checksession) {
          if (this.isLogged != "true") {
            const dialogRef = this.dialog.open(DialogComponent, {
              disableClose: true,
              data: { dialogname: 'loginpopup', goto: 'payment', goto1: "reg" }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
              this.getlocalvalues();
              if (result == "payment") {
                this.gotopayment(result);
              } else if (result == "reg") {
                this.gotopayment(result);
              }
            });
          } else {
            const dialogRef = this.dialog.open(DialogComponent, {
              disableClose: true,
              data: { dialogname: 'confirmpaymentpopup', email: this.userEmail, goto1: "confirmpayment" }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
              this.getlocalvalues();
              this.getLinkbtn = true;
              this.TLI_chat = true;
              localStorage.removeItem('bluecollar')
              this.feedbackReportData();
            });
          }
        }
      }
    } else {
      if (this.bluecollar) {
        this.getlinkNextstep();
      } else {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: true,
          data: {
            dialogname: 'jobdescriptionpopup',
            badProfile: this.badProfile,
            okProfile: this.okProfile,
            goodProfile: this.goodProfile,
            TLI_chat: this.TLI_chat,
            bluecollar1: this.bluecollar,
            forTLIchat: "yes"
          }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result == "chat") {
            this.chatpopup();
          } else if (result == "second") {
            this.getlinkNextstep();
          }
        });
      }
    }
    // } else {
    //   const dialogRef = this.dialog.open(DialogComponent, {
    //     disableClose: false,
    //     data: { dialogname: 'savevalidationpopup' }
    //   });
    //   dialogRef.afterClosed().subscribe((result: any) => {

    //   });

    // }


  }


   /** 
    * @ignore 
    */
  getlinkNextstep() {
    // if (!this.ad_id || !this.titlecheck || !this.citycon) {
    if (this.ad_idValue == "" || this.internal_Reference == "" || this.title == "" || this.location == "" || this.country == ""
      || this.ad_id == false || this.titlecheck == false || this.citycon == false) {
      this.secondcheck1();
    } else {

      if (this.isLogged != null && this.isLogged != undefined) {
        if (this.isLogged != "true") {
          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            data: { dialogname: 'loginpopup', goto: 'payment', goto1: "reg" }
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            this.getlocalvalues();
            if (result == "payment") {
              this.gotopayment(result);
            } else if (result == "reg") {
              this.gotopayment(result);
            }
          });
        } else {
          if (!this.userEmail) {
            this.userEmail = localStorage.getItem('userEmail');
          }
          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            data: { dialogname: 'confirmpaymentpopup', email: this.userEmail }
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            this.getlocalvalues();
            this.getLinkbtn = true;
            this.TLI_chat = true;
            this.feedbackReportData();
          });
        }
      } else {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: true,
          data: { dialogname: 'loginpopup' }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          this.getlocalvalues();
        });
      }
    }
    this.feedbackReportData();
  }

 setAdDataAfterLogin(){
  var setAdData = {
      "permanent": "",
      "fulltime": "",
      "email": localStorage.getItem('userEmail'),
      "city": "",
      "title": "",
      "location": "",
      "date": "",
      "company": "",
      "organisation_id": "111",
      "recruiter_id": localStorage.getItem('recruiter_id'),
      "country_code": "",
      "ad_id": this.accesstoken.ad_id,
      "method": "AUTH",
       "free_text": "",
        "as_company": "",
         "url": "",
       "reference_id": ""
    }

    this.http.commonMethod(setAdData,this.logonUrl.set_ad_data)
      .subscribe(data => {
        console.log(data, "set ad data")
      })
}
  gotopayment(gotovalue) {
    if (gotovalue == "payment") {
       this.setAdDataAfterLogin()
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'confirmpaymentpopup', email: this.userEmail, goto1: "confirmpayment" }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.getlocalvalues();
        

        // this.getLinkbtn = true;
        // this.TLI_chat = true;
        // this.feedbackReportData()
      });
    } else if (gotovalue == "reg") {
       this.setAdDataAfterLogin();
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'confirmpaymentpopup', email: this.userEmail, goto1: "confirmpayment" }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.getlocalvalues();
       
        // this.getLinkbtn = true;
        // this.TLI_chat = true;
        // this.feedbackReportData()
        // console.log(this.getLinkbtn, "3")

      });
    }
    this.feedbackReportData();

  }

  chatpopup() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'mat-dialog-container1',
      data: { dialogname: 'linkpopup', forTLIchat: "yes" }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getlocalvalues();
      // this.getLinkbtn = true;
      // this.TLI_chat = true;
      // this.feedbackReportData();
    });
    this.feedbackReportData();
  }

  secondcheck1() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: false,
      data: { dialogname: 'checkboxpopupFeedback' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      // this.thirdcheck1();
      this.getlocalvalues();
      // this.getLinkbtn = true;
      // this.TLI_chat = true;
      // this.feedbackReportData();
    });
    this.feedbackReportData();
  }

  thirdcheck1() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: false,
      data: { dialogname: 'jobdescriptionpopup' }
      // data: { dialogname: 'linkpopupregister' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      // this.thirdcheck1();
      this.getlocalvalues();
      // this.getLinkbtn = true;
      // this.TLI_chat = true;
      // this.feedbackReportData();
    });
  }
  openDialog3() {
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'confirmpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getlocalvalues();
      this.getLinkbtn = true;
      this.TLI_chat = true;
      this.feedbackReportData();
    });
  }

  demotool() {
    return "{{'TOOLTIP.ID' | translate}}"
  }


  // country, city and zipcode methods

  countriesList() {
    
    this.http.commonMethodGet(this.logonUrl.getCountries)
      .subscribe(data => {
        this.countriesValue = data;
        this.countriesValue = this.countriesValue.countries;
      });
  }

  citiesList(data) {
    var id = data.id;
    this.countryName = data.name;
    this.sortcountryName = data.sortname
    this.cityId = id;

    this.cityFlagvalues = {
      
      "countryid": id
     
    }
    this.http.commonMethod(this.cityFlagvalues,this.logonUrl.getCities)
      .subscribe(data => {
        this.cityvalue = data;
        this.cityvalue = this.cityvalue.cities;
      });
  }

  zipcodeData(data) {
    this.cityName = data
    this.zipFlagvalues = {
     
      "city": this.cityName
      
    }
    this.http.commonMethod(this.zipFlagvalues,this.logonUrl.getZipcodes)
      .subscribe(data => {
        this.zipvalue = data;
        this.zipvalue = this.zipvalue.zipcodes;
      })
  }

  // input box clear methods

  clearDataTitle() {
    if (this.notAvail = "" && this.location == "undefined") {
      this.notAvail = "";
    }
  }

  clearDataLocation() {
    if (this.location == "" && this.location == "undefined") {
      this.location = "";
    }
  }
  clearDataCountry() {
    if (this.country == "" && this.country == "undefined") {
      this.country = "";
    }
  }

  // remove methods

  jobtype(value) {
    if (value == 'partime') {
      this.ad_data_list.fulltime = '3'
    } else if (value == 'fulltime') {
      this.ad_data_list.fulltime = '1'
    } else if (value == 'intern') {
      this.ad_data_list.fulltime = '2'
    } else if (value == 'permanent') {
      this.ad_data_list.permanent = '1'
    } else if (value == 'contract') {
      this.ad_data_list.permanent = '2'
    }
    this.setAdvalues();
    // this.set_report("value", "index", "bullet_id", "data", "type", "")
  }

//toCheck
  savefunction(value) {
    this.getlocalvalues()

    // if (this.ad_data_list.permanent && this.ad_data_list.fulltime && this.remotevalue != "" && this.remotevalue != undefined) {
    if (value == "save") {
      if (this.isLogged == "false") {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: false,
          data: { dialogname: 'loginpopup' }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
           this.setAdDataAfterLogin()
        });
      } else {
        var data = {
         
          "ad_id": this.reportMainBody.feedback_status.ad_id
         
        }
        this.http.commonMethod(data,this.logonUrl.ad_status_checking)
          .subscribe(res => {
            this.response = res
            if (this.response.status == 200) {
              this.setAdvalues();
              this.feedbackReportData();
              const dialogRef = this.dialog.open(DialogComponent, {
                disableClose: false,
                data: { dialogname: 'datasavedpopup' }
              });
              dialogRef.afterClosed().subscribe((result: any) => {

              });
            } else {

            }
          })
      }
    } else if (value == "print") {
      if (this.isLogged == "false") {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: false,
          data: { dialogname: 'loginpopup' }
        });
        dialogRef.afterClosed().subscribe((result: any) => {

        });
      } else {
        this.PrintFunction();
      }
    }
  }

  seteducation(value){
    //[{ id: 1, value: 'Schulbildung' }, { id: 2, value: 'Lehrberuf' }, { id: 3, value: 'Studium' }, { id: 4, value: 'weiterführendes Studium' }];
    switch(value) { 
     case 'Schulbildung': { 
      this.newEdu = 1 
      break; 
      } 
     case 'Lehrberuf': { 
      this.newEdu = 2  
      break; 
      } 
     case 'Studium': { 
      this.newEdu = 3  
      break; 
     } 
     case 'weiterführendes Studium': { 
      this.newEdu = 4 
      break; 
    } 
     default: { 
      this.newEdu = 1  
       break; 
    } 
   } 
    this.set_report('profile_ad', "", "", "", "", "")
    this.profile_name = this.profile_ad_values[this.newEdu-1].value
    window.location.reload(); // Needs to reload the full page to show the new Value
  }

  // set report methods
  set_report1(event) {
    this.internal_Reference = event.target.value

    this.accesstoken = JSON.parse(localStorage.getItem('token'));
    this.accesstoken.internal_reference = this.internal_Reference
    localStorage.setItem("token", JSON.stringify(this.accesstoken));
    this.set_report("", "", "", "", "", "")
  }

  set_report(value, index, bullet_id, data, type, option) {
    var req = {};
    var ad_data_list = {};
    var ad_profile_values = {};
    var content_ad = {};
    this.request_updatefeedback = {
      
      "method": "AUTH",
      "document_type": "AD",
      "ad_id": this.ad_idValue,
      "internal_reference": this.internal_Reference,
      "segment_list": {
      }
    }
    if (value == 'ad_data_list') {
      ad_data_list = {
        "title": this.title,
        // "zip": this.zipcode1,
        "location": this.location,
        "country": this.country,
        "country_code": this.sortcountryName.toLowerCase(),
        "permanent": this.ad_data_list.permanent,
        "fulltime": this.ad_data_list.fulltime,
        // "recruiter_id": this.recruiter_id
      }
      this.request_updatefeedback.segment_list.ad_data_list = ad_data_list

    } else if (value == 'ad_profile_values') {
      for(var i = 0; i<this.skillset_id.length ;i++){
        ad_profile_values[this.skillset_id[i]]={krit_8_wert: this.careerlevelvalue_f,skillset_id:this.skillset_id[i]}
      }
      this.request_updatefeedback.segment_list.ad_profile_values = ad_profile_values
      console.log("this.request_updatefeedback.segment_list.ad_profile_values", this.request_updatefeedback.segment_list.ad_profile_values)

    } else if (value == 'content_ad') {
      if (data) {
        var comp;
        if (type == "competency") {
          comp = this.originalcompetency.filter(item => item.expression.toLowerCase() == data.expression.toLowerCase());
        } else if (type == "hardskill") {
          comp = this.originalHardSkils.filter(item => item.expression.toLowerCase() == data.expression.toLowerCase());
        } else if (type == "softskill") {
          comp = this.originalSoftSkils.filter(item => item.expression.toLowerCase() == data.expression.toLowerCase());
        } else if (type == "industries") {
          comp = this.originalIndustries.filter(item => item.expression.toLowerCase() == data.expression.toLowerCase());
        }
        content_ad[data.bullet_id] = comp
      }
      this.request_updatefeedback.segment_list.content_ad = content_ad
    } else if (value == 'profile_ad'){
      ad_profile_values[12]={krit_10_wert: this.newEdu, skillset_id:12}
      this.request_updatefeedback.segment_list.ad_profile_values=ad_profile_values
    }
    console.log("this.request_updatefeedback", this.request_updatefeedback)
    this.http.commonMethod(this.request_updatefeedback,this.logonUrl.set_report)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {
          this.setAdvalues();
          this.feedbackReportData();

        } else {

        }
      })
  }


  removecompetency(i) {
    this.slicedcompetency = this.finalBullet1;
    if (this.slicedcompetency.length < 5) {
      this.HideExpandCompatency = false;
      this.HideHalfCompatency = false;
    }
    if (this.slicedcompetency.length == 1) {
      this.competencyLength = false;
      this.competencyLengthZero = true;
    } else if (this.slicedcompetency.length > 1) {
      this.competencyLength = true;
      this.competencyLengthZero = false;
      var slicedcompetency1 = this.slicedcompetency.splice(i, 1);
      var uid = slicedcompetency1[0].uid;
      var keys = Object.keys(this.content_ad);
      var len = keys;
      Object.keys(this.content_ad).map(key => {
        this.content_ad[key].map((x, index) => {
          if (x.uid == uid) {
            this.content_ad[key].splice(index, 1)
          }
        });
      });

      // if(this.ad_data_list)


      var req = {
        // "flag": "UPDATE_FEEDBACK",
        // "clientid": "1",
        "email": "test@gmail.com",
        "accesstoken": this.access_token_getFeedback,
        "doctype": "AD",
        "adid": this.accesstoken.ad_id,
        "segment_list": {
          "content_ad": this.content_ad,
          "ad_data_list": {
            "title": this.title,
            "zip": this.selectedZipValue,
            "location": this.cityName,
            "company": "Test company",
            "url": "https:\\testlink-to-job-url",
            "country": this.countryName,
            "sector": "IT",
            "logo_url": "https:\\testlink_to_logo",
            "bewerbungs_email": "test@apply.de",
            "permanent": this.ad_data_list.permanent,
            "fulltime": this.ad_data_list.fulltime,
            "free_text": "a new note",
            "country_code": this.sortcountryName

          }
        },
        "deviceid": "",
        "osid": "",
        "sessionid": ""
      }
      this.http.commonMethod(req,this.logonUrl.set_report)
        .subscribe(data => {
        })
    }
  }


  getValues(title, event) {
    var event_value = event;
    if (title == "title") {
      this.title = event_value;
    } else if (title == "city") {
      this.location = event_value;
    } else if (title == "country") {
      this.country = event_value
    }
    // this.setAdvalues();
  }


  setAdvalues() {
    this.setAdValueList = {
      "permanent": this.ad_data_list.permanent,
      "fulltime": this.ad_data_list.fulltime,
      "email": "",
      "city": this.location,
      "title": this.title,
      "location": this.zip,
      "date": this.ad_data_list.date,
      "company": "",
      "organisation_id": "111",
      "recruiter_id": this.recruiter_id,
      "country_code": this.sortcountryName.toLowerCase(),
      "ad_id": this.accesstoken.ad_id,
      "method": "AUTH",
       "free_text": "",
        "as_company": "",
         "url": "",
       "reference_id": ""
    }

    this.http.commonMethod(this.setAdValueList,this.logonUrl.set_ad_data)
      .subscribe(data => {
        console.log(data, "set ad data")
      })
  }

  zipCodeCountry(zip) {
    this.zipCodebasedCountry = {
     
      "zipcode": zip,
      "name": "Germany"
    }
    this.zipcode = zip
    console.log("Get_ZipCity",this.zipCodebasedCountry);
    this.http.commonMethod(this.zipCodebasedCountry,this.logonUrl.getZipCity)
      .subscribe(data => {
        this.zipcodedata = data
        if (this.zipcodedata) {
          if (this.zipcodedata.city) {
            this.location = this.zipcodedata.city[0].cityname
            this.country = this.zipcodedata.city[0].countyname
            this.zipcode = zip
            this.zipcode1 = zip
          }
        }

      })
  }
  checkDataAvail() {
    this.country = "";
  }



  getlinkeasyapply() {
    var flagdata = {
      "flag": "GET_CV_URL",
      "profileid": "1",
      "email": this.userEmail,
      "clientadid": this.accesstoken.ad_id,
      "addesc": "android jd",
      "deviceid": "",
      "osid": "",
      "sessionid": this.sessionid
    }
    this.http.commonMethod(flagdata,this.logonUrl.get_cv_url)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200) {
          this.url = this.response.res.URL;
          this.sidenav.close();

          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            data: { dialogname: 'linkpopupregister', url: this.url }
          });
          dialogRef.afterClosed().subscribe((result: any) => {

          });
        } else {

        }
      })
  }

  logout() {
    var data = {
     
      "email": this.userEmail,
      "sessionid": this.sessionid
     
    }
    this.http.commonMethod(data,this.logonUrl.logout)
      .subscribe(data => {
        if (data != null) {
          var details: any = data;
          if (details.status == 200) {
            // this.MailMessage=details.message;
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.alertmsg(details.message, 'success');
            this.menuFlag = true;
            this.router.navigate(['/']);
          } else {
            if (details.message === "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.") {
              this.alertmsg(details.message, 'error')
              this.menuFlag = false;
              localStorage.setItem("isLogged", "false")
              localStorage.setItem("userEmail", "")
              localStorage.setItem("sessionid", "");
              this.menuFlag = true;
            } else {

              this.alertmsg(details.message, 'error')
              this.menuFlag = false;
            }
          }
          this.sidenav.close();
        }

        else {

        }
      }, error => {

      });
  }
  alertmsg(msg, errtype) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: { data: msg, classname: errtype },
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'blue-snackbar'
    });
  }
  checkingButton() {
    this.checked = false;
    this.rule1 = true
  }
  changepasswords() {
    // this.checksession();
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'changepasswordpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }
  zipdata(value) {
    this.selectedZipValue = value;
    this.zipcode1 = value
  }

  setzip(data) {
    console.log("exat Value", data)
    this.zip = data.target.value
  }
 


  createFile() {
   
    var data = document.getElementById('foo');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      console.log(heightLeft);

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('Admin.pdf'); // Generated PDF   
    });

  }

}
