//Candiate Match Report Page
//Radar Chart and Bar Chart for Candidate Mapping
import { Component, OnInit } from '@angular/core';
import { RadialChartOptions, ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { DialogComponent } from '../Dialog/Dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { sample } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SpeechRecognitionService } from '../CV_Service.service';
import { Router, ActivatedRoute, Params } from "@angular/router";
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { EncryptionService } from '../Services/encryption.service';
import { HttpserviceService } from '../Services/httpservice.service';
import 'nvd3';
import {urlEnv} from '../../environments/env';

/**
 * Page for Candidate Match Report,User Can View the Radar Chart and Pie chart based on the Api Mapping
 */
@Component({
    selector: 'app-match-report',
    templateUrl: './Candidate_Chart-Report.component.html',
    styleUrls: ['./Candidate_Chart-Report.component.css']
})
export class MatchReportComponent implements OnInit {
    public logonUrl = urlEnv;

    /**
    * @ignore
    */
    public radarChartOptions: RadialChartOptions = {
        responsive: true,
        scales: {
            // borderColor: red,
        },
    };
    adProfileChart: any;
    cvProfileChart: any;
    canditateTitle: any;
    getReportData: any;
    cvProfileValueData: any;
    sample1: any;
    cvProfileValueTitle: any;
    getReportDataAd: any;
    adProfileValueTitle: any;
    adProfileValueData: any;
    matchingProfie: any;
    informationDesc1: any;
    informationDesc2: any;
    jobTitle: any;
    obj: any;
    barChartPlugins: any;
    companyData: object;
    companyDataLogo: any;
    Logo: object;
    companyName: any;
    welcomeText: any;
    openTermsConditionsCV: any;
    directApply: boolean;
    EasyApply: boolean;
    param1: any;
    param2: any;

   /**
    * @ignore
    */
    public barChartOptions: ChartOptions = {
        responsive: true,

        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            xAxes: [{
                ticks: {
                    // display:true,
                    beginAtZero: true
                },

                gridLines: {

                    // color: "",
                    display: false,

                }
            }],
            yAxes: [{

                ticks: {
                    display: true,
                    beginAtZero: true
                },
                gridLines: {
                    // display: true,
                    display: false,
                    // color: "2f99af",

                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',

            }
        }
    };
    public barChartLabels: Label[];
    public barChartType: ChartType = 'horizontalBar';
    public barChartLegend = true;

    /**
     * @ignore
     */
    public barChartData: ChartDataSets[] =

        [
            {
                data: [], label: 'AD', borderColor: ['rgba(47, 153, 175, 0.7)'], backgroundColor: ['rgba(47, 153, 175, 0.7)']
            },
        ];
    public randomize(): void {
        // Only Change 3 values
        const data = [
            Math.round(Math.random() * 100),
            59,
            80,
            (Math.random() * 100),
            56,
            (Math.random() * 100),
            40];
        this.barChartData[0].data = data;
    }
    // Bar chart Data Ends Here

    /**
     * @ignore
     */
    public radarChartLabels: Label[];
    // public radarChartData: ChartDataSets[];
    public radarChartData: ChartDataSets[] =
        [
            {
                data: [], label: 'AD', borderColor: ['rgba(47, 153, 175, 0.7)'], backgroundColor: ['rgba(47, 153, 175, 0.7)']
            },
        ];
    public radarChartType: ChartType = 'radar';
    //Radar Chart Data Ends Here  

    /**
     * @ignore
     */
    public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
        console.log(event, active);
    }

    public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
        console.log(event, active);
    }

    constructor(private activatedRoute: ActivatedRoute, public dialog: MatDialog, public translate: TranslateService, private speechRecognitionService: SpeechRecognitionService, private router: Router, public encrypt: EncryptionService, private http: HttpserviceService) {
        var lang = localStorage.getItem('language')
        translate.addLangs(['en', 'de']);
        if (lang) {
            translate.setDefaultLang(lang);
            translate.use(lang);
        } else {
            translate.setDefaultLang('de');
            translate.use('de');
        }
    }

    /** fuction Desc
     *  Init function Starts Here  
     */
    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            console.log(params);
            this.param1 = params['dt'];
            this.param2 = params['type'];
            console.log(this.param1);
            console.log(this.param2);
            if (this.param1 == undefined || this.param2 == undefined) {
                this.router.navigate(['']);
            }
        });
        let type = localStorage.getItem("applytype");
        if (type == 'directapply') {
            this.directApply = true;
        }
        else {
            this.EasyApply = true;
        }
        this.getJobTitle();
        this.getMatchReportContentCV();
        this.companyDetails();
    }
    //End of Init

    /** 
     *  company Loago Detais Starts here
     */
    companyDetails() {
        let urlAdid = sessionStorage.getItem("UrlDecryptedAdId");
        let UrlDecryptedRecruiterId = sessionStorage.getItem("UrlDecryptedRecruiterId");
        var companyData;
        if (this.directApply) {
            companyData = {
                "flag": "GET_COMPANY_LOGO",
                "adid": urlAdid,
                "recruiter_id": UrlDecryptedRecruiterId
            }
        } else {
            companyData = {
                "flag": "GET_COMPANY_LOGO",
                "cvid": urlAdid,
                "recruiter_id": UrlDecryptedRecruiterId
            }
        }
        this.http.commonMethod(companyData,this.logonUrl.get_company_logo)
            .subscribe(data => {

                this.companyData = data;

                console.log("Data", this.companyData);

                this.companyDataLogo = this.companyData;
                this.Logo = this.companyDataLogo.res.status[0].logo;
                this.companyName = this.companyDataLogo.res.status[0].company_name;
                this.openTermsConditionsCV = this.companyDataLogo.res.status[0].terms_conditions;
                this.welcomeText = this.companyDataLogo.res.status[0].welcome_text;
                console.log(this.companyDataLogo.res.status[0].logo);

            });
    }
    //End Here Logo

    /**
     * @ignore
     */
    
    getMatchReportContentCV() {
        var cvId = sessionStorage.getItem('cvId');
        let urlAdid = sessionStorage.getItem("UrlDecryptedAdId");
        var req = {
             "method": "AUTH",
    "ad_id": urlAdid,
    "cv_id": cvId,
    "document_type": "AD",
    "report": "customer",
    "output": "data",
    "email": "",
    "e_recruiter": true
           
        }
       
        this.speechRecognitionService.setCvData(req).subscribe(response => {
            console.log("getMatchReport", response);
            this.getReportData = response;

            this.adProfileValueData = response.res.ad_profile_values.profil_info;
            this.adProfileValueTitle = response.res.ad_profile_values.profil_info;
            this.cvProfileValueData = response.res.cv_profile_values.profil_info;
            this.cvProfileValueTitle = response.res.cv_profile_values.profil_info;
            this.canditateTitle = response.res.matching_overview_neutral.matching_color;
            if (response.res.read_quality != null) {
                this.informationDesc1 = response.res.read_quality.quality_list.document_berufsgruppe_unique;
                this.informationDesc2 = response.res.read_quality.quality_list.document_conflicts.meldung;
            }
            
            //If CV valuesis Not available
            
            //Dynamic Data
            else {
                for (var i = 0; i < this.cvProfileValueData.length; i++) {
                    if (this.cvProfileValueData[i].skillset_id == '12') {
                        var candidateData = this.cvProfileValueData[i];
                    }
                }

                console.log("candidateData",candidateData);
                for (var i = 0; i < this.adProfileValueData.length; i++) {
                    if (this.adProfileValueData[i].skillset_id == '12') {
                        var recruiterData = this.adProfileValueData[i];
                    }
                }
                
                console.log("recruiterData",recruiterData);
                this.barChartLabels = [candidateData.titel_krit_1, candidateData.titel_krit_2, candidateData.titel_krit_3, candidateData.titel_krit_4, candidateData.titel_krit_9];
                // RadaChart Labels starts Here
                this.radarChartLabels = [candidateData.titel_krit_1, candidateData.titel_krit_2, candidateData.titel_krit_3, candidateData.titel_krit_4, candidateData.titel_krit_9];
                // BarChart Labels starts Here
                this.radarChartData =
                    [

                        {
                            data: [recruiterData.krit_1_wert, recruiterData.krit_2_wert,
                                recruiterData.krit_3_wert,
                                recruiterData.krit_4_wert,recruiterData.krit_9_wert], label: 'AD', borderColor: ['rgba(47, 153, 175, 0.7)'], backgroundColor: ['rgba(47, 153, 175, 0.7)']
                        },
                        {
                            data: [candidateData.krit_1_wert, candidateData.krit_2_wert,
                            candidateData.krit_3_wert,
                            candidateData.krit_4_wert, candidateData.krit_9_wert], label: 'CV', borderColor: ['rgba(39, 128, 146, 0.7)'], backgroundColor: ['rgba(39, 128, 146, 0.7)']
                        },
                    ];

                this.barChartData =

                    [

                        {
                            backgroundColor: 'rgba(47, 153, 175, 0.7)',
                            borderColor: 'rgba(47, 153, 175, 0.7)',
                            // borderWidth: 5,
                            data: [recruiterData.krit_1_wert, recruiterData.krit_2_wert,
                                recruiterData.krit_3_wert,
                                recruiterData.krit_4_wert, recruiterData.krit_9_wert], label: 'AD'
                        },


                        {
                            backgroundColor: 'rgba(39, 128, 146, 0.7)',
                            borderColor: 'rgba(39, 128, 146, 0.7)', data: [candidateData.krit_1_wert, candidateData.krit_2_wert,
                            candidateData.krit_3_wert,
                            candidateData.krit_4_wert, candidateData.krit_9_wert], label: 'CV'
                        }

                    ];
            }

        }),

            error => {
                console.log("AD", error)
            }
    }

    /**
     * @ignore
     */
    getJobTitle() {
        let adid = sessionStorage.getItem("UrlDecryptedAdId");
        var getJobTitle = {
            
            "method": "AUTH",
            "id_list": "",
            "ad_id":adid
        }
        console.log(getJobTitle);
        this.speechRecognitionService.getJobTitle(getJobTitle).subscribe(data => {
            console.log("jobtitle", data);
            if (data.status == '422') {
                console.log("job Title Not Available");
                this.jobTitle = "UX Manager";
            } else {
                this.jobTitle = data.res.message[0].title;
            }

        }
        ),
            error => {
                console.log(error)
            }
    }
     /**
      * @ignore
      */
    candidataClose() {
        const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: false,
            data: { dialogname: 'candidateClose' },

        });
        dialogRef.afterClosed().subscribe((result: any) => {

        });
    }
     /**
      * One to one mAtch Starts Here
      */
    getOnetoOneMatch() {
        var cvIdNmatch = sessionStorage.getItem('cvId');
        let adidNmatch = sessionStorage.getItem("UrlDecryptedAdId");
        this.obj = {};
        this.obj.flag = "1_TO_1_MATCH";
        this.obj.clientid = "1";
        this.obj.adidNmatch = adidNmatch;
        this.obj.cvIdNmatch = cvIdNmatch;
        this.obj.matching_filter_list = [];
        this.obj.db_state = "1";
        this.obj.detail_info = "0";
        this.obj.deviceid = "";
        this.obj.osid = "";
        this.speechRecognitionService.getOneToMatch(this.obj).subscribe(data => {
            console.log("1-to-end Match", data)
        },
            error => console.log(error)
        )
    }
    //One to one mAtch Ends Here
    //by Balaji
    setStateCandidate() {
        this.getOnetoOneMatch();
        let cv_id1 = sessionStorage.getItem("cvId");
        let urlAdid1 = sessionStorage.getItem("UrlDecryptedAdId");
        var setStateValues =
        {
            
            "method": "AUTH",
            "ad_id": urlAdid1,
            "cv_id": cv_id1,
            "state_list": [{
                "ad_id": urlAdid1,
                "cv_id": cv_id1,
                "state": 70
            }]
           
        }
        this.http.commonMethod(setStateValues,this.logonUrl.set_state).subscribe(data => {
            console.log("SetStateCandidate", data);
        },
            error => console.log(error)
        )
        let cv_id = sessionStorage.getItem("cvId");
        let urlAdid = sessionStorage.getItem("UrlDecryptedAdId");
        let UrlDecryptedRecruiterId = sessionStorage.getItem("UrlDecryptedRecruiterId");
        this.obj = {};
      
        this.obj.method ="AUTH";
        this.obj.cv_id = cv_id;
        this.obj.ad_id = urlAdid;
        this.obj.recruiter_id = UrlDecryptedRecruiterId;
        this.obj.organisation_id = "544";
        
        this.speechRecognitionService.setProtectCandidate(this.obj).subscribe(data => {
            console.log("SetProtectCandidate", data);

            const dialogRef = this.dialog.open(DialogComponent, {

                data: { dialogname: 'goSuccessFlag', forSuccessPage: 'yes' },
            });
            dialogRef.afterClosed().subscribe(() => {
            });


        },
            error => console.log(error)
        )

    }


}
