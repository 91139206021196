// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/**
 * Page for Production Environement
 */
export const environment = {
  production: true,
  //**********LOGON PROD************/
  
  // server_url: "https://betterheads.io"
  // server_url: "https://development.betterheads.io/betterheadsnew"
  // server_url: "https://beta.betterheads.io"
  // server_url: "https://integration.betterheads.io/betterheadsnew"
  //server_url: "http://betterheads-dev.insinno.de:8080/betterheadsnew"
  //server_url: "https://backend.talk-n-job.de/betterheadsnew",
  server_url: "https://backend.talk-n-job.de/betterheadsnew",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
