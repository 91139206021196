import { Component } from '@angular/core';
import { AnimationModule } from './animation/animation.module';
import { fadeAnimation } from './animations';
import { HeaderComponent } from './Common_Header/Common_Header.component';
/**
 * Main Page for Declare all Predefined Functionlity here
 */
declare let L;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeAnimation],
  providers:[HeaderComponent]
})


export class AppComponent {


  constructor(
    public header: HeaderComponent,
  ) {  }

  ngOnInit() {
  };
  title = 'BetterHeads';

  closenav() {
    console.log("sdf")
    this.header.demo()
  }
}
