// *Cockpit Show the Admin List of Direct Apply ,Easy apply and Easy Recruiter
import { Component, OnInit } from '@angular/core';
/**
 * Page for Recruiter All Admin Show
 */
@Component({
  selector: 'app-cockpit',
  templateUrl: './Cockpit.component.html',
  styleUrls: ['./Cockpit.component.css']
})
export class CockpitComponent implements OnInit {
  /**
   * @ignore
   */
  constructor() { }

  commonloader: false
  /**
   * @ignore
   */
  ngOnInit() {
  }
  /**
 * Go to Ercrutier Functionality
 */
  goToErecruiter() {
    localStorage.setItem("CockpitAdmin", 'cockpit')
    localStorage.removeItem("recruiterFeedback");
  }

}
