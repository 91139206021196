import { Component, OnInit, Injectable, ViewEncapsulation, ViewChild, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../Dialog/Dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { FileQueueObject, FileUploaderService } from '../fileupload.service';
import { Observable } from 'rxjs';
import { HttpserviceService } from '../Services/httpservice.service';
import { sample } from 'rxjs/operators';
import { Router } from "@angular/router";
import { FormControl, FormGroup, FormGroupDirective, FormBuilder, NgForm, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertComponent } from '../Common_Alert/Common_Alert.component';
import { __await } from 'tslib';
import { ScrollToAnimationEasing, ScrollToService, ScrollToConfigOptions, ScrollToOffsetMap } from '@nicky-lenaers/ngx-scroll-to';
import PerfectScrollbar from 'perfect-scrollbar';
import {urlEnv} from '../../environments/env';

@Component({
  selector: 'app-landing-page',
  templateUrl: './Old_Landing_Page.component.html',
  styleUrls: ['./Old_Landing_Page.component.css'],
})
@Injectable({
  providedIn: 'root'
})
export class LandingPageComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  @Output() onCompleteItem = new EventEmitter();
  @ViewChild('fileInput') fileInput;


public logonUrl = urlEnv;

  queue: Observable<FileQueueObject[]>;

  fileipload: FormGroup;
  eassyapply: FormGroup;

  commonloader: boolean = false;
  menuFlag = false;
  reason = '';
  fileuploadFlag = false;
  filename = "";
  uploadfile = [];

  fileType: any;
  public baseURL: any;
  langen: boolean = true;
  response: any;
  sdsa: object;
  checked = true;
  withdoc = false;
  localvalue: any;
  getlogindetails: any;
  sessionid: string;
  isLogged: any;
  userEmail: any;
  withoutdoc: boolean = true;
  accesstoken: any;
  url: any;
  profilepush: any;
  loginuser: any;
  recruiterid: string;
  sessionvar: any;
  menusessionvar: any;
  public ngxScrollToDestination: string;
  // public ngxScrollToEvent: ScrollToEvent;
  public ngxScrollToDuration: number;
  public ngxScrollToEasing: ScrollToAnimationEasing;
  public ngxScrollToOffset: number;
  getLinkbtn: boolean;
  TLI_chat: boolean;
  badProfile: any;
  okProfile: any;

  setAdValueList: any;
  access_token_getFeedback: any;
  ad_data_list: any;
  location: any;
  title: any;
  date: any;
  country: any;
  menuFlag1: boolean;
  scrHeight: any;
  scrWidth: any;

  withouteassy: boolean = true;
  withdoceassy: boolean = false;
  profilepusheassy: any;
  id: any;

  constructor(private _scrollToService: ScrollToService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public uploader: FileUploaderService,
    public service: HttpserviceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
  ) {

    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
    }

    this.eassyapply = this.formBuilder.group({
      withdoceassy: ['', [Validators.required]],
      withouteassy: ['',],
      profilepusheassy: ['',]
    });
  }
  triggerScrollTo() {
    // alert();


    const config: ScrollToConfigOptions = {

      target: 'easy',
      offset: -200
    };

    const ps = new PerfectScrollbar('#scroller', {
      wheelSpeed: 1,
      wheelPropagation: true,
      minScrollbarLength: 20,
      swipeEasing: true
    });

    // this._scrollToService.scrollTo(config);
  }
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  // DirectApplyScroll($element): void {


  // }
  ngOnInit() {

    this.queue = this.uploader.queue;
    this.uploader.onCompleteItem = this.completeItem;
    this.fileipload = this.formBuilder.group({
      withoutdoc: ['', [Validators.required]],
      withdoc: ['', [Validators.required]],
      profilepush: ['',],
      withdoceassy: ['', [Validators.required]],
      withouteassy: ['', [Validators.required]],
      profilepusheassy: ['', [Validators.required]]
    });
    // console.log(this.fileipload);
    if(localStorage.getItem('token')!="")
    this.accesstoken = JSON.parse(localStorage.getItem('token'));

    this.fileipload.value.withoutdoc = true;
    // this.fileipload.value.withoutdoc=true;
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    this.recruiterid = localStorage.getItem('recruiter_id');
    if (this.isLogged == "true") {
      this.menuFlag = false
    } else {
      this.menuFlag = true
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail;
    }
    // this.checksession()

    this.getScreenSize();
    this.id = setInterval(() => {
      this.getlocalvalues();
    }, 2000);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth,"dedede");
  }


  close1(reason: string) {
    this.reason = reason;
  }

  checksession() {
    var test = this.isLogged
    var data = {
    
        "email": this.userEmail,
        "sessionid": this.sessionid
       
    }
    this.service.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          this.sessionvar = res
          if (this.sessionvar.status == 200) {
          } else if (this.sessionvar.status == 422) {
            this.loginuser = ""
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.isLogged = localStorage.getItem('isLogged');
            this.sessionid = localStorage.getItem('sessionid');
            this.userEmail = localStorage.getItem('userEmail');
            this.loginuser = ""
            this.menuFlag1 = false;
            this.menuFlag = true;
            if (test == "true") {
              this.alertmsg(this.sessionvar.message, 'error');
            }
            this.menuFlag = true;
            this.router.navigate(['/'])
          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');
      });
  }

  menuclicksessioncheck(value) {
    var data = {
     
        "email": this.userEmail,
        "sessionid": this.sessionid
       
    }
    this.service.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          this.sessionvar = res
          if (this.sessionvar.status == 200) {
            if (value == "changepassword") {
              this.changepasswords()
            } else if (value == "easyapply") {
              this.router.navigate(['/cvadmin'])
            } else if (value == "admin") {
              this.router.navigate(['/daadmin'])
            } else if (value == "directapply") {
              this.router.navigate(['/'])
            } else if (value == "eassyrecruiter") {
              this.router.navigate(['/erecruiterlanding'])
            }
          } else if (this.sessionvar.status == 422) {
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.isLogged = localStorage.getItem('isLogged');
            this.sessionid = localStorage.getItem('sessionid');
            this.userEmail = localStorage.getItem('userEmail');
            this.loginuser = ""
            this.menuFlag = true;
            this.alertmsg(this.sessionvar.message, 'error');
            this.menuFlag = true;
            this.router.navigate(['/'])
          }
        } else {
          console.log("error")
        }
      }, error => {

      });

  }
  menuclick() {
    if (this.scrWidth >= 600) {
      this.router.navigate(['/pricing'])
    } else {
      this.openmobilepopup()
    }
  }
  openmobilepopup() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: false,
      data: { dialogname: 'mobileview' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }


  getlocalvalues() {
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    if (this.isLogged == "true") {
      this.menuFlag = false;
    } else {
      this.menuFlag = true;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail;
    }
  }

  loginclick() {
    if (this.scrWidth >= 600) {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'Logindetailspopup' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.length != 0) {
          localStorage.setItem("userEmail", result[0].email);
          localStorage.setItem('sessionid', result[0].session);
          this.sessionid = localStorage.getItem('sessionid');
          this.userEmail = localStorage.getItem("userEmail");
          this.isLogged = localStorage.getItem('isLogged');
          this.menuFlag = false;
        } else {
          this.menuFlag = true;
        }
        if (this.userEmail) {
          this.loginuser = this.userEmail;
        }

      });
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'mobileview' },
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }

  }

  // close(reason: string) {
  //   this.reason = reason;
  //   this.sidenav.close();
  // }

  completeItem = (item: FileQueueObject, response: any) => {
    this.onCompleteItem.emit({ item, response });
  }

  languageSelector(val) {
    localStorage.setItem('language', val)
    if (val == "de") {
      this.langen = true;
    } else {
      this.langen = false;
    }
  }

  openDialog1() {
    if (this.scrWidth >= 600) {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'Registerdetailspopup' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    } else {
      this.openmobilepopup()
    }
  }

  openuploadedialog() {
    localStorage.setItem('linktype', 'false');
    if (this.scrWidth >= 600) {
      localStorage.removeItem('bluecollar')
      if (this.fileipload.value.withoutdoc || this.fileipload.value.withdoc || this.fileipload.value.profilepush) {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: false,
          data: { dialogname: 'fileuploaddirectapplypopup' },
        });
        dialogRef.afterClosed().subscribe((result: any) => {

        });
      } else {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: false,
          data: { dialogname: 'checkboxpopup' },
        });
        dialogRef.afterClosed().subscribe((result: any) => {

        });
      }
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'mobileview' },
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }

  }

  setname() {
    const fileBrowser = this.fileInput.nativeElement;
    this.uploader.addToQueue(fileBrowser.files);
    let name = '';
    name = fileBrowser.files[0].name;
    this.filename = name
    if (this.filename) {
      this.fileuploadFlag = true
    }
  }

  addToQueue() {
    if (this.fileipload.value.withoutdoc || this.fileipload.value.withdoc || this.fileipload.value.profilepush) {
      const fileBrowser = this.fileInput.nativeElement;
      if (fileBrowser.files.length < 1) {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: false,
          data: { dialogname: 'fileuploadpopup' },
        });
        dialogRef.afterClosed().subscribe((result: any) => {

        });
      } else {
        if (fileBrowser.files[0] && fileBrowser.files[0].size < 10000000) {
          this.uploader.addToQueue(fileBrowser.files);
          let name = '';
          name = fileBrowser.files[0].name;
          this.filename = name
          if (this.filename) {
            this.fileuploadFlag = true
          }
          if (name) {
            this.fileType = name.split(".")[1];
          }
          let me = this;
          let file = fileBrowser.files[0];
          let reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = function () {
            // console.log(reader.result);
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
          reader.onload = this._handleReaderLoaded.bind(this);
        } else {
          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: false,
            data: { dialogname: 'fileuploadlimitpopup' },
          });
          dialogRef.afterClosed().subscribe((result: any) => {

          });
        }
      }
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'checkboxpopup' },
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }
  }


  _handleReaderLoaded(e) {
    this.commonloader = true;
    let reader = e.target;
    var test = reader.result;
    var test2 = test.replace(/\s/g, "");
    test2 = test.replace(/[+]/g, "-");
    if (test2) {
      test2 = test2.split("base64,")[1];
    }
    const obj = {
     
              "method": "AUTH",
              "base64": test2,
              "document_type": "AD",
              "extension": this.fileType,
              "link": "",
              "output": "JSON",
              "engine": "RAW",
              "read_only": "0",
              "read_nlp": "0",
              "info_only": "0",
              "white_collar": false
    }
    this.commonloader = true;
    this.service.commonMethod(obj,this.logonUrl.get_profile)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {
          this.localvalue = {
            access_token: this.response.res.message.access_token,
            ad_berufsgruppe: this.response.res.message.ad_berufsgruppe,
            ad_id: this.response.res.message.ad_id,
            clientadid: this.response.res.message.clientadid
          };
          localStorage.setItem('token', JSON.stringify(this.localvalue))
          if (this.isLogged) {
            var setAdValueList = {

              "permanent": "",
              "fulltime": "",
              "email": this.userEmail,
              "city": "",
              "title": "",
              "location": "",
              "date": "",
              "company": "",
              "organisation_id": "",
              "recruiter_id": this.recruiterid,
              "ad_id": this.response.res.message.ad_id,
              "country_code": "",
              "method": "AUTH",
       "free_text": "",
        "as_company": "",
         "url": "",
       "reference_id": ""
             

              
            }
            this.service.commonMethod(setAdValueList,this.logonUrl.set_ad_data)
              .subscribe(data => {
                this.commonloader = false;
                this.router.navigate(['/recruiterfeedback'])
              })
          } else {
            this.commonloader = false;
            this.router.navigate(['/recruiterfeedback'])
          }

        } else {
          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: false,
            data: { dialogname: 'commonerrorpopup' },
          });
          dialogRef.afterClosed().subscribe((result: any) => {

          });
          this.commonloader = false;
        }
      })
  }

  addToQueueeassy() {

    if (this.scrWidth >= 600) {
      if (this.isLogged == "true" && this.eassyapply.value.withdoceassy || this.eassyapply.value.withouteassy || this.eassyapply.value.profilepusheassy) {
        localStorage.setItem('linktype', 'true');
        localStorage.removeItem('bluecollar')
        if (this.isLogged == "true") {
          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            data: { dialogname: 'beforePaymentEasyApply', email: this.userEmail }

          });
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result == 'beforeApplyPaymentOk') {
              this.getLinkdialog();
            }

          });
        } else {
          this.getLinkdialog();
        }
      } else {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: false,
          data: { dialogname: 'checkboxpopup' },
        });
        dialogRef.afterClosed().subscribe((result: any) => {

        });
      }
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'mobileview' },
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }
  }

  opennewpopup() {
    localStorage.setItem('linktype', 'false');
    if (this.scrWidth >= 600) {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'landingfileloadpopup' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'mobileview' },
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }
  }





  getLinkdialog() {

    if (this.isLogged != null && this.isLogged != undefined) {
      if (this.isLogged != "true") {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: true,
          data: { dialogname: 'loginpopup', goto: 'payment', goto1: "reg" }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          this.getlocalvalues();
          if (result == "payment") {
            this.gotopayment(result);
          } else if (result == "reg") {
            this.gotopayment(result);
          }
        });
      } else {
        if (!this.userEmail) {
          this.userEmail = localStorage.getItem('userEmail');
        }
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: true,
          data: { dialogname: 'confirmpaymentpopup', email: this.userEmail, goto1: "confirmpayment" }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          this.getlocalvalues();
          this.getLinkbtn = true;
          this.TLI_chat = true;
        });
      }
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'loginpopup' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.getlocalvalues();
      });
    }
  }


  gotopayment(gotovalue) {
    if (gotovalue == "payment") {
      const dialogRef1 = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'beforePaymentEasyApply', email: this.userEmail }
      });
      dialogRef1.afterClosed().subscribe((result: any) => {
        if (result == 'beforeApplyPaymentOk') {
          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            data: { dialogname: 'confirmpaymentpopup', email: this.userEmail, goto1: "confirmpayment" }
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            this.getlocalvalues();
            // console.log(this.getLinkbtn, "2")
          });
        }

      });
    } else if (gotovalue == "reg") {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'beforePaymentEasyApply', email: this.userEmail }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        // console.log(result)
        if (result == 'beforeApplyPaymentOk') {
          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            data: { dialogname: 'confirmpaymentpopup', email: this.userEmail, goto1: "confirmpayment" }
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            this.getlocalvalues();
            // console.log(this.getLinkbtn, "3")

          });
        }

      });
    }

  }

  geteasyapplylink() {
    var flagdata = {
      "flag": "GET_CV_URL",
      "profileid": "1",
      "email": this.userEmail,
      "clientadid": "",
      "addesc": "android jd",
      "deviceid": "",
      "feedback": true,
      "osid": "",
      "sessionid": this.sessionid
    }
    this.service.commonMethod(flagdata,this.logonUrl.get_cv_url)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {
          this.url = this.response.res.URL;
          // this.sidenav.close();

          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            data: { dialogname: 'linkpopupregister', url: this.url }
          });
          dialogRef.afterClosed().subscribe((result: any) => {

          });
        } else {

        }
      })
  }

  changepasswords() {
    // this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'changepasswordpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }

  logout() {
    var data = {
      
      "email": this.userEmail,
      "sessionid": this.sessionid
     
    }
    this.service.commonMethod(data,this.logonUrl.logout)
      .subscribe(data => {
        if (data != null) {
          var details: any = data;
          if (details.status == 200) {
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.alertmsg(details.message, 'success');
            this.menuFlag = true;
          } else {
            this.alertmsg("Sitzung abgelaufen ", 'error')
            this.menuFlag = false;
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.menuFlag = true;
          }
          // this.sidenav.close();
        }
        else {

        }
      }, error => {

      });
  }
  alertmsg(msg, errtype) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: { data: msg, classname: errtype },
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'blue-snackbar'
    });
  }

  openDialog12() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: false,
      data: { dialogname: 'resetpassword' },

    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }
  setapplytype(type) {
    localStorage.setItem('applytype', type)
  }
}
