import { Injectable } from '@angular/core';
import * as shajs from 'sha.js';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() { }

 readonly e1 = "E";

  readonly h2 = "h";
  readonly o2 = "a";
  readonly b1 = "D";
  readonly F = "e";
  readonly j = "c";

  readonly k2 = "P";
  readonly c1 = "y";
  readonly pq = "D";



  public hashstring = "";
  public hashsubstring = "";
  public Encrypted = "";
  public Dencrypted = "";
  public camspayiv = CryptoJS.enc.Utf8.parse('vectobetterheads');
  // private iv = CryptoJS.enc.Utf8.parse('globalaesvectors');


  public EncryptionKey: "BeHeads190608"
  public DecryptionKey: "190608BeHeads"


  encryption(Mode, InutString) {
    let ad33 = "BeHeads190608"
    this.hashstring = shajs('sha256').update(ad33).digest('hex')
    this.hashsubstring = this.hashstring.substring(0, 32)
    let Enckey = CryptoJS.enc.Utf8.parse(this.hashsubstring);

    if (Mode == "Encrypt") {

      var Encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(InutString), Enckey,
        {
          keySize: 128 / 8,
          iv: this.camspayiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      //  console.log('Encrypted :' + Encrypted);
      Encrypted = Encrypted.ciphertext.toString(CryptoJS.enc.Base64);


      Encrypted = Encrypted.split('+').join('-')
      Encrypted = Encrypted.split('/').join('_')

      return Encrypted;
    }


    else if (Mode == "Decrypt") {
      if (InutString != null) {

        var Dencryptedinput = InutString.split('-').join('+')
        Dencryptedinput = Dencryptedinput.split('_').join('/')

        var Dencrypted = CryptoJS.AES.decrypt(Dencryptedinput, Enckey,
          {
            keySize: 128 / 8,
            iv: this.camspayiv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          });
        //   console.log('utf8 = ' + Dencrypted.toString(CryptoJS.enc.Utf8));

        Dencrypted = Dencrypted.toString(CryptoJS.enc.Utf8)



        return Dencrypted;
      } else {
        return null
      }
    }
  }

  Decryption(Mode, InutString) {
    let p09sdf = this.ewrwon242()
    this.hashstring = shajs('sha256').update(p09sdf).digest('hex')
    this.hashsubstring = this.hashstring.substring(0, 32)
    let Enckey = CryptoJS.enc.Utf8.parse(this.hashsubstring);
    if (Mode == "Decrypt") {
      var Dencryptedinput = InutString.split('-').join('+')
      Dencryptedinput = Dencryptedinput.split('_').join('/')
      var Dencrypted = CryptoJS.AES.decrypt(Dencryptedinput, Enckey,
        {
          keySize: 128 / 8,
          iv: this.camspayiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      //   console.log('utf8 = ' + Dencrypted.toString(CryptoJS.enc.Utf8));
    }

    Dencrypted = Dencrypted.toString(CryptoJS.enc.Utf8)

    return Dencrypted;
  }
  Generatechecksum(inpitstring, checksumkey) {

    var hash = CryptoJS.HmacSHA256(inpitstring, checksumkey);

    var hashInHex = hash.toString(CryptoJS.enc.Hex);


    return hashInHex;

  }
  camspayencryption(Mode, InutString, passphare) {
    this.hashstring = shajs('sha256').update(passphare).digest('hex')
    this.hashsubstring = this.hashstring.substring(0, 32)
    let Enckey = CryptoJS.enc.Utf8.parse(this.hashsubstring);

    if (Mode == "Encrypt") {
      var Encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(InutString), Enckey,
        {
          keySize: 128 / 8,
          iv: this.camspayiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      //  console.log('Encrypted :' + Encrypted);
      Encrypted = Encrypted.ciphertext.toString(CryptoJS.enc.Base64);


      Encrypted = Encrypted.split('+').join('-')
      Encrypted = Encrypted.split('/').join('_')

      return Encrypted;
    }


    else if (Mode == "Decrypt") {
      if (InutString != null) {

        var Dencryptedinput = InutString.split('-').join('+')
        Dencryptedinput = Dencryptedinput.split('_').join('/')

        var Dencrypted = CryptoJS.AES.decrypt(Dencryptedinput, Enckey,
          {
            keySize: 128 / 8,
            iv: this.camspayiv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          });
        //   console.log('utf8 = ' + Dencrypted.toString(CryptoJS.enc.Utf8));

        Dencrypted = Dencrypted.toString(CryptoJS.enc.Utf8)



        return Dencrypted;
      } else {
        return null
      }
    }
  }


  // EncCAMSPayDec

  // TkVJTEhobWFj
  dsfgd(s) {
    let e1 = s;
    return e1 + this.vjs('CA');
  }
  vjs(d) {
    let rando = Math.random() * 2;
    return d;
  }
  getvalue() {
    let m = "M"
    return m
  }
  getappend() {
    return "S" + this.h('P') + this.o2 + this.c1
  };

  h(sf) {
    return sf;
  }
  dfsdf() {
    let b1 = this.b1;
    let F = this.a23s(this.F)
    let j = this.j;
    return this.fdgfg(b1 + F + j)
  }
  a23s(dsd) {
    return dsd;
  }
  fdgfg(df) {
    return df;
  }
  Getsolve() {
    return this.h('S') + this.o2sd(this.k2) + this.c1;
  }

  o2sd(k2) {
    return k2 + this.o2;
  }

  addcalcu() {
    let g7 = this.pq;
    // let kk = this.oup(this.b1)
    let F = this.a23s(this.F)
    let j = this.j;
    return this.kuytrg(g7 + F + j)
  }
  kuytrg(gh) {
    return gh;
  }
  dsfghegd(s) {
    let e1 = s;
    return e1 + this.vjs('CA');
  }
  oup(dsd) {
    return dsd;
  }
  jfjgftr() {
    let m = "M"
    return m
  }
  sdfs1231() {
    let a2 = "En";
    let B2 = "c";
    return a2 + this.dsfgd(B2) + this.getvalue() + this.getappend() + this.dfsdf();
  }
  ewrwon242() {
    let v6 = "En";
    let H9 = "c";
    return v6 + this.dsfghegd(H9) + this.jfjgftr() + this.Getsolve() + this.addcalcu();
  }
}
