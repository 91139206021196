import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, HostListener, ElementRef } from '@angular/core';
import { RadialChartOptions, ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { HttpserviceService } from '../Services/httpservice.service';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../Dialog/Dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { EncryptionService } from '../Services/encryption.service';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AlertComponent } from '../Common_Alert/Common_Alert.component';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import * as html2canvas from 'html2canvas';
import {urlEnv} from '../../environments/env';

declare var jsPDF: any;
/**
 * Page for Recruiter Admin ,Recriuter Can View the Candidate List and Chat History , Favourite Candiate
 */

@Component({
  selector: 'app-da-admin',
  templateUrl: './DirectApply_Admin.component.html',
  styleUrls: ['./DirectApply_Admin.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [EncryptionService]
})
export class DaAdminComponent implements OnInit {
  //Common Ajax Var
  public logonUrl = urlEnv;
  public joblist: boolean = true;
  public candidatelist: boolean = false;
  public reportmatch: boolean = false;
  public jobheaderstrip: boolean = true;
  public dropdownfilter: boolean = false;
  public userdetails: boolean = false;
  public hideshowdynamicloop: boolean = false;
  public pagenavigator: boolean = true;
  datedasc: boolean = false;
  sticky: boolean = false;
  response: any;

  @ViewChild('pdfTable') pdfTable: ElementRef;
  // @ViewChild('stickyMenu') menuElement: ElementRef;
  menuPosition: any;


  menuFlag = false;
  langen: boolean = true;


  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Talents' },
    { value: 'pizza-1', viewValue: 'Marked' },
    { value: 'tacos-2', viewValue: 'New' }
  ];
  jddata: any;
  jddata3: any;
  data: any;
  isLogged: string;
  sessionid: string;
  userEmail: string;
  feedbackdata: any;
  loginuser: string;
  testValue: any;
  content_ad: any;
  buuletData: any[];
  hardSkils: any[];
  softSkils: any[];
  indutries: any[];
  profession: any[];
  competencyLengthZero: boolean;
  competencyLength: boolean;
  finalBullet27: any;
  slicedcompetency: any;
  professtion_berufsgruppe: any;

  countryCtrl = new FormControl();
  stateCtrl = new FormControl();
  zipCtrl = new FormControl();


  HideExpandCompatency: boolean = true
  HideHalfCompatency: boolean = false
  slicedHardSkill: any;
  HideHalfHardSkill: boolean = false;
  HideExpandHardSkill: boolean = true;
  slicedSoftSkills: any;
  HideHalfSoftSkills: boolean = false;
  HideExpandSoftSkills: boolean = true;
  slicedIndustries: any;
  HideHalfIndustry: boolean = false;
  HideExpandIndustry: boolean = true;
  HardskillLength: boolean;
  hardSkillLengthZero: boolean;
  finalHardSkils: any;
  finalSoftSkils: any;
  // HideHalfSoftSkills: boolean;
  softskillLength: boolean;
  softSkillLengthZero: boolean;
  industryLengthZero: boolean;
  industryLength: boolean;
  HideIndusrtyTips: boolean;
  finalIndustries: any;
  industrySeparatedValue: any;
  finalprofession: any;

  professionLength: boolean;
  professionLengthZero: boolean;
  slicedprofession: any;
  HideExpandprofession: boolean;
  HideHalfprofession: boolean;
  HideProfileName: boolean;
  HideProfileNameZero: boolean;
  profileReport: any;
  contentProfile: any;


  profile_ad_values: any = [{ id: 1, value: 'Schulbildung' }, { id: 2, value: 'Lehrberuf' }, { id: 3, value: 'Studium' }, { id: 4, value: 'weiterführendes Studium' }];
  remote_value: any = [{ id: 1, value: 'Ja', checked: false }, { id: 2, value: 'Nein', checked: false }]
  accesstoken: any;
  commonloader: boolean = false;
  backgrounddiv_enable: boolean;
  profile_ad: any;
  profile_ad_degree: any;
  profile_ad_training: any;
  ad_idValue: any;
  countryListValues: any;
  // countryCtrl: any;
  countriesValue: any;
  cityListValue: any;
  // stateCtrl: any;
  cityvalue: any;
  // zipCtrl: any;
  zipListValue: any;
  zipvalue: any;
  countryName: any;
  sortcountryName: any;
  cityId: any;
  cityFlagvalues: { "countryid": any;  };
  cityName: any;
  zipFlagvalues: {"city": any };
  profile_name: any;
  countryFlagvalues: { "flag": string; "deviceid": string; "osid": string; };
  zipCodebasedCountry: {  "zipcode": any;"name": string; };
  zipcode: any;
  zipcodedata: any;
  location: any;
  country: any;
  menuFlag1: boolean;

  @ViewChild('sidenav') sidenav: MatSidenav;
  sessionvar: any;
  url: any;
  recruiter_id: string;
  adlist: any;
  newdata: any[];
  listbystatedata: any;
  counters: any;
  candidatedata: any;
  currentcandidate_adid: any;
  currentcandidate_localchat: any;
  currentjddata: any;
  currentjddata1: any;
  jddata1: any;
  jddata2: any;
  zip: any;
  currentcandidateindex: any;
  localvalue: any;
  count: any;
  candidatedata1: any;
  count_c: any;
  selectAll = false;
  checksession1: boolean;
  currentcandidate_cvid: any;
  content_cv: any;
  currentempLengthZero: boolean;
  currentempLength: boolean;
  currentempbuuletData: any[];
  finalcv_Bullet27: any;
  HideExpandCurrentemp: boolean;
  HideHalfCurrentemp: boolean;
  slicedcurrentemp: any;
  response12: any;
  faveritedata: any;
  work_experience: any;
  likedata: any;
  interviewquestin: any;
  interviewquestin1: any;
  talentquestin: any;
  response1: any;
  adlist1: any;
  languagedata: any;
  currentlanguage: any;
  response2: any;
  adlist2: any;
  param3: any;
  resp: any;
  param4: any;
  param5: any;
/**
 * @ignore
 */
  constructor(
    public translate: TranslateService,
    public http: HttpserviceService,
    public dialog: MatDialog,
    private encrypt: EncryptionService,
    private router: Router,
    public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute,
  ) {
    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
      this.currentlanguage = lang
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
      this.currentlanguage = 'de'

    }

    this.countryListValues = this.countryCtrl.valueChanges
      .pipe(
        startWith(''),
        map(country => country ? this._filterCountry(country) : this.countriesValue)
      );

    this.cityListValue = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this._filterStates(state) : this.cityvalue)
      );

    this.zipListValue = this.zipCtrl.valueChanges
      .pipe(
        startWith(''),
        map(zip => zip ? this._filterZip(zip) : this.zipvalue)
      );

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
    this.jddata1 = [
      { name: "clientadid", checked: false },
      // { name: "profile_ad_id",checked: false},
    ];

  }
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  barChartPlugins: any;
  radarChartPlugins: any;
  param1: any;
  param2: any;
  redirectUrl: boolean = false;
  showChatHistory: boolean = false
  @HostListener('window:scroll', ['$event'])
  
  
  /** Handle Scroll for to get a with*/
  /**
   * @ignore
   */
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.menuPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  /**
   * function Desc
   * Init Funtion Starts here
   */
  ngOnInit() {
    this.getlanguagevalue()
    this.activatedRoute.queryParams.subscribe(params => {
      this.param1 = params['dt'];
      this.param2 = params['chat'];
      this.param3 = params['cvid'];
      this.param4 = params['recruiterId'];
      this.param5 = params['cv_id'];

      var EncryptedUrlId = this.encrypt.encryption("Decrypt", this.param1);
      var ParseUrl = JSON.parse(EncryptedUrlId);
      // if (ParseUrl && this.param2 != 'true') {
      this.isLogged = localStorage.getItem('isLogged');
      this.sessionid = localStorage.getItem('sessionid');
      this.userEmail = localStorage.getItem('userEmail');
      this.recruiter_id = localStorage.getItem('recruiter_id');
      this.accesstoken = JSON.parse(localStorage.getItem('token'));
      this.recruiter_id = localStorage.getItem('recruiter_id');
      if (this.isLogged == "true") {
        this.menuFlag1 = true;
      } else {
        this.menuFlag1 = false;
      }
      //Url CheckSession Starts 
     /**
      * @ignore
      */
      var test = this.isLogged
      var data = {
       
        "email": this.userEmail,
        "sessionid": this.sessionid,
       
      }
      this.http.commonMethod(data,this.logonUrl.session_check)
        .subscribe(res => {
          if (res) {
            this.commonloader = true;
            this.sessionvar = res
            if (this.sessionvar.status == 200) {
              this.commonloader = false
              if (ParseUrl && this.param2 != 'true') {
                this.checksession1 = true
                this.redirectUrl = true;
                this.match_reporthide()
                this.hideshowjobmatchreport(ParseUrl)
              } else if (this.param2) {
                this.checksession1 = true
                this.showChatHistory = true;
                this.showChat(ParseUrl)

              } else if (this.param3 || this.param4) {
                this.redirectUrl = true;
                this.checksession1 = true
                var falgd = {
                  "cv_id": ""
                }
                if (this.param3) {
                  falgd.cv_id = this.param3
                } else if (this.param4) {
                  falgd.cv_id = this.param5
                }
                this.http.commonMethod(falgd,this.logonUrl.cvid_based_adid)
                  .subscribe(res => {
                    this.resp = res
                    if (this.resp.status == 200) {
                      var card = this.resp.res[0]
                      if (this.param3) {
                        var d = {
                          ad_id: card.ad_id,
                          cv_id: card.cv_id,
                        }
                        this.match_reporthide()
                        this.hideshowjobmatchreport(d)
                      } else if (this.param4) {
                        this.onclickmatdiv()
                        this.getparticular_ad_candidates(card, "url")
                      }
                    } else {
                      console.log("error")
                    }

                  }, error => {
                    // this.alertmsg("somthing went wrong", 'error');

                  });

              } else {
                this.checksession1 = true
                this.getmy_data()
                // this.currentProfile();
                this.countriesList();
              }
            } else if (this.sessionvar.status == 422) {
              localStorage.setItem("isLogged", "false")
              localStorage.setItem("userEmail", "")
              localStorage.setItem("sessionid", "");
              this.isLogged = localStorage.getItem('isLogged');
              this.sessionid = localStorage.getItem('sessionid');
              this.userEmail = localStorage.getItem('userEmail');
              this.loginuser = ""
              this.menuFlag1 = false;
              if (test == "true") {
                this.alertmsg(this.sessionvar.message, 'error');
              }
              this.checksession1 = false
              this.router.navigate(['/'])
              return false
            }
          } else {
            console.log("error In EMAIL URL")
          }

        }, error => {
          this.alertmsg("Es ist ein Fehler aufgetreten", 'error');

        });
    });
    if (!this.redirectUrl && !this.showChatHistory) {
      this.dropdownfilter = true;
      this.backgrounddiv_enable = false;

      // this.checksession();
    }

  }
  ngAfterViewInit() {
    // setTimeout(() => {
    // this.menuPosition = this.menuElement.nativeElement.offsetTop;
    // }, 1000);
  }

  //Chat Hitory Popup
   /**
    * @ignore
    */
  showChat(id) {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'mat-dialog-container1',
      data: { dialogname: 'chatHistortPopup', cv_id: id.cv_id, ad_id: id.ad_id }
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }



  //marked Like By Balaji
   /**
    * @ignore
    * toCheck
    */
  setlike() {

    var flagdata = {
      
      "ad_id": this.feedbackdata.ad_profile_values.ad_id,
      "cv_id": this.feedbackdata.cv_profile_values.cv_id,
      "job_status": true
    }

    this.http.commonMethod(flagdata,this.logonUrl.job_like_dislike)
      .subscribe(res => {
        if (res) {
          this.likedata = res
          if (this.likedata.status == 200) {

          } else if (this.likedata.status == 422) {

          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });
  }
  chatHistory(value, i) {

    //TODO
    // chat history definitions
    var current = this.candidatedata1[i]
    if (value == "candidate") {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        panelClass: 'mat-dialog-container1',
        data: { dialogname: 'chatHistortPopup', cv_id: current.profile_cv_id, ad_id: current.profile_ad_id ,local_chat:current.local_chat}
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        panelClass: 'mat-dialog-container1',
        data: { dialogname: 'chatHistortPopup', cv_id: this.feedbackdata.cv_profile_values.cv_id, ad_id: this.feedbackdata.ad_data_list[0].profile_ad_id }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }

  }
  //Chart Function starts here


  /**
  * @ignore
  */
  public barChartOptions: ChartOptions = {
    responsive: true,

    // We use these empty structures as placeholders for dynamic theming.
    scales: {

      xAxes: [{
        ticks: {
          // display:true,
          beginAtZero: true
        },
        gridLines: {
          // color: "",
          display: false,
        }
      }],
      yAxes: [{
        ticks: {
          display: true,
          beginAtZero: true
        },
        gridLines: {
          // display: true,
          display: false,
          // color: "2f99af",
        }
      }]
    },
    plugins: {
      showTooltips: false,

      datalabels: {
        anchor: 'end',
        align: 'end',

      }
    },

    legend: {


      display: true
    }


  };
  /**
 * @ignore
 */
  public barChartLabels: Label[];
  /**
 * @ignore
 */
  public barChartType: ChartType = 'horizontalBar';
  /**
 * @ignore
 */
  public barChartLegend = true;
  /**
 * @ignore
 */
  public barChartData: ChartDataSets[] =

    [
      {
        data: [], borderColor: ['rgba(47, 153, 175, 0.7)'], backgroundColor: ['rgba(47, 153, 175, 0.7)']
      },
    ];
/**
 * @ignore
 */
  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59, 80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    this.barChartData[0].data = data;
  }
  // Bar chart Data Ends Here

  //Radar Chart Data Starts Here
  /**
 * @ignore
 */
  public radarChartLabels: Label[];
  /**
 * @ignore
 */
  public radarCharLegend = true;
  /**
 * @ignore
 */
  public radarChartData: ChartDataSets[] =
    [
      {
        data: [], borderColor: ['rgba(47, 153, 175, 0.7)'], backgroundColor: ['rgba(47, 153, 175, 0.7)']
      },
    ];
    /**
     * @ignore
     */
  public radarChartType: ChartType = 'radar';
  /**
   * @ignore
   */
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    scale: {
      ticks: {
        display: false
     }
    },
    legend: {
      display: true
    }
  };
  //Radar Chart Data Ends Here
/**
 * @ignore
 */
  match_reporthide() {
    this.joblist = false;
    this.dropdownfilter = false;
    this.jobheaderstrip = false;
  }

  //For Particular Candidate by Balaji
  /**
 * @ignore
 */
  onclickmatdiv() {
    this.joblist = false;
    this.dropdownfilter = false;
    this.userdetails = true
    this.candidatelist = true;
    this.jobheaderstrip = false;
  }
  //Match Report Hide Show by Balaji
  /**
 * @ignore
 */
  hideshowjobmatchreport(i) {
    this.feedbackReportData(i)
    this.candidatelist = false;
    this.reportmatch = true;
    this.jobheaderstrip = false;
    this.pagenavigator = false;

  }


/**
 * @ignore
 */
  loginclick() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'Logindetailspopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length != 0) {
        localStorage.setItem("userEmail", result[0].email);
        localStorage.setItem('sessionid', result[0].session);
        this.sessionid = localStorage.getItem('sessionid');
        this.userEmail = localStorage.getItem("userEmail");
        this.menuFlag1 = false;
      } else {
        this.menuFlag1 = true;
      }

    });
  }
  //Lang Selector 
  /**
 * @ignore
 */
  languageSelector(val) {
    localStorage.setItem('language', val);
    if (val == "de") {
      this.langen = true;
    } else {
      this.langen = false;
    }
  }

  /**
 * @ignore
 * toCheck
 */
  getlanguagevalue() {
    var language = this.currentlanguage
    this.http.getlangvalue(language)
      .subscribe(data => {
        if (data != null) {
          var details: any = data;
          this.languagedata = details;
        }
        else {

        }
      }, error => {

      });
  }

  /**
 * @ignore
 */
  onclickloopevent() {
    this.hideshowdynamicloop = !this.hideshowdynamicloop;
    if (this.hideshowdynamicloop) {
      this.hideshowdynamicloop = true;
    }
    else {
      this.hideshowdynamicloop = false;
    }

  }
  //Country Code by Balji
  /**
   * @ignore
   */
  private _filterCountry(value: string) {
    const filterValue = value.toLowerCase();
    return this.countriesValue.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
  }
/**
 * @ignore
 */
  private _filterStates(value: string) {
    const filterValue = value.toLowerCase();
    return this.cityvalue.filter(state => state.cityname.toLowerCase().indexOf(filterValue) === 0);
  }
  /**
   * @ignore
   */
  private _filterZip(value: string) {
    return this.zipvalue;
  }
 /** 
 * Method Desc
 * Common Session Check in All Page by Balaji Sachin
 */
  checksession() {
    var test = this.isLogged
    var data = {
    
        "email": this.userEmail,
        "sessionid": this.sessionid
       
    }
    this.http.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          console.log("coming from Normal Login");
          this.sessionvar = res
          if (this.sessionvar.status == 200) {
            this.checksession1 = true
            this.getmy_data()
            // this.currentProfile();
            this.countriesList();
          } else if (this.sessionvar.status == 422) {
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.isLogged = localStorage.getItem('isLogged');
            this.sessionid = localStorage.getItem('sessionid');
            this.userEmail = localStorage.getItem('userEmail');
            this.loginuser = ""
            this.menuFlag1 = false;
            if (test == "true") {
              this.alertmsg(this.sessionvar.message, 'error');
            }
            this.checksession1 = false
            this.router.navigate(['/'])
            return false
          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });
    // this.deletecount = 0
  }

/**
 * @ignore
 */
  close(reason: string) {
    this.sidenav.close();
  }

  /**
   * Common Alert Message
   * @example msg,Errtype
   */
  alertmsg(msg, errtype) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: { data: msg, classname: errtype },
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'blue-snackbar'
    });
  }

  /**
   * @ignore
   */
  getlocalvalues() {
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    if (this.isLogged == "true") {
      this.menuFlag1 = true;
    } else {
      this.menuFlag1 = false;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail.split('@')[0];
    }
  }

  /**
 * @ignore
 */
  openDialog3() {
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'confirmpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getlocalvalues();
      // this.getLinkbtn = true;
      // this.TLI_chat = true;
      this.feedbackReportData("");
    });
  }
 
 /**
   * Common Change password
   * @function Change
   */
  changepasswords() {
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'changepasswordpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }



  // country, city and zipcode methods
   /** 
    * @ignore 
    */
  countriesList() {
    this.http.commonMethodGet(this.logonUrl.getCountries)
      .subscribe(data => {
        this.countriesValue = data;
        this.countriesValue = this.countriesValue.countries;
      });
  }
   /** 
    * @ignore 
    */
  citiesList(data) {
    var id = data.id;
    this.countryName = data.name;
    this.sortcountryName = data.sortname
    this.cityId = id;

    this.cityFlagvalues = {
      
      "countryid": id
    
    }
    this.http.commonMethod(this.cityFlagvalues,this.logonUrl.getCities)
      .subscribe(data => {
        this.cityvalue = data;
        this.cityvalue = this.cityvalue.cities;
      });
  }
   /** 
    * @ignore 
    */
  zipcodeData(data) {
    this.cityName = data
    this.zipFlagvalues = {
    
      "city": this.cityName
    
    }
    this.http.commonMethod(this.zipFlagvalues,this.logonUrl.getZipcodes)
      .subscribe(data => {
        this.zipvalue = data;
        this.zipvalue = this.zipvalue.zipcodes;
      })
  }
   /** 
    * @ignore 
    */
  zipCodeCountry(zip) {
    this.zipCodebasedCountry = {
     
      "zipcode": zip,
     "name": "Germany"
    }
    this.zipcode = zip
    this.http.commonMethod(this.zipCodebasedCountry,this.logonUrl.getZipCity)
      .subscribe(data => {
        this.zipcodedata = data
        if (this.zipcodedata) {
          if (this.zipcodedata.city) {
            this.location = this.zipcodedata.city[0].cityname
            this.country = this.zipcodedata.city[0].countyname
            this.zipcode = zip
          }
        }

      })
  }

  
   /** 
    * @ignore 
    */
  currentpage: any = 1;
  totalnoof_page: any;
  // jobsper_page: any = 10;
  pageperjobs = ["10", "20", "50", "100"]
  jobsper_page: any = 10;
  // pageperjobs = [2, 4, 6, 100];
  gotovalue: number;
   /** 
    * @ignore 
    */
  setpageper_jobs(value) {
    this.jobsper_page = value
    this.totalnoof_page = this.jddata.length / this.jobsper_page
    this.totalnoof_page = Math.ceil(this.totalnoof_page)
    this.goto(1);
  }
   /** 
    * @ignore 
    */
  previous() {
    if (this.currentpage == 1) {
      alert(this.languagedata.ALERTS.PREVIOUS);
    } else {
      this.currentpage = this.currentpage - 1

      var count = this.count - this.jobsper_page
      if ((count % 10 != 0) && count >= 11){
        var dif = count % 10
        count = count - dif
      }
      var index = count - 10
      this.count = count
      if(count < 10){
        count = 10
        index = 0
      }
      else if(count == 10) {index = 0}
      
      var demo = this.jddata.slice(index, count);
      this.jddata1 = demo
    }
  }
  next() {
    var index = this.jobsper_page * this.currentpage
    if ((this.currentpage + 1) > this.totalnoof_page) {
      alert(this.languagedata.ALERTS.NEXT);
    } else {
      this.currentpage = this.currentpage + 1
      var count = index + this.jobsper_page
      if (count > this.jddata.length - 1) {
        count = this.jddata.length
      }
      this.count = count
      var demo = this.jddata.slice(index, count);
      this.jddata1 = demo

      // this.jddata.findIndex(item => item.expression == v.expression))
    }
  }
   /** 
    * @ignore 
    */
  gotopage(event) {
    var got = parseInt(event.target.value)
    if (got <= this.totalnoof_page && got > 0) {
      this.currentpage = got
      var index = (this.jobsper_page * this.currentpage) - this.jobsper_page
      var count = index + this.jobsper_page
      var demo = this.jddata.slice(index, count);
      this.count = count
      this.jddata1 = demo
    } else {
      alert(this.languagedata.ALERTS.NOPAGE);
    }
  }
   /** 
    * @ignore 
    */
  goto(value) {
    if (!value) {
      value = 1
    } else {

    }
    var index = this.jobsper_page * this.currentpage - 1
    this.currentpage = value
    var demo = this.jddata.slice(0, this.jobsper_page);
    this.jddata1 = demo
  }
  jobsperpage() {

  }
// pagination job end

// pagination candidate
   /** 
    * @ignore 
    */
  currentpage_c: any = 1;
  totalnoof_page_c: any;
  jobsper_page_c: any = 10;
  pageperjobs_c = ["10", "20", "50", "100"]

  // jobsper_page_c: any = 2;
  // pageperjobs_c = [2, 4, 6, 100];
  gotovalue_c: number;

  setpageper_candidate(value) {
    this.jobsper_page_c = value
    this.totalnoof_page_c = this.jddata.length / this.jobsper_page_c
    this.totalnoof_page_c = Math.ceil(this.totalnoof_page_c)
    this.goto_c(1);
  }
   /** 
    * @ignore 
    */
  previous_c() {
    if (this.currentpage_c == 1) {
      alert("Es gibt keine vorherige Seite");
    } else {
      this.currentpage_c = this.currentpage_c - 1

      var count = this.count_c - this.jobsper_page_c
      if ((count % 10 != 0) && count >= 11){
        var dif = count % 10
        count = count - dif
      }
      var index = count - 10
      this.count_c = count
      if(count < 10){
        count = 10
        index = 0
      }
      else if(count == 10) {index = 0}
      
      var demo = this.candidatedata.slice(index, count);
      this.candidatedata1 = demo
    }
  }
   /** 
    * @ignore 
    */
  next_c() {
    var index = this.jobsper_page_c * this.currentpage_c
    if ((this.currentpage_c + 1) > this.totalnoof_page_c) {
      alert("Sie sind bereits auf der letzten Seite");
    } else {
      this.currentpage_c = this.currentpage_c + 1
      var count = index + this.jobsper_page_c
      if (count > this.candidatedata.length - 1) {
        count = this.candidatedata.length
      }
      this.count_c = count
      var demo = this.candidatedata.slice(index, count);
      this.candidatedata1 = demo

      // this.jddata.findIndex(item => item.expression == v.expression))
    }
  }
   /** 
    * @ignore 
    */
  gotopage_c(event) {
    var got = parseInt(event.target.value)
    if (got <= this.totalnoof_page_c && got > 0) {
      this.currentpage_c = got
      var index = (this.jobsper_page_c * this.currentpage_c) - this.jobsper_page_c
      var count = index + this.jobsper_page_c
      var demo = this.candidatedata.slice(index, count);
      this.count_c = count
      this.candidatedata1 = demo
    } else {
      console.log("no page to show")
    }
  }
  goto_c(value) {
    if (!value) {
      value = 1
    } else {

    }
    var index = this.jobsper_page_c * this.currentpage_c - 1
    this.currentpage_c = value
    var demo = this.candidatedata.slice(0, this.jobsper_page_c);
    this.candidatedata1 = demo
  }
// pagination candidate end

  back1(value) {
    this.deletedata = [];
    this.selectAll = false;
    if (value == 'candidatelist') {
      this.onclickmatdiv()
      this.reportmatch = false;
    } else if (value == 'joblist') {
      this.joblist = true;
      this.candidatelist = false;
      this.reportmatch = false;
      this.jobheaderstrip = true;
      this.dropdownfilter = true;
      this.userdetails = false;
      this.hideshowdynamicloop = false;
      this.pagenavigator = true;
      this.getmy_data()

    }
  }

  /** 
    * @ignore 
    */
  setmarkedstate(i, value) {
    var current = this.candidatedata[i]
    var cr;
    if (value = "mark") {
      cr = 200710
    } else if (value = "unmark") {
      cr = 200071
    } else {
      cr = 200710
    }
    var flagdata = {
     
      "method": "AUTH",
      "cv_id": current.profile_cv_id,
      "ad_id": current.profile_ad_id,
      "state_list": [
        {
          "cv_id": current.profile_cv_id,
          "ad_id": current.profile_ad_id,
          "state": cr
        }
      ]
      
    }

    this.http.commonMethod(flagdata,this.logonUrl.set_state)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200) {
          this.getparticular_ad_candidates(this.currentcandidateindex, "")
        } else {

        }
      })
  }

  //Set Fav by Balji
   /** 
    * @ignore 
    */
  set_favert(i, value) {
    var current = this.candidatedata1[i]
    var flag = {
      
      "method": "AUTH",
      "mode": "candidate",
      "id_list": [
        {
          "ad_id": current.profile_ad_id,
          "cv_id": current.profile_cv_id,
          "recruiter_id": this.recruiter_id,
          "organisation_id": "111",
          "mode": "job"
        }
      ]
      
    }

    let url_set_favorite = this.logonUrl.set_favorite

    if (current.local_chat && current.local_chat==true)
    {
      url_set_favorite = this.logonUrl.local_set_favorite
    }

    this.http.commonMethod(flag,url_set_favorite)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200) {
          // this.AD_setstate()
          this.getparticular_ad_candidates(this.currentcandidateindex, "")
        } else {

        }
      })
  }


  //Get Favourite
   /** 
    * @ignore 
    */
  get_favert(i) {
    var current = this.candidatedata1[i]
    var data = {
     
      "method": "AUTH",
      "ad_id": current.profile_ad_id,
      "cv_id": current.profile_cv_id,
      "recruiter_id": this.recruiter_id,
      "organisation_id": "111",
      "mode": "job"
      
    }

    this.http.commonMethod(data,this.logonUrl.get_favorite)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200) {
          // this.AD_setstate()
        } else {

        }
      })
  }


  //Set State for Count
   /** 
    * @ignore 
    */
  AD_setstate(data) {
    var current = data.pop()
    if (current) {
      if (current.state == 200071 || current.state == '200071') {
        if (data.length > 0) {
          this.AD_setstate(data)
        }
      } else {
        var flagdata = {
         
          "method": "AUTH",
          "cv_id": current.profile_cv_id,
          "ad_id": current.profile_ad_id,
          "state_list": [
            {
              "cv_id": current.profile_cv_id,
              "ad_id": current.profile_ad_id,
              "state": 200071
            }
          ]
         
        }
        let url_set_state= this.logonUrl.set_state;

        if(current.local_chat && current.local_chat == true)
        {
          url_set_state=this.logonUrl.local_set_state
        }

        this.http.commonMethod(flagdata,url_set_state)
          .subscribe(res => {
            this.response = res
            if (this.response.status == 200) {
              if (data.length > 0) {
                this.AD_setstate(data)
              } else {

              }
            } else {

            }
          })
      }
    }


  }

   
    // For New Feature Blue Colar Starts
    
    /** 
     * @ignore 
     */ 
  GetDocumentData: any
  blueColorAdid: boolean = false
  GetDocs(cv) {
    var flagdata1 = {
     
      "cv_id": cv,
      "method": "AUTH",
      "document_type": "CV",
      "blue_collor": true,
      "white_collor": false,
      "easyapply": false
    }
    this.http.commonMethod(flagdata1,this.logonUrl.get_document)
      .subscribe(res => {
        this.GetDocumentData = res;

      })
  }
  //For New Feature Blue Colar ends
  getDocument: any

  // getparticular_ad_candidates by Balaji
  /**
 * @param
 * Obj and Option forGetting Counts
 */
  getparticular_ad_candidates(i, option) {
    this.selectAll = false;
    this.commonloader = true;
    console.log("localchat", i)
    if (option == "url") {
      this.currentcandidate_adid = i.ad_id;
      this.currentcandidate_localchat = i.local_chat
      this.currentjddata = i
    } else {
      this.currentcandidateindex = i
      this.currentcandidate_adid = this.jddata1[i].profile_ad_id
      this.currentcandidate_localchat = this.jddata1[i].local_chat
      this.currentjddata = this.jddata1[i]

    }
    var flagdata = {
      // "flag": "GET_LIST_BY_STATE",
      // "clientid": "1",
      // "document_type": "AD",
      // "id_list": this.currentcandidate_adid,
      // "deviceid": "",
      // "osid": "",
      // "state_list": "",
      "method": "AUTH",
        "document_type": "AD",
        "state_list": "",
        "id_list": this.currentcandidate_adid,
        "e_recruiter": false
    }
    if (option == "url") {

    } else {

    }

    if (option == 'appyledcount') {

    } else if (option == 'markerdcount') {

      // flagdata.id_list = this.jddata[i].markedvalue
    } else if (option == 'newcount') {

    }
    let internal_local_chat=this.currentjddata.local_chat

    let url_list_by_state = this.logonUrl.get_list_by_state

    if (internal_local_chat == true)
    {
      sessionStorage.setItem("LocalChat","true");
      url_list_by_state=this.logonUrl.local_get_list_by_state
    }

    this.http.commonMethod(flagdata,url_list_by_state)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {
          this.candidatedata = this.response.res.message
          for (var i = 0; i < this.candidatedata.length; i++) {
            if (this.currentjddata.blue_collor) {

              this.candidatedata[i].blue_collor = this.currentjddata.blue_collor
            }

            if (this.candidatedata[i].blue_collor) {
              // this.blueColorAdid = false
              if (this.candidatedata[i].keywords) {
                // this.candidatedata[i].keywords = this.candidatedata[i].keywords_cv;
                this.candidatedata[i].keywords = this.candidatedata[i].keywords_cv.split('/').slice(1).join('/');
                // this.candidatedata[i].keywordshead = this.candidatedata[i].keywords.split("/")[0];
              }
            } else {
              if (this.candidatedata[i].keywords) {
                this.candidatedata[i].keywords = this.candidatedata[i].keywords_cv;
                // this.candidatedata[i].keywords = this.candidatedata[i].keywords_cv.split('/').slice(1).join('/');
                this.candidatedata[i].keywordshead = this.candidatedata[i].keywords.split("/")[0];
              }
            }

            if(internal_local_chat && internal_local_chat == true)
            {
              this.candidatedata[i].local_chat=true;
            }
            else
            {
              this.candidatedata[i].local_chat=false;
            }
            
         /**
          * @ignore
          */ 
          }
          if (this.candidatedata) {
            var data = JSON.stringify(this.candidatedata)
            this.AD_setstate(JSON.parse(data));
            this.commonloader = false;
            var data1 = {
             
              "method": "AUTH",
              "ad_id": this.currentcandidate_adid,
              "cv_id": "",
              "recruiter_id": this.recruiter_id,
              "organisation_id": "111",
              "mode": "job"
            }

            let url_get_favorite =this.logonUrl.get_favorite

            if (internal_local_chat == true)
            {
              url_get_favorite=this.logonUrl.local_get_favorite 
            }
            this.http.commonMethod(data1,url_get_favorite)
              .subscribe(res => {
                this.response = res
                if (this.response.status == 200) {
                  var dd = this.response.res.message.job.favorite[this.recruiter_id]
                  if (dd) {
                    for (var i = 0; i < dd.length; i++) {
                      for (var j = 0; j < this.candidatedata.length; j++) {
                        if (this.candidatedata[j].profile_cv_id == dd[i]) {
                          this.candidatedata[j].favorite = true
                        }
                      }
                    }
                  }
                  if (option == 'markerdcount') {
                    var demo = []
                    for (var k = 0; k < this.candidatedata.length; k++) {
                      if (this.candidatedata[k].favorite) {
                        demo.push(this.candidatedata[k])
                      }
                    }
                    this.candidatedata = demo
                  } else if (option == 'newcount') {
                    var demo = []
                    for (var k = 0; k < this.candidatedata.length; k++) {
                      if (this.candidatedata[k].state == '70') {
                        demo.push(this.candidatedata[k])
                      }
                    }
                    this.candidatedata = demo
                  }

                  //adding if candidate_data is from localList
                  for (var k = 0; k < this.candidatedata.length; k++) {
                    if (internal_local_chat == true)
                    {
                      this.candidatedata[k].local_chat = true
                    }
                    else
                    {
                      this.candidatedata[k].local_chat = false
                    }
                  }

                  this.candidatedata1 = this.candidatedata
                  console.log(" ONE PARTICULAR CANDIDATE ",this.candidatedata1)
                  this.totalnoof_page_c = this.candidatedata.length / this.jobsper_page_c
                  this.totalnoof_page_c = Math.ceil(this.totalnoof_page_c)
                  this.goto_c(1)
                  this.commonloader = false;
                  console.log(this.candidatedata1)
                } else {

                }
              })


          }

        } else {

        }
      })

  }


  /* candidate delete */





  // GET_MY_DATA_COUNTERS Functionlity Starts here for Getting all Ad
  /**
 * getmy_data Methods starts Here
 * toCheck
 */
  getmy_data() {
    
          var flagdata = {
           
            "method" :"AUTH",
            "document_type": "AD",
            "organisation_id": "111",
            "recuiter_id": this.recruiter_id,
            "cv_id": "",
            "ad_id": ""
          }
          this.commonloader = true;
          this.jddata1 = []
          // local flow
          this.http.commonMethod(flagdata,this.logonUrl.local_get_my_data_counters)
          .subscribe(res => {
            // this.spinner.hide();
            this.response = res
            if (this.response.status == 200) {
              console.log(this.response, 'GEt my data Counters');
              if (this.response.res.message.NumberOfAds[0]) {
                this.adlist = this.response.res.message.NumberOfAds[0].adId
                if (this.adlist) {
                  var myflag = {
                   
                    "recruiter_id": this.recruiter_id,
                    "easyapply": true
                  }
                  this.http.commonMethod(myflag,this.logonUrl.easy_apply_links)
                    .subscribe(res => {
                      this.response1 = res
                      if (this.response1.status == 200) {
                        if (this.response1.res) {
                          this.adlist1 = this.response1.res
                          var array = this.adlist.split(",");
                          var er_flagdata = {
                            
                            "email": this.userEmail,
                            "e_recruiter": true
                          }
                          this.http.commonMethod(er_flagdata,this.logonUrl.e_recruiter_ids)
                            .subscribe(res => {
                              this.response2 = res
                              if (this.response2.status == 200) {
                                this.adlist2 = this.response2.res
                                if (this.adlist1) {
                                  var data = []
                                  for (var i = 0; i < array.length; i++) {
                                    for (var j = 0; j < this.adlist1.length; j++) {
                                      if (array[i] == this.adlist1[j].ad_id) {
                                        array.splice(i, 1);
                                      }
                                    }
                                  }
                                  for (var i = 0; i < array.length; i++) {
                                    for (var j = 0; j < this.adlist2.length; j++) {
                                      if (array[i] == this.adlist2[j].ad_id) {
                                        //data.push(array[i])
                                        array.splice(i, 1);
                                      }
                                    }
                                  }

                                  var energy = array.join();
                                  this.local_getAD_data(energy);
                                }
                              } else {
                                for (var i = 0; i < array.length; i++) {
                                  for (var j = 0; j < this.adlist1.length; j++) {
                                    if (array[i] == this.adlist1[j].ad_id) {
                                      array.splice(i, 1);
                                    }
                                  }
                                }
                                var energy = array.join();
                                this.local_getAD_data(energy);
                                // this.commonloader = false;
                              }
                            })
                        } else {
                          this.jddata1 = []
                        }
                      } else {
                        // this.commonloader = false;
                        this.local_getAD_data(this.adlist);
                      }
                    })
                }
              } else {
                this.jddata = [];
                this.totalnoof_page = 1;
                this.goto(1)
                this.commonloader = false;
              }
            } else {
            }
          })

          // global flow

          this.http.commonMethod(flagdata,this.logonUrl.get_my_data_counters)
            .subscribe(res => {
              // this.spinner.hide();
              this.response = res
              if (this.response.status == 200) {
                console.log(this.response, 'Global get_my_data_counters');
                console.log(this.response.res.message.NumberOfAds[0], 'Global get_my_data_counters');
                if (this.response.res.message.NumberOfAds[0]!= undefined) {
                  this.adlist = this.response.res.message.NumberOfAds[0].adId
                  if (this.adlist) {
                    var myflag = {
                     
                      "recruiter_id": this.recruiter_id,
                      "easyapply": true
                    }
                    this.http.commonMethod(myflag,this.logonUrl.easy_apply_links)
                      .subscribe(res => {
                        this.response1 = res
                        if (this.response1.status == 200) {
                          if (this.response1.res) {
                            this.adlist1 = this.response1.res
                            var array = this.adlist.split(",");
                            var er_flagdata = {
                              
                              "email": this.userEmail,
                              "e_recruiter": true
                            }
                            this.http.commonMethod(er_flagdata,this.logonUrl.e_recruiter_ids)
                              .subscribe(res => {
                                this.response2 = res
                                if (this.response2.status == 200) {
                                  this.adlist2 = this.response2.res
                                  if (this.adlist1) {
                                    var data = []
                                    for (var i = 0; i < array.length; i++) {
                                      for (var j = 0; j < this.adlist1.length; j++) {
                                        if (array[i] == this.adlist1[j].ad_id) {
                                          array.splice(i, 1);
                                        }
                                      }
                                    }
                                    for (var i = 0; i < array.length; i++) {
                                      for (var j = 0; j < this.adlist2.length; j++) {
                                        if (array[i] == this.adlist2[j].ad_id) {
                                          //data.push(array[i])
                                          array.splice(i, 1);
                                        }
                                      }
                                    }

                                    var energy = array.join();
                                    this.getAD_data(energy);
                                  }
                                } else {
                                  for (var i = 0; i < array.length; i++) {
                                    for (var j = 0; j < this.adlist1.length; j++) {
                                      if (array[i] == this.adlist1[j].ad_id) {
                                        array.splice(i, 1);
                                      }
                                    }
                                  }
                                  var energy = array.join();
                                  this.getAD_data(energy);
                                  // this.commonloader = false;
                                }
                              })
                          } else {
                            // // this.jddata1 = []
                            //this.commonloader = false;
                          }
                        } else {
                          // this.commonloader = false;
                          this.getAD_data(this.adlist);
                        }
                      })
                    // this.getAD_data(this.adlist);
                  }
                } else {
                  // this.jddata1 = []

                  // this is case when only local matches exist,and not global
                  this.jddata=this.jddata2
                  this.jddata1 = this.jddata
                  console.log("CASE _LOCAL_matches_only !!",this.jddata)
                  this.datesort("ascending")

                  this.totalnoof_page = this.jddata.length /this.jobsper_page
                  this.totalnoof_page = Math.ceil(this.totalnoof_page)
                  this.goto(1)
                  this.commonloader = false;

                }
              } else {
              }
            })
  }

  //get Ad Data By Start here By Balaji
   /**
    * getAD_data Methods starts Here
    */
  getAD_data(value) {
    var flagdata = {
     
      "method": "AUTH",
      "id_list": value,
      "ad_id":""
     
    }
    this.http.commonMethod(flagdata,this.logonUrl.get_ad_data)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200){
          this.jddata3 = this.response.res.message

          for (var i = 0; i < this.jddata3.length; i++) {
            for (var j = 0; j < this.response.res.link_data.length; j++) {
                if (this.jddata3[i].profile_ad_id && this.response.res.link_data[j]) {
                  if (this.jddata3[i].profile_ad_id == this.response.res.link_data[j].ad_id) {
                    this.jddata3[i].clientadid = this.response.res.link_data[j].client_ad_id
                    this.jddata3[i].status = this.response.res.link_data[j].ad_status
                    this.jddata3[i].ad_id = this.response.res.link_data[j].ad_id
                    this.jddata3[i].jdlink = this.response.res.link_data[j].link
                    this.jddata3[i].jdenhancedlink = this.response.res.link_data[j].enhanced_link
                    this.jddata3[i].blue_collor = this.response.res.link_data[j].blue_collor
                    this.jddata3[i].internal_reference = this.response.res.link_data[j].internal_reference
                    this.jddata3[i].local_chat=false  
                  }
                }
              }
          }
          this.getlistbystate(value)

        } else {
          this.jddata3=[]
        }
      })
  }

  local_getAD_data(value) {
    var flagdata = {
     
      "method": "AUTH",
      "id_list": value,
      "ad_id":""
     
    }
    this.http.commonMethod(flagdata,this.logonUrl.local_get_ad_data)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200 ) {
          this.jddata2 = this.response.res.message

          for (var i = 0; i < this.jddata2.length; i++) {
            for (var j = 0; j < this.response.res.link_data.length; j++) {
                if (this.jddata2[i].profile_ad_id && this.response.res.link_data[j]) {
                  if (this.jddata2[i].profile_ad_id == this.response.res.link_data[j].ad_id) {
                    this.jddata2[i].clientadid = this.response.res.link_data[j].client_ad_id
                    this.jddata2[i].status = this.response.res.link_data[j].ad_status
                    this.jddata2[i].ad_id = this.response.res.link_data[j].ad_id
                    this.jddata2[i].jdlink = this.response.res.link_data[j].link
                    this.jddata2[i].jdenhancedlink = this.response.res.link_data[j].enhanced_link
                    this.jddata2[i].blue_collor = this.response.res.link_data[j].blue_collor
                    this.jddata2[i].internal_reference = this.response.res.link_data[j].internal_reference
                    this.jddata2[i].local_chat=true                 }
                }
              }
          }
          this.local_getlistbystate(value)

        } else {
        this.jddata2=[]
        }
      })
  }


  //get List By Start here By Balaji
  /**
   * getlistbystate Methods starts Here
   */
  getlistbystate(value) {
     //GLOBAL FLOW
    var flagdata = {
      
      "method": "AUTH",
       "document_type": "AD",
       "state_list": "",
       "id_list": value,
       "e_recruiter": false
   }

   this.http.commonMethod(flagdata,this.logonUrl.get_list_by_state)
     .subscribe(res => {
       // this.spinner.hide();
       this.response = res
       if (this.response.status == 200) {;
         this.counters = this.response.res.message

         var newvalue = value.replace(/,/g, "','")

         newvalue = newvalue.toString()

         var data12 = {
          
           "method": "AUTH",
           "ad_id": "",
           "cv_id": "",
           "id_list": [newvalue],
           "recruiter_id": this.recruiter_id,
           "organisation_id": "111",
           "mode": "job"
         }
         this.http.commonMethod(data12,this.logonUrl.get_favorite)
           .subscribe(res => {
             // this.spinner.hide();
             this.response12 = res
             if (this.response12.status == 200) {
               // this.AD_setstate()
               this.faveritedata = this.response12.res.message.job.favorite
               for (var i = 0; i < this.jddata3.length; i++) {
                 this.jddata3[i].newcount = 0
                 this.jddata3[i].markerdcount = 0
                 this.jddata3[i].appyledcount = 0
                 if (this.counters && this.jddata3[i].status) {
                   for (var j = 0; j < this.counters.length; j++) {
                     if (this.jddata3[i].profile_ad_id == this.counters[j].profile_ad_id) {
                       if (this.counters[j].state == '70') {
                         this.jddata3[i].newcount++;
                         // this.jddata3[i].newcountArry.push()
                       }
                       // if (this.counters[j].state == '200710') {
                       //   this.jddata3[i].markerdcount++
                       // }
                       if (this.counters[j].state == '70' || this.counters[j].state == '200071' || this.counters[j].state == '200710' || this.counters[j].state == '200072' || this.counters[j].state == '200074') {
                         this.jddata3[i].appyledcount++
                       }
                     }
                   }

                   // }else{

                 }
               }
               var keys = Object.keys(this.faveritedata);
               var len = keys

               for (var i = 0; i < this.jddata3.length; i++) {
                 this.jddata3[i].markerdcount = 0
                 for (var j = 0; j < len.length; j++) {
                   if (this.jddata3[i].profile_ad_id == len[j]) {
                     this.jddata3[i].markerdcount = this.faveritedata[len[j]].length
                     this.jddata3[i].markedvalue = this.faveritedata[len[j]]
                   }
                 }

               }
               

               if (this.jddata3 !== undefined && this.jddata2 !== undefined) 
               {
                this.jddata=this.jddata3.concat(this.jddata2)
               }
               else if (this.jddata2 == undefined && this.jddata3 != undefined) 
              {
                this.jddata=this.jddata3
              }
              else if (this.jddata2.length != undefined && this.jddata3.length == undefined) 
              {
                this.jddata=this.jddata2
              }
              else
              {
                this.jddata=[]
              }

          

               this.datesort("ascending")
               this.jddata1 = this.jddata
               console.log("MERGE !!",this.jddata)
               this.totalnoof_page = this.jddata.length /this.jobsper_page
               this.totalnoof_page = Math.ceil(this.totalnoof_page)
               this.goto(1)
               this.commonloader = false;
               console.log("Final Data to Show",this.jddata1)

             } else {

             }
           })



       } else {

       }
     })


  }

  local_getlistbystate(value) {
    //LOCAL FLOW
    var flagdata = {
      
       "method": "AUTH",
        "document_type": "AD",
        "state_list": "",
        "id_list": value,
        "e_recruiter": false
    }

    this.http.commonMethod(flagdata,this.logonUrl.local_get_list_by_state)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200) {
          console.log(this.response.res.message, 'LOCAL_GETLISTBYSTATE');
          this.counters = this.response.res.message

          var newvalue = value.replace(/,/g, "','")

          newvalue = newvalue.toString()

          var data12 = {
           
            "method": "AUTH",
            "ad_id": "",
            "cv_id": "",
            "id_list": [newvalue],
            "recruiter_id": this.recruiter_id,
            "organisation_id": "111",
            "mode": "job"
          }
          this.http.commonMethod(data12,this.logonUrl.local_get_favorite)
            .subscribe(res => {
              // this.spinner.hide();
              this.response12 = res
              if (this.response12.status == 200) {
                // this.AD_setstate()
                this.faveritedata = this.response12.res.message.job.favorite
                console.log(this.jddata2, "LOCAL_LIST");
                for (var i = 0; i < this.jddata2.length; i++) {
                  this.jddata2[i].newcount = 0
                  this.jddata2[i].markerdcount = 0
                  this.jddata2[i].appyledcount = 0
                  if (this.counters && this.jddata2[i].status) {
                    for (var j = 0; j < this.counters.length; j++) {
                      if (this.jddata2[i].profile_ad_id == this.counters[j].profile_ad_id) {
                        if (this.counters[j].state == '70') {
                          this.jddata2[i].newcount++;
                          // this.jddata2[i].newcountArry.push()
                        }
                        // if (this.counters[j].state == '200710') {
                        //   this.jddata2[i].markerdcount++
                        // }
                        if (this.counters[j].state == '70' || this.counters[j].state == '200071' || this.counters[j].state == '200710' || this.counters[j].state == '200072' || this.counters[j].state == '200074') {
                          this.jddata2[i].appyledcount++
                        }
                      }
                    }

                    // }else{

                  }
                }
                var keys = Object.keys(this.faveritedata);
                var len = keys

                for (var i = 0; i < this.jddata2.length; i++) {
                  this.jddata2[i].markerdcount = 0
                  for (var j = 0; j < len.length; j++) {
                    if (this.jddata2[i].profile_ad_id == len[j]) {
                      this.jddata2[i].markerdcount = this.faveritedata[len[j]].length
                      this.jddata2[i].markedvalue = this.faveritedata[len[j]]
                    }
                  }

                }
              } else {

              }
            })



        } else {

        }
      })
  }



  // Sort Functionlity Start here BY balaji
  /**
   * @ignore
   */
  HideMoreBtnKeywords = true
  currentIndex: any;
    /** 
     * @ignore 
     */ 
  showFullKeywords(i, data) {
    this.currentIndex = i;
    this.HideMoreBtnKeywords = false
  }
    /** 
     * @ignore 
     */ 
  showhalfKeywords(i, data) {
    this.currentIndex = i;
    this.HideMoreBtnKeywords = true
  }
    /** 
     * @ignore 
     */ 
  comp(a, b) {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  }

  talentsasc: boolean = false
  markedasc: boolean = false
  newasc: boolean = false

    /** 
     * @ignore 
     */ 
  talentsasds(a, b) {
    return b.appyledcount - a.appyledcount;
  }
    /** 
     * @ignore 
     */ 
  talentsasdsdes(a, b) {
    return a.appyledcount - b.appyledcount;
  }
    /** 
     * @ignore 
     */ 
  talentsort(value) {
    if (value == "descending") {
      this.talentsasc = true
      this.jddata = this.jddata.sort(this.talentsasdsdes);
      this.jddata1 = this.jddata
      this.goto(1)
    } else {
      this.talentsasc = false
      this.jddata = this.jddata.sort(this.talentsasds);
      this.jddata1 = this.jddata
      this.goto(1)
    }

  }
    /** 
     * @ignore 
     */ 
  markeddec(a, b) {
    return b.markerdcount - a.markerdcount;
  }
    /** 
     * @ignore 
     */ 
  markedasec(a, b) {
    return a.markerdcount - b.markerdcount;
  }

    /** 
     * @ignore 
     */ 
  markedsort(value) {
    if (value == "descending") {
      this.markedasc = true
      this.jddata = this.jddata.sort(this.markeddec);
      this.jddata1 = this.jddata
      this.goto(1)
    } else {
      this.markedasc = false
      this.jddata = this.jddata.sort(this.markedasec);
      this.jddata1 = this.jddata
      this.goto(1)

    }
  }

  datesasds(a, b) {
    let b_date = b.ad_create_timestamp
    let a_date = a.ad_create_timestamp
    return b_date - a_date;
  }
   /**
    * @ignore
    */
  datesasdsdes(a, b) {
    let b_date = b.ad_create_timestamp
    let a_date = a.ad_create_timestamp
    return a_date - b_date;
  }
   /**
    * @ignore
    */
  datesort(value) {
    if (value == "descending") {
      this.datedasc = true
      this.jddata = this.jddata.sort(this.datesasdsdes);
      this.jddata1 = this.jddata
      this.goto(1)
    } else {
      this.datedasc = false
      this.jddata = this.jddata.sort(this.datesasds);
      this.jddata1 = this.jddata
      this.goto(1)
    }
  }


    /** 
     * @ignore 
     */ 
  newdec(a, b) {
    return b.newcount - a.newcount;
  }
    /** 
     * @ignore 
     */ 
  newasec(a, b) {
    return a.newcount - b.newcount;
  }
    /** 
     * @ignore 
     */ 
  newsort(value) {
    if (value == "descending") {
      this.newasc = true
      this.jddata = this.jddata.sort(this.newdec);
      this.jddata1 = this.jddata
      this.goto(1)
    } else {
      this.newasc = false
      this.jddata = this.jddata.sort(this.newasec);
      this.jddata1 = this.jddata
      this.goto(1)

    }
  }


  //newdata1 : any [];

 
    /** 
     *Check All Ad
     */ 
  docheckall() {

    if (this.selectAll === true) {
      this.jddata1.map((cid) => {
        cid.checked = true;
        this.deletedata.push(cid);

      });
    } else {
      this.jddata1.map((cid) => {
        cid.checked = false;
        this.deletedata = []
      });
    }

  }


  //Check All CV
    /** 
     * @ignore 
     */  
  docheckallcv() {
    if (this.selectAll === true) {
      this.candidatedata1.map((cid) => {
        cid.checked = true;
        this.deletedata1.push(cid);
      });
    } else {
      this.candidatedata1.map((cid) => {
        cid.checked = false;
        this.deletedata1 = []
      });
    }

  }

  //Add to delete Parrticular CV
    /** 
     * @ignore 
     */ 
  deletedata: any = [];
addtodelete(i, event) {
    this.newdata = []
    if (event.checked) {
      this.deletedata.push(this.jddata1[i])
    } else {
      if (this.deletedata.length >= 1) {
        var index = this.deletedata.indexOf(this.jddata1[i]);
        if (index > -1) {
          this.deletedata.splice(index, 1);
        }
        // this.deletedata = this.newdata
      }
    }
  }
    /** 
     * Add to delete Parrticular CV
     */ 
  deletedata1: any = [];
  checkedData: any;
 addtodeletecv(i, event) {

    this.newdata = []
    if (event.checked) {
      this.deletedata1.push(this.candidatedata1[i]);
    } else {
      if (this.deletedata1.length >= 1) {
        var index = this.deletedata1.indexOf(this.candidatedata1[i]);
        if (index > -1) {
          this.deletedata1.splice(index, 1);
        }
      }


    }
  }
  /**
   * @ignore
   */
  gotofeedback(data) {
    this.localvalue = {
      access_token: this.accesstoken.access_token,
      ad_id: data.ad_id,
      clientadid: data.clientadid,
      readonly: true
    };
    localStorage.setItem("token", JSON.stringify(this.localvalue))
    this.router.navigate(['/recruiterfeedback']);
  }
  gotofeedback_normal(data) {
    this.localvalue = {
      access_token: this.accesstoken.access_token,
      ad_id: data.ad_id,
      clientadid: data.clientadid,
      readonly: false
    };
    localStorage.setItem("token", JSON.stringify(this.localvalue))
    this.router.navigate(['/recruiterfeedback']);
  }
  
/**
 *Delete Particular Ad functionality Starts here
 */
  deleteJob() {
    
    var flagdata = {
      
      "method":"AUTH",
      "ad_id_list": this.deletedata,
      "document_type": "AD",
      "delete_list": [
        "READ",
        "PROFILE",
        "MATCH",
        "FILES"
      ],
    }
    this.commonloader = true
    this.http.commonMethod(flagdata,this.logonUrl.combined_delete_values)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        this.commonloader = true;
        if (this.response.status == 200) {
          this.getmy_data()
          this.deletedata = []
          this.selectAll = false

        } else {
          this.commonloader = false;
        }
      })
      window.location.reload();
  }

 /** 
  * Delete Particular Candidate functionality Starts here
  */ 
  deleteJobcv() {
    var flagdata = {
     
      "method":"AUTH",
      "cv_id_list": this.deletedata1,
     
      "document_type": "CV",
      "delete_list": [
        "READ",
        "PROFILE",
        "MATCH",
        "FILES"
      ]
    }
    this.commonloader = true;
    this.http.commonMethod(flagdata,this.logonUrl.combined_delete_values)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {
          this.commonloader = false;
          this.selectAll = false
          this.deletedata1 = []
          
          this.getparticular_ad_candidates(this.currentcandidateindex, "")
        } else {
          this.commonloader = false;
        }
      })
  }

//Print
  print() {
    if (this.isLogged == "false") {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'loginpopup' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    } else {
      this.createFile();

    }
  }

// NOT IN USE!! Instead => downloadAsPDF()
  createFile() {
    var data = document.getElementById('foo');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('l', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('Admin.pdf'); // Generated PDF   
    });

  }

  public downloadAsPDF() {
    const doc = new jsPDF('l');
    doc.setFontSize(10)
    doc.text("Title: "+this.deletedata1[0].title, 10, 6);
    doc.autoTable({html:"#mytable", styles: {cellPadding: 0.1, fontSize: 7}});
    doc.save('tableToPdf.pdf');
  }

  //Link Popup
  openlink(url, url1) {
    if (url) {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'linkpopupadmin', url: url, Enhanced_URL: url1 }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }
  }

  // button hide and show functions
  showFullCurrentemp() {
    this.slicedcompetency = this.finalcv_Bullet27;
    this.HideHalfCurrentemp = true;
    this.HideExpandCurrentemp = false;
  }
  showHalfCurrentemp() {
    this.slicedcompetency = this.finalcv_Bullet27.slice(1, 4);
    this.HideHalfCurrentemp = false;
    this.HideExpandCurrentemp = true;
  }

  showFullCompetency() {
    this.slicedcompetency = this.finalBullet27;
    this.HideHalfCompatency = true;
    this.HideExpandCompatency = false;
  }

  showHalfCompetency() {
    this.slicedcompetency = this.finalBullet27.slice(1, 4);
    this.HideHalfCompatency = false;
    this.HideExpandCompatency = true;
  }

  showFullHardSkill() {
    if (this.slicedHardSkill && this.slicedHardSkill.length == 3) {
      this.slicedHardSkill = this.finalHardSkils;
      this.HideHalfHardSkill = true;
      this.HideExpandHardSkill = false;
    }
    this.slicedHardSkill = this.finalHardSkils;
    this.HideHalfHardSkill = true;
    this.HideExpandHardSkill = false;
  }
  showHalfHardSkill() {
    this.slicedHardSkill = this.finalHardSkils.slice(1, 4);
    this.HideHalfHardSkill = false;
    this.HideExpandHardSkill = true;
  }
  showFullSoftSkills() {
    if (this.slicedSoftSkills && this.slicedSoftSkills.length == 3) {
      this.slicedSoftSkills = this.finalSoftSkils;
      this.HideHalfSoftSkills = true;
      this.HideExpandSoftSkills = false;
    } else {
      this.slicedSoftSkills = this.finalSoftSkils;
      this.HideHalfSoftSkills = true;
      this.HideExpandSoftSkills = false;
    }
  }
  showHalfSoftSkills() {
    this.slicedSoftSkills = this.finalSoftSkils.slice(1, 4);
    this.HideHalfSoftSkills = false;
    this.HideExpandSoftSkills = true;
  }

  showFullIndustry() {
    if (this.slicedIndustries == 3) {
      this.slicedIndustries = this.finalIndustries;
      this.HideHalfIndustry = false;
      this.HideExpandIndustry = false;
    }
    this.slicedIndustries = this.finalIndustries;
    this.HideHalfIndustry = true;
    this.HideExpandIndustry = false;
  }
  showHalfIndustry() {
    this.slicedIndustries = this.finalIndustries.slice(1, 4);
    this.HideHalfIndustry = false;
    this.HideExpandIndustry = true;
  }

  showFullprofession() {
    if (this.slicedprofession && this.slicedprofession.length == 3) {
      this.slicedprofession = this.finalprofession;
      this.HideHalfprofession = false;
      this.HideExpandprofession = false;
    }
    this.slicedprofession = this.finalprofession;
    this.HideHalfprofession = true;
    this.HideExpandprofession = false;
  }
  showHalfprofession() {
    this.slicedprofession = this.finalprofession.slice(1, 4);
    this.HideHalfprofession = false;
    this.HideExpandprofession = true;
  }
  //Hide Show Button Ends here

  adProfileValueTitle: any;
  adProfileValueData: any;
  matchingProfie: any;
  informationDesc1: any;
  informationDesc2: any;
  cvProfileValueData: any;
  cvProfileValueTitle: any;
  canditateTitle: any;
  

//Match Report by Balaji
  feedbackReportData(i) {
    
    this.commonloader = true
    var req;
    if (this.redirectUrl) {
      req = {
       
        "method": "AUTH",
    "ad_id": i.ad_id,
    "cv_id": i.cv_id,
    "document_type": "AD",
    "report": "customer",
    "output": "data",
    "email": this.userEmail,
    "e_recruiter": false
      }
    } else {
      req = {
       
         "method": "AUTH",
    "ad_id": this.candidatedata[i].profile_ad_id,
    "cv_id": this.candidatedata[i].profile_cv_id,
    "document_type": "AD",
    "report": "customer",
    "output": "data",
    "email": this.userEmail,
    "e_recruiter": false
      }
    }

    this.http.commonMethod(req,this.logonUrl.get_match_report)
      .subscribe(data => {
        this.commonloader = false
        this.feedbackdata = data;
       
        this.feedbackdata = this.feedbackdata.res;
        
         if(this.feedbackdata.cv_data_list == null || this.feedbackdata.cv_data_list.length == 0 || this.feedbackdata.cv_data_list.length == undefined){
           console.log("cvDataList NUll",this.feedbackdata)
          this.reportmatch = false;
           const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'noRecordsFound' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
          
         } 
         else{
          
             if (this.feedbackdata) {
          this.commonloader = false
           console.log(this.feedbackdata,"Full Match Report Data")
          this.adProfileValueData = this.feedbackdata.ad_profile_values.profil_info[2];
          this.adProfileValueTitle = this.feedbackdata.ad_profile_values.profil_info[2];
          this.cvProfileValueData = this.feedbackdata.cv_profile_values.profil_info;
          this.cvProfileValueTitle = this.feedbackdata.cv_profile_values.profil_info;
          this.canditateTitle = this.feedbackdata.matching_overview_neutral.matching_color;
          if (this.feedbackdata.read_quality) {
            this.informationDesc1 = this.feedbackdata.read_quality.quality_list.document_berufsgruppe_unique;
            this.informationDesc2 = this.feedbackdata.read_quality.quality_list.document_conflicts.meldung;
          }
          // BarChart Labels starts Here
          if (this.cvProfileValueTitle.titel_krit_1 && this.cvProfileValueTitle.titel_krit_2 && this.cvProfileValueTitle.titel_krit_3 && this.cvProfileValueTitle.titel_krit_4 && this.cvProfileValueTitle.titel_krit_9) {

          }
          if (this.cvProfileValueData.length > 0) {
            for (var i = 0; i < this.cvProfileValueData.length; i++) {
              if (this.cvProfileValueData[i].skillset_id == '12') {
                var candidateData = this.cvProfileValueData[i];
              }
            }
            this.barChartLabels = [candidateData.titel_krit_1, candidateData.titel_krit_2, candidateData.titel_krit_3, candidateData.titel_krit_4, candidateData.titel_krit_9];
            // RadaChart Labels starts Here
            this.radarChartLabels = [candidateData.titel_krit_1, candidateData.titel_krit_2, candidateData.titel_krit_3, candidateData.titel_krit_4, candidateData.titel_krit_9];

            this.radarChartData =
              [

                {
                  data: [this.adProfileValueData.krit_1_wert, this.adProfileValueData.krit_2_wert,
                  this.adProfileValueData.krit_3_wert,
                  this.adProfileValueData.krit_4_wert, this.adProfileValueData.krit_9_wert], borderColor: ['rgba(255, 99, 110, 0.7)'], backgroundColor: ['rgba(255, 99, 132, 0.7)'], label:"Stelle"
                },
                {
                  data: [candidateData.krit_1_wert, candidateData.krit_2_wert,
                  candidateData.krit_3_wert,
                  candidateData.krit_4_wert, candidateData.krit_9_wert], borderColor: ['rgba(39, 128, 146, 0.7)'], backgroundColor: ['rgba(39, 128, 146, 0.7)'], label:"CV"
                },
              ];

            this.barChartData =

              [

                {
                  backgroundColor: 'rgba(255, 99, 110, 0.7)',
                  borderColor: 'rgba(255, 99, 110, 0.7)',
                  // borderWidth: 5,
                  data: [this.adProfileValueData.krit_1_wert, this.adProfileValueData.krit_2_wert,
                  this.adProfileValueData.krit_3_wert,
                  this.adProfileValueData.krit_4_wert, this.adProfileValueData.krit_9_wert],
                  label:"Stelle"
                },


                {
                  backgroundColor: 'rgba(39, 128, 146, 0.7)',
                  borderColor: 'rgba(39, 128, 146, 0.7)', data: [candidateData.krit_1_wert, candidateData.krit_2_wert,
                  candidateData.krit_3_wert,
                  candidateData.krit_4_wert, candidateData.krit_9_wert],
                  label:"CV"
                }

              ];
          } else {

          }
          this.location = this.feedbackdata.cv_data_list[0].location
          this.country = this.feedbackdata.cv_data_list[0].country
          this.zip = this.feedbackdata.cv_data_list[0].zip
          this.content_ad = this.feedbackdata.content_ad;
          this.content_cv = this.feedbackdata.content_cv;

          if (this.feedbackdata.ad_data_list[0].date) {
            var ddd = this.feedbackdata.ad_data_list[0].date
            var dd = ddd.split('-')[2];
            var mm = ddd.split('-')[1];
            var yy = ddd.split('-')[0];
            yy = yy.substr(-2, 2)
            dd = dd.concat(".");
            var finaldate = dd.concat(mm);
            finaldate = finaldate.concat('.');
            finaldate = finaldate.concat(yy)
            this.feedbackdata.ad_data_list[0].date = finaldate
          }

          if (this.feedbackdata.profile_cv) {
            this.profile_ad = this.feedbackdata.profile_cv.niveau_ausbildung
            this.profile_ad_degree = this.feedbackdata.profile_cv.abschluesse
            this.profile_ad_training = this.feedbackdata.profile_cv.ausbildung

            if (this.feedbackdata.profile_cv.work_experience) {
              this.work_experience = this.feedbackdata.profile_cv.work_experience
            } else {
              this.work_experience = ""
            }
          }
          this.ad_idValue = this.feedbackdata.ad_profile_values.ad_id;

          var keys = Object.keys(this.content_ad);
          var keys_cv = Object.keys(this.content_cv);
          var len_cv = keys_cv
          var len = keys;
          var bullet27;
          var bullet2;
          var bullet3;
          var bullet4;
          var bullet5;
          this.buuletData = [];
          this.hardSkils = [];
          this.softSkils = [];
          this.indutries = [];
          this.profession = [];
          var cv_built27 = [];
          this.professtion_berufsgruppe = this.feedbackdata.profile_cv.Berufsgruppe;
          this.currentempbuuletData = [];

          if (this.feedbackdata.interview_questions_no_link) {
            this.interviewquestin1 = this.feedbackdata.interview_questions_no_link
            this.interviewquestin = this.interviewquestin1.filter(item => item.bereich_id == "5");
            this.talentquestin = this.interviewquestin1.filter(item => item.bereich_id == "7");

          }


          for (var i = 0; i < len_cv.length; i++) {

            //  current employement
            cv_built27 = this.content_cv[len_cv[i]].filter(item => item.bullet_id == "27");
            if (cv_built27.length > 0) {
              this.currentempLengthZero = false;
              this.currentempLength = true;
              this.currentempbuuletData.push(cv_built27);
              for (var j = 0; j < this.currentempbuuletData.length; j++) {
                this.finalcv_Bullet27 = this.currentempbuuletData[j];
                let removeDuplication = this.finalcv_Bullet27.filter((v, i) => this.finalcv_Bullet27.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalcv_Bullet27 = removeDuplication;
                if (this.finalcv_Bullet27.length > 3) {
                  this.slicedcurrentemp = this.finalcv_Bullet27.slice(1, 4);
                } else if (this.finalcv_Bullet27.length <= 3) {
                  this.slicedcurrentemp = this.finalcv_Bullet27;
                  this.HideExpandCurrentemp = false;
                  this.HideHalfCurrentemp = false;
                }
              }
            } else if (this.buuletData.length < 1) {
              this.currentempLengthZero = false;
              this.currentempLength = true;

            }

            // Competency
            bullet27 = this.content_cv[len_cv[i]].filter(item => item.bullet_id == "62");
            if (bullet27.length > 0) {
              this.competencyLengthZero = false;
              this.competencyLength = true;
              this.buuletData.push(bullet27);
              for (var j = 0; j < this.buuletData.length; j++) {
                this.finalBullet27 = this.buuletData[j];
                let removeDuplication = this.finalBullet27.filter((v, i) => this.finalBullet27.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalBullet27 = removeDuplication;
                if (this.finalBullet27.length > 3) {
                  this.slicedcompetency = this.finalBullet27.slice(1, 4);
                } else if (this.finalBullet27.length <= 3) {
                  this.slicedcompetency = this.finalBullet27;
                  this.HideExpandCompatency = false;
                  this.HideHalfCompatency = false;
                }
              }
            } else if (this.buuletData.length < 1) {
              this.competencyLengthZero = false;
              this.competencyLength = true;

            }

            // abschnitt_id == 61 or 6
            // hardskils
            bullet2 = this.content_cv[len_cv[i]].filter(item => item.abschnitt_id == "61" || item.abschnitt_id == "6");
            if (bullet2.length > 0) {
              this.HardskillLength = true;
              this.hardSkillLengthZero = false;
              this.hardSkils.push(bullet2);
              for (var j = 0; j < this.hardSkils.length; j++) {
                this.finalHardSkils = this.hardSkils[j];
                let removeDuplication = this.finalHardSkils.filter((v, i) => this.finalHardSkils.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalHardSkils = removeDuplication;
                if (this.finalHardSkils.length > 3) {
                  this.slicedHardSkill = this.finalHardSkils.slice(1, 4);
                  this.HideExpandHardSkill = true;
                  this.HideHalfHardSkill = false;
                }
                else if (this.finalHardSkils.length <= 3) {
                  this.slicedHardSkill = this.hardSkils[j];
                  this.HideExpandHardSkill = false;
                  this.HideHalfHardSkill = false;
                }
              }
            } else if (this.hardSkils.length == 0) {
              this.HardskillLength = false;
              this.hardSkillLengthZero = true;
            }

            // professions
            bullet5 = this.content_cv[len_cv[i]].filter(item => item.bullet_id == "27");
            if (bullet5.length > 0) {
              this.professionLength = true;
              this.professionLengthZero = false;
              this.profession.push(bullet5);
              for (var j = 0; j < this.profession.length; j++) {
                this.finalprofession = this.profession[j];
                let removeDuplication = this.finalprofession.filter((v, i) => this.finalprofession.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalprofession = removeDuplication;
                if (this.finalprofession.length > 3) {
                  this.slicedprofession = this.finalprofession.slice(1, 4);
                  this.HideExpandprofession = true;
                  this.HideHalfprofession = false;
                }
                else if (this.finalprofession.length <= 3) {
                  this.slicedprofession = this.finalprofession;
                  this.HideExpandprofession = false;
                  this.HideHalfprofession = false;
                }
              }
            } else if (this.profession && this.profession.length == 0) {
              this.professionLength = false;
              this.professionLengthZero = true;
            }

            // industry
            bullet4 = this.content_cv[len_cv[i]].filter(item => item.bullet_id == "28");
            if (bullet4.length > 0) {
              this.industryLengthZero = false;
              this.industryLength = true;
              this.HideIndusrtyTips = true;
              this.indutries.push(bullet4);
              for (var j = 0; j < this.indutries.length; j++) {
                this.finalIndustries = this.indutries[j];
                let removeDuplication = this.finalIndustries.filter((v, i) => this.finalIndustries.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalIndustries = removeDuplication;
                this.industrySeparatedValue = Array.prototype.map.call(this.finalIndustries, s => s.expression).toString();
                if (this.finalIndustries.length > 3) {
                  this.slicedIndustries = this.finalIndustries.slice(1, 4);
                }
                else if (this.finalIndustries && this.finalIndustries.length <= 3) {
                  this.slicedIndustries = this.finalIndustries;
                  this.HideExpandIndustry = false;
                  this.HideHalfIndustry = false;
                }
              }
            } else if (this.indutries && this.indutries.length == 0) {
              this.industryLengthZero = true;
              this.industryLength = false;
            }

          }


          if (this.profile_ad == "") {
            this.HideProfileName = true;
            this.HideProfileNameZero = true;
          } else if (this.profile_ad && this.profile_ad != "") {
            this.HideProfileName = true;
            this.HideProfileNameZero = false;
            this.profile_name = this.profile_ad_values[this.profile_ad - 1].value
          }

        }
         }

      }, error => {

      });
  }

  // Download for Save Fucntionlity starts here
  savePageData: any;
  download(filename, text) {
    var element = document.createElement('a');
element.setAttribute('href', 'data:text/csv; charset=utf-8,' + text);
element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    this.getparticular_ad_candidates(this.currentcandidateindex, "")
  }

  savePage(data) {
    if (this.deletedata1.length != 0) {
      var cvList = [];
      for (var i = 0; i < this.deletedata1.length; i++) {
        cvList.push(this.deletedata1[i])
      }

      var savePage;
      savePage = {
        
        "method": "AUTH",
        "custom_id": "548",
        "recruiter_id": this.recruiter_id,
        "ad_id": this.currentcandidate_adid,
        "cv_id_list": cvList,
        "local_chat": this.currentcandidate_localchat
      }
     

      this.http.commonMethod(savePage,this.logonUrl.get_talent_csv)
        .subscribe(response => {
          this.savePageData = response;
          var decodedString=  atob(this.savePageData.res.message.base_64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join('');
          this.download("sample.csv", decodedString);
          this.deletedata1 = [];
        })
    }
    else {
      this.alertmsg(this.languagedata.ALERTS.CHECKBOX_ALERT, 'error');
    }
  }
 
  //get Current profile Functionlity Starts here by Balaji
  currentProfile() {

    var data = {
      "id":  this.accesstoken.ad_id,
      "doc_type": "AD"
     
    }
    this.http.commonMethod(data,this.logonUrl.get_current_profile)
      .subscribe(data => {
        this.profileReport = data;
        this.contentProfile = this.profileReport.res;

      })


  }

  //CockPit Navigation
  back() {
    this.router.navigate(['/cockpit']);
  }

}
export interface Food {
  value: string;
  viewValue: string;
}
