//Alert Mesage Popup Page for
import { Component, OnInit,Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA,MatSnackBarRef} from '@angular/material';
/**
 * Page for Common Alert Message Popup
 */
@Component({
  selector: 'app-alert',
  templateUrl: './Common_Alert.component.html',
  styleUrls: ['./Common_Alert.component.css']
})
export class AlertComponent implements OnInit {
/**
 * @ignore
 */
  constructor(@Inject(MAT_SNACK_BAR_DATA) 
  public data: any,private snackBarRef: MatSnackBarRef<AlertComponent>) { }

  /**
 * Ng init Function
 */
  ngOnInit() {
    console.log(this.data);
  }
  /**
 * onActionBtnClick
 */
  onActionBtnClick() {
    this.snackBarRef.dismissWithAction();
  }

}
