//E-Recruiter Admin page for showing the E-Recruiter Candidate List
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { RadialChartOptions, ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { HttpserviceService } from '../Services/httpservice.service';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../Dialog/Dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { EncryptionService } from '../Services/encryption.service';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AlertComponent } from '../Common_Alert/Common_Alert.component';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import * as html2canvas from 'html2canvas';
import { Options, LabelType } from 'ng5-slider';
import {urlEnv} from '../../environments/env';
/**
 * Page for E-Recruiter Admin
 */
declare var jsPDF: any;
@Component({
  selector: 'app-easy-recruiter-admin',
  templateUrl: './ER_Admin.component.html',
  styleUrls: ['./ER_Admin.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [EncryptionService]
})
export class EasyRecruiterAdminComponent implements OnInit {
   /**  variable Desc
    * VAribale Declarion Start here
    */
   public logonUrl = urlEnv;
  public OnlyFav: boolean = false;
  public joblist: boolean = true;
  public candidatelist: boolean = false;
  public reportmatch: boolean = false;
  public jobheaderstrip: boolean = true;
  public dropdownfilter: boolean = false;
  public userdetails: boolean = true;
  public hideshowdynamicloop: boolean = false;
  public pagenavigator: boolean = true;

  response: any;


  menuFlag = false;
  langen: boolean = true;


  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Talents' },
    { value: 'pizza-1', viewValue: 'Marked' },
    { value: 'tacos-2', viewValue: 'New' }
  ];
  jddata: any;
  data: any;
  isLogged: string;
  sessionid: string;
  userEmail: string;
  feedbackdata: any;
  loginuser: string;
  testValue: any;
  content_ad: any;
  buuletData: any[];
  hardSkils: any[];
  softSkils: any[];
  indutries: any[];
  profession: any[];
  competencyLengthZero: boolean;
  competencyLength: boolean;
  finalBullet27: any;
  slicedcompetency: any;

  countryCtrl = new FormControl();
  stateCtrl = new FormControl();
  zipCtrl = new FormControl();


  HideExpandCompatency: boolean = true
  HideHalfCompatency: boolean = false
  slicedHardSkill: any;
  HideHalfHardSkill: boolean = false;
  HideExpandHardSkill: boolean = true;
  slicedSoftSkills: any;
  HideHalfSoftSkills: boolean = false;
  HideExpandSoftSkills: boolean = true;
  slicedIndustries: any;
  HideHalfIndustry: boolean = false;
  HideExpandIndustry: boolean = true;
  HardskillLength: boolean;
  hardSkillLengthZero: boolean;
  finalHardSkils: any;
  finalSoftSkils: any;
  // HideHalfSoftSkills: boolean;
  softskillLength: boolean;
  softSkillLengthZero: boolean;
  industryLengthZero: boolean;
  industryLength: boolean;
  HideIndusrtyTips: boolean;
  finalIndustries: any;
  industrySeparatedValue: any;
  finalprofession: any;

  professionLength: boolean;
  professionLengthZero: boolean;
  slicedprofession: any;
  HideExpandprofession: boolean;
  HideHalfprofession: boolean;
  HideProfileName: boolean;
  HideProfileNameZero: boolean;
  profileReport: any;
  contentProfile: any;


  profile_ad_values: any = [{ id: 1, value: 'Schulbildung' }, { id: 2, value: 'Lehrberuf' }, { id: 3, value: 'Studium' }, { id: 4, value: 'weiterführendes Studium' }];
  remote_value: any = [{ id: 1, value: 'Ja', checked: false }, { id: 2, value: 'Nein', checked: false }]
  accesstoken: any;
  commonloader: boolean = false;
  commonloaderAS: boolean = false;
  showheatmap: boolean = false;
  backgrounddiv_enable: boolean;
  profile_ad: any;
  profile_ad_degree: any;
  profile_ad_training: any;
  ad_idValue: any;
  countryListValues: any;
  // countryCtrl: any;
  countriesValue: any;
  cityListValue: any;
  // stateCtrl: any;
  cityvalue: any;
  // zipCtrl: any;
  zipListValue: any;
  zipvalue: any;
  countryName: any;
  sortcountryName: any;
  cityId: any;
  cityFlagvalues: { "countryid": any; };
  cityName: any;
  zipFlagvalues: {"city": any };
  profile_name: any;
  // defaultpage: Number = 10;
  // defaultpage10: boolean = true;

  countryFlagvalues: { "flag": string; "deviceid": string; "osid": string; };
  zipCodebasedCountry: {  "zipcode": any;"name": string; };
  zipcode: any;
  zipcodedata: any;
  location: any;
  country: any;
  menuFlag1: boolean;

  @ViewChild('sidenav') sidenav: MatSidenav;
  sessionvar: any;
  url: any;
  recruiter_id: string;
  adlist: any;
  newdata: any[];
  listbystatedata: any;
  counters: any;
  candidatedata: any;
  currentcandidate_adid: any;
  currentjddata: any;
  currentjddata1: any;
  jddata1: any;
  zip: any;
  currentcandidateindex: any;
  localvalue: any;
  count: any;
  candidatedata1: any;
  candidatedata1_all: any;
  count_c: any;
  selectAll = false;
  checksession1: boolean;
  currentcandidate_cvid: any;
  content_cv: any;
  currentempLengthZero: boolean;
  currentempLength: boolean;
  currentempbuuletData: any[];
  finalcv_Bullet27: any;
  HideExpandCurrentemp: boolean;
  HideHalfCurrentemp: boolean;
  slicedcurrentemp: any;
  response12: any;
  faveritedata: any;
  work_experience: any;
  likedata: any;
  interviewquestin: any;
  interviewquestin1: any;
  talentquestin: any;
  recruiterid: any;
  maxDistance: number = 1000;
  professtion_berufsgruppe: any;

  additonalfilter: boolean = false
  searchfoucusb: boolean = false

  // distance
   /** 
    * @ignore
    */
  distancevalue: number = 0;
  distancehighValue: number = this.maxDistance;
  distance: Options = {
    floor: 0,
    ceil: this.maxDistance,
    translate: (value: number, label: LabelType): string => {
      switch (value) {
        case 0:
          return value + 'km';
        default:
          return value + 'km';
      }
    }
  };


  // searchfocus
   /** 
    * @ignore
    */
  searchfocusvalue: number = 1;
  searchfocus: Options = {};

  // profession
  /** 
    * @ignore
    */
  professionvalue: number = 0;
  professionhighValue: number = 4;
  filterprofession: Options = {
    floor: 0,
    ceil: 4,
  };

  // education
   /** 
    * @ignore
    */
  educationvalue: number = 0;
  educationhighValue: number = 4;
  education: Options = {
  };

  // careerlevel
   /** 
    * @ignore
    */
  careerlevelvalue: number = 0;
  careerlevelhighValue: number = 4;
  careerlevel: Options = {
  };

  // advancedfilter
   /** 
    * @ignore
    */
  filterform: FormGroup;
  currentlanguage: any;
  languagedata: any;
  aus: any;
  currentrecruiter: any;
  timestampdata: any;
  paymentTimestamp: any;
  matchTimestamp:any;
  candidatesearch: boolean = false
  currentcandidatedata: any;
  candidateDataforPayment:any;

  constructor(
    public translate: TranslateService,
    public http: HttpserviceService,
    public dialog: MatDialog,
    private encrypt: EncryptionService,
    private formBuilder: FormBuilder,
    private router: Router,
    public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute,
  ) {

    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
      this.currentlanguage = lang
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
      this.currentlanguage = 'de'
    }

    this.countryListValues = this.countryCtrl.valueChanges
      .pipe(
        startWith(''),
        map(country => country ? this._filterCountry(country) : this.countriesValue)
      );

    this.cityListValue = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this._filterStates(state) : this.cityvalue)
      );

    this.zipListValue = this.zipCtrl.valueChanges
      .pipe(
        startWith(''),
        map(zip => zip ? this._filterZip(zip) : this.zipvalue)
      );

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
    this.jddata1 = [
      { name: "clientadid", checked: false },
      // { name: "profile_ad_id",checked: false},
    ];

  }
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  barChartPlugins: any;
  radarChartPlugins: any;
  param1: any;
  param2: any;
  redirectUrl: boolean = false;
  showChatHistory: boolean = false;
  checkCockpit: any;
  DontCheckSession: boolean = false;
  showFav = true
  showfavvv = false
   /**  function Desc
    * Init Function Starts Here
    */
  ngOnInit() {
    this.getlanguagevalue();
    // this.defaultpage = 10
    this.checkCockpit = localStorage.getItem("CockpitAdmin");
    var checkLogged = localStorage.getItem('isLogged');

    console.log("Checking Session", this.DontCheckSession);

    this.dropdownfilter = true;
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    this.recruiter_id = localStorage.getItem('recruiter_id');
    if (this.isLogged == "true") {
      this.menuFlag1 = true;
    } else {
      this.menuFlag1 = false;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail.split('@')[0];
    }
    this.accesstoken = JSON.parse(localStorage.getItem('token'));
    this.recruiter_id = localStorage.getItem('recruiter_id');

    this.backgrounddiv_enable = false;
    this.checksession();
    this.searchforms()
    // this.getmy_data()
  }
    /** 
     * Common Language Selector By balaji Sachin
     * toCheck
     */
  getlanguagevalue() {
    var language = this.currentlanguage
    this.http.getlangvalue(language)
      .subscribe(data => {
        if (data != null) {
          var details: any = data;
          this.languagedata = details
          console.log(details, "details");
          console.log(this.aus, "aus")
          this.searchfocus = {
            floor: 0,
            ceil: 5,
            translate: (value: number, label: LabelType): string => {
              switch (value) {
                case 0:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.SEARCHFOCUS.ZERO;
                case 1:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.SEARCHFOCUS.ONE;
                case 2:
                  return value + '';
                case 3:
                  return value + '';
                case 4:
                  return value + '';
                default:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.SEARCHFOCUS.MAX;
              }
            }
          }

          this.filterprofession = {
            floor: 0,
            ceil: 3,
            translate: (value: number, label: LabelType): string => {
              switch (value) {
                case 0:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.FILTERPROFESSION.ZERO;
                case 1:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.FILTERPROFESSION.ONE;
                case 2:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.FILTERPROFESSION.TWO;
                default:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.FILTERPROFESSION.THREE;
              }
            }
          }

          this.education = {
            floor: 0,
            ceil: 3,
            translate: (value: number, label: LabelType): string => {
              switch (value) {
                case 0:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.EDUCATION.ZERO;
                case 1:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.EDUCATION.ONE;
                case 2:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.EDUCATION.TWO;
                default:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.EDUCATION.THREE;
              }
            }
          }

          this.careerlevel = {
            floor: 0,
            ceil: 3,
            translate: (value: number, label: LabelType): string => {
              switch (value) {
                case 0:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.CAREERLEVEL.ZERO;
                case 1:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.CAREERLEVEL.ONE;
                case 2:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.CAREERLEVEL.TWO;
                default:
                  return this.languagedata.ERECRUITERADMIN_FILTERS.CAREERLEVEL.THREE;
              }
            }
          }
        }
        else {

        }
      }, error => {

      });
  }
    /** 
     * For Get Fav
     */
  
  GetFavorite(i) {
    this.OnlyFav = true;
    this.showFav = false
    console.log("Particular Adid",this.currentcandidateindex)
    // this.showfavvv=true this.currentrecruiter
    this.getparticular_ad_candidates(this.currentcandidateindex, "test")
  }
    /** 
     * @ignore 
     */
  GetAll() {
    this.OnlyFav = false;
    this.showFav = true
    console.log("Particular Adid",this.currentcandidateindex)
    this.checkCockpit = localStorage.getItem("CockpitAdmin");
    var checkLogged = localStorage.getItem('isLogged');
    this.getparticular_ad_candidates(this.currentcandidateindex, "test")

  }
    /** 
     * @ignore 
     */
  searchforms() {
    this.filterform = this.formBuilder.group({
      keywords: [''],
      location: ['',],
      searchfocus: ['',],
      profession: ['',],
      education: ['',],
      careerlevel: ['',],
    })
  }
  //Show Chat History
    /** 
     * @ignore 
     */
  showChat(id) {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'mat-dialog-container1',
      data: { dialogname: 'chatHistortPopup', cv_id: id.cv_id }
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }

  // search change
    /** 
     * @ignore 
     */
  changeserach_filter(value) {
    if (value == "keywordsearch") {
      this.candidatesearch = true
    } else {
      this.candidatesearch = false
    }
  }

  submitsearch() {
    //For the Filtersliders
    var filterValue = this.filterform.value.keywords
    this.candidatedata1 = this.candidatedata
    if (filterValue){
      this.candidatedata1 = this.candidatedata1.filter(item => item.keywords.toLowerCase().indexOf(filterValue) > -1 || item.cv_plain_text.toLowerCase().indexOf(filterValue) > -1);

    }
    else{
      this.candidatedata1 = this.candidatedata
    }
    
    this.candidatedata1 = this.candidatedata1.filter(item => item.malus_ausbildung == '' || (item.malus_ausbildung >= this.educationvalue && item.malus_ausbildung <= this.educationhighValue));
    this.candidatedata1 = this.candidatedata1.filter(item => item.malus_berufsgruppe == '' || ( item.malus_berufsgruppe >= this.professionvalue && item.malus_berufsgruppe <= this.professionhighValue));
    this.candidatedata1 = this.candidatedata1.filter(item => item.malus_karriere_lvl == '' || (item.malus_karriere_lvl >= this.careerlevelvalue && item.malus_karriere_lvl <= this.careerlevelhighValue));
    this.candidatedata1 = this.candidatedata1.filter(item => item.ml_distance == '' || (item.ml_distance >= this.distancevalue && item.ml_distance <= this.distancehighValue));
    //console.log("FILTERED CANDIDATE DATA",this.candidatedata1)
    
    this.candidatedata1_all = this.candidatedata1 //For the actual hits
      this.totalnoof_page_c = this.candidatedata1.length / this.jobsper_page_c
      this.totalnoof_page_c = Math.ceil(this.totalnoof_page_c)
      this.goto_search(1)
  }

  submitsearch2(searchvalue: string) {
    //KEYWORD & Plaintext FILTERING 
    this.candidatedata1 = this.candidatedata
    searchvalue = searchvalue.toString().toLowerCase();
    if (searchvalue){
      this.candidatedata1 = this.candidatedata1.filter(item => item.keywords.toLowerCase().indexOf(searchvalue) > -1 || item.cv_plain_text.toLowerCase().indexOf(searchvalue) > -1);
    }
    else{
      this.candidatedata1 = this.candidatedata
    }
    this.candidatedata1_all = this.candidatedata1 //For the actual hits
    this.totalnoof_page_c = this.candidatedata1.length / this.jobsper_page_c
    this.totalnoof_page_c = Math.ceil(this.totalnoof_page_c)
    this.goto_search(1)
  }

  //NOT IN USE
  clientside_submitsearch() {
    //KEYWORD FILTERING
    console.log("KEYWORDS FILTER:",this.filterform.value.keywords.toLowerCase());
    this.candidatedata1 = this.candidatedata.filter(function(element) {
      return element.keywords.toLowerCase() == this.filterform.value.keywords.toLowerCase();
    });
    this.goto_search(1)
  }

    /** 
     * @ignore 
     */
  advancedfilter() {
    if (this.additonalfilter) {
      this.additonalfilter = false
    } else {
      this.additonalfilter = true
    }
  }
    /** 
     * Set Like Functionlity Starts Here BY Balaji Sachin
     */
  
  setlike() {
    console.log(this.feedbackdata)
    // this.feedbackReportData

    var flagdata = {
      
      "ad_id": this.feedbackdata.ad_profile_values.ad_id,
      "cv_id": this.feedbackdata.cv_profile_values.cv_id,
      "job_status": true
    }

    this.http.commonMethod(flagdata,this.logonUrl.job_like_dislike)
      .subscribe(res => {
        if (res) {
          this.likedata = res
          if (this.likedata.status == 200) {
            console.log(this.likedata)

          } else if (this.likedata.status == 422) {

          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });
  }

  //Chart Function starts here
  // Bar Chart Starts Here
    /** 
     * @ignore 
     */
  public barChartOptions: ChartOptions = {
    responsive: true,

    // We use these empty structures as placeholders for dynamic theming.
    scales: {

      xAxes: [{
        ticks: {
          // display:true,
          beginAtZero: true
        },
        gridLines: {
          // color: "",
          display: false,
        }
      }],
      yAxes: [{
        ticks: {
          display: true,
          beginAtZero: true
        },
        gridLines: {
          // display: true,
          display: false,
          // color: "2f99af",
        }
      }]
    },
    plugins: {
      showTooltips: false,

      datalabels: {
        anchor: 'end',
        align: 'end',

      }
    },

    legend: {


      display: true
    }


  };
  public barChartLabels: Label[];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartData: ChartDataSets[] =

    [
      {
        data: [], borderColor: ['rgba(47, 153, 175, 0.7)'], backgroundColor: ['rgba(47, 153, 175, 0.7)']
      },
    ];

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59, 80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    this.barChartData[0].data = data;
  }
  // Bar chart Data Ends Here

  //Radar Chart Data Starts Here
    /** 
     * @ignore 
     */
  public radarChartLabels: Label[];
  public radarCharLegend = true;
  public radarChartData: ChartDataSets[] =
    [
      {
        data: [], borderColor: ['rgba(47, 153, 175, 0.7)'], backgroundColor: ['rgba(47, 153, 175, 0.7)']
      },
    ];
  public radarChartType: ChartType = 'radar';
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    scale: {
      ticks: {
        display: false
     }
    },
    legend: {
      display: true
    }
  };
  //Radar Chart Data Ends Here

  match_reporthide() {
    this.joblist = false;
    this.dropdownfilter = false;
    this.jobheaderstrip = false;
  }

  //Show Particular Candiate Details
    /** 
     * @ignore 
     */
  onclickmatdiv(i, value) {
    this.joblist = false;
    this.dropdownfilter = false;
    this.userdetails = true
    this.candidatelist = true;
    this.jobheaderstrip = false;
    this.OnlyFav = false;
    this.showFav = true;
    this.getparticular_ad_candidates(i, value)
  }
  //Hide and Show Match Report
  /** 
    * @ignore 
    */
  hideshowjobmatchreport(i, data) {
    this.currentcandidatedata = data
    localStorage.setItem("getParticualrIndexValue", i)
    if (this.candidatedata[i].blue_collor) {
      console.log("error")
    } else {
      this.getListByStateMatchReport(data, i)
      // this.feedbackReportData(i)
      this.candidatelist = false;
      this.reportmatch = true;
      this.jobheaderstrip = false;
      this.pagenavigator = false;
    }

  }
    /** 
     * @ignore 
     */

  loginclick() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'Logindetailspopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length != 0) {
        localStorage.setItem("userEmail", result[0].email);
        localStorage.setItem('sessionid', result[0].session);
        this.sessionid = localStorage.getItem('sessionid');
        this.userEmail = localStorage.getItem("userEmail");
        this.menuFlag1 = false;
      } else {
        this.menuFlag1 = true;
      }

    });
  }
    /** 
     * @ignore 
     */
  languageSelector(val) {
    localStorage.setItem('language', val);
    if (val == "de") {
      this.langen = true;
    } else {
      this.langen = false;
    }
  }
    /** 
     * @ignore 
     */
  onclickloopevent() {
    this.hideshowdynamicloop = !this.hideshowdynamicloop;
    if (this.hideshowdynamicloop) {
      this.hideshowdynamicloop = true;
    }
    else {
      this.hideshowdynamicloop = false;
    }

  }
  private _filterCountry(value: string) {
    const filterValue = value.toLowerCase();
    return this.countriesValue.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterStates(value: string) {
    const filterValue = value.toLowerCase();
    return this.cityvalue.filter(state => state.cityname.toLowerCase().indexOf(filterValue) === 0);
  }
  private _filterZip(value: string) {
    return this.zipvalue;
  }
  getParticularRecruiterAdid: any;
  
    /** 
     * @ignore 
     */
  gotopayment(gotovalue) {
    if (gotovalue == "payment") {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'confirmpaymentpopup', email: this.userEmail, goto: 'closeRecruiter' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.getlocalvalues();
        
      });
    } else if (gotovalue == "reg") {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'confirmpaymentpopup', email: this.userEmail, goto: 'closeRecruiter' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.getlocalvalues();
        

      });
    }
    // this.feedbackReportData();

  }
  matchReportNavigation = false
  firstTimeMatchReport = false
  RedirectParticularCandidate = false
  particularCvid: any
  MatchReportEye() {
    this.matchReportNavigation = true
    var candidate = this.getCurrentdataMatchEye
    console.log("matchReport Eye", candidate)
    var value = "matchreport";
    this.checkEye(candidate, value)
  }
//GET_E_RECRUITER_TIMESTAMP Function Starts here
    /** 
     * @ignore 
     */
  checkEye(candidatedata, value) {
    this.particularCvid = candidatedata.profile_cv_id
    console.log(this.particularCvid)
    console.log(this.currentcandidateindex)
    console.log(candidatedata, "checkeye")
    this.isLogged = localStorage.getItem('isLogged');
    if (!this.isLogged) {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'loginpopup', goto: 'payment', goto1: "reg" }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.getlocalvalues();
        if (result == "payment") {
          this.gotopayment(result);
        } else if (result == "reg") {
          this.gotopayment(result);
        }
      });
    } else {
      var flagdata = {}
      if (value == 'candidate') {
        flagdata = {
          "ad_id": candidatedata.profile_ad_id,
          "cv_id": candidatedata.profile_cv_id,
          "email": this.userEmail,
         
        }
      } else {
        flagdata = {
          "ad_id": this.feedbackdata.ad_profile_values.ad_id,
          "cv_id": this.feedbackdata.cv_profile_values.cv_id,
          "email": this.userEmail,
          
        }
        candidatedata = this.currentcandidatedata
      }
      this.http.commonMethod(flagdata,this.logonUrl.e_recruiter_timestamp)
        .subscribe(res => {
          this.response = res
          console.log("Check Payment", this.response)
          if (this.response.status == 200) {
             //logic for payment 
          // response timestamp
          // another variable for timestamp of candidate
          // if no state_history matchTimestamp= timestamp
          // else matchTimestamp´= explode timestamp_history
          if  (candidatedata.state_history!="")
          {
            this.matchTimestamp= candidatedata.state_history.split(",")[0].split("_")[1]
          }
          else
          {
            this.matchTimestamp=candidatedata.timestamp
          }
          this.paymentTimestamp=this.response.timestamp[0].timestamp
          
          //this.timestampdata = this.response.timestamp
            // console.log(parseInt(candidatedata.timestamp), parseInt(this.timestampdata[0].timestamp) + 86400, "dd")
            // console.log(parseInt(candidatedata.timestamp) == parseInt(this.timestampdata[0].timestamp) + 86400, "dd")
            //if (parseInt(candidatedata.timestampa) >= parseInt(this.timestampdata[0].timestamp) + 86400) 
            console.log("MatchTimestamp", this.matchTimestamp)
            console.log("Payment Timestamp", this.paymentTimestamp)
            if (parseInt(this.matchTimestamp) > (parseInt(this.paymentTimestamp) + 86400)) 
            {
              this.candidateDataforPayment=candidatedata
              this.candidateDataforPayment.timestamp=Math.floor(Date.now() / 1000)
              console.log("Payment Timestamp", this.candidateDataforPayment.timestamp)
            
              const dialogRef = this.dialog.open(DialogComponent, {
                disableClose: true,
                data: {
                  dialogname: 'confirmpaymentpopup',
                  email: this.userEmail,
                  goto: 'closeRecruiter',
                  candidatedata: this.candidateDataforPayment,
                  paymenttime: "first"
                }
              });
              dialogRef.afterClosed().subscribe((result: any) => {
                this.getlocalvalues();
                const dialogRef = this.dialog.open(DialogComponent, {
                  disableClose: true,
                  data: {
                    dialogname: 'paymentsuccess',
                  }
                });
                dialogRef.afterClosed().subscribe((result: any) => {

                });

              });
            } else{
              if (value == "candidate") {
                window.open(candidatedata.profile_link_cv, '_blank');
              } else {
                window.open(this.ProfileLink, '_blank');
              }
            }
          } else if (this.response.status == 422) {
            console.log("Time Stamp error")
            this.firstTimeMatchReport = true
            const dialogRef = this.dialog.open(DialogComponent, {
              disableClose: true,
              data: {
                dialogname: 'confirmpaymentpopup',
                email: this.userEmail,
                candidatedata: candidatedata,
                goto: 'closeRecruiter',
                paymenttime: "first"
              }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
              this.getlocalvalues();
              if (result == 'success') {
                const dialogRef = this.dialog.open(DialogComponent, {
                  disableClose: true,
                  data: {
                    dialogname: 'paymentsuccess',
                  }
                });
                dialogRef.afterClosed().subscribe((result: any) => {

                 
                  if (value == 'candidate') {
                    this.RedirectParticularCandidate = true
                    console.log("calling Particular Adid");
                    console.log("Current Adid",this.currentcandidateindex)
                    this.getparticular_ad_candidates(this.currentcandidateindex, "")
                  } else {
                    console.log(this.currentcandidateindex)
                    console.log("calling Match Report");
                    this.candidatelist = false;
                    this.reportmatch = true;
                    this.jobheaderstrip = false;
                    this.pagenavigator = false;
                    this.feedbackReportData(this.currentcandidateindex)
                  }
                });

              }
            });

          }
        }, error => {

        });
    }
  }
   /** 
    * Method Desc
    * Common Session Check in All Page
    */
 
  checksession() {
    var test = this.isLogged
    var data = {
        "email": this.userEmail,
        "sessionid": this.sessionid,
    }
    this.http.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          this.sessionvar = res
          if (this.sessionvar.status == 200) {
            this.checksession1 = true
            var ActiveSourceAdid = sessionStorage.getItem("ActiveSurceAdid");
            this.checkCockpit = localStorage.getItem("CockpitAdmin");
            console.log("Comin From CockpitAdmin")
            this.getmy_data()

          } else if (this.sessionvar.status == 422) {
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.isLogged = localStorage.getItem('isLogged');
            this.sessionid = localStorage.getItem('sessionid');
            this.userEmail = localStorage.getItem('userEmail');
            this.loginuser = ""
            this.menuFlag1 = false;
            if (test == "true") {
              this.alertmsg(this.sessionvar.message, 'error');
            }
            this.checksession1 = false
            this.router.navigate(['/'])
            return false
          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });
    // this.deletecount = 0
  }

  menuclicksessioncheck(value) {
    var data = {
        "email": this.userEmail,
        "sessionid": this.sessionid,
    }
    this.http.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          this.sessionvar = res
          if (this.sessionvar.status == 200) {
            if (value == "changepassword") {
              this.changepasswords()
            } else if (value == "easyapply") {
              // this.geteasyapplylink()
              this.router.navigate(['/cvadmin'])
            } else if (value == "admin") {
              this.router.navigate(['/daadmin'])
            } else if (value == "directapply") {
              this.openDialog3()
            }
          } else if (this.sessionvar.status == 422) {
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.loginuser = ""
            this.alertmsg(this.sessionvar.message, 'error');
            this.menuFlag1 = false;
            this.menuFlag = false;
            this.router.navigate(['/'])
          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });

  }
  close(reason: string) {
    this.sidenav.close();
  }
     /**
      * Common Alert Message
      * @example msg,Errtype
      */
  
  alertmsg(msg, errtype) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: { data: msg, classname: errtype },
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'blue-snackbar'
    });
  }
   /** 
    * @ignore 
    */
  getlocalvalues() {
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    if (this.isLogged == "true") {
      this.menuFlag1 = true;
    } else {
      this.menuFlag1 = false;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail.split('@')[0];
    }
  }
   /** 
    * @ignore 
    */
  openDialog3() {
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'confirmpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getlocalvalues();
      // this.getLinkbtn = true;
      // this.TLI_chat = true;
      this.feedbackReportData("");
    });
  }
    /**
     * Common Change Passwords For All Pages
     * @function Change
     */
  changepasswords() {
    // this.checksession();
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'changepasswordpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }

  // country, city and zipcode methods
  /**
   * @ignore
   */
  countriesList() {
    this.http.commonMethodGet(this.logonUrl.getCountries)
      .subscribe(data => {
        this.countriesValue = data;
        this.countriesValue = this.countriesValue.countries;
      });
  }
  /**
   * @ignore
   */
  citiesList(data) {
    var id = data.id;
    this.countryName = data.name;
    this.sortcountryName = data.sortname
    this.cityId = id;

    this.cityFlagvalues = {
     
      "countryid": id,
    }
    this.http.commonMethod(this.cityFlagvalues,this.logonUrl.getCities)
      .subscribe(data => {
        this.cityvalue = data;
        this.cityvalue = this.cityvalue.cities;
      });
  }
  /**
   * @ignore
   */
  zipcodeData(data) {
    this.cityName = data
    this.zipFlagvalues = {
      "city": this.cityName,
    }
    this.http.commonMethod(this.zipFlagvalues,this.logonUrl.getZipcodes)
      .subscribe(data => {
        this.zipvalue = data;
        this.zipvalue = this.zipvalue.zipcodes;
      })
  }
   /**
    * @ignore
    */
  zipCodeCountry(zip) {
    this.zipCodebasedCountry = {
     "zipcode": zip,
     "name": "Germany"
    }
    this.zipcode = zip
    this.http.commonMethod(this.zipCodebasedCountry,this.logonUrl.getZipCity)
      .subscribe(data => {
        this.zipcodedata = data
        // console.log(this.zipcodedata.city[0].cityname)
        if (this.zipcodedata) {
          if (this.zipcodedata.city) {
            this.location = this.zipcodedata.city[0].cityname
            this.country = this.zipcodedata.city[0].countyname
            this.zipcode = zip
          }
        }

      })
  }

  // pagination  Starts job
  /**
   * @ignore
   */
 currentpage: any = 1;
  totalnoof_page: any;
  // jobsper_page: any = 10;
  pageperjobs = ["10", "20", "50", "100"]
  jobsper_page: any = 10;
  // pageperjobs = [2, 4, 6, 100];
  gotovalue: number;
  /**
   * @ignore
   */
  setpageper_jobs(value) {
    this.jobsper_page = value
    this.totalnoof_page = this.jddata.length / this.jobsper_page
    this.totalnoof_page = Math.ceil(this.totalnoof_page)
    this.goto(1);
  }
  /**
   * @ignore
   */
  previous() {
    if (this.currentpage == 1) {
      // alert("No previous page exists");
      alert(this.languagedata.ALERTS.PREVIOUS);
    } else {
      this.currentpage = this.currentpage - 1

      var count = this.count - this.jobsper_page
      if ((count % 10 != 0) && count >= 11){
        var dif = count % 10
        count = count - dif
      }
      var index = count - 10
      this.count = count
      if(count < 10){
        count = 10
        index = 0
      }
      else if(count == 10) {index = 0}
      
      var demo = this.jddata.slice(index, count);
      this.jddata1 = demo

      // this.currentpage = this.currentpage - 1
      // var count = this.count - this.jobsper_page
      // var index = count - this.jobsper_page
      // if (index < 0) {
      //   index = 0
      // }

      // this.count = count
      // var demo = this.jddata.slice(index, count);
      // this.jddata1 = demo
    }
  }
  /**
   * @ignore
   */
  next() {
    var index = this.jobsper_page * this.currentpage
    if ((this.currentpage + 1) > this.totalnoof_page) {
      // alert("No next page exists");
      alert(this.languagedata.ALERTS.NEXT);
    } else {
      this.currentpage = this.currentpage + 1
      var count = index + this.jobsper_page
      if (count > this.jddata.length - 1) {
        count = this.jddata.length
      }
      this.count = count
      var demo = this.jddata.slice(index, count);
      this.jddata1 = demo

      // this.jddata.findIndex(item => item.expression == v.expression))
    }
  }
  /**
   * @ignore
   */
  gotopage(event) {
    // alert(event)
    // var got = parseInt(event.target.value)

    if (event <= this.totalnoof_page && event > 0) {
      this.currentpage = event
      var index = (this.jobsper_page * this.currentpage) - this.jobsper_page
      var count = index + this.jobsper_page
      var demo = this.jddata.slice(index, count);
      this.count = count
      this.jddata1 = demo
    } else {
      console.log("no page to show")
    }
  }
  /**
   * @ignore
   */
  goto(value) {
    if (!value) {
      value = 1
    } else {

    }
    var index = this.jobsper_page * this.currentpage - 1
    this.currentpage = value
    var demo = this.jddata.slice(0, this.jobsper_page);
    this.jddata1 = demo
  }
 // pagination job end

// pagination candidate
  /**
   * @ignore
   */
  currentpage_c: any = 1;
  totalnoof_page_c: any;
  jobsper_page_c: any = 10;
  pageperjobs_c = ["10", "20", "50", "100"]
 
  gotovalue_c: number;
  totalPages = [];
  /**
   * @ignore
   */
  setpageper_candidate(value) {
    this.jobsper_page_c = value;
    this.totalnoof_page_c = this.candidatedata.length / this.jobsper_page_c
    this.totalnoof_page_c = Math.ceil(this.totalnoof_page_c)
    this.goto_c(1);
  }
  /**
   * @ignore
   */
  previous_c() {
    if (this.currentpage_c == 1) {
      alert(this.languagedata.ALERTS.PREVIOUS);
    } else {
      this.currentpage_c = this.currentpage_c - 1

      var count = this.count_c - this.jobsper_page_c
      if ((count % 10 != 0) && count >= 11){
        var dif = count % 10
        count = count - dif
      }
      var index = count - 10
      this.count_c = count
      if(count < 10){
        count = 10
        index = 0
      }
      else if(count == 10) {index = 0}
      
      var demo = this.candidatedata.slice(index, count);
      this.candidatedata1 = demo

    }
  }
  /**
   * @ignore
   */
  next_c() {
    var index = this.jobsper_page_c * this.currentpage_c
    if ((this.currentpage_c + 1) > this.totalnoof_page_c) {
      // alert("No next page exists");
      alert(this.languagedata.ALERTS.NEXT);
    } else {
      this.currentpage_c = this.currentpage_c + 1
      var count = index + this.jobsper_page_c
      if (count > this.candidatedata.length - 1) {
        count = this.candidatedata.length
      }
      this.count_c = count
      var demo = this.candidatedata.slice(index, count);
      this.candidatedata1 = demo

     
    }
  }
  /**
   * @ignore
   */
  gotopage_c(event) {
    var got = parseInt(event.target.value)
    if (got <= this.totalnoof_page_c && got > 0) {
      this.currentpage_c = got
      var index = (this.jobsper_page_c * this.currentpage_c) - this.jobsper_page_c
      var count = index + this.jobsper_page_c
      var demo = this.candidatedata.slice(index, count);
      this.count_c = count
      this.candidatedata1 = demo
    } else {
      console.log("no page to show")
    }
  }
  /**
   * @ignore
   */
  goto_c(value) {
    if (!value) {
      value = 1
    } else {

    }
    var index = this.jobsper_page_c * this.currentpage_c - 1
    this.currentpage_c = value
    var demo = this.candidatedata.slice(0, this.jobsper_page_c);
    this.candidatedata1 = demo
  }

  // pagination candidate end
  /**
   * Back Button to Show List based on Condition
   */

  back1(value) {

    if (value == 'candidatelist') {
      this.onclickmatdiv(this.currentcandidateindex, "")
      this.reportmatch = false;
    } else if (value == 'joblist') {
      if(this.additonalfilter){
        this.additonalfilter = false
      }
      // if (this.checkCockpit == 'cockpit') {
      this.joblist = true;
      this.candidatelist = false;
      
      this.reportmatch = false;
      this.jobheaderstrip = true;
      this.dropdownfilter = true;
      this.userdetails = false;
      this.hideshowdynamicloop = false;
      this.pagenavigator = true;
      this.getmy_data()

    } else if (value == "cockpit") {
      this.router.navigate(["/cockpit"]);
    }
  }

  /**
   * Set Fav functionlity for Candiate List By Balaji Sachin
   */
  set_favert(i, value) {
    var current = this.candidatedata1[i]
    var flag = {
     
      "method": "AUTH",
      "mode": "candidate",
      "id_list": [
        {
          "ad_id": current.profile_ad_id,
          "cv_id": current.profile_cv_id,
          "recruiter_id": this.recruiter_id,
          "organisation_id": "111",
          "mode": "job"
        }
      ]
      
    }

    this.http.commonMethod(flag,this.logonUrl.set_favorite)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {
          this.getparticular_ad_candidates(this.currentcandidateindex, "")
        } else {

        }
      })
  }



  //Call GetActive Source
  /**
   * @ignore
   */
  callGetActiveSource() {
    this.GetActiveSouce()
    this.commonloader = true
  }
  getActiveSourceData: any
  getActiveSourceAdid: any
  GetActiveSouce() {
    console.log(this.currentrecruiter, 'dfsd')
    var advalue = JSON.parse(localStorage.getItem('eraddetails'))
    this.accesstoken = JSON.parse(localStorage.getItem('token'))
    var getActiveSource = {
      "method":"AUTH",
      "title": localStorage.getItem("JobTitleRecruiter"),
      "base64": "",
      "extension": "",
      "link": "",
      "ad_id": this.currentrecruiter.profile_ad_id,
      "email": this.userEmail,
      "zip_code": this.currentrecruiter.zip,
      "country_code": this.currentrecruiter.country_code,
      "search_distance":"100",
      "semantic_search":[]
    }
    this.http.commonMethod(getActiveSource,this.logonUrl.get_active_sourcing)
      .subscribe(data => {

        this.getActiveSourceData = data;
        if (this.getActiveSourceData.status == 200) {
          console.log("ActiveSourceData", this.getActiveSourceData);
          this.getActiveSourceAdid = this.getActiveSourceData.res.header.parameter.ad_id;
          console.log(this.getActiveSourceAdid);
          this.getAD_data1(this.currentrecruiter.profile_ad_id)
          this.commonloader = false; // Closes the loading screen popups
          this.commonloaderAS = false;
          const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: false,
            data: { dialogname: 'activesourcingmessage' },
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            // this.getmy_data()
          });

        }

      });
  }

  KeywordsExpand = false;
  initialKeywords = true
  ExpandKeyWords() {
    // alert(this.initialKeywords)
    this.KeywordsExpand = true
    this.initialKeywords = false;
  }
  changeToInitial() {
    this.KeywordsExpand = false
    this.initialKeywords = true;
  }
  /**
   * @ignore
   */
  getAD_data1(value) {
    this.commonloader = true;
    console.log(value, "getaddata")
    var flagdata = { 
      "method": "AUTH",
      "id_list": value,
      "ad_id":""
    }
    this.http.commonMethod(flagdata,this.logonUrl.get_ad_data)
      .subscribe(res => {
        console.log(res, 'GEt AD data');
        this.commonloader = false;
        this.response = res
        if (this.response.status == 200) {
          console.log(this.response, "response")
          this.router.navigate(['/erecruiterAdmin'])
        } else {

        }
      })
  }

  rerunActiveSourcing(i,data)
  {
    this.commonloaderAS = true; // Opens the loading screen popup
    this.currentrecruiter = this.jddata1[i]
    this.callGetActiveSource()
  }

  /**
   * @ignore
   */
  startTLIchat(i, data) {
    var current = this.jddata1[i]
    console.log(current, "current")
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'mat-dialog-container1',
      data: { dialogname: 'linkpopup', forTLIchat: "yes" }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result)
      if (result) {
        var data = {
         
          "ad_id": current.ad_id,
          "chat_status": true,
        }
        this.http.commonMethod(data,this.logonUrl.chat_status_checking)
          .subscribe(res => {
            this.response = res
            if (this.response.status == 200) {
              const dialogRef = this.dialog.open(DialogComponent, {
                disableClose: false,
                data: { dialogname: 'activesourcingmessage' },
              });
              dialogRef.afterClosed().subscribe((result: any) => {
                console.log("su")
                this.getmy_data()
              });

            } else {

            }
          })
      } else {

      }
    });
  }
   /** @param
    *  Recruiter Login
    */
  
  getparticular_ad_candidates(i, option) {
    console.log("getparticular_ad_candidates values:",i)
    if (i !== "") {
      this.currentrecruiter = this.jddata1[i]
      this.currentcandidate_adid = this.jddata1[i].profile_ad_id
    } else {
      console.log("else")
    }
    this.currentcandidateindex = i
    this.commonloader = true;
    this.currentjddata = this.jddata1[i]
    console.log("Current JdData",this.jddata1)
    var flagdata = {
      "method": "AUTH",
      "document_type": "AD",
      "state_list": "",
      "id_list": this.currentcandidate_adid,
      "e_recruiter": true,
      "return_json_attribute":"1",
      "return_plain_text":"1",
      "last_two_projects":"1"
    }

    this.http.commonMethod(flagdata,this.logonUrl.get_list_by_state_active_sourcing)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {

          this.candidatedata = this.response.res.message
          //Change "Umkreisfilter" Max-Value
          this.changemaxdistance_from_distancefilter()
          
          this.candidatedata1_all = this.response.res.message
          console.log("GetList by State", this.candidatedata)
          console.log("Particular CvId", this.particularCvid)
          if (this.RedirectParticularCandidate) {

            for (var i = 0; i < this.candidatedata.length; i++) {
              if (this.candidatedata[i].profile_cv_id == this.particularCvid) {
                window.open(this.candidatedata[i].profile_link_cv, '_blank')
                console.log(this.candidatedata[i].profile_link_cv)
              }
            }
          }
          this.RedirectParticularCandidate = false
          for (var i = 0; i < this.candidatedata.length; i++) {
            if (this.candidatedata[i].keywords) {
              this.candidatedata[i].keywords = this.candidatedata[i].keywords_cv
              this.candidatedata[i].keywordshead = this.candidatedata[i].keywords.split("/")[0];
            }
          }
          if (this.candidatedata) {
            var data = JSON.stringify(this.candidatedata)
            var current = this.candidatedata[0]
            var data1 = {
              
              "method": "AUTH",
              "ad_id": this.currentcandidate_adid,
              "cv_id": "",
              "recruiter_id": this.recruiter_id,
              "organisation_id": "111",
              "mode": "job",
            }
            this.http.commonMethod(data1,this.logonUrl.get_favorite)
              .subscribe(res => {
                this.response = res
                if (this.response.status == 200) {
                  var dd = this.response.res.message.job.favorite[this.recruiter_id]

                  if (dd) {
                    for (var i = 0; i < dd.length; i++) {
                      for (var j = 0; j < this.candidatedata.length; j++) {
                        if (this.candidatedata[j].profile_cv_id == dd[i]) {
                          this.candidatedata[j].favorite = true

                        }
                      }
                    }
                  }
                  // console.log(this.OnlyFav)
                  if (this.OnlyFav == true) {
                    var demo = []
                    for (var x = 0; x < this.candidatedata.length; x++) {
                      if (this.candidatedata[x].favorite == true) {
                        demo.push(this.candidatedata[x])
                      }
                    }
                    this.candidatedata = demo
                    this.candidatedata1 = this.candidatedata

                    this.totalnoof_page_c = this.candidatedata.length / this.jobsper_page_c
                    this.totalnoof_page_c = Math.ceil(this.totalnoof_page_c)
                    this.goto_c(1)
                    this.commonloader = false;
                  } else {
                    if (option == 'markerdcount') {
                      var demo = []
                      for (var k = 0; k < this.candidatedata.length; k++) {
                        if (this.candidatedata[k].favorite) {
                          demo.push(this.candidatedata[k])
                        }
                      }
                      this.candidatedata = demo
                    } else if (option == 'newcount') {
                      var demo = []
                      for (var k = 0; k < this.candidatedata.length; k++) {
                        if (this.candidatedata[k].state == '70') {
                          demo.push(this.candidatedata[k])
                        }
                      }
                      this.candidatedata = demo
                    }
                    this.candidatedata1 = this.candidatedata

                    this.totalnoof_page_c = this.candidatedata.length / this.jobsper_page_c
                    this.totalnoof_page_c = Math.ceil(this.totalnoof_page_c)
                    this.goto_c(1)
                    this.commonloader = false;
                    console.log("Particular Candidate", this.candidatedata1)
                  }

                } else {

                }
              })


          }
        } else {

        }
      })
    // console.log(this.candidatedata, "candidatedata")

  }

  changemaxdistance_from_distancefilter(){
    var current_max_distance:any = 0;
    current_max_distance = Math.ceil(Math.max.apply(Math, this.candidatedata.map(function(o) { return o.ml_distance; })))
    
    if(current_max_distance == 0 || current_max_distance == ""){
      console.log("No Maxdistance found: maxDistance is set to 1000")
      this.maxDistance = 1000
    }
    else{
      this.maxDistance = current_max_distance
      console.log("Maxdistance: maxDistance is ", this.maxDistance, "km")
    }
    
    //Configurate max value of the "Umkreisfilter" in the candidate list
    this.distancevalue= 0
    this.distancehighValue = this.maxDistance
    this.distance = {
      floor: 0,
      ceil: this.maxDistance,
    translate: (value: number, label: LabelType): string => {
      switch (value) {
        case 0:
          return value + 'km';
        default:
          return value + 'km';
        }
      }
    };
  }

    /**
     * Calling Get My Data 
     */
  getmy_data() {
    var getRecruiter =
    {
      "email": this.userEmail,
      "e_recruiter": true
    }
    this.commonloader = true
    this.http.commonMethod(getRecruiter,this.logonUrl.e_recruiter_ids)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {
          console.log(this.response, 'RecruiterDatas');
          if (this.response.res) {
            this.adlist = this.response.res
            var ad = '';
            for (var i = 0; i < this.adlist.length; i++) {
              if (i > 0) {
                ad = ad.concat(',')
              }
              ad = ad.concat(this.adlist[i].ad_id)
            }

            if (ad) {
              this.getAD_data(ad);
            }
           
          } else {
            this.jddata1 = []
            this.commonloader = false;
          }
        } else {
          this.commonloader = false;
        }
      })

  }
  getAD_data(value) {
    var flagdata = {
      "method": "AUTH",
      "id_list": value,
      "ad_id":""
    }
    this.http.commonMethod(flagdata,this.logonUrl.get_ad_data)
      .subscribe(res => {
        this.response = res
        if (this.response.status == 200) {
          this.jddata = this.response.res.message

          this.jddata = this.jddata.sort(this.comp);
          this.datesort("ascending")
          for (var i = 0; i < this.jddata.length; i++) {
            for (var j = 0; j < this.response.res.link_data.length; j++) {
              if (this.jddata[i].profile_ad_id && this.response.res.link_data[j]) {
                if (this.jddata[i].profile_ad_id == this.response.res.link_data[j].ad_id) {
                  this.jddata[i].clientadid = this.response.res.link_data[j].client_ad_id
                  this.jddata[i].ad_id = this.response.res.link_data[j].ad_id
                  this.jddata[i].internal_reference = this.response.res.link_data[j].internal_reference
                }
              }
            }
          }
          //this.getlistbystate(value)
          this.getlistbystateactivesourcing(value)
          
        } else {

        }
      })
  }
  keywordsearch() {
    var filterValue = this.filterform.value.keywords
    console.log(filterValue, "filterValue")
    console.log(this.candidatedata1)
    this.candidatedata1 = this.candidatedata.filter(item => item.keywords.toLowerCase().indexOf(filterValue) > -1);
    console.log(this.candidatedata1)

    this.totalnoof_page_c = this.candidatedata.length / this.jobsper_page_c
    this.totalnoof_page_c = Math.ceil(this.totalnoof_page_c)
    this.goto_search(1)

  }
  goto_search(value) {
    if (!value) {
      value = 1
    } else {

    }
    var index = this.jobsper_page_c * this.currentpage_c - 1
    this.currentpage_c = value
    var demo = this.candidatedata1.slice(0, this.jobsper_page_c);
    this.candidatedata1 = demo
  }


  //Get_list_by_state for Check_match_report
   /**
    * @ignore
    */
  getTimestamp: any
  getCurrentdataMatchEye: any
  getListByStateMatchReport(data, i) {
    console.log(data)
    var getcurrentdata = data;
    var flagdata = {
      "ad_id": getcurrentdata.profile_ad_id,
      "cv_id": getcurrentdata.profile_cv_id,
      "email": this.userEmail,
    }


    this.http.commonMethod(flagdata,this.logonUrl.e_recruiter_timestamp)
      .subscribe(res => {
        this.getTimestamp = res
        console.log("Time Stamp Data", this.getTimestamp)
       
        if (this.getTimestamp.status == 200) {
          console.log("time Stamp Exists")
          this.feedbackReportData(i)
          this.getCurrentdataMatchEye = data;
          if (getcurrentdata.timestamp >= this.getTimestamp.timestamp[0].timestamp + 86400) {
            console.log("coming timestamp")
            this.feedbackReportData(i)
          } else {
            this.feedbackReportData(i)
          }
        }
        else {
          console.log("time Stamp Not Exists")
          this.feedbackReportData(i)
          this.getCurrentdataMatchEye = data;
        }
      })
  }
   /**
    * @ignore
    */
  getlistbystate(value) {
    var flagdata = {
      "method": "AUTH",
      "document_type": "AD",
      "state_list": "",
      "id_list": value,
      "e_recruiter": true
    }

    this.http.commonMethod(flagdata,this.logonUrl.get_list_by_state)
      .subscribe(res => {
        this.ArrayTest = []
        this.response = res
        if (this.response.status == 200) {
          this.counters = this.response.res.message
          var newvalue = value.replace(/,/g, "','")
          newvalue = newvalue.toString()
          var data12 = {
           
            "ad_id": "",
            "method": "AUTH",
            "id_list": [newvalue],
            "cv_id": "",
            "recruiter_id": "",
            "organisation_id": "",
            "mode": "job",
          }
          this.http.commonMethod(data12,this.logonUrl.get_favorite)
            .subscribe(res => {
              this.response12 = res
              if (this.response12.status == 200) {
                // this.AD_setstate()
                this.faveritedata = this.response12.res.message.job.favorite

                for (var i = 0; i < this.jddata.length; i++) {
                  this.jddata[i].newcount = 0
                  this.jddata[i].markerdcount = 0
                  this.jddata[i].appyledcount = 0
                  if (this.counters) {
                    for (var j = 0; j < this.counters.length; j++) {
                      if (this.jddata[i].profile_ad_id == this.counters[j].profile_ad_id) {
                        if (this.counters[j].state == '70') {
                          this.jddata[i].newcount++;
                          // this.jddata[i].newcountArry.push()
                        }
                        
                        if (this.counters[j].state == '1000' || this.counters[j].state == '1001' || this.counters[j].state == '1002' || this.counters[j].state == '1003'
                          || this.counters[j].state == '1004' || this.counters[j].state == '1005') {
                          this.jddata[i].appyledcount++
                        }
                      }
                    }

                    // }else{

                  }
                }
                var keys = Object.keys(this.faveritedata);
                var len = keys

                for (var i = 0; i < this.jddata.length; i++) {
                  this.jddata[i].markerdcount = 0
                  for (var j = 0; j < len.length; j++) {
                    if (this.jddata[i].profile_ad_id == len[j]) {
                      this.jddata[i].markerdcount = this.faveritedata[len[j]].length
                      this.jddata[i].markedvalue = this.faveritedata[len[j]]
                    }
                  }

                }

                this.jddata = this.jddata.sort(this.comp);
                this.datesort("ascending")
                this.jddata1 = this.jddata
                console.log(this.jddata)
                this.totalnoof_page = this.jddata.length / this.jobsper_page
                this.totalnoof_page = Math.ceil(this.totalnoof_page)
                for (var i = 1; i < this.totalnoof_page + 1; i++) {
                  this.ArrayTest.push(i)
                }
                this.goto(1)
                this.commonloader = false;
              } else {
                this.commonloader = false;
              }
            })
        } else {
        }
      })
  }
  getlistbystateactivesourcing(value) {
    var flagdata = {
      "method": "AUTH",
      "document_type": "AD",
      "state_list": "",
      "id_list": value,
      "e_recruiter": true,
      "return_json_attribute":"1",
      "return_plain_text":"1",
      "last_two_projects":"1"
    }
    this.http.commonMethod(flagdata,this.logonUrl.get_list_by_state_active_sourcing)
      .subscribe(res => {
        this.ArrayTest = []
        this.response = res
        if (this.response.status == 200) {
          this.counters = this.response.res.message
          var newvalue = value.replace(/,/g, "','")
          newvalue = newvalue.toString()
          var data12 = {
           
            "ad_id": "",
            "method": "AUTH",
            "id_list": [newvalue],
            "cv_id": "",
            "recruiter_id": "",
            "organisation_id": "",
            "mode": "job",
          }
          this.http.commonMethod(data12,this.logonUrl.get_favorite)
            .subscribe(res => {
              this.response12 = res
              if (this.response12.status == 200) {
                // this.AD_setstate()
                this.faveritedata = this.response12.res.message.job.favorite
                for (var i = 0; i < this.jddata.length; i++) {
                  this.jddata[i].newcount = 0
                  this.jddata[i].markerdcount = 0
                  this.jddata[i].appyledcount = 0
                  if (this.counters) {
                    for (var j = 0; j < this.counters.length; j++) {
                      if (this.jddata[i].profile_ad_id == this.counters[j].profile_ad_id) {
                        if (this.counters[j].state == '70') {
                          this.jddata[i].newcount++;
                          // this.jddata[i].newcountArry.push()
                        }
                        
                        if (this.counters[j].state == '1000' || this.counters[j].state == '1001' || this.counters[j].state == '1002' || this.counters[j].state == '1003'
                        || this.counters[j].state == '1004' || this.counters[j].state == '1005') {
                          this.jddata[i].appyledcount++
                        }
                      }
                    }
                    // }else{
                  }
                }
                var keys = Object.keys(this.faveritedata);
                var len = keys
                for (var i = 0; i < this.jddata.length; i++) {
                  this.jddata[i].markerdcount = 0
                  for (var j = 0; j < len.length; j++) {
                    if (this.jddata[i].profile_ad_id == len[j]) {
                      this.jddata[i].markerdcount = this.faveritedata[len[j]].length
                      this.jddata[i].markedvalue = this.faveritedata[len[j]]
                    }
                  }
                }
                this.jddata = this.jddata.sort(this.comp);
                this.datesort("ascending")
                this.candidatedata1_all = [] 

                this.jddata1 = this.jddata
                console.log(this.jddata)
                this.totalnoof_page = this.jddata.length / this.jobsper_page
                this.totalnoof_page = Math.ceil(this.totalnoof_page)
                for (var i = 1; i < this.totalnoof_page + 1; i++) {
                  this.ArrayTest.push(i)

                }

                this.goto(1)
                this.commonloader = false;
              } else {
                this.commonloader = false;
              }
            })
        } else {
        }
      })
  }

  ArrayTest = []
  ArrayForTest = ["1","2"];

  HideMoreBtnKeywords = true
  // showParticularIndex :any
  currentIndex: any = null;
    /**
     * @ignore
     */
  showFullKeywords(i, data) {
    this.currentIndex = i;
    // this.showParticularIndex = i
    this.HideMoreBtnKeywords = false
  }
   /**
    * @ignore
    */
  showhalfKeywords(i, data) {
    this.currentIndex = null;
    this.HideMoreBtnKeywords = true
  }

  HideHeadmaps = true
  // showParticularIndex :any
    /**
     * @ignore
     */
  showHeadmaps(i, data) {
    this.currentIndex = i;
    this.HideHeadmaps = false
  }
   /**
    * @ignore
    */
  hideHeadmaps(i, data) {
    this.currentIndex = null;
    this.HideHeadmaps = true
  }


   /**
    * @ignore
    */
  comp(a, b) {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  }

  talentsasc: boolean = false
  markedasc: boolean = false
  datedasc: boolean = false
  newasc: boolean = false

  /**
   * @ignore
   */
  talentsasds(a, b) {
    return b.appyledcount - a.appyledcount;
  }
   /**
    * @ignore
    */
  talentsasdsdes(a, b) {
    return a.appyledcount - b.appyledcount;
  }
   /**
    * @ignore
    */
  talentsort(value) {
    if (value == "descending") {
      this.talentsasc = true
      console.log(this.jddata)
      this.jddata = this.jddata.sort(this.talentsasdsdes);
      this.jddata1 = this.jddata
      this.goto(1)
    } else {
      this.talentsasc = false
      this.jddata = this.jddata.sort(this.talentsasds);
      this.jddata1 = this.jddata
      this.goto(1)
    }

  }
   /**
    * @ignore
    */
  markeddec(a, b) {
    return b.markerdcount - a.markerdcount;
  }
   /**
    * @ignore
    */
  markedasec(a, b) {
    return a.markerdcount - b.markerdcount;
  }

   /**
    * @ignore
    */
  markedsort(value) {
    if (value == "descending") {
      this.markedasc = true
      this.jddata = this.jddata.sort(this.markeddec);
      this.jddata1 = this.jddata
      this.goto(1)
    } else {
      this.markedasc = false
      this.jddata = this.jddata.sort(this.markedasec);
      this.jddata1 = this.jddata
      this.goto(1)
    }
  }
  /**
   * @ignore
   */
  datesasds(a, b) {
    let b_date = b.ad_create_timestamp
    let a_date = a.ad_create_timestamp
    return b_date - a_date;
  }
   /**
    * @ignore
    */
  datesasdsdes(a, b) {
    let b_date = b.ad_create_timestamp
    let a_date = a.ad_create_timestamp
    return a_date - b_date;
  }
   /**
    * @ignore
    */
  datesort(value) {
    if (value == "descending") {
      this.datedasc = true
      this.jddata = this.jddata.sort(this.datesasdsdes);
      this.jddata1 = this.jddata
      this.goto(1)
    } else {
      this.datedasc = false
      this.jddata = this.jddata.sort(this.datesasds);
      this.jddata1 = this.jddata
      this.goto(1)

    }
  }

  /**
   * @ignore
   */
  newdec(a, b) {
    return b.newcount - a.newcount;
  }
   /**
    * @ignore
    */
  newasec(a, b) {
    return a.newcount - b.newcount;
  }
   /**
    * @ignore
    */
  newsort(value) {
    if (value == "descending") {
      this.newasc = true
      this.jddata = this.jddata.sort(this.newdec);
      this.jddata1 = this.jddata
      this.goto(1)
    } else {
      this.newasc = false
      this.jddata = this.jddata.sort(this.newasec);
      this.jddata1 = this.jddata
      this.goto(1)

    }
  }


  //newdata1 : any [];
  /**
   * @ignore
   */
  docheckall() {
    if (this.selectAll === true) {
      this.jddata1.map((cid) => {
        cid.checked = true;
        this.deletedata.push(cid.profile_ad_id);

      });
    } else {
      this.jddata1.map((cid) => {
        cid.checked = false;
        this.deletedata = []
      });
    }

  }
  /**
   * @ignore
   */
  docheckallcv() {
    if (this.selectAll === true) {
      this.candidatedata1.map((cid) => {
        cid.checked = true;
        this.deletedata1.push(cid.profile_cv_id);

      });
    } else {
      this.candidatedata1.map((cid) => {
        cid.checked = false;
        this.deletedata1 = []
      });
    }

  }

  deletedata: any = [];
   /**
    * @ignore
    */
  addtodelete(i, event) {
    this.newdata = []
    if (event.checked) {
      this.deletedata.push(this.jddata1[i].profile_ad_id)
    } else {
      if (this.deletedata.length >= 1) {
        var index = this.deletedata.indexOf(this.jddata1[i].profile_ad_id);
        if (index > -1) {
          this.deletedata.splice(index, 1);
        }
        // this.deletedata = this.newdata
      }
    }
    console.log("Checked Data", this.deletedata)
  }


  deletedata1: any = [];
  addtodeletecv(i, event) {
    this.newdata = []
    if (event.checked) {
      this.deletedata1.push(this.candidatedata1[i].profile_cv_id);
    } else {
      if (this.deletedata1.length >= 1) {
        var index = this.deletedata1.indexOf(this.candidatedata1[i].profile_cv_id);
        if (index > -1) {
          this.deletedata1.splice(index, 1);
        }
        console.log("Checked Data", this.deletedata1)
      }
    }
  }
   /**
    * @ignore
    */
  gotofeedback(data) {
    this.localvalue = {
      access_token: this.accesstoken.access_token,
      ad_id: data.ad_id,
      clientadid: data.clientadid,
      readonly: true
    };
    localStorage.setItem("token", JSON.stringify(this.localvalue))
    this.router.navigate(['/easyRecruiterFeedback']);
  }
   /**
    * @ignore
    */
  deleteJob() {
    this.commonloader = true;
    var flagdata = {
      
      "method":"AUTH",
      "ad_id_list": 
        this.deletedata,
      "document_type": "AD",
      "delete_list": [
        "READ",
        "PROFILE",
        "MATCH",
        "FILES"
      ]
    }
    this.commonloader = true
    this.http.commonMethod(flagdata,this.logonUrl.delete_values)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200) {
          this.getmy_data()
          //this.commonloader = false;
          this.deletedata = []
        } else {
          this.commonloader = false;
        }
      })
  }


   /**
    * @ignore
    */
  deleteJobcv() {
    this.commonloader = true;
    var flagdata = {
      "method":"AUTH",
      "cv_id_list": 
        this.deletedata1,
      "document_type": "CV",
      "delete_list": [
        "READ",
        "PROFILE",
        "MATCH",
        "FILES"
      ]
    }
    this.http.commonMethod(flagdata,this.logonUrl.delete_values)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200) {
          //this.getmy_data()
          // this.ge
          console.log(this.currentcandidateindex, "deleteValues")
          this.getparticular_ad_candidates(this.currentcandidateindex, "test")
          this.commonloader = false;
          // this.getOWNCANDIDATE(this.goto);
          // this.deletedata1 = []
        } else {
          this.commonloader = false;
        }
      })
  }

  /**
   * @ignore
   */
  print() {
    if (this.isLogged == "false") {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'loginpopup' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    } else {
      this.createFile();

    }
  }
   /**
    * @ignore
    */
  createFile() {
    
    var data = document.getElementById('foo');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      console.log(heightLeft);

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('Admin.pdf'); // Generated PDF   
    });

  }
    /**
     * @ignore
     */
  openlink(url) {
    if (url) {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        data: { dialogname: 'linkpopupadmin', url: url }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    }
  }


  showheatmapPopup(i, data){ //by clicking the icon in the ER Job list!!
    //localStorage.setItem("JobTitleRecruiter", this.title)
    var localvalue = JSON.parse(localStorage.getItem('token'))
    //console.log("BEFORElocalvalue:", localvalue.ad_id)
    localvalue.ad_id = data.ad_id
    localStorage.setItem("token", JSON.stringify(localvalue))
    // console.log("AFTERlocalvalue:", localvalue.ad_id)
    // console.log("data:", data.ad_id)
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: false,
      width: '90%',
      data: { dialogname: 'GetHeatMap', OkButton: 'false' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log("er")
    });
  }

  // button hide and show functions
   /**
    * @ignore
    */
  showFullCurrentemp() {
    this.slicedcompetency = this.finalcv_Bullet27;
    this.HideHalfCurrentemp = true;
    this.HideExpandCurrentemp = false;
  }
   /**
    * @ignore
    */
  showHalfCurrentemp() {
    this.slicedcompetency = this.finalcv_Bullet27.slice(1, 4);
    this.HideHalfCurrentemp = false;
    this.HideExpandCurrentemp = true;
  }
  /**
   * @ignore
   */
  showFullCompetency() {
    this.slicedcompetency = this.finalBullet27;
    this.HideHalfCompatency = true;
    this.HideExpandCompatency = false;
  }
   /**
    * @ignore
    */
  showHalfCompetency() {
    this.slicedcompetency = this.finalBullet27.slice(1, 4);
    this.HideHalfCompatency = false;
    this.HideExpandCompatency = true;
  }
  /**
   * @ignore
   */
  showFullHardSkill() {
    if (this.slicedHardSkill && this.slicedHardSkill.length == 3) {
      this.slicedHardSkill = this.finalHardSkils;
      this.HideHalfHardSkill = true;
      this.HideExpandHardSkill = false;
    }
    this.slicedHardSkill = this.finalHardSkils;
    this.HideHalfHardSkill = true;
    this.HideExpandHardSkill = false;
  }
   /**
    * @ignore
    */
  showHalfHardSkill() {
    this.slicedHardSkill = this.finalHardSkils.slice(1, 4);
    this.HideHalfHardSkill = false;
    this.HideExpandHardSkill = true;
  }
   /**
    * @ignore
    */
  showFullSoftSkills() {
    if (this.slicedSoftSkills && this.slicedSoftSkills.length == 3) {
      this.slicedSoftSkills = this.finalSoftSkils;
      this.HideHalfSoftSkills = true;
      this.HideExpandSoftSkills = false;
    } else {
      this.slicedSoftSkills = this.finalSoftSkils;
      this.HideHalfSoftSkills = true;
      this.HideExpandSoftSkills = false;
    }
  }
   /**
    * @ignore
    */
  showHalfSoftSkills() {
    this.slicedSoftSkills = this.finalSoftSkils.slice(1, 4);
    this.HideHalfSoftSkills = false;
    this.HideExpandSoftSkills = true;
  }
   /**
    * @ignore
    */
  showFullIndustry() {
    if (this.slicedIndustries == 3) {
      this.slicedIndustries = this.finalIndustries;
      this.HideHalfIndustry = false;
      this.HideExpandIndustry = false;
    }
    this.slicedIndustries = this.finalIndustries;
    this.HideHalfIndustry = true;
    this.HideExpandIndustry = false;
  }
   /**
    * @ignore
    */
  showHalfIndustry() {
    this.slicedIndustries = this.finalIndustries.slice(1, 4);
    this.HideHalfIndustry = false;
    this.HideExpandIndustry = true;
  }
   /**
    * @ignore
    */
  showFullprofession() {
    if (this.slicedprofession && this.slicedprofession.length == 3) {
      this.slicedprofession = this.finalprofession;
      this.HideHalfprofession = false;
      this.HideExpandprofession = false;
    }
    this.slicedprofession = this.finalprofession;
    this.HideHalfprofession = true;
    this.HideExpandprofession = false;
  }
   /**
    * @ignore
    */
  showHalfprofession() {
    this.slicedprofession = this.finalprofession.slice(1, 4);
    this.HideHalfprofession = false;
    this.HideExpandprofession = true;
  }

  adProfileValueTitle: any;
  adProfileValueData: any;
  matchingProfie: any;
  informationDesc1: any;
  informationDesc2: any;
  cvProfileValueData: any;
  cvProfileValueTitle: any;
  canditateTitle: any;


  ProfileLink: any
  feedbackReportData(i) {
    this.commonloader = true
    console.log(this.candidatedata)
    // console.log(i)
    var ci = localStorage.getItem("getParticualrIndexValue")
    // alert(i);
    i = ci
    console.log(i)
    var req;
    

    console.log("normal Call", this.candidatedata1)
    if (this.candidatedata1[i].profile_cv_id) {
      req = {
        
   "method": "1TO1",
    "ad_id": this.candidatedata[i].profile_ad_id,
    "cv_id": this.candidatedata[i].profile_cv_id,
    "document_type": "AD",
    "report": "customer",
    "output": "data",
    "email": this.userEmail,
    "e_recruiter": true
      }

    }
    console.log("normalcall 1", this.candidatedata1[i].profile_cv_id)
    // }

    this.http.commonMethod(req,this.logonUrl.get_match_report_active_sourcing)
      .subscribe(data => {
        this.feedbackdata = data;
        this.feedbackdata = this.feedbackdata.res;
        console.log(this.feedbackdata, "feed")
        if (this.feedbackdata) {
          // if()
          this.commonloader = false

          this.adProfileValueData = this.feedbackdata.ad_profile_values.profil_info[2];
          this.adProfileValueTitle = this.feedbackdata.ad_profile_values.profil_info[2];
          this.cvProfileValueData = this.feedbackdata.cv_profile_values.profil_info;
          this.cvProfileValueTitle = this.feedbackdata.cv_profile_values.profil_info;
          this.canditateTitle = this.feedbackdata.matching_overview_neutral.matching_color;
          if (this.feedbackdata.read_quality) {
            this.informationDesc1 = this.feedbackdata.read_quality.quality_list.document_berufsgruppe_unique;
            this.informationDesc2 = this.feedbackdata.read_quality.quality_list.document_conflicts.meldung;
          }
          // console.log(this.feedbackdata, "feedbackdata");
          // BarChart Labels starts Here
          if (this.cvProfileValueTitle.titel_krit_1 && this.cvProfileValueTitle.titel_krit_2 && this.cvProfileValueTitle.titel_krit_3 && this.cvProfileValueTitle.titel_krit_4 && this.cvProfileValueTitle.titel_krit_9) {

          }
          if (this.cvProfileValueData.length > 0) {
            for (var i = 0; i < this.cvProfileValueData.length; i++) {
              if (this.cvProfileValueData[i].skillset_id == '12') {
                var candidateData = this.cvProfileValueData[i];
              }
            }
            this.barChartLabels = [candidateData.titel_krit_1, candidateData.titel_krit_2, candidateData.titel_krit_3, candidateData.titel_krit_4, candidateData.titel_krit_9];
            // RadaChart Labels starts Here
            /**
             * @ignore
             */
            this.radarChartLabels = [candidateData.titel_krit_1, candidateData.titel_krit_2, candidateData.titel_krit_3, candidateData.titel_krit_4, candidateData.titel_krit_9];

            this.radarChartData =
              [

                {
                  data: [this.adProfileValueData.krit_1_wert, this.adProfileValueData.krit_2_wert,
                  this.adProfileValueData.krit_3_wert,
                  this.adProfileValueData.krit_4_wert, this.adProfileValueData.krit_9_wert], borderColor: ['rgba(255, 99, 110, 0.7)'], backgroundColor: ['rgba(255, 99, 132, 0.7)'], label:"Stelle"
                },
                {
                  data: [candidateData.krit_1_wert, candidateData.krit_2_wert,
                  candidateData.krit_3_wert,
                  candidateData.krit_4_wert, candidateData.krit_9_wert], borderColor: ['rgba(39, 128, 146, 0.7)'], backgroundColor: ['rgba(39, 128, 146, 0.7)'], label:"CV"
                },
              ];

            this.barChartData =

              [

                {
                  backgroundColor: 'rgba(255, 99, 110, 0.7)',
                  borderColor: 'rgba(255, 99, 110, 0.7)',
                  // borderWidth: 5,
                  data: [this.adProfileValueData.krit_1_wert, this.adProfileValueData.krit_2_wert,
                  this.adProfileValueData.krit_3_wert,
                  this.adProfileValueData.krit_4_wert, this.adProfileValueData.krit_9_wert],
                  label:"Stelle"
                },


                {
                  backgroundColor: 'rgba(39, 128, 146, 0.7)',
                  borderColor: 'rgba(39, 128, 146, 0.7)', data: [candidateData.krit_1_wert, candidateData.krit_2_wert,
                  candidateData.krit_3_wert,
                  candidateData.krit_4_wert, candidateData.krit_9_wert],
                  label:"CV"
                }

              ];
          } else {

          }

         

          // this.title = this.ad_data_list.title
          this.location = this.feedbackdata.cv_data_list[0].location
          this.country = this.feedbackdata.cv_data_list[0].country
          this.zip = this.feedbackdata.cv_data_list[0].zip
          this.content_ad = this.feedbackdata.content_ad;
          this.content_cv = this.feedbackdata.content_cv;
          this.ProfileLink = this.feedbackdata.cv_data_list[0].profile_link;

          if (this.feedbackdata.ad_data_list[0].date) {
            var ddd = this.feedbackdata.ad_data_list[0].date
            var dd = ddd.split('-')[2];
            var mm = ddd.split('-')[1];
            var yy = ddd.split('-')[0];
            yy = yy.substr(-2, 2)
            dd = dd.concat(".");
            var finaldate = dd.concat(mm);
            finaldate = finaldate.concat('.');
            finaldate = finaldate.concat(yy)
            this.feedbackdata.ad_data_list[0].date = finaldate
          }

          if (this.feedbackdata.profile_cv) {
            this.profile_ad = this.feedbackdata.profile_cv.niveau_ausbildung
            this.profile_ad_degree = this.feedbackdata.profile_cv.abschluesse
            this.profile_ad_training = this.feedbackdata.profile_cv.ausbildung

            if (this.feedbackdata.profile_cv.work_experience) {
              this.work_experience = this.feedbackdata.profile_cv.work_experience
            } else {
              this.work_experience = ""
            }
          }
          this.ad_idValue = this.feedbackdata.ad_profile_values.ad_id;

          var keys = Object.keys(this.content_ad);
          var keys_cv = Object.keys(this.content_cv);
          var len_cv = keys_cv
          var len = keys;
          var bullet27;
          var bullet2;
          var bullet3;
          var bullet4;
          var bullet5;
          this.buuletData = [];
          this.hardSkils = [];
          this.softSkils = [];
          this.indutries = [];
          this.profession = [];
          var cv_built27 = [];
          this.professtion_berufsgruppe = this.feedbackdata.profile_cv.Berufsgruppe;
          this.currentempbuuletData = [];

          if (this.feedbackdata.interview_questions_no_link) {
            this.interviewquestin1 = this.feedbackdata.interview_questions_no_link
            this.interviewquestin = this.interviewquestin1.filter(item => item.bereich_id == "5");
            this.talentquestin = this.interviewquestin1.filter(item => item.bereich_id == "7");

          }

          //cv_built27 = this.content_cv[len_cv[i]].filter(item => item.bullet_id == "27");

          for (var i = 0; i < len_cv.length; i++) {

            //  current employement       
          /**
           * @ignore
           */
            cv_built27 = this.content_cv[len_cv[i]].filter(item => item.bullet_id == "27");
            if (cv_built27.length > 0) {
              this.currentempLengthZero = false;
              this.currentempLength = true;
              this.currentempbuuletData.push(cv_built27);
              for (var j = 0; j < this.currentempbuuletData.length; j++) {
                this.finalcv_Bullet27 = this.currentempbuuletData[j];
                let removeDuplication = this.finalcv_Bullet27.filter((v, i) => this.finalcv_Bullet27.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalcv_Bullet27 = removeDuplication;
                if (this.finalcv_Bullet27.length > 3) {
                  this.slicedcurrentemp = this.finalcv_Bullet27.slice(1, 4);
                } else if (this.finalcv_Bullet27.length <= 3) {
                  this.slicedcurrentemp = this.finalcv_Bullet27;
                  this.HideExpandCurrentemp = false;
                  this.HideHalfCurrentemp = false;
                }
              }
            } else if (this.buuletData.length < 1) {
              this.currentempLengthZero = false;
              this.currentempLength = true;

            }

            // Competency
            /**
             * @ignore
             */
            bullet27 = this.content_cv[len_cv[i]].filter(item => item.bullet_id == "62");
            if (bullet27.length > 0) {
              this.competencyLengthZero = false;
              this.competencyLength = true;
              this.buuletData.push(bullet27);
              for (var j = 0; j < this.buuletData.length; j++) {
                this.finalBullet27 = this.buuletData[j];
                let removeDuplication = this.finalBullet27.filter((v, i) => this.finalBullet27.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalBullet27 = removeDuplication;
                if (this.finalBullet27.length > 3) {
                  this.slicedcompetency = this.finalBullet27.slice(1, 4);
                } else if (this.finalBullet27.length <= 3) {
                  this.slicedcompetency = this.finalBullet27;
                  this.HideExpandCompatency = false;
                  this.HideHalfCompatency = false;
                }
              }
            } else if (this.buuletData.length < 1) {
              this.competencyLengthZero = false;
              this.competencyLength = true;

            }

            // abschnitt_id == 61 or 6
            // hardskils  
             /**
              * @ignore
              */
            bullet2 = this.content_cv[len_cv[i]].filter(item => item.abschnitt_id == "61" || item.abschnitt_id == "6");
            if (bullet2.length > 0) {
              this.HardskillLength = true;
              this.hardSkillLengthZero = false;
              this.hardSkils.push(bullet2);
              for (var j = 0; j < this.hardSkils.length; j++) {
                this.finalHardSkils = this.hardSkils[j];
                let removeDuplication = this.finalHardSkils.filter((v, i) => this.finalHardSkils.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalHardSkils = removeDuplication;
                if (this.finalHardSkils.length > 3) {
                  this.slicedHardSkill = this.finalHardSkils.slice(1, 4);
                  this.HideExpandHardSkill = true;
                  this.HideHalfHardSkill = false;
                }
                else if (this.finalHardSkils.length <= 3) {
                  this.slicedHardSkill = this.hardSkils[j];
                  this.HideExpandHardSkill = false;
                  this.HideHalfHardSkill = false;
                }
              }
            } else if (this.hardSkils.length == 0) {
              this.HardskillLength = false;
              this.hardSkillLengthZero = true;
            }

            // professions          
            /**
             * @ignore
             */
            bullet5 = this.content_cv[len_cv[i]].filter(item => item.bullet_id == "27");
            if (bullet5.length > 0) {
              this.professionLength = true;
              this.professionLengthZero = false;
              this.profession.push(bullet5);
              for (var j = 0; j < this.profession.length; j++) {
                this.finalprofession = this.profession[j];
                let removeDuplication = this.finalprofession.filter((v, i) => this.finalprofession.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalprofession = removeDuplication;
                if (this.finalprofession.length > 3) {
                  this.slicedprofession = this.finalprofession.slice(1, 4);
                  this.HideExpandprofession = true;
                  this.HideHalfprofession = false;
                }
                else if (this.finalprofession.length <= 3) {
                  this.slicedprofession = this.finalprofession;
                  this.HideExpandprofession = false;
                  this.HideHalfprofession = false;
                }
              }
            } else if (this.profession && this.profession.length == 0) {
              this.professionLength = false;
              this.professionLengthZero = true;
            }

            // industry    
            /**
             * @ignore
             */
            bullet4 = this.content_cv[len_cv[i]].filter(item => item.bullet_id == "28");
            if (bullet4.length > 0) {
              this.industryLengthZero = false;
              this.industryLength = true;
              this.HideIndusrtyTips = true;
              this.indutries.push(bullet4);
              for (var j = 0; j < this.indutries.length; j++) {
                this.finalIndustries = this.indutries[j];
                let removeDuplication = this.finalIndustries.filter((v, i) => this.finalIndustries.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
                this.finalIndustries = removeDuplication;
                this.industrySeparatedValue = Array.prototype.map.call(this.finalIndustries, s => s.expression).toString();
                if (this.finalIndustries.length > 3) {
                  this.slicedIndustries = this.finalIndustries.slice(1, 4);
                }
                else if (this.finalIndustries && this.finalIndustries.length <= 3) {
                  this.slicedIndustries = this.finalIndustries;
                  this.HideExpandIndustry = false;
                  this.HideHalfIndustry = false;
                }
              }
            } else if (this.indutries && this.indutries.length == 0) {
              this.industryLengthZero = true;
              this.industryLength = false;
            }

          }

          // for (var i = 0; i < len.length; i++) {

          //   // softskils
             
          //     /**
          //      * @ignore
          //      */
          //   bullet3 = this.content_ad[len[i]].filter(item => item.abschnitt_id == "62");
          //   if (bullet3.length > 0) {
          //     this.softskillLength = true;
          //     this.softSkillLengthZero = false;
          //     this.softSkils.push(bullet3);
          //     for (var j = 0; j < this.softSkils.length; j++) {
          //       this.finalSoftSkils = this.softSkils[j];
          //       let removeDuplication = this.finalSoftSkils.filter((v, i) => this.finalSoftSkils.findIndex(item => item.expression.toLowerCase() == v.expression.toLowerCase()) === i);
          //       this.finalSoftSkils = removeDuplication;
          //       if (this.finalSoftSkils.length > 3) {
          //         this.slicedSoftSkills = this.finalSoftSkils.slice(1, 4);
          //         this.HideHalfSoftSkills = false;
          //         this.HideExpandSoftSkills = true;
          //       }
          //       else if (this.finalSoftSkils.length <= 3) {
          //         this.slicedSoftSkills = this.finalSoftSkils
          //         this.HideHalfSoftSkills = false;
          //         this.HideExpandSoftSkills = false;
          //       }
          //     }
          //   }
          //   else if (this.softSkils.length == 0) {
          //     this.softskillLength = false;
          //     this.softSkillLengthZero = true;
          //   }

          // }

          if (this.profile_ad == "") {
            this.HideProfileName = true;
            this.HideProfileNameZero = true;
          } else if (this.profile_ad && this.profile_ad != "") {
            this.HideProfileName = true;
            this.HideProfileNameZero = false;
            this.profile_name = this.profile_ad_values[this.profile_ad - 1].value
          }

        } else {

        }

      }, error => {

      });
  }
savePageData: any;
  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv; charset=utf-8,' + text);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
   /** @param
    *  Save Page
    */
  savePage(data) {
    console.log("checkData", this.deletedata1)
    console.log("Particular Candidate Data", data)
    console.log("Particalur Adid", this.currentcandidate_adid)
    if (this.deletedata1.length != 0) {
      var cvList = '';
      for (var i = 0; i < this.deletedata1.length; i++) {
        if (i > 0 && i != undefined) {
          cvList = cvList.concat(',')
        }
        cvList = cvList.concat(this.deletedata1[i])
      }
      console.log("CvList", cvList)

      var savePage;
      // alert(this.currentcandidate_adid);
      savePage =
        {
          
          "method": "AUTH",
          "custom_id": "548",
          "recruiter_id": this.recruiter_id,
          "ad_id": this.currentcandidate_adid,
          "cv_id_list": [cvList]
        }


      console.log(savePage)
      this.http.commonMethod(savePage,this.logonUrl.get_talent_csv)
        .subscribe(response => {
          console.log("Save Page", response);
          this.savePageData = response;
          var decodedString=  atob(this.savePageData.res.message.base_64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('');
          this.download("sample.csv", decodedString);
          this.deletedata1 = [];
        })
    }
    else {
      // this.alertmsg("please Select CheckBox","error");
      this.alertmsg(this.languagedata.ALERTS.CHECKBOX_ALERT, 'error');
    }
  }
  /**
   * @ignore
   */
  currentProfile() {

    var data = {
      "id":  this.accesstoken.ad_id,
      "doc_type": "AD"
    }
    this.http.commonMethod(data,this.logonUrl.get_current_profile)
      .subscribe(data => {
        this.profileReport = data;
        this.contentProfile = this.profileReport.res;
      })
}


  /**
   * @ignore
   */

  back() {
    if (this.candidatelist) {

    } else if (this.candidatelist) {

    } else if (this.reportmatch) {
      this.joblist = false;
      this.dropdownfilter = false;
      this.userdetails = true
      this.candidatelist = true;
      this.jobheaderstrip = false;

    }
  }

}
export interface Food {
  value: string;
  viewValue: string;
}
