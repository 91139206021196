import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Candidate_Home/Candidate_Home.component';
import { MyAccountComponent } from './Candidate_Chat/Candidate_Chat.component';
import { FeedBackScreenComponent } from './Candidate_FeedBack/Candidate_FeedBack.component';
import { MatchReportComponent } from './Candidate_Chart-Report/Candidate_Chart-Report.component';
import { SuccessComponent } from './Candidate_Success/Candidate_Success.component';
import { FeedbackComponent } from './DirectApply_Feedback/DirectApply_Feedback.component';
import { HeaderComponent } from './Common_Header/Common_Header.component';
import { DaAdminComponent } from './DirectApply_Admin/DirectApply_Admin.component';
import { CVAdminComponent } from './Candidate_Admin/Candidate_Admin.component';
import { EasyApplyComponent } from './Candidate_Easy-Apply_JD/Candidate_Easy-Apply_JD.component';
import { HitmapComponent } from './Candidate_HeatMap/Candidate_Heatmap.component';
import { LandingPageComponent } from './Old_Landing_Page/Old_Landing_Page.component';
import { AuthGuard } from './auth.guard';
import { PricingMVPComponent } from './Pricing-mvp/Pricing-mvp.component';
import { CockpitComponent } from './Cockpit/Cockpit.component';
import { ErecruiterLandingComponent } from './E_Recruiter-landing/E_Recruiter-landing.component';
import { EASYRECRUITERFEEDBACKComponent } from './ER_Feedback/ER_Feedback.component';
import { EasyRecruiterAdminComponent } from './ER_Admin/ER_Admin.component';
import { NewLPComponent } from './New_Landing/New_Landing.component';
import { ADLandingComponent } from './DirectApply_Landing/DirectApply_Landing.component';



const routes: Routes = [
  { path: 'home/:site/:id/:title', component: HomeComponent },
  { path: 'auto/:id', component: HomeComponent },

  { path: 'chat/:site/:id/:title', component: MyAccountComponent },
  { path: 'chat/:site', component: MyAccountComponent },
  { path: 'match-report/:site/:id/:title', component: MatchReportComponent },
  { path: 'success/:site/:id/:title', component: SuccessComponent },
  { path: 'success/:site', component: SuccessComponent },
  { path: 'candidatefeedback/:site/:id/:title', component: FeedBackScreenComponent },

  { path: 'home/:site/:id/:title/:enhanced', component: HomeComponent },
  { path: 'chat/:site/:id/:title/:enhanced', component: MyAccountComponent },
  { path: 'match-report/:site/:id/:title/:enhanced', component: MatchReportComponent },
  { path: 'success/:site/:id/:title/:enhanced', component: SuccessComponent },
  { path: 'candidatefeedback/:site/:id/:title/:enhanced', component: FeedBackScreenComponent },

  { path: 'home', component: HomeComponent },
  { path: 'chat', component: MyAccountComponent },
  { path: 'match-report', component: MatchReportComponent },
  { path: 'success', component: SuccessComponent },
  { path: 'candidatefeedback', component: FeedBackScreenComponent },
  { path: 'recruiterfeedback', component: FeedbackComponent },
  { path: 'header', component: HeaderComponent, canActivate: [AuthGuard] },
  { path: 'daadmin', component: DaAdminComponent, canActivate: [AuthGuard] },
  { path: 'easyApplyJD', component: EasyApplyComponent },
  { path: 'hitMap', component: HitmapComponent },
  // { path: 'directapply', component: DirectApplyComponent },
  { path: 'pricing', component: PricingMVPComponent },
  { path: 'cockpit', component: CockpitComponent },
  { path: 'cvadmin', component: CVAdminComponent, canActivate: [AuthGuard] },
  { path: 'easyRecruiterFeedback', component: EASYRECRUITERFEEDBACKComponent },
  { path: 'newlp', component: LandingPageComponent },
  { path: 'erecruiterlanding', component: ErecruiterLandingComponent },
  { path: 'erecruiterAdmin', component: EasyRecruiterAdminComponent },
  { path: 'directapplylanding', component: ADLandingComponent },
  { path: '', component: NewLPComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule],
  providers: [HeaderComponent]
})

export class AppRoutingModule { }
// function newFunction() {
//   return { path: '**', component: PricingMVPComponent };
// }

