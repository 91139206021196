import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { urlEnv } from "../../environments/env";
/**
 * Page for Http Service
 * All Api from Logon
 */

@Injectable({
  providedIn: "root",
})
export class HttpserviceService {
  private userEmail = localStorage.getItem("userEmail");
  private sessionid = localStorage.getItem("sessionid");
  private isLogged = localStorage.getItem("isLogged");
  private token = localStorage.getItem("token");

  private head = new HttpHeaders({
    "Content-Type": "application/json",
    sessionid: this.sessionid,
    email: this.userEmail,
  });
  // chatApi = 'https://api.erecruiting.io/text-to-speech';

  getlocalvalues() {
    this.isLogged = localStorage.getItem("isLogged");
    this.sessionid = localStorage.getItem("sessionid");
    this.userEmail = localStorage.getItem("userEmail");
  }

  //New
  public logonUrl = urlEnv;
  //Old
  // private serverUrl = environment.server_url;
  // private userurl = environment.userUrl;
  public reportvalue = "./assets/i18n/";
  // public QATURL = environment.server_url + 'userauth';
  // public QATURLLOGON = environment.server_url + 'logon';

  constructor(private http: HttpClient) {}

  /**
   * CommonPostMethod using Observable Object
   * @param jsonData
   * @param url
   */
  commonMethod(jsonData: any, url): Observable<object> {
    this.getlocalvalues();
    var head= new HttpHeaders().set("content-type", "application/json");
    if (jsonData.sessionid)
     head = new HttpHeaders().set("content-type", "application/json").set("sessionid", jsonData.sessionid);
    if (this.sessionid) 
    head = new HttpHeaders().set("content-type", "application/json").set("sessionid", this.sessionid);
    // .set('email', this.userEmail)
    //console.debug(`Post to ${url} with head ${JSON.stringify(head)}`, jsonData);
    return this.http.post(url, jsonData, { headers: head }).pipe(
      tap((data) => {
      //console.debug(`Response from ${url}`,data);

        // data = this.Encryption.Decryption("Decrypt", data);
      }),
      catchError(this.getError)
    );
  }

  /**
   *
   * @param jsonData
   * Login Method
   * @param url
   */
  loginMethod(jsonData: object, url): Observable<object> {
    this.getlocalvalues();
    const head = new HttpHeaders().set("content-type", "application/json");
    return this.http.post(url, jsonData, { headers: head }).pipe(
      tap((data) => {}),
      catchError(this.getError)
    );
  }

  /**
   *
   * @param jsonData
   * Verify Email
   * Verify otp
   * register
   * login
   * forget password
   * Reset password
   * resend Otp
   * @param url
   */
  withoutSessionMethod(jsonData: object, url): Observable<object> {
    this.getlocalvalues();
    const head = new HttpHeaders().set("content-type", "application/json");
    return this.http.post(url, jsonData, { headers: head }).pipe(
      tap((data) => {}),
      catchError(this.getError)
    );
  }

  /**
   * Common Get Method using Observable Method
   * @param getUrl
   */
  commonMethodGet(getUrl): Observable<object> {
    this.getlocalvalues();
    var head = new HttpHeaders()
      .set("content-type", "application/json");
      if(this.sessionid)
      head = new HttpHeaders()
      .set("content-type", "application/json")
      .set("sessionid", this.sessionid);
    // .set('email', this.userEmail)
    return this.http.get(getUrl, { headers: head }).pipe(
      tap((data) => {
        // data = this.Encryption.Decryption("Decrypt", data);
      }),
      catchError(this.getError)
    );
  }
  /**
   * Common Get Method using Observable Method
   * getReportJSON
   */
  public getReportJSON(): Observable<object> {
    const head = new HttpHeaders().set("content-type", "application/json");
    return this.http.get(this.reportvalue + "reportResponse.json").pipe(
      tap((data) => {}),
      catchError(this.getError)
    );
  }
  /**
   * Common Get Method using Observable Method
   *
   */
  public getlangvalue(value): Observable<object> {
    const head = new HttpHeaders().set("content-type", "application/json");
    return this.http.get(this.reportvalue + "" + value + ".json").pipe(
      tap((data) => {}),
      catchError(this.getError)
    );
  }
  private getError(error: Response): Observable<any> {
    console.error(error);
    return throwError("Server Issue");
  }
}
