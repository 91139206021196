import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
/**
 * Page for Candidate Feedback , User can View the Feedback of his/her Profile Details in Feedback
 */

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-easy-apply',
  templateUrl: './Candidate_Easy-Apply_JD.component.html',
  styleUrls: ['./Candidate_Easy-Apply_JD.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class EasyApplyComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  /**
   * @ignore
   */
  
  public hideshowdynamicbutton: boolean = false;
  /**
   * @ignore
   */
  
  public hideshowdynamidays: boolean = false;
  /**
   * @ignore
   */
  
  public hideshowdowndroparraow: boolean = false;
  /**
   * @ignore
   */
  
  public hideshowupdroparraow: boolean = false;
  /**
   * @ignore
   */

  public hideshowdatedownarrow: boolean = false;
  /**
   * @ignore
   */
  
  public hideshowdateuparrow: boolean = false;

  EasyapplyheaderFlag = false;
  filterFlag = false;
  jobAlertsFlag = true;
  jobViewFlag = false;
  step = 0;
  reason = '';

  constructor(public translate: TranslateService) {
    var lang = localStorage.getItem('language')

    translate.addLangs(['en', 'de']);

    if (lang) {

      translate.setDefaultLang(lang);

      translate.use(lang);

    } else {

      translate.setDefaultLang('de');

      translate.use('de');

    } 
   }
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();
  /**  function desc
   * Init Function Starts here
   */
  ngOnInit() {
    this.hideshowdowndroparraow = true;
    this.hideshowupdroparraow = false;

    this.hideshowdatedownarrow = true;
    this.hideshowdateuparrow = false;
  }

  setStep(index: number) {
    this.step = index;
  }

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }


  onclickloopevent() {
    this.hideshowdynamicbutton = !this.hideshowdynamicbutton;
    if (this.hideshowdynamicbutton) {
      this.hideshowdynamicbutton = true;
      this.hideshowdowndroparraow = false;
      this.hideshowupdroparraow = true;
    }
    else {
      this.hideshowdynamicbutton = false;
      this.hideshowdowndroparraow = true;
      this.hideshowupdroparraow = false;
    }

  }

  onclickdaysevent() {
    this.hideshowdynamidays = !this.hideshowdynamidays;
    if (this.hideshowdynamidays) {
      this.hideshowdynamidays = true;
      this.hideshowdatedownarrow = false;
      this.hideshowdateuparrow = true;
    }
    else {
      this.hideshowdynamidays = false;
      this.hideshowdatedownarrow = true;
      this.hideshowdateuparrow = false;
    }

  }

  filter() {
    this.filterFlag = true;
    this.jobAlertsFlag = false;
    this.jobViewFlag = false;
  }

  Apply() {
    this.jobViewFlag = true;
    this.jobAlertsFlag = false;
  }
 
  backJob() {
    this.jobAlertsFlag = true;
    this.jobViewFlag = false;
  }
  /**
   * @ignore
   */
  
  cancel() {
    this.jobAlertsFlag = true;
    this.filterFlag = false;
    this.jobViewFlag = false;
  }
}
