//Common Header for All Pages
import {
  Component, OnInit, ViewChild, ChangeDetectorRef, Injectable,
  Input, Output, EventEmitter, Renderer2
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { HttpserviceService } from '../Services/httpservice.service';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../Dialog/Dialog.component';
import { AlertComponent } from '../Common_Alert/Common_Alert.component';
import { TranslateService } from '@ngx-translate/core';
import {urlEnv} from '../../environments/env';
/**
 * Page for Common Header for all the pages
 */
@Component({
  selector: 'app-header',
  templateUrl: './Common_Header.component.html',
  styleUrls: ['./Common_Header.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class HeaderComponent implements OnInit {
  // @Output() myEvent = new EventEmitter();
  /**
   * Ajax Variable
   */
  public logonUrl = urlEnv;
  chatApiHeader = false;
  @ViewChild('sidenav') sidenav: MatSidenav;
  sessionvar: any;
  userEmail: any;
  sessionid: any;
  isLogged: any;
  response: any;
  url: any;
  newheader: boolean = false
  langen: boolean = true;
  betterheadswhite: boolean;
  id: any;
  language: string;
  /**
 * @ignore
 */
  constructor(public router: Router,
    public translate: TranslateService,
    public http: HttpserviceService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2
  ) {
    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
    }

  }

  @Output() public sidenavToggle = new EventEmitter();
  /**
 * @ignore
 */
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
    // this.demo.emit();
  }
  @Input() inputSideNav: MatSidenav;

  /**
 * Ng Init Functions starts here
 * @method checksession
 */
  ngOnInit() {

    // this.myEvent.emit(null)

    if (this.router.url === "/" || this.router.url === "/directapplylanding" || this.router.url === "/newlp" || this.router.url === "/erecruiterlanding") {
     this.newheader = true
    }
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    this.language = localStorage.getItem('language');
    // this.loginuser = this.userEmail.split('@')[0];
    if (this.isLogged == "true") {
      this.menuFlag = true;
    } else {
      this.menuFlag = false;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail;
    }
    this.betterheadswhite = true

    if (this.language == 'de') {

      this.langen = false
    } else if (this.language == 'en') {
      this.langen = true
    }


    this.id = setInterval(() => {
      this.getlocalvalues();
    }, 2000);
    if (this.router.url === "/directapplylanding" || this.router.url === "/newlp" || this.router.url === "/erecruiterlanding" || this.router.url === "/recruiterfeedback" || this.router.url === "/easyRecruiterFeedback") {
      console.log("Not Checking Session !!!!!!!!!!!!!!!!!!!!!!!!");
      // this.checksession()
    }else{
      console.log("session Check Apply!!!!!!!!!!!!!!!!!!!!!!!!!");
      this.checksession()
    }
  }
  /**
 * @ignore
 */
  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }
  /**
  * @ignore
  */
  demo() {
    console.log("calll")
    alert()
  }


  checksession() {
    // return "true"
    var test = this.isLogged
    console.log("login status =", test)
    var data = {
    
        "email": this.userEmail,
        "sessionid": this.sessionid,
       
    }
    console.log("Obj",data)
    this.http.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          this.sessionvar = res
            console.log(this.sessionvar);
          if (this.sessionvar.status == 200) {
            console.log("session check success")
            this.menuFlag = true;
          } else if (this.sessionvar.status == 422) {
            this.loginuser = ""
            localStorage.setItem("isLogged", "false")
            localStorage.removeItem("userEmail")
            localStorage.setItem("sessionid", "");
            this.isLogged = localStorage.getItem('isLogged');
            this.sessionid = localStorage.getItem('sessionid');
            // this.userEmail = localStorage.getItem('userEmail');
            this.loginuser = ""
            this.menuFlag = false;
            this.router.navigate(['/'])
            if (test == "true") {
              this.alertmsg(this.sessionvar.message, 'error');
            }
            // this.menuFlag = true;
          }
          // return false
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');
      });
  }
/**
 * @ignore
 */
  openPdfDoc2() {
    window.open('https://www.talk-n-job.de/wp-content/uploads/2020/09/DS-015-00_Datenschutz_ApplyZ.pdf', '_blank');
  }
  /**
 * @ignore
 */
  openPdfDoc1() {
    window.open('https://www.talk-n-job.de/wp-content/uploads/2020/09/AGB-und-Auftragsdatenverarbeitung.pdf', '_blank');
  }
/**
 * @ignore
 */
  getlocalvalues() {
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    if (this.isLogged == "true") {
      this.menuFlag = true;
    } else {
      this.menuFlag = false;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail;
    }
    if (this.router.url === "/") {
      this.newheader = true
    }

  }
/**
 * @ignore
 */
  languageSelector(val) {
    localStorage.setItem('language', val);
    console.log(val, "value");
    if (val == "de") {
      this.langen = true;
    } else {
      this.langen = false;
    }
  }
  /**
 * @ignore
 */
  loginclick() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'Logindetailspopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length != 0) {
        localStorage.setItem("userEmail", result[0].email);
        localStorage.setItem('sessionid', result[0].session);
        this.sessionid = localStorage.getItem('sessionid');
        this.userEmail = localStorage.getItem("userEmail");
        this.isLogged = localStorage.getItem("isLogged");
        this.menuFlag = false;
      } else {
        // this.menuFlag = true;
      }
      this.sessionid = localStorage.getItem('sessionid');
      this.userEmail = localStorage.getItem("userEmail");
      this.isLogged = localStorage.getItem("isLogged");

      if (this.userEmail) {
        this.loginuser = this.userEmail;
      }
      if (this.isLogged == "true") {
        this.menuFlag = true;
      } else {
        this.menuFlag = false;
      }

    });
  }
  /**
 * @ignore
 */
  close(reason: string) {
    this.sidenav.close();
  }

  /**
 * Common Change password
 * @function Change
 */
  changepasswords() {
    // this.checksession();
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'changepasswordpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }


  /**
 * @ignore
 */
  openDialog1() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: false,
      data: { dialogname: 'Registerdetailspopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length != 0) {
        if (result[0].email) {
          localStorage.setItem("userEmail", result[0].email);
          localStorage.setItem('sessionid', result[0].session);
          this.sessionid = localStorage.getItem('sessionid');
          this.userEmail = localStorage.getItem("userEmail");
          this.isLogged = localStorage.getItem("isLogged");
          if (this.isLogged == "true") {
            this.menuFlag = true;
          } else {
            this.menuFlag = false;
          }
          if (this.userEmail) {
            this.loginuser = this.userEmail;
          }
        }

      }
      else {
        this.menuFlag = false;
      }
    });
  }
  /**
 * @ignore
 */
  openDialog3() {
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'confirmpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getlocalvalues();
      // this.getLinkbtn = true;
      // this.TLI_chat = true;

      // console.log(this.getLinkbtn, "7")
    });
  }
  menuFlag1: boolean;
  loginuser: string;
  menuFlag: boolean;
/**
 * Common Alert Message
 * @example msg,Errtype
 */
  alertmsg(msg, errtype) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: { data: msg, classname: errtype },
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'blue-snackbar'
    });
  }
  /**
 * @ignore
 */
  menuclicksessioncheck(value) {
    var data = {
     
        "email": this.userEmail,
        "sessionid": this.sessionid,
       
    }
    this.http.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          this.sessionvar = res
          if (this.sessionvar.status == 200) {
            console.log("session check success")
            if (value == "changepassword") {
              this.changepasswords()
            } else if (value == "easyapply") {
              this.router.navigate(['/cvadmin'])
            } else if (value == "admin") {
              this.router.navigate(['/daadmin'])
            } else if (value == "directapply") {
              this.openDialog3()
            }
          } else if (this.sessionvar.status == '422') {
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.loginuser = ""
            this.alertmsg(this.sessionvar.message, 'error');
            this.menuFlag1 = false;
            this.menuFlag = false;
            this.router.navigate(['/'])
          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });

  }
  reason = '';
  /**
 * @ignore
 */
  closeHeaderMenu(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }
  /**
 * Common LogOut
 */
  logout() {
    
    var data = {
      
      "email": this.userEmail,
      "sessionid": this.sessionid,
    
    }
    this.http.commonMethod(data,this.logonUrl.logout)
      .subscribe(data => {
        if (data != null) {
          var details: any = data;
          console.log("data",details);
          if (details.status == 200) {
            // this.MailMessage=details.message;
            localStorage.setItem("isLogged", "false")
            localStorage.removeItem("userEmail")
            localStorage.setItem("sessionid", "");
            localStorage.setItem("recruiter_id", "");
            this.alertmsg("Erfolgreich ausgeloggt", 'success');
            this.menuFlag = true;
            this.router.navigate(['/']);
          } else {
            // if (details.message === "Your sesson has been expired.Please login now !") {
              this.alertmsg(details.message, 'error')
              this.menuFlag = false;
              localStorage.setItem("isLogged", "false")
              localStorage.removeItem("userEmail")
              localStorage.setItem("sessionid", "");
               localStorage.setItem("recruiter_id", "");
              this.router.navigate(['/']);
              this.menuFlag = true;
            
          }
          this.sidenav.close();
        }

        else {

        }
      }, error => {

      });
  }

}
