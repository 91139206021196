//Page for Easy Apply Admin
//Can view the Easy Apply Candidate List
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { RadialChartOptions, ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { HttpserviceService } from '../Services/httpservice.service';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../Dialog/Dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { EncryptionService } from '../Services/encryption.service';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertComponent } from '../Common_Alert/Common_Alert.component';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import * as html2canvas from 'html2canvas';
import {urlEnv} from '../../environments/env';

declare var jsPDF: any;
/**
 * Page for Easy Appply Admin,Recruiter can View the Candidate List
 */
@Component({
  selector: 'app-cv-admin',
  templateUrl: './Candidate_Admin.component.html',
  styleUrls: ['./Candidate_Admin.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [EncryptionService]
})
export class CVAdminComponent implements OnInit {

  /**
   * @ignore
   */
  public logonUrl = urlEnv;
  public candidatelist: boolean = false;
  public reportmatch: boolean = false;
  public dropdownfilter: boolean = false;
  public userdetails: boolean = false;
  public hideshowdynamicloop: boolean = false;
  /**
   * @ignore
   */

  public joblist: boolean = true;
  public jobheaderstrip: boolean = true;
  public jobpaginator: boolean = true;

  /**
   * @ignore
   */

  public joblist1: boolean = false;
  public jobheaderstrip1: boolean = false;
  public pagenavigator: boolean = false;

  response: any;
  menuFlag = false;
  langen: boolean = true;
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Talents' },
    { value: 'pizza-1', viewValue: 'Marked' },
    { value: 'tacos-2', viewValue: 'New' }
  ];
  jddata: any;
  data: any;
  isLogged: string;
  sessionid: string;
  userEmail: string;
  feedbackdata: any;
  loginuser: string;
  testValue: any;
  content_ad: any;
  buuletData: any[];
  hardSkils: any[];
  softSkils: any[];
  indutries: any[];
  profession: any[];
  competencyLengthZero: boolean;
  competencyLength: boolean;
  finalBullet27: any;
  slicedcompetency: any;

  countryCtrl = new FormControl();
  stateCtrl = new FormControl();
  zipCtrl = new FormControl();


  accesstoken: any;
  commonloader: boolean = false;
  backgrounddiv_enable: boolean;
  profile_ad: any;
  profile_ad_degree: any;
  profile_ad_training: any;
  ad_idValue: any;
  menuFlag1: boolean;

  @ViewChild('sidenav') sidenav: MatSidenav;
  sessionvar: any;
  url: any;
  recruiter_id: string;
  adlist: any;
  newdata: any[];
  listbystatedata: any;
  counters: any;
  candidatedata: any;
  currentcandidate_adid: any;
  currentjddata: any;
  currentjddata1: any;
  jddata1: any;
  zip: any;
  count: any;
  keywordshead: any;
  languagedata: any;
  currentlanguage: any;
  param1: any;

  /**
   * @ignore
   */
  constructor(
    public translate: TranslateService,
    public http: HttpserviceService,
    public dialog: MatDialog,
    private encrypt: EncryptionService,
    private router: Router,
    public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute,
  ) {
    var lang = localStorage.getItem('language')
    translate.addLangs(['en', 'de']);
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
      this.currentlanguage = lang
    } else {
      translate.setDefaultLang('de');
      translate.use('de');
      this.currentlanguage = 'de'
    }
  }

  talentsasc: boolean = false
  markedasc: boolean = false
  newasc: boolean = false

  deletedata: any = [];
  /**
   * pagination 
   */
  /**
   * @ignore
   */

  currentpage: any = 1;
  totalnoof_page: any;
  jobsper_page: any = 10;
  pageperjobs = ["10", "20", "50", "100"]


  candidatelistBack = false
  joblistBack = true

  candidataDataList: any
  candidataDataList1: any
  candidataDataList2: any

  test: any
  finalGetCvData: any
  response12: any
  faveritedata: any

  currentcandidateindex: any;

  GetCvData: any
  selectAll = false;
  deletedata1: any = [];
  resp: any

  /** function Desc
   * Init Function Starts here BY Balaji
   */
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.param1 = params['cv_id'];

      this.getlanguagevalue()
      this.dropdownfilter = true;
      this.isLogged = localStorage.getItem('isLogged');
      this.sessionid = localStorage.getItem('sessionid');
      this.userEmail = localStorage.getItem('userEmail');
      console.log(this.isLogged)
      if (this.isLogged == "true") {
        this.menuFlag = false
      } else {
        this.menuFlag = true
      }
      if (this.userEmail) {
        this.loginuser = this.userEmail.split('@')[0];
      }
      this.accesstoken = JSON.parse(localStorage.getItem('token'));
      this.recruiter_id = localStorage.getItem('recruiter_id');
      this.backgrounddiv_enable = false;

      var data = {
        
        "email": this.userEmail,
        "sessionid": this.sessionid
       
      }
      this.commonloader = true;
      this.http.commonMethod(data,this.logonUrl.session_check)
        .subscribe(res => {
          this.sessionvar = res
          this.commonloader = false;
          if (this.sessionvar.status == 200) {
            if (this.param1) {
              this.redirectUrl = true
              var falgd = {
               
                "cv_id": this.param1
              }
              this.http.commonMethod(falgd,this.logonUrl.cvid_based_adid)
                .subscribe(res => {
                  this.resp = res
                  if (this.resp.status == 200) {
                    var card = this.resp.res[0]
                    console.log(card, "res")
                    this.redirectUrlAdmin(card)
                  } else {

                  }
                })


            } else {

              this.checksession()
            }
          } else {
            this.alertmsg(this.sessionvar.message, 'error');
            this.router.navigate(['/'])
          }
        })
    })


  }
  /** 
   * Method Desc
   * Common Session Check in All Page by Balaji Sachin
   */
  checksession() {
    var test = this.isLogged
    var data = {
     
        "email": this.userEmail,
        "sessionid": this.sessionid,
       
    }
    this.http.commonMethod(data,this.logonUrl.session_check)
      .subscribe(res => {
        if (res) {
          console.log("coming from Normal Login");
          this.sessionvar = res
          if (this.sessionvar.status == 200) {
            // this.checksession1 = true
            this.jobListEasyApply()
            // this.currentProfile();
            // this.countriesList();
          } else if (this.sessionvar.status == '422') {
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.isLogged = localStorage.getItem('isLogged');
            this.sessionid = localStorage.getItem('sessionid');
            this.userEmail = localStorage.getItem('userEmail');
            this.loginuser = ""
            this.menuFlag1 = false;
            if (test == "true") {
              this.alertmsg(this.sessionvar.message, 'error');
            }
            // this.checksession1 = false
            this.router.navigate(['/'])
            return false
          }
        } else {
          console.log("error")
        }

      }, error => {
        // this.alertmsg("somthing went wrong", 'error');

      });
    // this.deletecount = 0
  }

  /** @Param
   * Sort Option Starts Here
   */


  newdec(a, b) {
    return b.newcount - a.newcount;
  }
  newasec(a, b) {
    return a.newcount - b.newcount;
  }


  /** @param
   * New On Sort Ad List
   */

  newsort(value) {
    if (value == "descending") {
      this.newasc = true
      this.candidataDataList2 = this.candidataDataList2.sort(this.newdec);
      this.candidataDataList1 = this.candidataDataList2
      this.gotoForList(1)
    } else {
      this.newasc = false
      this.candidataDataList2 = this.candidataDataList2.sort(this.newasec);
      this.jddata1 = this.candidataDataList2
      this.gotoForList(1)

    }
  }

  /** 
   *  On Click Easy Apply Links Navigation
   */

  onclickUrl(link) {
    console.log(link);

    window.open(link, "_blank");
  }
  /** 
   * @ignore
   */
  markeddec(a, b) {
    return b.markerdcount - a.markerdcount;
  }
  /** 
   * @ignore
   */
  markedasec(a, b) {
    return a.markerdcount - b.markerdcount;
  }


  /** 
   *@ignore
   */
  markedsort(value) {
    if (value == "descending") {
      this.markedasc = true
      this.candidataDataList2 = this.candidataDataList2.sort(this.markeddec);
      this.candidataDataList1 = this.candidataDataList2
      this.gotoForList(1)
    } else {
      this.markedasc = false
      this.candidataDataList2 = this.candidataDataList2.sort(this.markedasec);
      this.candidataDataList1 = this.candidataDataList2
      this.gotoForList(1)

    }
  }
  /** 
   * @ignore 
   */
  talentsasds(a, b) {
    return b.appyledcount - a.appyledcount;
  }
  /** 
   * @ignore 
   */
  talentsasdsdes(a, b) {
    return a.appyledcount - b.appyledcount;
  }
  /** 
   * @ignore 
   */

  talentsort(value) {
    if (value == "descending") {
      this.talentsasc = true
      this.candidataDataList2 = this.candidataDataList2.sort(this.talentsasdsdes);
      this.candidataDataList1 = this.candidataDataList2
      this.gotoForList(1)
    } else {
      this.talentsasc = false
      this.candidataDataList2 = this.candidataDataList2.sort(this.talentsasds);
      this.candidataDataList1 = this.candidataDataList2
      this.gotoForList(1)
    }

  }

  // Sort Option Ends Here

  /** 
   * Print Page 
   */
  /** 
   * @ignore 
   */
  print() {
    if (this.isLogged == "false") {
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: false,
        data: { dialogname: 'loginpopup' }
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      });
    } else {
      this.createFile();

    }


  }
  /** 
   * @ignore 
   * toCheck
   */
  getlanguagevalue() {
    var language = this.currentlanguage
    this.http.getlangvalue(language)
      .subscribe(data => {
        if (data != null) {
          var details: any = data;
          this.languagedata = details
          console.log(details, "details");
        }
        else {

        }
      }, error => {

      });
  }
  /** 
   * @ignore 
   */
  createFile() {
    // html2canvas(document.querySelector("#foo")).then(canvas => {
    //   var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);

    //   var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //   pdf.addImage(imgData, 0, 0);
    //   pdf.save('converteddoc.pdf');

    // });
    var data = document.getElementById('foo');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      console.log(heightLeft);

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('Admin.pdf'); // Generated PDF   
    });

  }

  /** 
    *  On Click for Navigate CV List
    */

  onclickmatdiv(i) {
    this.dropdownfilter = false;
    this.userdetails = true
    this.candidatelist = true;

    this.joblist = false;
    this.jobheaderstrip = false;
    this.jobpaginator = false
    this.joblistBack = false

    this.joblist1 = true
    this.jobheaderstrip1 = true
    this.pagenavigator = true
    this.candidatelistBack = true

  }
  /** 
   * @ignore 
   */
  redirectUrlAdmin(i) {
    this.dropdownfilter = false;
    this.userdetails = true
    this.candidatelist = true;

    this.joblist = false;
    this.jobheaderstrip = false;
    this.jobpaginator = false
    this.joblistBack = false

    this.joblist1 = true
    this.jobheaderstrip1 = true
    this.pagenavigator = true
    this.candidatelistBack = true
    this.getparticular_ad_candidates(i,"")
  }
  /** 
   * @ignore 
   */
  loginclick() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'Logindetailspopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length != 0) {
        localStorage.setItem("userEmail", result[0].email);
        localStorage.setItem('sessionid', result[0].session);
        this.sessionid = localStorage.getItem('sessionid');
        this.userEmail = localStorage.getItem("userEmail");
        this.menuFlag = false;
      } else {
        this.menuFlag = true;
      }

    });
  }
  languageSelector(val) {
    localStorage.setItem('language', val);
    console.log(val, "value");
    if (val == "de") {
      this.langen = true;
    } else {
      this.langen = false;
    }
  }
  /**
   * Common Alert Message
   * @example msg,Errtype
   */
  alertmsg(msg, errtype) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: { data: msg, classname: errtype },
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'blue-snackbar'
    });
  }
  /** 
   * @ignore 
   */
  getlocalvalues() {
    this.isLogged = localStorage.getItem('isLogged');
    this.sessionid = localStorage.getItem('sessionid');
    this.userEmail = localStorage.getItem('userEmail');
    if (this.isLogged == "true") {
      this.menuFlag1 = true;
    } else {
      this.menuFlag1 = false;
    }
    if (this.userEmail) {
      this.loginuser = this.userEmail.split('@')[0];
    }
  }
  /** 
   * @ignore 
   */
  openDialog3() {
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'confirmpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getlocalvalues();
      // this.getLinkbtn = true;
      // this.TLI_chat = true;

      // console.log(this.getLinkbtn, "7")
    });
  }
  /**
   * Common Change password
   * @function Change
   */
  changepasswords() {
    // this.checksession();
    this.sidenav.close();
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { dialogname: 'changepasswordpopup' }
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }
  /** 
   * Pagination for AD and CV
   */

  setpageper_jobs(value) {
    console.log("change page")
    this.jobsper_page = value
    this.totalnoof_page = this.candidataDataList2.length / this.jobsper_page
    this.totalnoof_page = Math.ceil(this.totalnoof_page)
    this.gotoForList(1);
  }

  setpageper_jobsForList(value) {
    console.log("change page")
    this.jobsper_page = value
    this.totalnoof_page = this.candidatedata.length / this.jobsper_page
    this.totalnoof_page = Math.ceil(this.totalnoof_page)
    this.goto(1);
  }
  /** 
   * previous for CV List
   */
  previous() {
    var index = this.count - this.jobsper_page
    if (this.currentpage == 1) {
      alert(this.languagedata.ALERTS.PREVIOUS);
    } else {
      this.currentpage = this.currentpage - 1
      // var demo = this.jddata.splice(2, this.jobsper_page);
      var count = index + this.jobsper_page
      // if (count > this.jddata.length - 1) {
      //   count = this.jddata.length
      // }
      var demo = this.candidatedata.slice(index, count);
      this.jddata1 = demo
    }
  }
  /** 
   * Next for CV List
   */

  next() {
    var index = this.jobsper_page * this.currentpage
    if ((this.currentpage + 1) > this.totalnoof_page) {
      alert(this.languagedata.ALERTS.NEXT);
    } else {
      this.currentpage = this.currentpage + 1
      var count = index + this.jobsper_page
      if (count > this.candidatedata.length - 1) {
        count = this.candidatedata.length
      }
      this.count = count
      var demo = this.candidatedata.slice(index, count);
      this.jddata1 = demo

      // this.jddata.findIndex(item => item.expression == v.expression))
    }
  }
  /** 
  * Next for Ad List
  */

  nextForList() {
    console.log(this.candidataDataList1);
    var index = this.jobsper_page * this.currentpage
    if ((this.currentpage + 1) > this.totalnoof_page) {
      alert(this.languagedata.ALERTS.NEXT);
    } else {
      this.currentpage = this.currentpage + 1
      var count = index + this.jobsper_page
      if (count > this.candidataDataList2.length - 1) {
        count = this.candidataDataList2.length
      }
      this.count = count
      var demo = this.candidataDataList2.slice(index, count);
      this.candidataDataList1 = demo
      console.log(this.candidataDataList1);

      // this.jddata.findIndex(item => item.expression == v.expression))
    }
  }
  /** 
  * Next for pre List
  */
  previousForList() {
    var index = this.count - this.jobsper_page
    if (this.currentpage == 1) {
      alert(this.languagedata.ALERTS.PREVIOUS);
    } else {
      this.currentpage = this.currentpage - 1
      // var demo = this.jddata.splice(2, this.jobsper_page);
      var count = index + this.jobsper_page
      // if (count > this.jddata.length - 1) {
      //   count = this.jddata.length
      // }
      var demo = this.candidataDataList2.slice(index, count);
      this.candidataDataList1 = demo
    }
  }
  /** 
   * Go to Particular CV List
   */
  gotopage(event) {
    console.log(event.target.value)
    var got = parseInt(event.target.value)
    if (got <= this.totalnoof_page) {
      this.currentpage = got
      var index = (this.jobsper_page * this.currentpage) - this.jobsper_page
      var count = index + this.jobsper_page
      var demo = this.candidatedata.slice(index, count);
      this.count = count
      this.jddata1 = demo
    } else {
      alert(this.languagedata.ALERTS.NOPAGE);
      console.log("no page to show")
    }
  }
  /** 
   * Go to Particular Ad List
   */
  gotopageForList(event) {
    console.log(event.target.value)
    var got = parseInt(event.target.value)
    if (got <= this.totalnoof_page) {
      this.currentpage = got
      var index = (this.jobsper_page * this.currentpage) - this.jobsper_page
      var count = index + this.jobsper_page
      var demo = this.candidataDataList2.slice(index, count);
      this.count = count
      this.candidataDataList1 = demo
    } else {
      alert(this.languagedata.ALERTS.NOPAGE);
      console.log("no page to show")
    }
  }
  /**  @param
   * Cv List
   */
  goto(value) {
    console.log(value)
    if (!value) {
      value = 1
    } else {

    }


    var index = this.jobsper_page * this.currentpage - 1
    this.currentpage = value
    var demo = this.candidatedata.slice(0, this.jobsper_page);
    this.jddata1 = demo
  }
  /** 
    * Ad List
    */
  gotoForList(value) {
    if (!value) {
      value = 1
    } else {

    }
    var index = this.jobsper_page * this.currentpage - 1
    this.currentpage = value
    var demo = this.candidataDataList2.slice(0, this.jobsper_page);
    // var demo = this.candidataDataList1.slice(0, this.jobsper_page);
    this.candidataDataList1 = demo


  }
  /** 
   * back for Return Ad List
   */
  /**
   * @ignore
   */
  back1(value) {
    console.log(value)
    // this.jobheaderstrip = true
    if (value == 'candidatelist') {
      console.log("testing")

      this.joblist = true
      this.jobheaderstrip = true
      this.jobpaginator = true
      this.candidatelistBack = true

      this.joblist1 = false
      this.pagenavigator = false
      this.jobheaderstrip1 = false
      this.joblistBack = false
      this.jobListEasyApply()


    } else {
      // this.alertmsg(value," Sorry something Went Wrong")
    }
  }

  /**
   * @ignore
   */

  set_favert(i, value) {
    console.log("JdData1", this.jddata1[i])
    var current = this.jddata1[i]
    var flag = {
      "method": "AUTH",
      "mode": "candidate",
      "id_list": [
        {
          "ad_id": current.profile_ad_id,
          "cv_id": current.profile_cv_id,
          "recruiter_id": this.recruiter_id,
          "organisation_id": "111",
          "mode": "job"
        }
      ],
     
    }

    this.http.commonMethod(flag,this.logonUrl.set_favorite)
      .subscribe(res => {
        // this.spinner.hide();
        this.response = res
        console.log("setFavourite", this.response)
        if (this.response.status == 200) {
          this.getparticular_ad_candidates(this.currentcandidateindex, "")
        } else {

        }
      })
  }
 
  /**
   * @ignore
   */


  comp(a, b) {
    // console.log(a,b)
    return new Date(b.created_date).getTime() - new Date(a.created_date).getTime();
  }
  /** 
   *  Select All Ad
   */

  docheckall() {

    console.log(this.deletedata);
    if (this.selectAll === true) {
      this.candidataDataList1.map((cid) => {
        cid.checked = true;
        // console.log(cid);
        this.deletedata.push(cid.ad_id);


      });
      console.log("Deleted Data", this.deletedata)
    } else {
      this.candidataDataList1.map((cid) => {
        cid.checked = false;
        this.deletedata = []
      });
    }

  }

  /** 
   *  Select All CV
   */

  docheckallcv() {
    console.log("Cv List", this.jddata1);
    if (this.selectAll === true) {
      this.jddata1.map((cid) => {
        cid.checked = true;
        // console.log(cid);
        this.deletedata1.push(cid.profile_cv_id);
        //  console.log(this.deletedata)

      });
      console.log("Deleted Data in Inside CV", this.deletedata1);
    } else {
      this.jddata1.map((cid) => {
        cid.checked = false;
        this.deletedata1 = []
      });
    }
  }
  /** 
   *  Getting All Easy Apply Links here by Balaji Sachin
   */
  jobListEasyApply() {
    var listJobs = {
      
      "recruiter_id": localStorage.getItem('recruiter_id'),
      "easyapply": true
    }
    this.http.commonMethod(listJobs,this.logonUrl.easy_apply_links)
      .subscribe(response => {
        this.candidataDataList = response;
        if (this.candidataDataList.status == 200) {
          this.candidataDataList1 = this.candidataDataList.res;
          this.candidataDataList2 = this.candidataDataList1
          console.log("EasyApplyLinks Data", this.candidataDataList2)
          this.getmy_data()
          this.jobheaderstrip1 = false
          this.jobheaderstrip = true

        }else{
          this.candidataDataList1 =[];
        }
      })

  }
  /** 
   * Get My Data Based on Auth Token and Calling GET_MY_DATA_COUNTERS BY Balaji Sachin
   * toCheck
   */

  getmy_data() {
    
    this.recruiter_id = localStorage.getItem('recruiter_id');
    
          var flagdata =
          {
            
            "method" :"AUTH",
            "document_type": "AD",
            "organisation_id": "111",
            "recuiter_id": this.recruiter_id,
            "cv_id": "",
            "ad_id": ""
           
          }
          this.commonloader = true;
          this.http.commonMethod(flagdata,this.logonUrl.get_my_data_counters)
            .subscribe(res => {
              this.jobheaderstrip1 = false
              this.jobheaderstrip = true

              this.response = res
              if (this.response.status == 200) {
                console.log(this.response, 'GEt my data Counters');
                if (this.response.res.message.NumberOfAds[0]) {
                  this.adlist = this.response.res.message.NumberOfAds[0].adId
                  if (this.adlist) {
                    // console.log(this.adlist,"gmdc")
                    this.getAD_data(this.adlist);

                  }
                } else {
                  this.candidataDataList1 = []
                  this.commonloader = false;
                }
              } else {
                this.commonloader = false;
              }
            })
        // }
      // })


  }
  /** 
   * Calling Get Ad_Data Functionlity Her By Balaji Sachin
   */

  getAD_data(value) {
    // console.log(value,"getaddata")
    var flagdata = {
     
      "method": "AUTH",
      "id_list": value,
      "ad_id":""
      
    }
    this.http.commonMethod(flagdata,this.logonUrl.get_ad_data)
      .subscribe(res => {
        this.jobheaderstrip1 = false
        this.jobheaderstrip = true

        // console.log(res, 'GEt CV data');
        // this.spinner.hide();
        this.response = res
        if (this.response.status == 200) {
          // console.log(this.response, "response")
          this.GetCvData = this.response.res.message
          console.log(this.GetCvData, "Get AD Data")
          this.getlistbystate(value)

          // console.log(this.jddata, "final")

        } else {

        }
      })
  }
  currentpage_c: any = 1;
  totalnoof_page_c: any;
  jobsper_page_c: any = 10;
  pageperjobs_c = ["10", "20", "50", "100"]

  /** 
   * Calling GET_LIST_BY_STATE Functionlity Her By Balaji Sachin
   */

  getlistbystate(value) {
    // console.log(value);
   
    var flagdata = {
      
      "method": "AUTH",
     "document_type": "AD",
      "state_list": "",
      "id_list": value,
      "e_recruiter": false
     
    }
    this.commonloader = true;
    this.http.commonMethod(flagdata,this.logonUrl.get_list_by_state)
      .subscribe(res => {
        this.jobheaderstrip = true
        this.jobheaderstrip1 = false

        this.response = res
        if (this.response.status == 200) {
          var newvalue = value.replace(/,/g, "','")

          newvalue = newvalue.toString()
          // console.log(newvalue)

          var data12 = {
            
            "method": "AUTH",
            "ad_id": "",
            "cv_id": "",
            "id_list":[newvalue],
            "recruiter_id": this.recruiter_id,
            "organisation_id": "111",
            "mode": "job"
          }
          this.http.commonMethod(data12,this.logonUrl.get_favorite)
            .subscribe(res => {
              // this.spinner.hide();
              this.response12 = res
              if (this.response12.status == 200) {
                // this.AD_setstate()
                this.faveritedata = this.response12.res.message.job.favorite
                console.log(this.faveritedata, "FAVORITE data")
                this.commonloader = false;
                this.counters = this.response.res.message
                console.log(this.counters, 'GETLISTBYSTATE');
                for (var i = 0; i < this.GetCvData.length; i++) {
                  this.GetCvData[i].newcount = 0
                  this.GetCvData[i].markerdcount = 0
                  this.GetCvData[i].appyledcount = 0
                  for (var j = 0; j < this.counters.length; j++) {
                    if (this.GetCvData[i].profile_ad_id == this.counters[j].profile_ad_id) {
                      if (this.counters[j].state == '70') {
                        this.GetCvData[i].newcount++
                      }
                      if (this.counters[j].state == '200710') {
                        this.GetCvData[i].markerdcount++
                      }
                      if (this.counters[j].state == '70' || this.counters[j].state == '200071' || this.counters[j].state == '200710' || this.counters[j].state == '200072' || this.counters[j].state == '200074') {
                        this.GetCvData[i].appyledcount++
                      }
                    }
                  }
                  this.finalGetCvData = this.GetCvData;
                  // this.totalnoof_page = this.jddata.length / this.jobsper_page
                  // this.gotoForList(1)

                }
                console.log("GetList and GetADData",this.finalGetCvData)
                // console.log("1111",this.candidataDataList2)
                var keys = Object.keys(this.faveritedata);
                var len = keys

                for (var i = 0; i < this.finalGetCvData.length; i++) {
                  this.finalGetCvData[i].markerdcount = 0
                  for (var j = 0; j < len.length; j++) {
                    // console.log(this.jddata[i].profile_ad_id, this.faveritedata[len[j]])
                    if (this.finalGetCvData[i].profile_ad_id == len[j]) {
                      this.finalGetCvData[i].markerdcount = this.faveritedata[len[j]].length
                      this.finalGetCvData[i].markedvalue = this.faveritedata[len[j]]
                      // console.log(this.jddata[i], "jd")
                    }
                  }

                }

                for (var i = 0; i < this.finalGetCvData.length; i++) {

                  for (var j = 0; j < this.candidataDataList2.length; j++) {
                    if (this.candidataDataList2[j].ad_id == this.finalGetCvData[i].profile_ad_id) {
                      this.candidataDataList2[j].newcount = this.finalGetCvData[i].newcount
                      this.candidataDataList2[j].markerdcount = this.finalGetCvData[i].markerdcount
                      this.candidataDataList2[j].appyledcount = this.finalGetCvData[i].appyledcount

                      //for Get Current Adid Data
                      this.candidataDataList2[j].country = this.finalGetCvData[i].country
                      this.candidataDataList2[j].location = this.finalGetCvData[i].location

                      this.candidataDataList2[j].title = this.finalGetCvData[i].title
                      this.candidataDataList2[j].cust_id = this.finalGetCvData[i].cust_id
                      this.candidataDataList2[j].zip = this.finalGetCvData[i].zip
                    }
                  }

                }
                console.log("Final List of Admin",this.candidataDataList2)
                this.candidataDataList2 = this.candidataDataList2.sort(this.comp);
                this.candidataDataList1 = this.candidataDataList2;
                console.log("Count List", this.candidataDataList1.length)
                this.totalnoof_page = this.candidataDataList2.length / this.jobsper_page
                this.totalnoof_page = Math.ceil(this.totalnoof_page)
                this.gotoForList(1)
                this.commonloader = false;


              }
            })
        } else {
          this.alertmsg(this.response.message, 'error')
        }
      })

  }
  redirectUrl = false
  /** param
   * GetParticular Candidate
   */
  /**
   * @ignore
   */
  getparticular_ad_candidates(i, option) {
    // console.log(i.ad_id)

    var flagdata;
    if (this.redirectUrl) {
      // this.currentcandidateindex = i
      this.commonloader = true;
      this.currentcandidate_adid = i.ad_id
      this.currentjddata = i

      flagdata = {
       
      "method": "AUTH",
       "document_type": "AD",
      "state_list": "",
      "id_list": i.ad_id,
      "e_recruiter": false
     
      }
    } else {
      console.log("Particular Candidate List", this.candidataDataList1)
      this.currentcandidateindex = i
      this.commonloader = true;
      this.currentcandidate_adid = this.candidataDataList1[i].ad_id
      this.currentjddata = this.candidataDataList1[i]
      console.log("CUrrent JdData",this.currentjddata);
      flagdata = {
        
        "method": "AUTH",
        "document_type": "AD",
        "state_list": "",
        "id_list": this.currentcandidate_adid,
        "e_recruiter": false
      }
    }

    if (option == 'appyledcount') {

    } else if (option == 'markerdcount') {

     
    } else if (option == 'newcount') {

    }
    this.http.commonMethod(flagdata,this.logonUrl.get_list_by_state)
      .subscribe(res => {
        console.log(res, 'GETLISTBYSTATE');
        this.response = res
        if (this.response.status == 200) {
          this.redirectUrl = false
          this.candidatedata = this.response.res.message
          // console.log(this.candidatedata, "candidate")
          for (var i = 0; i < this.candidatedata.length; i++) {
            // if (this.currentjddata.blue_collor) {
            //   this.candidatedata[i].blue_collor = this.currentjddata.blue_collor
            // }
            if (this.candidatedata[i].keywords) {
              this.candidatedata[i].keywords = this.candidatedata[i].keywords_cv
              // this.candidatedata[i].keywordshead = this.candidatedata[i].keywords
              this.candidatedata[i].keywordshead = this.candidatedata[i].keywords.split("/")[0];
            }
          }
          if (this.candidatedata) {
            var data = JSON.stringify(this.candidatedata)
            this.AD_setstate(JSON.parse(data));
            this.commonloader = false;

            // var current = this.candidataDataList2[0]
            var data1 = {
              
              "method": "AUTH",
              "ad_id": this.currentcandidate_adid,
              "cv_id": "",
              "recruiter_id": this.recruiter_id,
              "organisation_id": "111",
              "mode": "job"
            }
            this.http.commonMethod(data1,this.logonUrl.get_favorite)
              .subscribe(res => {
                // this.spinner.hide();
                this.response = res
                if (this.response.status == 200) {
                  console.log(this.response, 'GET_FAVORITE');
                  // this.AD_setstate()
                  var dd = this.response.res.message.job.favorite[this.recruiter_id]
                  if (dd) {
                    for (var i = 0; i < dd.length; i++) {
                      for (var j = 0; j < this.candidatedata.length; j++) {
                        if (this.candidatedata[j].profile_cv_id == dd[i]) {
                          this.candidatedata[j].favorite = true
                        }
                      }
                    }
                  }
                  if (option == 'markerdcount') {
                    var demo = []
                    for (var k = 0; k < this.candidatedata.length; k++) {
                      if (this.candidatedata[k].favorite) {
                        demo.push(this.candidatedata[k])
                      }
                    }
                    this.candidatedata = demo
                  } else if (option == 'newcount') {
                    var demo = []
                    for (var k = 0; k < this.candidatedata.length; k++) {
                      if (this.candidatedata[k].state == '70') {
                        demo.push(this.candidatedata[k])
                      }
                    }
                    this.candidatedata = demo
                  }

                  this.jddata1 = this.candidatedata;
                  console.log("Final Get Own Candidate Data", this.jddata1);
                  this.totalnoof_page = this.candidatedata.length / this.jobsper_page
                  this.totalnoof_page = Math.ceil(this.totalnoof_page)
                  this.goto(1);
                  this.commonloader = false;
                  // console.log(this.candidatedata1)
                } else {

                }
              })


          }
        } else {

        }
      })
    // console.log(this.candidatedata, "candidatedata")

  }
  /**
   * @ignore
   */
  AD_setstate(data) {
    console.log("Set State Data", data)
    var current = data.pop()
    if (current) {
      if (current.state == 200710 || current.state == "200710") {
        if (data.length > 0) {
          this.AD_setstate(data)
        }
      } else {
        
        var flagdata = {
          
          "method": "AUTH",
          "cv_id": current.profile_cv_id,
          "ad_id": current.profile_ad_id,
          "state_list": [
            {
              "cv_id": current.profile_cv_id,
              "ad_id": current.profile_ad_id,
              "state": 200071
            }
          ],
          
        }
        this.http.commonMethod(flagdata,this.logonUrl.set_state)
          .subscribe(res => {
            // this.spinner.hide();
            this.response = res
            if (this.response.status == 200) {
              // console.log(this.response, 'setstate res');
              if (data.length > 0) {
                this.AD_setstate(data)
              } else {

              }
            } else {

            }
          })
      }
    }


  }
  /**@param
   * Deleted Starts Here
   */

  addtodelete(i, event) {
    console.log("CandidateList for Delete", this.candidataDataList1)

    console.log(this.deletedata, "this.deletedata")
    this.newdata = []
    if (event.checked) {
      this.deletedata.push(this.candidataDataList1[i].ad_id);
      console.log("cvid", this.deletedata);
    } else {
      if (this.deletedata.length >= 1) {
        var index = this.deletedata.indexOf(this.candidataDataList1[i].ad_id);
        if (index > -1) {
          this.deletedata.splice(index, 1);
        }
        // this.deletedata = this.newdata
      }
    }
    // console.log(this.deletedata, "DeletedID")
  }
  addtodeletecv(i, event) {
    console.log(this.deletedata1, "this.deletedata1")
    this.newdata = []
    if (event.checked) {
      this.deletedata1.push(this.jddata1[i].profile_cv_id);
    } else {
      if (this.deletedata1.length >= 1) {
        var index = this.deletedata1.indexOf(this.jddata1[i].profile_cv_id);
        if (index > -1) {
          this.deletedata1.splice(index, 1);
        }
        console.log("Checked Data", this.deletedata1)
      }
    }
    // console.log(this.deletedata1, "DeletedID")
  }
  /**
   * @ignore
   */

  savePageData: any
  savePage(data) {
    console.log("checkData", this.deletedata1)
    console.log("Particular Candidate Data", data)
    console.log("Particalur Adid", this.currentcandidate_adid)
    if (this.deletedata1.length != 0) {
      var cvList = [];
      for (var i = 0; i < this.deletedata1.length; i++) {
        cvList.push(this.deletedata1[i])
      }
      console.log("CvList", cvList)

      var savePage;
     
      savePage =
        {
          "method": "AUTH",
          "custom_id": "548",
          "recruiter_id": this.recruiter_id,
          "ad_id": this.currentcandidate_adid,
          "cv_id_list": cvList
        }
      console.log(savePage)
      this.http.commonMethod(savePage,this.logonUrl.get_talent_csv)
        .subscribe(response => {
          console.log("Save Page", response);
          this.savePageData = response;
          var decodedString=  atob(this.savePageData.res.message.base_64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('');
          // console.log(decodedString);
          this.download("sample.csv", decodedString);
          this.deletedata1 = [];
        })
    }
    else {
      // this.alertmsg("please Select CheckBox","error");
      this.alertmsg(this.languagedata.ALERTS.CHECKBOX_ALERT, 'error');
    }
  }
  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv; charset=utf-8,' + text);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    this.getparticular_ad_candidates(this.currentcandidateindex, "")
    // this.getmy_data()
  }
  /** 
   * Deleted for Ad Value By Balaji
   */
  /**
   * @ignore
   */

  
  deleteJob() {
    this.commonloader = true;
    console.log("delete JobList", this.deletedata);
    var flagdata = {
     
      "method":"AUTH",
      "ad_id_list": this.deletedata,
      "document_type": "AD",
      "delete_list": [
        "READ",
        "PROFILE",
        "MATCH",
        "FILES"
      ]
     
    }
    console.log(flagdata);
    this.http.commonMethod(flagdata,this.logonUrl.delete_values)
      .subscribe(res => {
        console.log(res, 'delte values Ad Value');

        this.response = res
        if (this.response.status == 200) {
          this.jobListEasyApply()
          this.commonloader = false;
          this.deletedata = []
          this.selectAll = false

        } else {
          this.alertmsg(this.response.message, 'error')
          this.commonloader = false;
        }
      })
  }
  /** 
   * Deleted for Particular Candidates By Balaji
   */
  deleteJobcv() {
    this.commonloader = true;
    console.log("delete Particular Candidates", this.deletedata1);
    console.log("GetList BY State Calling", this.currentcandidateindex)
    var flagdata = {
     
      "method":"AUTH",
      "cv_id_list":  this.deletedata1,
     
      "document_type": "CV",
      "delete_list": [
        "READ",
        "PROFILE",
        "MATCH",
        "FILES"
      ]
      
    }

    console.log(flagdata);
    this.http.commonMethod(flagdata,this.logonUrl.delete_values)
      .subscribe(res => {
        console.log(res, 'delte values for CV');
        this.response = res
        if (this.response.status == 200) {

          // this.back1('candidatelist')
          this.commonloader = false;
          this.deletedata1 = []
          this.selectAll = false

          this.getparticular_ad_candidates(this.currentcandidateindex,"")
        } else {
          this.alertmsg(this.response.message, 'error')
          this.commonloader = false;
        }
      })
  }

  HideMoreBtnKeywords = true
  currentIndex: any;
  /**
   * @ignore
   */

  showFullKeywords(i, data) {
    this.currentIndex = i;
    this.HideMoreBtnKeywords = false
  }
  /**
   * @ignore
   */

  showhalfKeywords(i, data) {
    this.currentIndex = i;
    this.HideMoreBtnKeywords = true
  }
  /**
   * @ignore
   */

  logout() {
    var data = {
     
      "email": this.userEmail,
      "sessionid": this.sessionid,
      
    }
    this.http.commonMethod(data,this.logonUrl.logout)
      .subscribe(data => {
        if (data != null) {
          var details: any = data;
          if (details.status == 200) {
            // this.MailMessage=details.message;
            localStorage.setItem("isLogged", "false")
            localStorage.setItem("userEmail", "")
            localStorage.setItem("sessionid", "");
            this.alertmsg(details.message, 'success');
            this.menuFlag = true;
            this.router.navigate(['/']);
          } else {
            if (details.message === "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.") {
              this.alertmsg(details.message, 'error')
              this.menuFlag = false;
              localStorage.setItem("isLogged", "false")
              localStorage.setItem("userEmail", "")
              localStorage.setItem("sessionid", "");
              this.menuFlag = true;
            } else {

              this.alertmsg(details.message, 'error')
              this.menuFlag = false;
            }
          }
          this.sidenav.close();
        }

        else {

        }
      }, error => {

      });
  }
  /** 
   * CockPit Navigation
   */

  back() {
    this.router.navigate(['/cockpit']);
  }
}
export interface Food {
  value: string;
  viewValue: string;
}